import Guid from 'common/util/guid';
import ServicePeriod from './servicePeriod';

export default class ClientHistory {
    memberId!: number;

    forename!: string;

    surname!: string;

    text!: string;

    type!: string;

    typeDescription!: string;

    servicePeriodId!: number;

    memberDataHistory!: {
        mebmerDataHistoryId: Guid;
        dataType: string;
        dataTypeDescription: string;
        servicePeriod: ServicePeriod;
        bulkBatchId?: Guid;
    };

    bulkBatchId!: Guid;

    updated!: Date;

    updatedBy!: Guid;
}
