import Guid from 'common/util/guid';
import axios, { AxiosResponse } from '~/axios';

axios.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status === 200) {
            return Promise.resolve(response);
        }
        return Promise.reject();
    },
    (error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
            window.localStorage.clear();
            window.location.href = '/';
        }
        console.log(error);
        if (error.response) {
            error.response.id = Guid.newGuid();
            return Promise.reject(error.response);
        }
        error.id = Guid.newGuid();
        return Promise.reject(error);
    },
);
