// eslint-disable-next-line max-classes-per-file
import Guid from 'common/util/guid';

export class MemberSearchResultServicePeriod {
    servicePeriodId!: number;

    status!: string;

    statusDate!: string;

    statusDescription!: string;
}

export default class MemberSearchResult {
    systemMemberId!: Guid;

    memberId!: number;

    forenames!: string;

    surname!: string;

    dateBirth!: string;

    nino!: string;

    servicePeriods!: Array<MemberSearchResultServicePeriod>;

    verifiedStatus!: string;
}
