import ClientCalendar from '@/models/clientCalendar';
import ClientContext from '@/models/clientContext';
import ClientStats from '@/models/clientMemberStats';
import DataUpdateStats from '@/models/dataUpdateStats';
import MemberDocumentStats from '@/models/memberDocumentStats';
import FinancialData from '@/models/financialData';
import GovernanceInformation from '@/models/governanceInformation';
import JobDateStats, { JobDateStat } from '@/models/jobDateStats';
import MemberDemographics from '@/models/memberDemographics';
import MemberFeedbackScores from '@/models/memberFeedbackScores';
import MemberInteractions from '@/models/memberInteractions';
import MemberLogins from '@/models/memberLogins';
import MemberMovements from '@/models/memberMovements';
import MemberRegistrations from '@/models/memberRegistrations';
import ModellerStats from '@/models/modellerStats';
import PageVisits from '@/models/pageVisits';
import TransferStats from '@/models/transferStats';
import WebRegistrations from '@/models/webRegistrations';
import Http from 'common/api/apiHelper';
import Client from 'common/models/client';
import Guid from 'common/util/guid';

const apiVersion = 'v1.0';
export default class ClientsApi {
    static getClients () {
        return Http.getAsArray(`${apiVersion}/client/`, Client);
    }

    static getClient (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}`, Client);
    }

    static getClientContext (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/context`, ClientContext);
    }

    static getClientStatistics (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/statistics`, ClientStats);
    }

    static getMemberDemographics (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/demographics`, MemberDemographics);
    }

    static getCompleteJobs (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/statistic/job?from=${from}&to=${to}&type=Complete&granularity=${granularity}`, JobDateStats);
    }

    static getCurrentInProgressJobs (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/inprogressjobs/`, JobDateStat);
    }

    static getInProgressJobs (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/statistic/job?from=${from}&to=${to}&type=InProgress&granularity=${granularity}`, JobDateStats);
    }

    static getMemberInteractions (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/memberinteractions?from=${from}&to=${to}&granularity=${granularity}`, MemberInteractions);
    }

    static getClientCalendar (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/clientCalendar`, ClientCalendar);
    }

    static getWebRegistrations (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/webregistrations?from=${from}&to=${to}&granularity=${granularity}`, WebRegistrations);
    }

    static getMembershipMovements (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/membershipmovements?from=${from}&to=${to}&granularity=${granularity}`, MemberMovements);
    }

    static getTranferStatistics (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/statistic/transfer?from=${from}&to=${to}&granularity=${granularity}`, TransferStats);
    }

    static getPostcodes () {
        return Http.get('/postcodes.csv', String, {
            useBasePath: false,
        });
    }

    static getGovernanceInformation (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/governanceInformation`, GovernanceInformation);
    }

    static getMemberFeedback (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/feedback`, MemberFeedbackScores);
    }

    static getMemberRegistrations (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/web/statistics/registrations?from=${from}&to=${to}&granularity=${granularity}`, MemberRegistrations);
    }

    static getMemberLogins (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/web/statistics/logins?from=${from}&to=${to}&granularity=${granularity}`, MemberLogins);
    }

    static getPageVisits (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/web/statistics/pagevisits?from=${from}&to=${to}&granularity=${granularity}`, PageVisits);
    }

    static getModellerStats (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/web/statistics/modeller?from=${from}&to=${to}&granularity=${granularity}`, ModellerStats);
    }

    static getDataUpdateStats (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/web/statistics/dataupdates?from=${from}&to=${to}&granularity=${granularity}`, DataUpdateStats);
    }

    static getMemberDocumentStats (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/web/statistics/documents?from=${from}&to=${to}&granularity=${granularity}`, MemberDocumentStats);
    }

    static getFinancials (clientId: Guid, from: string, to: string, granularity: 'Weekly' | 'Monthly') {
        return Http.get(`${apiVersion}/client/${clientId}/financials?from=${from}&to=${to}&granularity=${granularity}`, FinancialData);
    }

    static getDocuments (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/document`);
    }

    static getDocument (clientId: Guid, name: string) {
        return Http.get(`${apiVersion}/client/${clientId}/document/url?name=${name}`);
    }

    static wakePrintApi () {
        return Http.get('https://printpdf.lcp.uk.com/api/wake', undefined, { useBasePath: false, useToken: false });
    }
}
