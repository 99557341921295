import Guid from 'common/util/guid';
import { MemberDataTypeFamily } from './memberDataTypeFamily';

export default class Client {
    clientId!: Guid;

    name!: string;

    fullName!: string;

    shortName!: string;

    code!: string;

    teamId!: Guid;

    teamName!: string;

    workManagementReportFolderUrl?: string;

    memberDataReportFolderUrl?: string;

    memberDataTemplateFolderUrl?: string;

    memberDataToLoadFolderUrl?: string;

    memberDataExportFolderUrl?: string;

    teamIsReferral!: boolean;

    hasMemberWeb?: boolean;

    workManagement!: boolean;

    areConfigIdsReadOnly!: boolean;

    isLive?: boolean;

    sharepointSiteId?: string;

    hasClientWeb?: boolean;

    hasWorkManagement?: boolean;

    hasFollowUpPane?: boolean;

    timeRecordingClient?: string;

    liveTeamId?: string;

    dataLoadEngineMemberDataTypeFamily!: MemberDataTypeFamily;

    managementTeamId!: Guid;

    securityTeamId!: Guid;

    teamClientEmail?: string;

    bulkEmailAddress?: string;
}
