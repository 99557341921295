
import { Component, Vue, Prop } from '~/vue-property-decorator';
import moment from '~/moment';
import * as STORE from 'common/store/storepaths';

@Component({
    components: { },
})
export default class Scheduler extends Vue {
    @Prop()
    value!: Date | null;

    scheduledRunNow = true;

    scheduledRunNowChanged() {
        if (!this.scheduledRunNow) {
            this.$emit('input', moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'));
        } else {
            this.$emit('input', null);
        }
    }

    emitValue(val: string) {
        const scheduledTime = this.scheduledRunNow ? null : moment(val, 'YYYY/MM/DD HH:mm:ssZ').utc().format('YYYY-MM-DDTHH:mm:ssZ');
        this.$emit('input', scheduledTime);
    }

    get formattedDate() {
        return moment(this.value).format('YYYY-MM-DD HH:mm');
    }

    get schedulerEnabled (): boolean {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION]['feature.md.scheduledbatchjobs.enabled'] === 'true';
    }
}

