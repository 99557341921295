import Guid from 'common/util/guid';
import { ClassComponent } from '~/@lcp/dynamic-ui-typescript';
import DataValidation from './dataValidation';

export default class DataType {
    dataType!: string;

    current!: {
        dynamicUiDefinition: ClassComponent;
        value: unknown;
    }

    lastVerified?: {
        dynamicUiDefinition: ClassComponent;
        value: unknown;
    }

    memberDataId!: Guid;

    validationResults!: Array<DataValidation>;

    isLiveUpdating!: boolean;

    isDeleted!: boolean;
}
