import webformsApi from 'common/api/webformsApi';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface WebformsState {
    formTypes: Array<unknown>;
    formCollections: Array<unknown>;
    formEmailTemplates: Array<unknown>;
    formStatuses: Record<number, string>;
}
export const state: WebformsState = {
    formTypes: [],
    formCollections: [],
    formEmailTemplates: [],
    formStatuses: {
        0: 'Not started',
        1: 'In progress',
        2: 'Complete',
    },
};

export const getters: GetterTree<WebformsState, unknown> = {
    formTypes: (s) => s.formTypes,
    formStatuses: (s) => s.formStatuses,
    formCollections: (s) => s.formCollections,
    formEmailTemplates: (s) => s.formEmailTemplates,
};

export const actions: ActionTree<WebformsState, unknown> = {
    getFormTypes ({ commit }, templateUrl) {
        webformsApi.getWebFormList(templateUrl).then((result) => {
            commit('setFormTypes', result.forms);
            commit('setFormCollections', result.formCollections);
            commit('setFormEmailTemplates', result.emailTemplates);
        });
    },
};

export const mutations: MutationTree<WebformsState> = {
    setFormTypes (s, data) {
        s.formTypes = data;
    },
    setFormCollections (s, data) {
        s.formCollections = data;
    },
    setFormEmailTemplates (s, data) {
        s.formEmailTemplates = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
