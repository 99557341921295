import { render, staticRenderFns } from "./addressComponent.vue?vue&type=template&id=23a5d082&scoped=true&"
import script from "./addressComponent.vue?vue&type=script&lang=ts&"
export * from "./addressComponent.vue?vue&type=script&lang=ts&"
import style0 from "./addressComponent.vue?vue&type=style&index=0&id=23a5d082&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../ClientWeb/FrontEnd/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a5d082",
  null
  
)

export default component.exports