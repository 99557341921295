import Guid from 'common/util/guid';

export default class User {
    userId!: Guid;

    forenames!: string;

    surname!: string;

    lcpCode!: string;

    department!: string;

    email!: string;

    added!: Date;

    removed!: Date;
}
