import { IDeathContactDetails } from './deathContactDetails';

export default class DeathSpouseOrCivilPartnerContactDetails implements IDeathContactDetails {
    forenames = '';

    surname = '';

    title = '';

    email = '';

    addressLine1 = '';

    addressLine2 = '';

    addressLine3 = '';

    addressLine4 = '';

    addressPostCode = '';

    addressCountry = '';

    telephoneNumber = '';

    contactPreference = '';
}
