
/* eslint-disable no-restricted-globals */

import documentsApi from 'common/api/documentsApi';
import cloneDeep from 'common/util/cloneDeep';
import Status from 'common/util/pulseStatusEnum';
import * as STORE from 'common/store/storepaths';
import Guid from 'common/util/guid';
import Document from 'common/models/correspondence/document';
import DocumentCatgeory from 'common/models/correspondence/documentCategory';
import Client from 'common/models/client';
import User from 'common/models/user';
import JobSummary from 'common/models/jobSummary';
import moment from '~/moment';
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';

@Component({
    components: { },
})
export default class EditDocument extends Vue {
    @Prop()
    disableRevert!: boolean;

    @Prop()
    docId!: string;

    @Prop({ default: () => [] })
    existingFilenames!: Array<string>;

    @Prop()
    documentMemberId!: number;

    @Prop()
    canDuplicateFinalised!: boolean;

    loading = true;

    loadingVersionHistory = true;

    loadingPreview = false;

    previewUrl: string | null = null;

    documentEdited = new Document();

    documentPreEdit = new Document();

    showFinalConfirmation = false;

    showDuplicateFinalised = false;

    showRevertToDraftDialog = false;

    newDocumentName = "";

    documentCategories: Array<DocumentCatgeory> = [];

    showJobAssign = false;

    newJobId: Guid | null = null;

    showEffectiveDateChangeNotification = false;

    today = moment(new Date()).format('DD/MM/YYYY');

    jobs: Array<JobSummary> = [];

    async mounted () {
        if (!this.clients?.length) {
            await this.$store.dispatch('client/getUserClients');
        }
        if (this.currentClient && !this.storeCurrentClient) {
            await this.$store.dispatch(STORE.SET_CURRENT_CLIENT, this.currentClient);
        }
        if (!this.users?.length) {
            this.$store.dispatch('users/getAllUsers', this.apiUrl);
        }
        if (this.docId) {
            await this.getDocument();
        }

        this.getCategories();
    }

    get fileExtension () {
        const arr = this.documentPreEdit?.fileName?.split('.') ?? [];
        return arr[arr.length - 1];
    }

    get draftIcon () {
        switch (this.fileExtension) {
            case 'doc':
            case 'docx':
            case 'docm':
                return 'file-word';
            case 'xls':
            case 'xlsx':
            case 'xlsm':
                return 'file-excel';
            case 'pdf':
                return 'file-pdf';
            case 'txt':
                return 'file-alt';
            case 'msg':
                return 'envelope';
            case 'png':
            case 'bmp':
            case 'jpeg':
            case 'jpg':
            case 'gif':
            case 'tif':
                return 'image';
            default:
                return 'file';
        }
    }

    get apiUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION][STORE.PULSE_API_URL] ?? '';
    }

    get canRename () {
        return !this.previewUrl || this.previewUrl.indexOf('sharepoint.com') === -1;
    }

    get usersById () {
        return this.$store.getters[STORE.GET_ALL_USERS_BY_ID] as Record<string, User>;
    }

    get users () {
        return this.$store.getters[STORE.GET_ALL_USERS] as Array<User> | undefined;
    }

    get isIframed () {
        return this.$route?.query?.hideIntro;
    }

    get filteredCategories () {
        const categories = this.documentCategories.map((category) => ({
            name: category.name,
            id: category.id,
            isUser: category.isUser,
            isScanning: category.isScanning,
            isEmail: category.isEmail,
            disabled: !category.isEnabled,
        }));
        return categories.filter((a) => {
            if (this.documentPreEdit?.source.isUser) return a.isUser;
            if (this.documentPreEdit?.source.isScanning) return a.isScanning;
            if (this.documentPreEdit?.source.isEmail) return a.isEmail;
            return true;
        });
    }

    get isClientDoc () {
        return this.itemDetails?.length && this.itemDetails[1].startsWith('J') || this.itemDetails.length === 2;
    }

    get availableJobs () {
        if (this.jobs) return this.jobs;
        return [];
    }

    get systemMemberId () {
        return this.documentPreEdit.memberId;
    }

    get memberData () {
        const memberData = this.$store.getters['memberData/memberData'](this.clientName, this.memberId);
        return memberData;
    }

    get job () {
        const jobs = this.documentEdited.jobs;
        if (jobs && jobs.length > 0) {
            return this.availableJobs.find((a) => a.jobId === jobs![0].jobId);
        }
        return null;
    }

    get linkUrl () {
        let docId;
        if (this.memberId) {
            docId = this.memberId;
        } else if (this.job?.reference) {
            docId = this.job.reference;
        } else {
            docId = '';
        }

        return `${this.hostUrl}/docs/${this.currentClient?.code}-${docId}-${this.documentEdited.sequence}?hideIntro=true`;
    }

    get documentId () {
        return this.documentPreEdit?.documentId;
    }

    get reversedVersionHistory () {
        return [...this.versionHistory].sort((a, b) => (a.updated < b.updated ? 1 : -1));
    }

    get versionHistory () {
        return this.documentPreEdit?.history || [];
    }

    get createdBy () {
        return this.versionHistory?.find((a) => a.type === 'Created')?.updatedBy;
    }

    get finalisedBy () {
        return [...this.versionHistory].reverse().find((a) => a.type === 'SetToFinal')?.updatedBy;
    }

    get onlineBy () {
        return [...this.versionHistory].reverse().find((a) => a.type === 'MadeAvailableOnline')?.updatedBy;
    }

    get isProduction () {
        return this.environment === 'live';
    }

    get hasWorkmanagement () {
        return this.clients.find((a) => a.name === this.clientName)?.workManagement;
    }

    get environment () { return process.env.VUE_APP_ENVIRONMENT; }

    get userInfo () {
        return this.$store.getters['auth/userInfo'];
    }

    get hostUrl () {
        return `${window.location.protocol}//${window.location.host}`;
    }

    get workingDocument () {
        return this.documentEdited.canEdit;
    }

    get fileDetailsChanged () {
        return this.documentPreEdit.category !== this.documentEdited.category
                  || this.documentPreEdit.name !== this.documentEdited.name
                  || moment(this.documentPreEdit.effectiveDate).format('DD MM YYYY') !== moment(this.documentEdited.effectiveDate).format('DD MM YYYY');
    }

    get invalidFileDetailsMessage () {
        if (!this.documentEdited || !this.documentEdited.name) return null;
        if (this.documentEdited.name?.toUpperCase() === this.documentPreEdit.name?.toUpperCase()) return null;
        const invalidCharacters = [':', '?', '!', '<', '>', '/', '\\', '*', '"', '|'];
        for (let i = 0; i < invalidCharacters.length; i += 1) {
            if (this.documentEdited.name.includes(invalidCharacters[i])) return `Invalid character ${invalidCharacters[i]}`;
        }

        if (this.existingFileNamesUpperCase.indexOf(this.documentEdited.name?.toUpperCase()) > -1) return 'A document with this name already exists';

        return null;
    }

    get existingFileNamesUpperCase () {
        return this.existingFilenames.map((t) => t?.toUpperCase());
    }

    get itemDetails () {
        return this.docId.split('-');
    }

    get editingAvailable () {
        return this.documentEdited.status !== 'Online' && this.documentEdited.status !== 'Withdrawn';
    }

    get onlineAvailable () {
        const category = this.documentCategories.find((t) => t.name === this.documentPreEdit.categoryName);
        if (!category) return false;
        return category.isAvailableOnline && this.documentPreEdit.fileType.isAvailableOnline;
    }

    get userCanMakeOnline () {
        return this.finalisedBy !== this.userInfo.oid;
    }

    get currentClient () {
        if (this.clients && this.itemDetails.length) {
            const id = this.itemDetails[0];
            const found = this.clients.find((a) => a.code === id);
            if (found) return found;
        }
        return null;
    }

    get storeCurrentClient () {
        return this.$store.getters[STORE.GET_CURRENT_CLIENT];
    }

    get clients () {
        return this.$store.getters[STORE.GET_CLIENTS] as Array<Client>;
    }

    get clientId () {
        return this.currentClient?.clientId;
    }

    get clientName () {
        return this.currentClient?.name;
    }

    get memberId () {
        if (this.itemDetails.length) {
            if (this.itemDetails[1].startsWith('J')) {
                return null;
            }
            if (this.itemDetails.length <= 2 && !this.documentMemberId) {
                return null;
            }
            return Number(this.itemDetails[1]);
        }
        return null;
    }

    get docReference () {
        return this.itemDetails[this.itemDetails.length - 1];
    }

    get documentStatus () {
        return this.documentEdited.status;
    }

    get jobPrefix () {
        return `J-${this.currentClient?.code}-`;
    }

    get isValidExcelDocumentExtension () {
        return this.documentPreEdit.fileType.extension === 'xlsx'
            || this.documentPreEdit.fileType.extension === 'xls'
            || this.documentPreEdit.fileType.extension === 'xlsm'
            || this.documentPreEdit.fileType.extension === 'xlsb';
    }

    getColor (status: string) {
        const color = Status.getColour(status);
        return color;
    }

    getIcon (status: string) {
        const icon = Status.getIcon(status);
        return icon;
    }

    getName (status: string) {
        const name = Status.getName(status);
        return name;
    }

    gotoCalc (calcId: string) {
        if (!calcId) return;
        window.open(`/${this.currentClient?.name}/memberData/${this.memberId}/calculationHistory/${calcId}`, '_blank');
    }

    async getDocument () {
        if (!this.clientId) return;
        if (this.memberId) {
            await documentsApi.getMemberFileDetails(this.clientId, this.docReference, this.memberId, this.apiUrl).then(async (result) => {
                this.initialiseForDocument(result);
                this.loading = true;
                this.findJob(result);
                if (this.newJobId && this.systemMemberId) {
                    await this.getJob(this.newJobId);
                }
                this.performParentMessage();
                this.loading = false;
            }).finally(() => {
                this.loading = false;
            });
        } else {
            await documentsApi.getFileDetails(this.clientId, this.docReference, this.apiUrl).then(async (result) => {
                this.initialiseForDocument(result);
                this.loading = true;
                this.findJob(result);
                if (this.newJobId) {
                    await this.getJob(this.newJobId);
                }
                this.performParentMessage();
                this.loading = false;
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    findJob (document: Document) {
        document.jobId = document.jobs?.length ? document.jobs[0].jobId : null;
        this.newJobId = document.jobId;
    }

    async getJob (jobId: Guid) {
        this.jobs = [await this.$store.dispatch('jobs/getJob', { jobId, pulseApiUrl: this.apiUrl }) ];
    }

    initialiseForDocument (document: Document) {
        this.documentEdited = cloneDeep(document);
        this.documentPreEdit = cloneDeep(document);
        Vue.set(this.documentEdited, 'category', this.documentEdited.categoryName);
        Vue.set(this.documentPreEdit, 'category', this.documentPreEdit.categoryName);
        Vue.set(this.documentEdited, 'effectiveDate', this.documentEdited.effectiveDate);
        Vue.set(this.documentPreEdit, 'effectiveDate', this.documentPreEdit.effectiveDate);

        this.getPreview();
    }

    performParentMessage () {
        if (parent) {
            parent.postMessage('loaded', '*');
        }
    }

    makeFinal () {
        this.loading = true;
        this.showFinalConfirmation = false;
        if (!this.clientId) return;
        documentsApi.makeFinal(this.clientId, this.documentId, this.apiUrl).then((result: Document) => {
            if (this.documentPreEdit.effectiveDate < result.effectiveDate) {
                this.showEffectiveDateChangeNotification = true;
            }
            this.getDocument();
            this.loading = false;
            this.reloadParentWindow();
        }).finally(() => {
            this.loading = false;
        });
    }

    reloadParentWindow () {
        if (parent) {
            parent.postMessage('reload', '*');
            this.$emit('documentChanged', this.documentEdited);
        }
    }

    duplicateFinalisedDocument () {
        this.loading = true;
        if (!this.clientId) {
            return
        }
        documentsApi.duplicate(this.clientId, this.documentId, this.newDocumentName, this.apiUrl).then((result) => {
            this.loading = false;
            if (this.isIframed) {
                window.location.href = `${this.itemDetails[0]}-${this.itemDetails[1]}-${result.sequence}?hideIntro=true`;
            }
            this.documentEdited = result;
            this.documentPreEdit = cloneDeep(result);
            this.reloadParentWindow();
            this.$emit('select', result);
            this.$emit('close');
            this.showRevertToDraftDialog = false;
        }).finally(() => {
            this.loading = false;
            this.newDocumentName = "";
            this.showDuplicateFinalised = false;
        });
    }

    revertToDraft () {
        this.loading = true;
        if (!this.clientId) return;
        const request = { effectiveDate: this.documentEdited.effectiveDate };
        documentsApi.revertToDraft(this.clientId, this.documentId, request, this.apiUrl).then((result) => {
            this.loading = false;
            if (this.isIframed) {
                window.location.href = `${this.itemDetails[0]}-${this.itemDetails[1]}-${result.sequence}?hideIntro=true`;
            }
            this.documentEdited = result;
            this.documentPreEdit = cloneDeep(result);
            this.reloadParentWindow();
            this.$emit('select', result);
            this.$emit('close');
            this.showRevertToDraftDialog = false;
        }).finally(() => {
            this.loading = false;
        });
    }

    deleteDocument () {
        this.loading = true;
        if (!this.clientId) return;
        documentsApi.delete(this.clientId, this.documentId, this.apiUrl).then(() => {
            this.loading = false;
            // eslint-disable-next-line no-restricted-globals
            if (parent) {
                // eslint-disable-next-line no-restricted-globals
                parent.postMessage('close', '*');
            }
            this.$emit('documentDeleted', this.documentEdited);
            this.$emit('close');
        }).finally(() => {
            this.loading = false;
        });
    }

    updateOnlineStatus (isAvailableOnline: boolean) {
        if (isAvailableOnline && this.documentStatus !== 'Final') return;
        this.loading = true;
        const request = { isAvailableOnline };
        if (!this.clientId) return;
        documentsApi.updateOnlineStatus(this.clientId, this.documentId, request, this.apiUrl).then(() => {
            this.getDocument();
            this.loading = false;
            this.reloadParentWindow();
        }).finally(() => {
            this.loading = false;
        });
    }

    saveFileDetails () {
        this.loading = true;
        const request: { docId: string; fileName?: string; documentCategoryId?: Guid; effectiveDate?: Date } = {
            docId: this.docId,
        };
        request.fileName = this.documentEdited.name;
        request.documentCategoryId = this.documentCategories.find((a) => a.name === this.documentEdited.category)!.id;
        request.effectiveDate = this.documentEdited.effectiveDate;

        if (!this.clientId) return;
        documentsApi.updateFileDetails(this.clientId, this.documentId, request, this.apiUrl).then((result) => {
            const history = [...this.documentEdited.history];
            result.history = history;
            this.documentEdited = result;
            this.documentPreEdit = cloneDeep(result);
            this.loading = false;
            this.getPreview();
            this.reloadParentWindow();
            this.getDocument();
            this.$emit('loadCorrespondence');
        }).finally(() => {
            this.loading = false;
        });
    }

    getPreview () {
        this.previewUrl = null;
        if (!this.clientId) return;
        this.loadingPreview = true;
        documentsApi.previewDocument(this.clientId, this.documentId, this.apiUrl).then((result) => {
            const suffix = result.url.indexOf('sharepoint') > -1 ? '' : '#toolbar=0';
            if (this.documentStatus === 'Draft') this.previewUrl = `${result.url}${suffix}`;
            else this.previewUrl = result.url;
        }).finally(() => {
            this.loadingPreview = false;
        });
    }

    editDocument () {
        this.loading = true;
        if (!this.clientId) return;
        documentsApi.getLinkForEdit(this.clientId, this.documentId, this.apiUrl).then((result) => {
            window.open(result.url, '_blank');
            this.getPreview();
            this.loading = false;
        }).finally(() => {
            this.loading = false;
        });
    }

    formatDate (date: Date | string, noHours = false) {
        if (noHours) {
            return moment(date).format('DD/MM/YYYY');
        }
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    copyLink () {
        const copyText = document.getElementById('urlToCopy') as HTMLInputElement;
        if (copyText) {
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand('copy');
        }

        this.$store.dispatch('messages/addMessage', {
            status: 'Success', title: 'Copy successful', popup: true, message: `Link has been copied to the clipboard:<br/><br/><span class="select">${copyText.value}</span>`,
        });
    }

    assignJob () {
        this.loading = true;
        if (this.newJobId) {
            documentsApi.assignJob(this.newJobId, this.documentId, this.apiUrl, !!this.job).then(() => {
                this.loading = false;
                this.showJobAssign = false;
                this.getDocument();
                this.reloadParentWindow();
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    unassignJob () {
        if (!this.documentEdited?.jobs?.length) return;
        documentsApi.unAssignJob(this.documentEdited.jobs[0].jobId, this.documentEdited.jobs[0].jobDocumentId, this.apiUrl).then(() => {
            this.loading = false;
            this.getDocument();
            this.reloadParentWindow();
        }).finally(() => {
            this.loading = false;
        });
    }

    getCategories () {
        documentsApi.getDocumentCategories(this.apiUrl).then((result) => {
            this.documentCategories = result;
        });
    }

    returnToIndex () {
        this.loading = true;
        if (!this.clientId) return;
        documentsApi.returnToIndex(this.clientId, this.documentId, this.apiUrl).then(() => {
            this.loading = false;
            this.$emit('documentDeleted', this.documentEdited);
            if (parent) {
                parent.postMessage('close', '*');
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    getUser (userId: Guid) {
        const user = this.usersById[userId.toString()];
        if (!user) return 'System';
        return `${user.forenames} ${user.surname}`;
    }

    @Watch('docId')
    docIdChanged () {
        if (this.docId) {
            this.getDocument();
        }
    }

    @Watch('fileDetailsChanged')
    hasFileDetailsChanged () {
        this.$emit('dirtyChanged', this.fileDetailsChanged);
    }
}
