import Guid from 'common/util/guid';
import axios from '~/axios';
import Http from './apiHelper';

const apiVersion = 'v1.0';
export default class UsersApi {
    static getClients (isLive: boolean|undefined ){
        const query = isLive === undefined ? '' : `?isLive=${isLive}`;

        return Http.get(`${apiVersion}/client/web${query}`);
    }

    static generateWebForm (clientId: Guid, formRequest: unknown) {
        return Http.post(`${apiVersion}/client/${clientId}/webform/generate`, formRequest);
    }

    static getAllWebForms (clientId: Guid, query: Record<string, string[]>) {
        const queryString = Object.keys(query).map((key) => (Array.isArray(query[key]) ? query[key].map((arrVal) => `${key}=${arrVal}`).join('&') : `${key}=${query[key]}`)).join('&');
        return Http.get(`${apiVersion}/client/${clientId}/webforms?${queryString}`);
    }

    static getWebForm (clientId: Guid, webId: string) {
        return Http.get(`${apiVersion}/client/${clientId}/webform/${webId}`);
    }

    static getWebFormTemplate (templateUrl: string, template: string) {
        const instance = axios.create({});

        return instance.get(`${templateUrl}${template}.json`).then((result) => result.data);
    }

    static getWebFormList (templateUrl: string) {
        const instance = axios.create({});

        return instance.get(`${templateUrl}forms.json?t=${Date.now() / 1000}`).then((result) => result.data);
    }

    static getFormUploads (clientId: Guid, webFormId: string) {
        return Http.get(`${apiVersion}/client/${clientId}/webform/${webFormId}/uploads`);
    }

    static reopenWebForm (clientId: Guid, webFormId: string) {
        return Http.post(`${apiVersion}/client/${clientId}/webform/${webFormId}/reopen`, null);
    }

    static saveFormNotes (clientId: Guid, webFormId: string, data: { adminNote: string}) {
        return Http.post(`${apiVersion}/client/${clientId}/webfors/${webFormId}/saveAdminNote`, data);
    }

    static getFormMember (clientCode: string, memberId: number) {
        return Http.get(`${apiVersion}/webForm/${clientCode}/member/${memberId}`);
    }

    static deleteForm (clientId: Guid, webFormId: string) {
        return Http.delete(`${apiVersion}/client/${clientId}/webform/${webFormId}`);
    }

    static restoreForm (clientId: Guid, webFormId: string) {
        return Http.post(`${apiVersion}/client/${clientId}/webform/${webFormId}/restore`, null);
    }

    static unlockForm (clientId: Guid, webId: string) {
        return Http.post(`${apiVersion}/client/${clientId}/webform/${webId}/unlock`, null);
    }
}
