
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import ColourService from '@/services/colourService';
import dayjs from 'dayjs';
import PageVisits from '@/models/pageVisits';
import Highcharts from 'highcharts';

@Component
export default class Demographics extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    @Prop()
        filter!: string | null;

    get pageVisits (): Array<PageVisits> | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'pageVisits');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'pageVisits');
    }

    get pages () {
        const pageNames: Record<string, boolean> = {};
        if (!this.pageVisits) return [];
        this.pageVisits.forEach((date) => {
            date.pageVisitsByDate.forEach((page) => {
                if (!pageNames[page.pageName]) pageNames[page.pageName] = true;
            });
        });
        return Object.keys(pageNames).filter((a) => !this.filter || this.filter === a);
    }

    get pageVisitsTransformed () {
        if (!this.pageVisits) return [];
        const vals: Array<{ type: 'column'; name: string; data: Array<number>; color: string }> = [];
        this.pages.forEach((page) => {
            vals.push({
                name: this.formatUrl(page),
                type: 'column',
                data: this.pageVisits!.map((a) => a.pageVisitsByDate.find((b) => b.pageName === page)?.visits ?? 0),
                color: ColourService.getSeriesColour('page', this.formatUrl(page)),
            });
        });

        return vals;
    }

    get colours () {
        return [ColourService.colours[0]];
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'area',
            },
            xAxis: {
                categories: this.pageVisits?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')) ?? [],
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            colors: this.colours,
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderWidth: 0,
                },
                series: {
                    animation: !this.printing,
                },
            },
            series: this.pageVisitsTransformed || [],
        };
    }

    toUppercase (word: string) {
        const wordArr = word.split('').filter((a) => a);
        return wordArr[0].toUpperCase() + wordArr.slice(1).join('');
    }

    formatUrl (url: string) {
        if (!url?.trim() || url.trim() === '/') return 'Home';
        let newUrl = url.startsWith('/') ? url.substring(1) : url;
        newUrl = newUrl.replace(/-/g, ' ');
        newUrl = newUrl.replace(/\//g, ' - ');
        return newUrl.split(' ').map((a) => this.toUppercase(a)).join(' ').replace('Pension Tool', 'Modeller');
    }
}
