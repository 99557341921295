
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import FinancialData from '@/models/financialData';

@Component
export default class Transfers extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean | null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'financials') || false as boolean;
    }

    get transferStats (): FinancialData {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'financials');
    }

    get transferStatsTranformed () {
        if (!this.transferStats) return null;
        let groupNames: Array<string> = [];
        this.transferStats.periods.forEach((period) => {
            period.groups.forEach((group) => {
                groupNames.push(group.name);
            });
        });
        groupNames = [...new Set(groupNames)].filter((a) => !this.filter || this.filter === a);
        return groupNames.map((groupName) => ({
            name: groupName,
            color: ColourService.getSeriesColour('financial', groupName),
            type: 'column',
            data: this.transferStats.periods.map((a) => a.groups.find((b) => b.name === groupName)?.amount ?? 0),
        })) as Array<SeriesOptionsType>;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
            },
            xAxis: {
                categories: this.transferStats?.periods.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: (that) => dayjs(that.value).format('DD MMM'),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            tooltip: {
                pointFormat: '{series.name}: <b>£{point.y}</b><br/>',
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
                labels: {
                    formatter () {
                        const label = this.value;
                        return `${(label ?? 0).toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'GBP',
                        })}`;
                    },
                },

            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '0.9rem',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '400',
                    width: 200,
                },

            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.transferStatsTranformed || [],
        };
    }
}
