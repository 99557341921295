import { render, staticRenderFns } from "./last-refreshed.vue?vue&type=template&id=58464130&scoped=true&"
import script from "./last-refreshed.vue?vue&type=script&lang=ts&"
export * from "./last-refreshed.vue?vue&type=script&lang=ts&"
import style0 from "./last-refreshed.vue?vue&type=style&index=0&id=58464130&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../ClientWeb/FrontEnd/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58464130",
  null
  
)

export default component.exports