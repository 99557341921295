import Http from 'common/api/apiHelper';
import Client from 'common/models/client';
import TeamSummary from 'common/models/teamSummary';
import TeamType from 'common/models/teamType';
import Guid from 'common/util/guid';

const apiVersion = 'v1.0';

export default class TeamsApi {
    static addNewTeam (data: { name: string; microsoftTeamName?: string; teamType: TeamType; isReferral: boolean }) {
        return Http.post(`${apiVersion}/team`, data, undefined, { showSuccessMessage: true, message: 'Team has been added' });
    }

    static getOtherUsers () {
        return Http.get(`${apiVersion}/team/allOtherUsers`);
    }

    static getTeams (pulseApiUrl = '') {
        return Http.getAsArray(`${pulseApiUrl}${apiVersion}/team`, TeamSummary, { useBasePath: !pulseApiUrl });
    }

    static getTeamAssignment () {
        return Http.get(`${apiVersion}/user/team/assignment`);
    }

    static getAssignment () {
        return Http.get(`${apiVersion}/user/team/assignment`);
    }

    static getClientAssignment () {
        return Http.get(`${apiVersion}/user/client/assignment`);
    }

    static getReferralTeams () {
        return Http.getAsArray(`${apiVersion}/team?type=Referral`, TeamSummary);
    }

    static getReferralTeamWorkstreams (teamId: Guid) {
        return Http.get(`${apiVersion}/team/${teamId}/workstream`);
    }

    static getReferralWorkstreamDefinitions (teamId: Guid, workstreamId: Guid, scope: string) {
        return Http.get(`${apiVersion}/team/${teamId}/workstream/${workstreamId}/jobdefinition?scope=${scope || 'All'}`);
    }

    static getTeamUsers (teamId: Guid) {
        return Http.getAsArray<{ userId: Guid; fromSync: boolean }>(`${apiVersion}/team/${teamId}/user`);
    }

    static getClients (teamId: Guid) {
        return Http.getAsArray(`${apiVersion}/team/${teamId}/client`, Client);
    }

    static updateTeam (teamId: Guid, data: { name: string; microsoftTeamName?: string }) {
        return Http.put(`${apiVersion}/team/${teamId}`, data, undefined, { showSuccessMessage: true, message: 'Team has been updated' });
    }
}
