
import { Component, Vue, Watch } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import ClientContext from '@/models/clientContext';
import Client from 'common/models/client';
import PrintPreview from '@/components/print-preview.vue';
import ClientConfig from '@/models/client';
import ClientNameService from '@/services/clientNameService';

@Component({
    components: { PrintPreview },
})
export default class HeaderBar extends Vue {
    showingPrint = false;

    get showDates () {
        return this.$route.meta?.showDates;
    }

    get showPrint () {
        return this.$route.meta?.showPrint;
    }

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    get loggedIn () {
        return this.$store.getters['auth/loggedIn'];
    }

    get clientName () {
        if (this.$route.path.indexOf('/demo') > -1) return 'Demo Scheme';
        if (this.clientId) {
            const name = this.clients.find((a) => a.clientId === this.clientId)?.name;
            if (name === 'Generali Pension Fund') return 'Assicurazioni Generali SpA (UK Branch) Pension Fund';
            if (name === 'Main Fund ICI') return 'ICI Pension Fund';
            if (name === 'BSI') return 'BSI Retirement Benefits Plan';
            return name;
        }
        return '';
    }

    get clientPath () {
        if (!this.clientName) return '';
        if (this.$route.path.indexOf('/demo') > -1) return 'demo';
        return ClientNameService.clientNameToPath(this.clientName);
    }

    get clientFontSize () {
        if (this.clientName && this.clientName?.length > 40) return { 'font-size': '1.4rem' };
        return { 'font-size': '1.7rem' };
    }

    get clientContext () {
        if (this.clientId) {
            return this.$store.getters['clientContext/context'](this.clientId) as ClientContext;
        }
        return null;
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfig ?? {};
    }

    get hasReportingRole () {
        return this.clientConfiguration?.hasReportingRole;
    }

    signOut () {
        this.$store.dispatch('auth/logout');
    }

    @Watch('clientId', { immediate: true })
    clientIdChanged () {
        this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId);

        if (!this.clientContext && this.clientId && this.hasReportingRole) {
            this.$store.dispatch('clientContext/getContext', this.clientId).then(() => {
                this.$store.dispatch('clientStatistics/loadClientStatsForDashboard', {
                    clientId: this.clientId,
                    context: this.clientContext,
                });
            });
        }

        document.title = `Pulse - ${this.clientName}`;
    }

    @Watch('hasReportingRole', { immediate: true })
    hasReportingRoleChanged () {
        if (!this.clientContext && this.clientId && this.hasReportingRole) {
            this.$store.dispatch('clientContext/getContext', this.clientId).then(() => {
                this.$store.dispatch('clientStatistics/loadClientStatsForDashboard', {
                    clientId: this.clientId,
                    context: this.clientContext,
                });
            });
        }

        if (this.hasReportingRole === false) {
            this.$router.push(`/${this.clientPath}/members/`);
        }
    }
}
