import Guid from 'common/util/guid';

export default class DeletedMember {
    dateBirth!: Date;

    deletedReason!: string;

    forenames!: string;

    isDeleted!: boolean;

    memberId!: number;

    nino!: string;

    postCode!: string;

    servicePeriods!: Array<{
        servicePeriodId: number;
        status: string;
        statusDate: Date;
    }>;

    surname!: string;

    systemMemberId!: Guid;

    deletedBy?: Guid;

    deletedOn?: Date;
}
