
import Client from 'common/models/client';
import ClientNameService from '@/services/clientNameService';
import {
    Component, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class ClientList extends Vue {
    get loading () {
        return this.$store.getters['clientContext/loadingClients'];
    }

    gotoClient (client: Client) {
        this.$store.commit('clientContext/setSelectedClient', client.clientId);

        this.$router.push(`/${ClientNameService.clientNameToPath(client.name)}`);
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    signOut () {
        this.$store.dispatch('auth/logout');
    }

    clientName (client: Client) {
        if (client.name === 'Generali Pension Fund') return 'Assicurazioni Generali SpA (UK Branch) Pension Fund';
        if (client.name === 'Main Fund ICI') return 'ICI Pension Fund';
        if (client.name === 'BSI') return 'BSI Retirement Benefits Plan';
        return client.name;
    }

    get orderedClients () {
        if (!this.clients) return [];
        return [...this.clients].sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    @Watch('clients', { immediate: true })
    clientsChanged () {
        if (this.clients?.length === 1) {
            this.gotoClient(this.clients[0]);
        }
    }
}
