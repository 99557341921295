/* eslint-disable max-classes-per-file */
import { Type } from '~/@lcp/class-mapper';

export class AddressResponse {
    formatted_address: Array<string> = [];

    thoroughfare!: string;

    building_name!: string;

    sub_building_name!: string;

    sub_building_number!: string;

    building_number!: string;

    line_1!: string;

    line_2!: string;

    line_3!: string;

    line_4!: string;

    locality!: string;

    town_or_city!: string;

    county!: string;

    district!: string;

    country!: string;

    get key () {
        return `${this.formatted_address[0]},${this.formatted_address[1]},${this.formatted_address[2]},${this.formatted_address[3]}`;
    }
}

export default class AddressesResponse {
    postcode!: string;

    latitude!: number;

    longitude!: number;

    @Type(() => AddressResponse)
    addresses!: Array<AddressResponse>;
}
