import UserRole from 'common/models/permissions/userRole';
import User from 'common/models/user';
import UserDetails from 'common/models/userDetails';
import UserTeamAssignment from 'common/models/userTeamAssignment';
import Guid from 'common/util/guid';
import Http from './apiHelper';

const apiVersion = 'v1.0';

export default class UsersApi {
    static getUsers (pulseApiUrl = '') {
        return Http.getAsArray<User>(`${pulseApiUrl}${apiVersion}/user`, User, { useBasePath: !pulseApiUrl });
    }

    static addFavourite (id: Guid, pulseApiUrl = '') {
        return Http.post(`${pulseApiUrl}${apiVersion}/user/favourite`, { clientId: id }, undefined, { useBasePath: !pulseApiUrl });
    }

    static removeFavourite (id: Guid, pulseApiUrl = '') {
        return Http.put(`${pulseApiUrl}${apiVersion}/user/favourite/remove`, { clientId: id }, undefined, { useBasePath: !pulseApiUrl });
    }

    static getFavourites (pulseApiUrl = '') {
        return Http.get<{ ids?: Array<Guid> }>(`${pulseApiUrl}${apiVersion}/user/favourite`, undefined, { useBasePath: !pulseApiUrl });
    }

    static getUserTeams (userId: Guid) {
        return Http.getAsArray<UserTeamAssignment>(`${apiVersion}/user/${userId}/team`, UserTeamAssignment);
    }

    static getUserRoles (userId: Guid) {
        return Http.getAsArray<UserRole>(`${apiVersion}/user/${userId}/role`, UserRole);
    }

    static getUserDetails (url?: string) {
        return Http.get<UserDetails>(`${url ?? ''}${apiVersion}/user/detail`, UserDetails, {
            useBasePath: !url,
        });
    }
}
