
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ClientsApi from '@/api/clientApi';
import Guid from 'common/util/guid';
import ClientCalendar from '@/models/clientCalendar';
import dayjs from 'dayjs';

@Component
export default class CompletedJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    clientCalendar: ClientCalendar | null = null;

    loading = false;

    get fromDate () {
        return this.$store.getters['clientContext/fromDate'](this.clientId) as string;
    }

    get toDate () {
        return this.$store.getters['clientContext/toDate'](this.clientId) as string;
    }

    formatDate (date: string) {
        return dayjs(date).format('DD/MM/YYYY');
    }

    @Watch('fromDate', { immediate: true })
    @Watch('toDate')
    async datesChanged () {
        this.clientCalendar = await ClientsApi.getClientCalendar(this.clientId);
    }

    get clientEvents () {
        const months: { [key: string ]: Array<{ date: string; name: string; status: string }>} = {};
        if (!this.clientCalendar?.events) return months;
        this.clientCalendar.events.sort((a, b) => (a.date > b.date ? 1 : -1)).filter((a, i) => i < 50).forEach(((a) => {
            let key = dayjs(a.date).format('MMMM');
            if (dayjs(a.date).year() !== dayjs(new Date()).year()) {
                key += ` ${dayjs(a.date).format('YYYY')}`;
            }
            if (!months[key]) months[key] = [];
            months[key].push(a);
        }));
        return months;
    }
}
