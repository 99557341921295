
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ClientsApi from '@/api/clientApi';
import Guid from 'common/util/guid';
import MemberDemographics from '@/models/memberDemographics';
import ColourService from '@/services/colourService';
import Highcharts from 'highcharts';

@Component
export default class Demographics extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    @Prop()
        tableView!: boolean | null;

    @Watch('tableView', { immediate: true })
    tableViewChanged () {
        this.viewAsTable = !!this.tableView;
    }

    viewAsTable = false;

    memberDemographics: MemberDemographics | null = null;

    loading = false;

    get fromDate () {
        return this.$store.getters['clientContext/fromDate'](this.clientId) as string;
    }

    get toDate () {
        return this.$store.getters['clientContext/toDate'](this.clientId) as string;
    }

    @Watch('fromDate', { immediate: true })
    @Watch('toDate')
    async datesChanged () {
        this.loading = true;
        this.memberDemographics = await ClientsApi.getMemberDemographics(this.clientId);
        this.loading = false;
    }

    get tableData () {
        const ages: Array<{ min: number; max: number; [type: string]: number }> = [
            { min: 0, max: 18 },
            { min: 19, max: 30 },
            { min: 31, max: 40 },
            { min: 41, max: 50 },
            { min: 51, max: 60 },
            { min: 61, max: 70 },
            { min: 71, max: 80 },
            { min: 81, max: 90 },
            { min: 91, max: 100 },
            { min: 101, max: 200 },
        ];
        if (!this.memberDemographics) return [];
        this.memberTypes.forEach((type) => {
            ages.forEach((age) => {
                this.memberDemographics!.countByStatusAndAge[type].forEach((count) => {
                    if (count.age >= age.min && count.age <= age.max) {
                        if (!age[type]) age[type] = 0;
                        age[type] += count.count;
                    }
                });
            });
        });
        return ages;
    }

    get memberDemographicsTransformed () {
        if (!this.memberDemographics?.countByStatusAndAge) return null;
        const ages: { [ age: string ]: { [type: string]: number }} = {};
        this.memberTypes.forEach((type) => {
            this.memberDemographics!.countByStatusAndAge[type].forEach((a) => {
                if (!ages[a.age]) ages[a.age] = {};
                ages[a.age][type] = a.count;
            });
        });
        const vals: Array<{ type: 'column'; name: string; data: Array<number>; color: string }> = [];
        this.memberTypes.forEach((type) => {
            const val: { type: 'column'; name: string; data: Array<number>; color: string } = {
                name: type,
                type: 'column',
                data: [],
                color: ColourService.getSeriesColour('demographics', type),
            };
            this.ages.forEach((age) => {
                val.data.push(ages[age] ? ages[age][type] || 0 : 0);
            });
            vals.push(val);
        });
        return vals;
    }

    get memberTypes () {
        return Object.keys(this.memberDemographics?.countByStatusAndAge || {});
    }

    get colours () {
        return ColourService.colours;
    }

    get ages () {
        let minAge = -1;
        let maxAge = -1;
        this.memberTypes.forEach((type) => {
            this.memberDemographics!.countByStatusAndAge[type].forEach((a) => {
                if (a.age > maxAge || maxAge === -1) maxAge = a.age;
                if (a.age < minAge || minAge === -1) minAge = a.age;
            });
        });
        const vals: Array<string> = [];
        for (let i = minAge; i <= maxAge; i += 1) {
            vals.push(i.toString());
        }
        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
            },
            xAxis: {
                categories: this.ages,
                title: {
                    text: 'Age',
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '0.9rem',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '400',
                },
            },
            credits: {
                enabled: false,
            },
            colors: this.colours,
            plotOptions: {
                series: {
                    animation: !this.printing,
                },
                column: {
                    stacking: 'normal',
                },
            },
            series: this.memberDemographicsTransformed || [],
        };
    }
}
