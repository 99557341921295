import calculationsApi from 'common/api/calculationsApi';
import CalculationHistorySummary from 'common/models/calculations/calculationHistorySummary';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface CalculationsState {
    calculations: Array<CalculationHistorySummary>;
    calculationsDate: Date;
}

export const state: CalculationsState = {
    calculations: [],
    calculationsDate: new Date(),
};

export const getters: GetterTree<CalculationsState, unknown> = {
    calculations: (s) => s.calculations,
    calculationsDate: (s) => s.calculationsDate,
};

export const actions: ActionTree<CalculationsState, unknown> = {
    getMemberCalculations ({ commit }, { clientId, memberId }) {
        return calculationsApi.getCalculationHistory(clientId, memberId).then((calculations) => {
            commit('setCalculations', calculations);
            return calculations;
        });
    },
    clearCalculations ({ commit }) {
        commit('clearCalculations');
    },
};

export const mutations: MutationTree<CalculationsState> = {
    setCalculations (s, calculations) {
        s.calculations = calculations;
        s.calculationsDate = new Date();
    },
    clearCalculations (s) {
        s.calculations = [];
        s.calculationsDate = new Date();
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
