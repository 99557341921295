
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import MemberDocumentStats from '@/models/memberDocumentStats';

@Component
export default class MemberCorrespondenceViews extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    get documentStats (): Array<MemberDocumentStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberDocumentsStats');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberDocumentsStats');
    }

    get personalCorrespondenceCreatedTotal () {
        if (!this.documentStats) return 0;
        return this.documentStats.reduce((a, b) => a + b.correspondenceCreatedTotal, 0);
    }

    get webQueriesTotal () {
        if (!this.documentStats) return 0;
        return this.documentStats.reduce((a, b) => a + b.webQueriesSubmittedTotal, 0);
    }

    get formsSubmittedTotal () {
        if (!this.documentStats) return 0;
        return this.documentStats.reduce((a, b) => a + Object.values(b.webFormsSubmitted ?? {})?.reduce((c, d) => c + Object.values(d.splitByStatus).reduce((e, f) => e + f), 0), 0);
    }

    get documentsViewedTotal () {
        if (!this.documentStats) return 0;
        return this.documentStats.reduce((a, b) => a + Object.values(b.documentsViewed ?? {})?.reduce((c, d) => c + Object.values(d.splitByStatus).reduce((e, f) => e + f), 0), 0);
    }
}
