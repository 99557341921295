
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import JobDateStats from '@/models/jobDateStats';
import ColourService from '@/services/colourService';
import Highcharts, { SeriesOptionsType } from 'highcharts';

@Component
export default class CompletedJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        viewByCase!: string | null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'completedCases') || false as boolean;
    }

    get completedJobStats (): JobDateStats {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'completedCases');
    }

    get completedJobStatsTranformed () {
        if (!this.completedJobStats) return null;
        const slas: { [sla: string]: { [date: string]: number }} = {};
        this.completedJobStats.dataByDate.forEach((date) => {
            if (!date.countsBySla) return;
            Object.values(date.countsBySla).forEach((type) => {
                Object.keys(type).forEach((sla) => {
                    if (!slas[sla]) slas[sla] = {};
                    if (!slas[sla][date.startDate.toString()]) slas[sla][date.startDate.toString()] = 0;
                    if (!this.filter || this.filter === sla) {
                        slas[sla][date.startDate.toString()] += type[sla];
                    }
                });
            });
        });

        const orderedKeys = [...new Set(['Beyond statutory', 'Beyond target', 'Within target', ...Object.keys(slas)])];
        return orderedKeys.map((sla) => ({
            name: sla,
            color: ColourService.getSeriesColour('jobs-sla', sla),
            type: 'column',
            data: this.completedJobStats.dataByDate
                .map((a) => (slas[sla] ? slas[sla][a.startDate.toString()] : 0) || 0),
        })) as Array<SeriesOptionsType>;
    }

    get caseTypes () {
        const arr: Array<string> = [];
        if (!this.completedJobStats) return [];
        this.completedJobStats.dataByDate.forEach(((a) => {
            arr.push(...Object.keys(a.counts));
        }));
        return [...new Set(arr)];
    }

    get completedJobStatsTransformedByCase () {
        if (!this.completedJobStats) return null;
        const vals: Array<SeriesOptionsType> = [];
        const data: { [key: string]: Array<number>} = {
            'Within target': [],
            'Beyond target': [],
            'Beyond statutory': [],
        };
        this.caseTypes.forEach((key, i) => {
            Object.values(this.completedJobStats.dataByDate).forEach((date) => {
                if (!date.countsBySla) return;
                if (!data['Within target'][i]) data['Within target'][i] = 0;
                if (!data['Beyond target'][i]) data['Beyond target'][i] = 0;
                if (!data['Beyond statutory'][i]) data['Beyond statutory'][i] = 0;
                Object.keys(date.countsBySla[key] || {}).forEach((type) => {
                    data[type][i] += (date.countsBySla![key][type]) || 0;
                });
            });
        });

        Object.keys(data).forEach((key) => {
            if (this.filter && this.filter !== key) return;
            vals.push({
                name: key,
                color: ColourService.getSeriesColour('jobs-sla', key),
                type: 'column',
                data: data[key],
            });
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            xAxis: {
                categories:
                    this.viewByCase
                        ? this.caseTypes
                        : this.completedJobStats?.dataByDate.map((a) => dayjs(a.startDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => (this.viewByCase ? that.value : dayjs(that.value).format('DD MMM'))),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                        // whiteSpace: 'nowrap',
                    },
                    overflow: 'none',
                },

            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.viewByCase ? this.completedJobStatsTransformedByCase || [] : this.completedJobStatsTranformed || [],
        };
    }
}
