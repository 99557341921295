import Guid from 'common/util/guid';
import ServicePeriod from '../servicePeriod';

export default class MemberHistoryItem {
    memberHistoryId!: Guid;

    text!: string;

    type!: string;

    typeDescription!: string;

    servicePeriods!: Array<ServicePeriod>;

    memberDataHistory!: {
        mebmerDataHistoryId: Guid;
        dataType: string;
        dataTypeDescription: string;
        servicePeriod: ServicePeriod;
    };

    updated!: Date;

    updatedBy!: Guid;
}
