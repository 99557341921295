export default class FinancialData {
    periods!: Array<{
        fromDate: string;
        toDate: string;
        groups: Array<{
            name: string;
            amount: number;
        }>;
        startBalance: number;
        endBalance: number;
    }>;

    startBalance!: number;

    endBalance!: number;
}
