import memberApi from 'common/api/member';
import MemberVerificationSummary from 'common/models/memberVerificationSummary';
import Guid from 'common/util/guid';
import {
    Commit, ActionTree, GetterTree, MutationTree,
} from '~/vuex';

interface VerificationState {
    verifications: Array<MemberVerificationSummary>;
    verificationsDate: Date;
}

export const state: VerificationState = {
    verifications: [],
    verificationsDate: new Date(),
};

export const getters: GetterTree<VerificationState, unknown> = {
    verifications: (s: VerificationState) => s.verifications,
    verificationsDate: (s: VerificationState) => s.verificationsDate,
};

export const actions: ActionTree<VerificationState, unknown> = {
    getMemberVerifications ({ commit }: { commit: Commit }, { clientId, memberId }: {clientId: Guid; memberId: number}) {
        return memberApi.getMemberVerifications(clientId, memberId)
            .then((verifications) => {
                commit('setVerifications', verifications);
                return verifications;
            });
    },
};

export const mutations: MutationTree<VerificationState> = {
    setVerifications (s: VerificationState, verifications: Array<MemberVerificationSummary>) {
        s.verifications = verifications;
        s.verificationsDate = new Date();
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
