
import { Vue, Component, Watch } from 'vue-property-decorator';
import Client from 'common/models/client';
import Guid from 'common/util/guid';
import ClientNameService from '@/services/clientNameService';
import ClientConfig from '@/models/client';

@Component
export default class Navbar extends Vue {
    showNextRow = false;

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfig ?? {};
    }

    get clientName () {
        if (!this.clients) return '';
        return this.clients.find((a) => a.clientId === this.clientId)?.name;
    }

    get clientPath () {
        if (!this.clientName) return '';
        if (this.$route.path.indexOf('/demo') > -1) return 'demo';
        return ClientNameService.clientNameToPath(this.clientName);
    }

    get client () {
        return this.clients?.find((a) => a.clientId === this.clientId);
    }

    get isLive () {
        return window.location.href.indexOf('.lcp.uk.com') > -1;
    }

    get hasMemberWeb () {
        return this.clientConfiguration?.hasMemberWeb;
    }

    get hasPacia () {
        return this.clientConfiguration?.hasPacia;
    }

    get hasPaciaRole () {
        return this.clientConfiguration?.hasPaciaRole;
    }

    get hasReportingRole () {
        return this.clientConfiguration?.hasReportingRole;
    }

    @Watch('clientId', { immediate: true })
    clientIdChanged () {
        if (this.clientId) {
            this.$store.dispatch('clientContext/getClientConfiguration', this.clientId);
        }
    }
}

