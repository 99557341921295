import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=1d7c0b51&scoped=true&"
import script from "./btn.vue?vue&type=script&lang=js&"
export * from "./btn.vue?vue&type=script&lang=js&"
import style0 from "./btn.vue?vue&type=style&index=0&id=1d7c0b51&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../ClientWeb/FrontEnd/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d7c0b51",
  null
  
)

export default component.exports