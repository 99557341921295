
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import ModellerStats from '@/models/modellerStats';
import Highcharts from 'highcharts';

@Component
export default class ModellerInteractionsByAgeChart extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean;

    viewByType = true;

    ageBands = [
        { min: 101, max: 200, label: '> 100' },
        { min: 91, max: 100 },
        { min: 81, max: 90 },
        { min: 71, max: 80 },
        { min: 61, max: 70 },
        { min: 51, max: 60 },
        { min: 41, max: 50 },
        { min: 31, max: 40 },
        { min: 19, max: 30 },
        { min: 0, max: 18, label: '< 19' },
    ];

    get modellerStatistics (): Array<ModellerStats> | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'modellerStats');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'modellerStats');
    }

    get colours () {
        return ColourService.colours;
    }

    get modellerInteractionPages () {
        if (!this.modellerStatistics) return [];
        const pages: Array<string> = [];
        this.modellerStatistics.forEach((row) => {
            pages.push(...row.ageGroupInteractions.map((a) => a.interactionName));
        });
        return [...new Set(pages)];
    }

    get modellerStatisticsTransformed () {
        if (!this.modellerStatistics) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];

        this.ageBands.forEach((ageBand) => {
            let ageTotal = 0;
            const ageBandData: Array<number> = [];
            if (this.viewByType) {
                this.modellerInteractionPages.forEach((page) => {
                    let total = 0;
                    this.modellerStatistics!.forEach((row) => {
                        const section = row.ageGroupInteractions.find((a) => a.interactionName === page)?.dataByAge;
                        Object.keys(section || {}).forEach((age) => {
                            if (Number(age) >= ageBand.min && Number(age) <= ageBand.max) {
                                total += section![age];
                            }
                        });
                    });
                    ageBandData.push(total);
                    ageTotal += total;
                });
            } else {
                this.modellerStatistics!.forEach((row) => {
                    let total = 0;
                    row.ageGroupInteractions.forEach((section) => {
                        Object.keys(section.dataByAge ?? {}).forEach((age) => {
                            if (Number(age) >= ageBand.min && Number(age) <= ageBand.max) {
                                total += section.dataByAge[age];
                            }
                        });
                    });
                    ageBandData.push(total);
                    ageTotal += total;
                });
            }
            if (ageTotal === 0) return;
            if (!this.filter || this.filter === (ageBand.label ?? `${ageBand.min} - ${ageBand.max}`)) {
                vals.push({
                    name: ageBand.label ?? `${ageBand.min} - ${ageBand.max}`,
                    type: 'column',
                    data: ageBandData,
                    color: ColourService.getSeriesColour('age', ageBand.label ?? `${ageBand.min} - ${ageBand.max}`),
                });
            }
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
            },
            xAxis: {
                categories: this.viewByType ? this.modellerInteractionPages : this.modellerStatistics?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => (this.viewByType ? this.formatInteraction(that.value as string) : dayjs(that.value).format('DD MMM'))),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            colors: this.colours,
            plotOptions: {
                column: {
                    stacking: this.viewByType ? undefined : 'normal',
                },
            },
            series: this.modellerStatisticsTransformed || [],
        };
    }

    formatInteraction (interaction: string) {
        const result = interaction.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
}
