
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import DataUpdateStats from '@/models/dataUpdateStats';
import Highcharts from 'highcharts';

@Component
export default class DataUpdatesByDataTypeChart extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'dataUpdates') || false as boolean;
    }

    get dataUpdates (): Array<DataUpdateStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'dataUpdates');
    }

    get categories () {
        if (!this.dataUpdates) return [];
        const dataTypes: Array<string> = [];
        this.dataUpdates.forEach((date) => {
            dataTypes.push(...Object.keys(date.dataByType));
        });
        return [...new Set(dataTypes)];
    }

    get dataUpdatesTransformed () {
        if (!this.dataUpdates) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];

        this.categories.forEach((dataType) => {
            const dataTypeData: Array<number> = [];
            this.dataUpdates!.forEach((row) => {
                dataTypeData.push(row.dataByType[dataType] ?? 0);
            });
            if (!this.filter || this.filter === this.formatDataType(dataType)) {
                vals.push({
                    name: this.formatDataType(dataType),
                    type: 'column',
                    data: dataTypeData,
                    color: ColourService.getSeriesColour('dataTypes', dataType),
                });
            }
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.dataUpdates?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.dataUpdatesTransformed || [],
        };
    }

    formatDataType (dataType: string) {
        const result = dataType.replace('Update', '').replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1)
            .replace('Dependants', 'Dependant Details')
            .replace('Status', 'Information')
            .replace('Phone Numbers', 'Contact Details')
            .replace('Email Address', 'Email Details')
            .replace('Communication', 'Contact')
            .replace('Change', '');
    }
}
