
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import Guid from 'common/util/guid';
import Highcharts, { Point } from 'highcharts';
import ColourService from '@/services/colourService';
import ToggleSwitch from 'common/components/toggle-switch.vue';
import PieLegend from '@/services/pieLegendService';
import DataUpdateStats from '@/models/dataUpdateStats';

@Component({ components: { ToggleSwitch } })
export default class MemberDataUpdatesByDataTypePie extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        showLegend!: boolean;

    selectedPoint: string | null = null;

    selectedColourIndex: number | null = null;

    chartCenterX = 0;

    chartCenterY = 0;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'dataUpdates') || false as boolean;
    }

    get dataUpdates (): Array<DataUpdateStats> | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'dataUpdates');
    }

    get centerPos () {
        return {
            top: `calc(${this.chartCenterY}px + 1.5rem) !important`,
            left: `calc(${this.chartCenterX}px + 1.5rem) !important`,
        };
    }

    get totalDataUpdates () {
        return this.dataUpdatesTransformed?.filter((a) => a.name === this.selectedPoint || !this.selectedPoint)?.reduce((a, b) => a + (b.y ?? 0), 0);
    }

    get dataUpdatesTransformed () {
        if (!this.dataUpdates) return null;
        const totals: Record<string, number> = {};
        this.dataUpdates.forEach((date) => {
            Object.keys(date.dataByType).forEach((dataType) => {
                if (!totals[dataType]) totals[dataType] = 0;
                totals[dataType] += date.dataByType[dataType];
            });
        });
        return Object.keys(totals)
            .map((seriesName) => ({
                name: this.formatDataType(seriesName),
                y: totals[seriesName],
                color: ColourService.getSeriesColour('dataTypes', seriesName),
            })).sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    formatDataType (dataType: string) {
        const result = dataType.replace('Update', '').replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1)
            .replace('Dependants', 'Dependant Details')
            .replace('Status', 'Information')
            .replace('Phone Numbers', 'Contact Details')
            .replace('Email Address', 'Email Details')
            .replace('Communication', 'Contact')
            .replace('Change', '');
    }

    formatNumber (number?: string | number) {
        return Math.round(Number(number ?? 0)).toLocaleString('en');
    }

    pointClicked (point: Point) {
        this.selectedColourIndex = point.colorIndex;
        this.selectedPoint = point.name === this.selectedPoint ? null : point.name;
        this.$emit('pointSelected', { category: 'dataUpdatesByDataType', series: this.selectedPoint });
    }

    clearFilter () {
        (this.$refs.chart as unknown as { chart: Highcharts.Chart }).chart.series[0].points.forEach((a) => a.select(false));
        this.selectedPoint = null;
        this.$emit('pointSelected', { category: 'dataUpdatesByDataType', series: this.selectedPoint });
    }

    get chartOptions (): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
                events: {
                    load () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                    redraw () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                },
            },
            xAxis: {
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: this.showLegend ? PieLegend.get().legend : {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    allowPointSelect: true,
                    showInLegend: true,
                    borderWidth: 0,
                    point: {
                        events: {
                            legendItemClick: (event) => {
                                event.preventDefault();
                                event.target.select(!event.target.selected);
                                this.pointClicked(event.target);
                            },
                        },
                    },
                },
            },
            series: [{
                type: 'pie',
                name: 'Data updates',
                innerSize: '70%',
                slicedOffset: 5,
                data: this.dataUpdatesTransformed!,
                events: {
                    click: (event) => { this.pointClicked(event.point); },
                },
            }],
        };
    }
}
