/* eslint-disable max-classes-per-file */
export class JobDateStat {
    startDate!: Date;

    endDate!: Date;

    counts!: { [ jobType: string ]: number };

    countsBySla?: { [ jobType: string ]: { [ sla: string]: number }};

    averageCompletionTime?: number;
}

export default class JobDateStats {
    dataByDate!: Array<JobDateStat>;

    averageCompletionTime?: number;
}
