import { ClassComponent } from '~/@lcp/dynamic-ui-typescript';
import DataTypeHistoryItem from './dataTypeHistoryItem';

export default class DataTypeReview {
    dataType!: string;

    dynamicUiDefinition!: ClassComponent;

    canVerify!: boolean;

    data: Array<DataTypeHistoryItem> = []
}
