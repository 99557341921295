

import * as STORE from 'common/store/storepaths';
import { TimeZone, timeZones } from 'common/util/timezones';
import clientApi from 'common/api/clientsApi';
import moment from '~/moment';
import { Component, Vue, Prop } from '~/vue-property-decorator';

@Component({
    name: 'client-telephone-conversation',
    components: { },
})
export default class ClientTelephoneConversation extends Vue {
    @Prop()
    value!: boolean;

    conversation= '';

    incomingCall= true;

    isLoading = false;

    otherCallerCapacity= '';

    otherCompany= '';

    otherName= '';

    otherNumber= '';

    subject= '';

    timeOfCall: Date = new Date();

    timeZones: Array<TimeZone>= timeZones;

    title = `Member Data - Telephone conversation - ${this.currentClient.name} - ${this.formatDate(this.timeOfCall)}`;

    callOptions = [
        { id: true, name: 'Incoming call' },
        { id: false, name: 'Outgoing call' },
    ]

    mounted () {
        window.document.title = this.title;
    }

    get requiredFields () {
        const fieldsRequired = [];

        if (!this.otherName.length) {
            fieldsRequired.push('enter the <b>name</b> for the call');
        }

        if (!this.otherNumber.length) {
            fieldsRequired.push('enter the <b>phone number</b> for the call');
        }

        if (!this.subject.length) {
            fieldsRequired.push('enter a <b>subject</b>');
        }

        if (!this.conversation.length) {
            fieldsRequired.push('enter details for the <b>conversation</b>');
        }

        if (!fieldsRequired.length) {
            return null;
        }

        const fields = fieldsRequired.length === 1 ? fieldsRequired[0] : `${fieldsRequired.slice(0, -1).join(', ')} and ${fieldsRequired[fieldsRequired.length - 1]}`;
        return `Please ${fields} to submit the form`;
    }

    get canSave () {
        return !this.requiredFields;
    }

    get currentClient () {
        return this.$store.getters[STORE.GET_CURRENT_CLIENT];
    }

    closeWindow () {
        window.close();
    }

    formatDate (date: Date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    save () {
        const phoneCallData = {
            time: moment(this.timeOfCall, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
            data: {
                conversation: this.conversation,
                incomingCall: this.incomingCall,
                isMember: false,
                otherCallerDetails: {
                    capacity: this.otherCallerCapacity,
                    name: this.otherName,
                    company: this.otherCompany,
                    number: this.otherNumber,
                },
                subject: this.subject,
            },
        };

        this.isLoading = true;

        clientApi.createClientCall(this.currentClient.clientId, phoneCallData)
            .then(() => {
                this.isLoading = false;
                this.closeWindow();
            }).catch((err) => {
                this.$store.dispatch('messages/addError', err, { root: true });
                this.isLoading = false;
            });
    }
}

