import PageVisitDate from './pageVisitDate';

export default class PageVisits {
    fromDate!: string;

    toDate!: string;

    webVisitsByDate!: {
        visits: number;
        time: number;
    };

    pageVisitsByDate!: Array<PageVisitDate>;
}
