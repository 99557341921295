
import { Component, Prop, Vue } from 'vue-property-decorator';
import SchemePays from '@/models/memberDataTypes/schemePays';

@Component
export default class SchemePaysVue extends Vue {
    @Prop()
        dataTypeData!: SchemePays;

    get items () {
        return this.dataTypeData.items?.sort((a, b) => (a.taxYearEnding < b.taxYearEnding ? 1 : -1));
    }
}
