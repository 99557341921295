
import { Component, Prop, Vue } from 'vue-property-decorator';
import StatusHistory from '@/models/memberDataTypes/statusHistory';

@Component
export default class StatusHistoryVue extends Vue {
    @Prop()
        dataTypeData!: StatusHistory;

    get items () {
        return this.dataTypeData?.items?.sort((a, b) => (a.dateOfChange < b.dateOfChange ? 1 : -1));
    }
}
