
import * as STORE from 'common/store/storepaths';
import Address from 'common/models/address';
import AddressesResponse, { AddressResponse } from 'common/models/addressResponse';
import Map from '~/@lcp/class-mapper';
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';
import axios from '~/axios';

@Component
export default class AddressComponent extends Vue {
    @Prop()
    value!: Address;

    address: Address | null = null;

    isLoading = false;

    showResults = false;

    lookupFailed = false;

    addressResponse: AddressesResponse | null = null;

    selectedAddress: string | null = null;

    axiosInstance = axios.create({
        headers: {
            Authorization: {},
        },
    });

    get countryOptions () {
        return this.$store.getters[STORE.GET_COUNTRIES];
    }

    get pulseApiUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION][STORE.PULSE_API_URL] ?? '';
    }

    get getAddressDomainToken () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION]['getaddress.domain.token'];
    }

    get lookupUrl () {
        return `https://api.getAddress.io/find/${this.address?.addressPostCode?.trim()}?expand=true&api-key=${this.getAddressDomainToken}&sort=true`;
    }

    mounted () {
        if (!this.countryOptions?.length) {
            this.isLoading = true;
            this.$store.dispatch(STORE.LOAD_COUNTRIES, this.pulseApiUrl).then(() => {
                this.isLoading = false;
            });
        }
    }

    searchPostcode () {
        this.isLoading = true;
        this.addressResponse = null;
        this.lookupFailed = false;
        return this.axiosInstance.get<AddressResponse>(this.lookupUrl)
            .then((response) => {
                this.showResults = true;
                this.addressResponse = Map(response.data, AddressesResponse) as AddressesResponse;
            })
            .catch((error) => {
                console.log(error);
                this.lookupFailed = true;
                this.showResults = false;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    reset () {
        for (let i = 1; i < 5; i += 1) {
            this.address![`addressLine${i}`] = '';
        }
        this.address!.addressPostCode = '';
        this.address!.addressCountry = '';
        this.showResults = false;
    }

    addressSelected () {
        if (!this.addressResponse || !this.selectedAddress) return;
        const address = this.addressResponse.addresses.find((a) => a.key === this.selectedAddress);
        if (!address) return;
        let nextValid = 0;
        if (!this.address) this.address = new Address();

        for (let i = 1; i < 5; i += 1) {
            this.address[`addressLine${i}`] = '';
        }

        for (let i2 = 0; i2 < address.formatted_address.length; i2 += 1) {
            if (address.formatted_address[i2].trim().length) {
                nextValid += 1;
                this.address[`addressLine${nextValid}`] = address.formatted_address[i2];
            }
        }
        if (nextValid === 5 && address.formatted_address[4].length) {
            this.address.Line4 = `${address.formatted_address[3]}, ${address.formatted_address[4]}`;
        }

        this.address.addressCountry = 'United Kingdom';
    }

    @Watch('value', { immediate: true, deep: true })
    valueChanged () {
        if (!this.value) {
            this.$emit('input', new Address());
        }
        if (JSON.stringify(this.value) !== JSON.stringify(this.address)) {
            this.address = this.value;
        }
    }

    @Watch('address', { deep: true })
    addressChanged () {
        this.lookupFailed = false;
        this.$emit('input', this.address);
    }
}
