
import ClientsApi from '@/api/clientApi';
import Guid from 'common/util/guid';
import {
    Component, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class Documents extends Vue {
    loading = false;

    documents: Array<{ name: string; path: string }> = [];

    search = '';

    selectedFilter = 'All';

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get filters () {
        return ['All', ...new Set(this.documents.map((a) => a.path))]
            .sort((a, b) => (this.formatFilter(a) > this.formatFilter(b) ? 1 : -1));
    }

    get filteredDocuments () {
        return this.documents.filter((a) => a.name?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 && (this.selectedFilter === 'All' || this.selectedFilter === a.path));
    }

    formatFilter (path: string) {
        const newPath = path.replace('ClientWeb/Documents/', '');
        if (newPath === 'ClientWeb/Documents') return 'General';
        if (newPath === '') return 'General';
        return newPath;
    }

    async downloadDocument (documentToDownload: {name: string; path: string}) {
        const fullName = `${documentToDownload.path}/${documentToDownload.name}`;
        const url = await ClientsApi.getDocument(this.clientId, fullName) as { url: string };
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url.url;
        // the filename you want
        a.setAttribute('download', documentToDownload.name);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url.url);
    }

    @Watch('clientId', { immediate: true })
    async clientIdChanged () {
        this.documents = await ClientsApi.getDocuments(this.clientId) as Array<{ name: string; path: string }>;
    }
}
