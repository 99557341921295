
import { Component, Prop, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import ClientStats from '@/models/clientMemberStats';

@Component
export default class MemberStatisticsTile extends Vue {
    @Prop()
        clientId!: Guid;

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberStats');
    }

    get memberTypes () {
        return Object.keys(this.memberStats.countByStatus);
    }

    get memberStats () {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberStats') as ClientStats;
    }
}
