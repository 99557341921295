import Message from 'common/models/message';
import Guid from 'common/util/guid';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface MessageState {
    messages: Array<Message>;
}

export const state: MessageState = {
    messages: [],
};

export const getters: GetterTree<MessageState, unknown> = {
    messages: (s) => s.messages,
};

export const actions: ActionTree<MessageState, unknown> = {
    addError ({ commit }, error: Message) {
        console.log(error);
        error.id = Guid.newGuid().toString();
        error.isError = true;
        if (error.data?.errors?.length) {
            error.data.errors.forEach((err) => {
                commit('addMessage', { ...error, data: err.message });
            });
            return;
        }
        if (error.data?.Errors?.length) {
            error.data.Errors.forEach((err) => {
                commit('addMessage', { ...error, data: err.Message });
            });
            return;
        }
        commit('addMessage', error);
    },
    addMessage ({ commit }, message: Message) {
        message.id = Guid.newGuid().toString();
        commit('addMessage', message);
        if (!message.popup) {
            window.setTimeout(() => {
                commit('dismissMessage', message.id);
            }, 7000);
        }
    },
    dismissMessage ({ commit }, messageId) {
        commit('dismissMessage', messageId);
    },
    dismissAllMessages({commit}) {
        commit('dismissAllMessages');
    }
};

export const mutations: MutationTree<MessageState> = {
    addMessage (s, message) {
        s.messages.push(message);
    },
    dismissMessage (s, messageId) {
        for (let i = 0; i < s.messages.length; i += 1) {
            if (s.messages[i].id === messageId) {
                s.messages.splice(i, 1);
                return;
            }
        }
    },
    dismissAllMessages (s) {
        s.messages = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
