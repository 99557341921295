
import Guid from 'common/util/guid';

import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ClientConfig from '@/models/client';

@Component
export default class ContactTile extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    loadingUrls: { [id: string]: boolean } = {};

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'team') || false as boolean;
    }

    get clientConfig () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfig ?? {};
    }

    get teamMembers () {
        return this.clientConfig?.teamMembers || [];
    }

    @Watch('teamMembers', { immediate: true })
    teamMembersChanged () {
        if (this.teamMembers?.length) {
            this.teamMembers.forEach(async (teamMember) => {
                if (this.loadingUrls[teamMember.id.toString()]) return;
                Vue.set(this.loadingUrls, teamMember.id.toString(), true);
            });
        }
    }
}
