<template>
    <div class="container">
        <div class="column" v-if="loaded">
            <div class="intro">
                <h1><img class="logo" src="../assets/logo-intro.svg"/>Pulse</h1>
                <p>Please log in below. Alternatively, if you don't already have an account, click Login and follow the instructions on screen to sign up.</p>
                <btn v-if="loaded" @click="login" text="Login" icon="user-friends"/>
                <loading v-else />
            </div>
            <div class="footer">
                <span>© Copyright LCP {{ year }}</span>
                <div>
                    <a href="/terms-conditions" target="_blank">Terms & Conditions</a>
                    <a href="https://lcpcloud.com/privacy" target="_blank">LCP Cloud privacy policy</a>
                </div>
            </div>
        </div>
        <div class="column"  v-if="loaded">
            <img src="/pulse.svg" class="cardiogram"/>
            <div class="byline">
                <h2>Insight into administration activity</h2>
                <p>LCP Pulse gives you access to key administration reporting in real-time</p>
            </div>
            <img src="./../assets/pasa.png" class="pasa" />

        </div>
        <loading v-if="!loaded"/>
    </div>
</template>

<script>
import loading from 'common/components/loading.vue';

export default {
    components: { loading },
    async mounted () {
        await this.$store.dispatch('auth/getToken').then((result) => {
            if (!result) {
                if (this.$route.query?.signIn || this.$route.query?.signin) {
                    this.$router.push(this.$route.path);
                    this.login();
                }
            }
            this.loaded = true;
        }).catch(() => {
            this.loaded = true;
        });
        if (this.$route.query?.signIn || this.$route.query?.signin) {
            this.$router.push(this.$route.path);
        }
    },
    data () {
        return {
            loaded: false,
        };
    },
    methods: {
        login () {
            return this.$store.dispatch('auth/login').then(() => {
                if (this.$route.query.url) {
                    window.location.href = this.$route.query.url;
                }
                this.$nextTick(() => {
                    this.$store.dispatch('auth/getToken');
                });
            });
        },
        logout () {
            this.$store.dispatch('auth/logout').then(() => {
                this.$store.dispatch('auth/getToken');
            });
        },
    },
    computed: {
        environment () {
            return this.siteConfiguration['environment.name'];
        },
        siteConfiguration () {
            return this.$store.getters['configuration/site'];
        },
        year () {
            return new Date().getFullYear();
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/index.scss';
.container {
    display: flex;
    .column {
        position: relative;
        width: 60%;
        overflow: hidden;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        justify-items: center;
        flex-direction: column;
        @media (max-width: 1000px) {
            width: 100%;
            height: 100%;
        }
        &:last-child {
            width: 40%;
            background: $lcpDarkBlue;
            background-image: url('@/assets/chevron-bg.png');
            background-repeat: no-repeat;
            background-position: bottom right;
            min-width: 500px;
            @media (max-width: 1000px) {
                display: none;
            }
        }
    }
}

.intro {
    padding: 1rem;
}

.cardiogram {
    position: absolute;
    bottom: 0;
    opacity: 0;
    width: 100%;
}

.pasa {
    align-self: flex-end;
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
}

p {
    font-size: 1.25rem;
    max-width: 590px;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 2rem;
}

.logo {
    height: 38px;
    margin-right: 0.25rem;
}

.byline {
    color: #fff;
    max-width: 400px;
    position: relative;
    border-radius: 0.5rem;
    text-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
    h2 {
        padding: 0;
        margin-bottom: 2rem;
        line-height: 1.5;
    }
}

h1 {
    font-size: 3rem;
    font-weight: 700;
    color: $lcpDarkBlue;
    margin-bottom: 2rem;
}

button {
    border: 0;
    background: $lcpWatermelon;
    color: white;
    font-size: 1.3rem;
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: background .5s;
    &:hover {
        background: lighten($primaryColour, 5);
    }
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    a {
        color: $lcpDarkBlue;
        text-decoration: underline;
        padding: 1rem;
    }
}
</style>
