
import { Component, Prop, Vue } from 'vue-property-decorator';
import PensionSuspensions from '@/models/memberDataTypes/pensionSuspensions';
import dayjs from 'dayjs';

@Component
export default class PensionSuspensionsVue extends Vue {
    @Prop()
        dataTypeData!: PensionSuspensions;

    get items () {
        return this.dataTypeData?.items?.sort((a, b) => (a.from < b.from ? 1 : -1));
    }

    getToDate (to: number) {
        return to ? dayjs(new Date(to)).format('DD MMM YYYY') : 'Active';
    }
}
