const statusCollection: {[key: string]: string} = {
    Upcoming: 'Upcoming',
    InProgress: 'In Progress',
    Complete: 'Complete',
    Ceased: 'Ceased',
    Deleted: 'Deleted',
    ReferredExternal: 'In Progress',
    ReferredInternal: 'In Progress',
};

const statusCollectionColours: {[key: string]: string} = {
    Upcoming: '#00a3c7',
    InProgress: '#faa743',
    Complete: '#6cbd6c',
    Ceased: '#f27174',
    Deleted: '#f27174',
    ReferredExternal: '#faa743',
    ReferredInternal: '#faa743',
};

const statusCollectionIcons: {[key: string]: string} = {
    Upcoming: 'hourglass-start',
    InProgress: 'spinner',
    Complete: 'check',
    Ceased: 'ban',
    Deleted: 'ban',
    ReferredExternal: 'spinner',
    ReferredInternal: 'spinner',
};

export default {
    Upcoming: 'Upcoming',
    InProgress: 'InProgress',
    Complete: 'Complete',
    Ceased: 'Ceased',
    ReferredExternal: 'InProgress',
    ReferredInternal: 'InProgress',
    getStatuses () {
        return [this.InProgress, this.Upcoming, this.Complete, this.Ceased];
    },
    getStatusMap () {
        return statusCollection;
    },
    getName (id: string): string {
        return statusCollection[id];
    },
    getColour (id: string): string {
        return statusCollectionColours[id];
    },
    getIcon (id: string): string {
        return statusCollectionIcons[id];
    },
};
