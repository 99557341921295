
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import MemberLogins from '@/models/memberLogins';

@Component
export default class MemberLoginsChart extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberLogins') || false as boolean;
    }

    get memberLogins (): Array<MemberLogins> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberLogins');
    }

    get memberLoginsTranformed () {
        if (!this.memberLogins) return null;

        const vals: Array<SeriesOptionsType> = [];

        if (!this.filter || this.filter === 'New logins') {
            vals.push({
                name: 'New logins',
                color: ColourService.getSeriesColour('memberLogins', 'New logins'),
                type: 'column',
                data: this.memberLogins.map((a) => (a.logins ?? 0) - (a.repeatVisits ?? 0)),
            });
        }

        if (!this.filter || this.filter === 'Repeat logins') {
            vals.push({
                name: 'Repeat logins',
                color: ColourService.getSeriesColour('memberLogins', 'Repeat logins'),
                type: 'column',
                data: this.memberLogins.map((a) => a.repeatVisits ?? 0),
            });
        }

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.memberLogins?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.memberLoginsTranformed || [],
        };
    }
}
