
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';

@Component({
    name: 'profile-photo',
    components: { },
})
export default class ProfilePhoto extends Vue {
    @Prop()
    id!: string

    @Prop()
    email!: string;

    @Prop({ default: 'offline' })
    presence!: string;

    @Prop({ default: true })
    color!: boolean;

    @Prop()
    disabled!: boolean;

    useFallback = false;

    mounted () {
        if (!this.users.length) {
            this.$store.dispatch('users/getAllUsers', this.userUrl);
        }
    }

    get hoverText () {
        if (this.title) return this.title;
        if (this.disabled) return this.email;
        if (!this.email) return null;
        return `${this.email}`;
    }

    get userUrl () {
        return this.$store.getters['configuration/site']['pulse-api.url'];
    }

    get userPhotoUrl () {
        return this.$store.getters['configuration/site']['user.blob.url'];
    }

    get selectedUser () {
        return this.usersById[this.id] || {};
    }

    get usersById () {
        return this.$store.getters['users/allUsersByUserId'];
    }

    get users () {
        return this.$store.getters['users/allUsers'];
    }

    get title () {
        const name = this.selectedUser?.surname ? `${this.selectedUser.forenames} ${this.selectedUser.surname}` : this.email;
        if (name) return `${name}`;
        return '';
    }

    get link () {
        // eslint-disable-next-line no-script-url
        if (this.disabled) return 'javascript:void(0)';
        const email = this.email || this.selectedUser.email;
        if (email) return `sip:${email}`;
        // eslint-disable-next-line no-script-url
        return 'javascript:void(0)';
    }

    @Watch('id')
    idChanged () {
        this.useFallback = false;
    }
}

