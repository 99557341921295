import Http from 'common/api/apiHelper';
import SystemList from '../models/systemList';

const apiVersion = 'v1.0';
export default class SystemListApi {
    static getList (listName: string, pulseApiUrl = '') {
        return Http.get<SystemList>(`${pulseApiUrl}${apiVersion}/List/${listName}`, SystemList, {
            useBasePath: !pulseApiUrl,
        });
    }
}
