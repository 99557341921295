import SystemList from 'common/models/systemList';
import SystemListApi from 'common/api/systemListApi';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface CommonState {
    isChristmas: boolean;
    titles: SystemList | null;
    countries: SystemList | null;
}

export const state: CommonState = {
    isChristmas: new Date().getMonth() === 11 && new Date().getDate() >= 12 && new Date().getDate() < 2,
    titles: null,
    countries: null,
};

export const getters: GetterTree<CommonState, unknown> = {
    getTitles: (s) => s.titles,
    getCountries: (s) => s.countries,
};

export const mutations: MutationTree<CommonState> = {
    setTitles (s, titles: SystemList) {
        s.titles = titles;
    },
    setCountries (s, countries: SystemList) {
        s.countries = countries;
    },
};

export const actions: ActionTree<CommonState, unknown> = {
    async loadTitles ({ commit }, pulseApiUrl: string) {
        return SystemListApi.getList('Titles', pulseApiUrl)
            .then((result) => {
                const titles = Object.values<string>(result.list);
                commit('setTitles', titles);
                return titles;
            });
    },
    async loadCountries ({ commit }, pulseApiUrl: string) {
        return SystemListApi.getList('Countries', pulseApiUrl)
            .then((result) => {
                const formattedCountries = Object.values<string>(result.list).map((country) => ({ name: country, value: country, disabled: country.startsWith('-----------') }));
                commit('setCountries', formattedCountries);
                return formattedCountries;
            });
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
