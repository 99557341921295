export default class WebRegistrations {
    dataByDate: Array<{
        date: string;
        registrations: number;
        availableToRegister: number;
    }> = [];

    registeredTotal!: number;

    registeredPeriod!: number;

    registeredPercentage!: number;

    registeredPeriodPercentageOfTotal!: number;

    unregistered!: number;
}
