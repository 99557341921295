
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import MemberLogins from '@/models/memberLogins';
import Highcharts from 'highcharts';

@Component
export default class MemberLoginsByAgeChart extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        showLegend!: boolean | null;

    ageBands = [
        { min: 101, max: 200, label: '> 100' },
        { min: 91, max: 100 },
        { min: 81, max: 90 },
        { min: 71, max: 80 },
        { min: 61, max: 70 },
        { min: 51, max: 60 },
        { min: 41, max: 50 },
        { min: 31, max: 40 },
        { min: 19, max: 30 },
        { min: 0, max: 18, label: '< 19' },
    ];

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberLogins') || false as boolean;
    }

    get memberLogins (): Array<MemberLogins> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberLogins');
    }

    get memberLoginsTranformed () {
        if (!this.memberLogins) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];
        this.ageBands.forEach((ageBand) => {
            let ageTotal = 0;
            const ageBandData: Array<number> = [];
            this.memberLogins!.forEach((row) => {
                let total = 0;
                Object.keys(row.loginsByAge).forEach((age) => {
                    if (Number(age) >= ageBand.min && Number(age) <= ageBand.max) {
                        total += row.loginsByAge[age] ?? 0;
                    }
                });
                ageTotal += total;
                ageBandData.push(total);
            });
            if (ageTotal === 0) return;
            if (!this.filter || this.filter === (ageBand.label ?? `${ageBand.min} - ${ageBand.max}`)) {
                vals.push({
                    name: ageBand.label ?? `${ageBand.min} - ${ageBand.max}`,
                    type: 'column',
                    data: ageBandData,
                    color: ColourService.getSeriesColour('age', ageBand.label ?? `${ageBand.min} - ${ageBand.max}`),
                });
            }
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.memberLogins?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: this.showLegend || false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.memberLoginsTranformed || [],
        };
    }
}
