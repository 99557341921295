
import MemberFeedbackScores from '@/models/memberFeedbackScores';
import MemberFeedback from 'common/models/memberFeedback';
import YearlyQuarterlyFeedback from 'common/models/yearlyQuarterlyFeedback';
import Guid from 'common/util/guid';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class FeedbackQuarterlyScore extends Vue {
    @Prop() isCommunication!: boolean;

    @Prop() isService!: boolean;

    @Prop() isGovernanceScreen!: boolean;

    feedbacktype: string | null = null;

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get feedbackScores (): MemberFeedbackScores {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'feedback');
    }

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'feedback');
    }

    get previousFourFeedback (): Array<YearlyQuarterlyFeedback> {
        if (!this.feedbackScores?.memberFeedbackScores) return [];
        const distinctyears = this.findDistinctYears(this.feedbackScores.memberFeedbackScores);
        const feedback: Array<YearlyQuarterlyFeedback> = [];
        distinctyears.forEach((year) => {
            let filtered = this.feedbackScores.memberFeedbackScores.filter((item) => item.year === year);

            filtered = filtered.sort((a, b) => ((a.quarter < b.quarter) ? 1 : -1));
            const memberFeedbacksFiltered = new Array<MemberFeedback>();

            filtered.forEach((mf) => {
                if (memberFeedbacksFiltered.length < 4) {
                    memberFeedbacksFiltered.push(mf);

                    const yearlyFeedback = new YearlyQuarterlyFeedback();
                    yearlyFeedback.year = year;
                    yearlyFeedback.feedback = mf;

                    feedback.push(yearlyFeedback);
                }
            });
        });
        return feedback;
    }

    findDistinctYears (memberFeedback: Array<MemberFeedback>) {
        let distinctyears = [...new Map(memberFeedback.map((item) => [item.year, item.year])).values()];

        distinctyears = distinctyears.sort((a, b) => ((a < b) ? 1 : -1));

        return distinctyears;
    }
}

