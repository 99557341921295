
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import Guid from 'common/util/guid';
import Highcharts, { Point } from 'highcharts';
import ColourService from '@/services/colourService';
import MemberInteractions from '@/models/memberInteractions';
import PieLegend from '@/services/pieLegendService';
import Client from 'common/models/client';
import ClientConfiguration from '@/models/client';

@Component
export default class InProgressJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        showLegend!: boolean;

    selectedPoint: string | null = null;

    selectedColourIndex: number | null = null;

    chartCenterX = 0;

    chartCenterY = 0;

    get memberInteractions (): MemberInteractions | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberInteractions');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberInteractions');
    }

    get totalJobs () {
        if (!this.inprogressJobStatsTranformed) return 0;
        return this.inprogressJobStatsTranformed.filter((a) => a.name === this.selectedPoint || !this.selectedPoint).map((a) => a.y).reduce((a, b) => b + a, 0);
    }

    get centerPos () {
        return {
            top: `calc(${this.chartCenterY}px + 1.5rem) !important`,
            left: `calc(${this.chartCenterX}px + 1.5rem) !important`,
        };
    }

    get inprogressJobStatsTranformed () {
        if (!this.memberInteractions) return null;
        const counts: { [key: string ]: number } = {
            'Phone calls': 0,
            'Web requests': 0,
            Emails: 0,
        };
        this.memberInteractions.dataByDate.forEach(((a) => {
            counts['Phone calls'] += a.phoneCalls;
            counts['Web requests'] += a.webQueries;
            counts.Emails += a.emails;
        }));
        if (!this.hasMemberWeb) {
            delete counts['Web requests'];
        }
        return Object.keys(counts)
            .map((seriesName) => ({
                name: seriesName,
                y: counts[seriesName],
                color: ColourService.getSeriesColour('interactions', seriesName),
            }));
    }

    get client () {
        return (this.$store.getters['clientContext/clients'] as Array<Client>).find((a) => a.clientId === this.clientId);
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfiguration ?? {};
    }

    get hasMemberWeb () {
        return this.clientConfiguration?.hasMemberWeb;
    }

    pointClicked (point: Point) {
        this.selectedColourIndex = point.colorIndex;
        this.selectedPoint = point.name === this.selectedPoint ? null : point.name;
        this.$emit('pointSelected', { category: 'interactions', series: this.selectedPoint });
    }

    formatNumber (number?: string | number) {
        return Math.round(Number(number ?? 0)).toLocaleString('en');
    }

    clearFilter () {
        (this.$refs.chart as unknown as { chart: Highcharts.Chart }).chart.series[0].points.forEach((a) => a.select(false));
        this.selectedPoint = null;
        this.$emit('pointSelected', { category: 'interactions', series: this.selectedPoint });
    }

    get chartOptions (): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'pie',
                events: {
                    load () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                    redraw () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                },
            },
            xAxis: {
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: this.showLegend ? PieLegend.get().legend : {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        useHTML: true,
                        distance: -5,
                        style: {
                            fontSize: '0px',
                            width: 0,
                            height: 0,
                        },
                        formatter () {
                            switch (this.point.name) {
                                case 'Phone calls': return '<div class="label"><img src="/phone.png" /></div>';
                                case 'Web requests': return '<div class="label"><img src="/globe.png" /></div>';
                                case 'Emails': return '<div class="label"><img src="/envelope.png" /></div>';
                                default: return '<div />';
                            }
                        },
                    },
                    allowPointSelect: true,
                    showInLegend: true,
                    borderWidth: 0,
                    point: {
                        events: {
                            legendItemClick: (event) => {
                                event.preventDefault();
                                event.target.select(!event.target.selected);
                                this.pointClicked(event.target);
                            },
                        },
                    },
                },
            },
            series: [{
                type: 'pie',
                name: 'Interactions',
                innerSize: '70%',
                data: this.inprogressJobStatsTranformed!,
                events: {
                    click: (event) => { this.pointClicked(event.point); },
                },
            }],
        };
    }
}
