
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import { SeriesOptionsType } from 'highcharts';
import FinancialData from '@/models/financialData';

@Component
export default class Transfers extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean | null;

    currentFilter: string | null = null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'financials') || false as boolean;
    }

    get transferStats (): FinancialData {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'financials');
    }

    get transferStatsTranformed () {
        if (!this.transferStats) return null;
        let groupNames: Array<string> = [];
        this.transferStats.periods.forEach((period) => {
            period.groups.forEach((group) => {
                groupNames.push(group.name);
            });
        });
        groupNames = [...new Set(groupNames)];
        return groupNames.map((groupName) => ({
            name: groupName,
            color: ColourService.getSeriesColour('financial', groupName),
            type: 'column',
            data: this.transferStats.periods.map((a) => a.groups.find((b) => b.name === groupName)?.amount ?? 0),
        })) as Array<SeriesOptionsType>;
    }

    get startDate () {
        return this.transferStats?.periods[0].fromDate;
    }

    get endDate () {
        return this.transferStats?.periods[this.transferStats.periods.length - 1].toDate;
    }

    get startBalance () {
        return this.transferStats?.startBalance;
    }

    get endBalance () {
        return this.transferStats?.endBalance;
    }

    get highestVal () {
        return Math.max(...this.events.map((a) => Math.abs(a.val)));
    }

    get events () {
        const events: Array<{ date: string; name: string; val: number; color: string }> = [];
        if (!this.transferStats) return [];
        this.transferStats.periods.forEach((val) => {
            val.groups.forEach((group) => {
                events.push({
                    name: group.name,
                    val: group.amount,
                    date: val.fromDate,
                    color: ColourService.getSeriesColour('financial', group.name),
                });
            });
        });
        return events;
    }

    barColour (e: { date: string; name: string; val: number; color: string }, pos: 'first' | 'last') {
        if (e.val < 0 && pos === 'last') return {};
        if (e.val > 0 && pos === 'first') return {};
        return {
            width: `calc(${Math.abs(Math.ceil((e.val / this.highestVal) * 100))}% - 1px)`,
            background: e.color,

        };
    }

    formatDate (date: string) {
        if (!date) return '';
        return dayjs(date).format('DD MMM YYYY');
    }

    formatNumber (val: number) {
        return `${(val ?? 0).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
        })}`.replace('£', '');
    }

    filterRow (page: string) {
        if (this.currentFilter === page) {
            this.currentFilter = null;
            this.$emit('pointSelected', { category: 'income', series: null });
        } else {
            this.currentFilter = page;
            this.$emit('pointSelected', { category: 'income', series: page });
        }
    }
}
