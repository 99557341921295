
import {
    Component, Prop, Vue, Watch,
} from '~/vue-property-decorator';

@Component({
    name: 'grid-paging',
    components: {},
})
export default class GridPaging extends Vue {
    @Prop()
    total!: number;

    @Prop()
    pageNumber!: number;

    @Prop()
    pageSize!: number;

    @Prop()
    pageSizeOptions!: Array<number>;

    @Prop()
    isLoading?: boolean;

    internalPageSize = 0;

    errorMsg = '';

    mounted () {
        if (this.getPageSizeOptions && this.internalPageSize && !this.getPageSizeOptions?.includes(this.internalPageSize)) {
            this.errorMsg = `PageSizeOptions does not contain PageSize value of ${this.internalPageSize}`;
            throw new Error(this.errorMsg);
        }
    }

    areNavigationButtonsDisabled() {
        if (this.isLoading == undefined || this.isLoading == null) {
            return false;
        }

        return this.isLoading;
    }

    previousClick () {
        this.$emit('pageNumberChanged', this.pageNumber - 1);
    }

    nextClick () {
        this.$emit('pageNumberChanged', this.pageNumber + 1);
    }

    pageSizeChanged () {
        this.$emit('pageSizeChanged', this.internalPageSize);
    }

    get getPageSizeOptions () {
        return this.pageSizeOptions ?? [10, 20, 50];
    }

    @Watch('pageSize', { immediate: true })
    pageSizePropChanged () {
        this.internalPageSize = this.pageSize ?? this.getPageSizeOptions[0];
    }
}
