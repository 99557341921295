import { render, staticRenderFns } from "./feedback-quarterly-score.vue?vue&type=template&id=39acc577&scoped=true&"
import script from "./feedback-quarterly-score.vue?vue&type=script&lang=ts&"
export * from "./feedback-quarterly-score.vue?vue&type=script&lang=ts&"
import style0 from "./feedback-quarterly-score.vue?vue&type=style&index=0&id=39acc577&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39acc577",
  null
  
)

export default component.exports