

import * as STORE from 'common/store/storepaths';
import InformantContactDetails from 'common/models/deathInformantContactDetails';
import { ContactPreferences, RelationshipToDeceased } from 'common/models/deathEnums';
import { Component, Vue, Prop } from '~/vue-property-decorator';
import AddressComponent from './addressComponent.vue';
import { EMAIL_REGEX } from 'common/util/emailHelper';

@Component({
    name: 'death-notification-informant-contact-details',
    components: {
        AddressComponent,
    },
})
export default class DeathNotificationInformantContactDetails extends Vue {
    @Prop() memberId!: string;

    @Prop() contactDetails!: InformantContactDetails

    isLoading = false;

    memberTitleOptions: Array<string> = [];

    contactPreferenceOptions= Object.keys(ContactPreferences).map((a) => ({ name: a, value: ContactPreferences[a as keyof typeof ContactPreferences] }));

    relationshipToDeceasedOptions= Object.keys(RelationshipToDeceased).map((a) => ({ name: a, value: RelationshipToDeceased[a as keyof typeof RelationshipToDeceased] }));

    emailRegex = EMAIL_REGEX;

    async mounted () {
        this.isLoading = true;
        await this.loadMemberTitles();
        this.isLoading = false;
    }

    get pulseApiUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION][STORE.PULSE_API_URL] ?? '';
    }

    async loadMemberTitles () {
        const list = this.$store.getters[STORE.GET_TITLES];
        if (list == null) {
            this.memberTitleOptions = await this.$store.dispatch(STORE.LOAD_TITLES, this.pulseApiUrl);
        } else {
            this.memberTitleOptions = list;
        }
    }
}

