export enum YesNoUnknown {
    Yes = 'Yes',
    No = 'No',
    Unknown = 'Unknown'
}

export enum YesNoDontKnow {
    'Yes' = 'Yes',
    'No' = 'No',
    'Don\'t know' = 'DontKnow'
}

export enum ContactPreferences {
    Email = 'Email',
    Post = 'Post'
}

export enum RelationshipToDeceased {
    'Widow(er)' = 'Widow',
    'Civil Partner' = 'CivilPartner',
    'Child' = 'Child',
    'Other' = 'Other',
    'Legal Personal Representative' = 'LegalPersonalRepresentative',
    'Solicitors' = 'Solicitors'
}

export enum SpouseOrCivilPartnerContactOptions {
    'Yes' = 'UseSpouseOrCivilPartner',
    'No - same as informant' = 'UseInformant',
    'No - same as future correspondence' = 'UseFutureCorrespondence',
    'Unknown' = 'Unknown'
}

export enum OverUnderPaymentOptions {
    None = 'None',
    OverpaymentGross = 'OverpaymentGross',
    OverpaymentNone = 'OverpaymentNone',
    OverpaymentPossible = 'OverpaymentPossible',
    Underpayment = 'Underpayment',
    Unknown = 'Unknown'
}