import jobApi from 'common/api/jobApi';
import JobSummary from 'common/models/jobSummary';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface JobState {
    jobs: Array<JobSummary>;
    clientJobs: Array<JobSummary>;
    jobsDate: Date;
}

export const state: JobState = {
    jobs: [],
    clientJobs: [],
    jobsDate: new Date(),
};

export const getters: GetterTree<JobState, unknown> = {
    jobs: (s) => s.jobs,
    clientJobs: (s) => s.clientJobs,
    jobsDate: (s) => s.jobsDate,
};

export const actions: ActionTree<JobState, unknown> = {
    getPulseMemberJobs ({ commit }, { clientId, memberId, jobUrl, filter, commitJob = true }) {
        return jobApi.getJobsByMemberId(clientId, memberId, filter || 'All', jobUrl).then((jobs: Array<JobSummary>) => {
            if (commitJob) {
                commit('setJobs', { jobs });
            }
            return jobs;
        });
    },
    getPulseClientJobs ({ commit }, { clientId, jobUrl, filter, commitJob = true }) {
        return jobApi.getJobs(clientId, filter || 'All', 'All', jobUrl, 'Client').then((jobs: Array<JobSummary>) => {
            if (commitJob) {
                commit('setClientJobs', { jobs });
            }
            return jobs;
        });
    },
    getJob (context, { jobId, pulseApiUrl }) {
        return jobApi.getJob(jobId, pulseApiUrl);
    }
};

export const mutations: MutationTree<JobState> = {
    setJobs (s, jobs) {
        s.jobs = jobs;
        s.jobsDate = new Date();
    },
    setClientJobs (s, clientJobs) {
        s.clientJobs = clientJobs;
        s.jobsDate = new Date();
    },
};
