
import { Component, Vue, Prop } from '~/vue-property-decorator';

@Component({
    name: 'copyLinkButton',
    components: { },
})
export default class CopyLinkbutton extends Vue {
    @Prop()
    url!: string;

    copyLink () {
        const copyText = document.getElementById('urlToCopy')! as HTMLInputElement;

        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');

        this.$store.dispatch('messages/addMessage', {
            status: 'Success', title: 'Copy successful', popup: true, message: `Link has been copied to the clipboard:<br/><br/><span class="select">${copyText.value}</span>`,
        });
    }
}

