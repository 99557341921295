import Guid from 'common/util/guid';

export default class CorrespondenceSummary {
    id!: Guid;

    reference!: string;

    inOutInternal?: string;

    type!: string;

    category?: string;

    description!: string;

    status?: string;

    from?: string;

    to?: string;

    effectiveDate!: Date;

    phoneCallId?: number;

    webQueryId?: number;

    jobId?: string;

    documentLastUpdatedBy?: Guid;

    hasCalculation?: boolean;
}
