
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import MemberDocumentStats from '@/models/memberDocumentStats';
import Highcharts from 'highcharts';

@Component
export default class PayslipViewsByStatus extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberDocumentsStats') || false as boolean;
    }

    get documentStats (): Array<MemberDocumentStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberDocumentsStats');
    }

    get categories () {
        if (!this.documentStats) return [];
        const dataTypes: Array<string> = [];
        this.documentStats.forEach((date) => {
            dataTypes.push(...Object.keys(date.payslipDataByStatus));
        });
        return [...new Set(dataTypes)];
    }

    get documentStatsTransformed () {
        if (!this.documentStats) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];

        this.categories.forEach((status) => {
            const dataTypeData: Array<number> = [];
            this.documentStats!.forEach((row) => {
                dataTypeData.push(row.payslipDataByStatus[status] ?? 0);
            });
            if (!this.filter || this.filter === status) {
                vals.push({
                    name: status,
                    type: 'column',
                    data: dataTypeData,
                    color: ColourService.getSeriesColour('status', status),
                });
            }
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.documentStats?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.documentStatsTransformed || [],
        };
    }
}
