import Guid from 'common/util/guid';
import MemberCalculation from 'common/models/calculations/memberCalculation';
import CalculationArguments from 'common/models/calculations/calculationArguments';
import CalculationResult from 'common/models/calculations/calculationResult';
import CalculationHistorySummary from 'common/models/calculations/calculationHistorySummary';
import CalculationHistoryItem from 'common/models/calculations/calculationHistoryItem';
import ManualCalculationDefinition from 'common/models/calculations/manualCalculationDefinition';
import Http from './apiHelper';

const apiVersion = 'v1.0';

export default class CalculationsApi {
    static getCalculationsForMember (clientId: Guid, memberId: number, servicePeriodId: number) {
        return Http.getAsArray<MemberCalculation>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculationType`);
    }

    static getCalculationArguments (clientId: Guid, calculationId: string) {
        return Http.get<CalculationArguments>(`${apiVersion}/client/${clientId}/calculation/${calculationId}`);
    }

    static runCalculation (clientId: Guid, memberId: number, servicePeriodId: number, calculationId: string, data: { arguments: string }) {
        return Http.post<CalculationResult>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation/${calculationId}/run`, data);
    }

    static runCalculationForMemberStatusChange (clientId: Guid, memberId: number, servicePeriodId: number, calculationId: Guid, retirementOption: string) {
        return Http.get<CalculationResult>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation/memberStatusChange/${calculationId}/${retirementOption}`);
    }

    static getManualCalculationsForMember (clientId: Guid, memberId: number, servicePeriodId: number) {
        return Http.getAsArray<ManualCalculationDefinition>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation/manualType`);
    }

    static runManualCalculation (clientId: Guid, memberId: number, servicePeriodId: number, data: unknown) {
        return Http.post<CalculationResult>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation`, data, undefined, {
            showSuccessMessage: true,
            successTitle: 'Success',
            message: 'Calculation created',
        });
    }

    static getCalculationHistory (clientId: Guid, memberId: number) {
        return Http.getAsArray<CalculationHistorySummary>(`${apiVersion}/client/${clientId}/member/${memberId}/calculation`);
    }

    static getCalculationHistoryItem (clientId: Guid, memberId: number, servicePeriodId: number, calculationId: Guid) {
        return Http.get<CalculationHistoryItem>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation/${calculationId}`);
    }

    static finaliseCalculation (clientId: Guid, memberId: number, servicePeriodId: number, calculationId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation/${calculationId}/finalise`, {}, undefined, {
            showSuccessMessage: true,
            successTitle: 'Success',
            message: 'Calculation finalised',
        });
    }

    static updateCalculation (clientId: Guid, memberId: number, servicePeriodId: number, calculationId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation/${calculationId}/`, data, undefined, {
            showSuccessMessage: true,
            successTitle: 'Success',
            message: 'Calculation saved',
        });
    }

    static duplicateCalculation (clientId: Guid, memberId: number, servicePeriodId: number, calculationId: Guid) {
        return Http.get<ManualCalculationDefinition>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/calculation/${calculationId}/copy`, ManualCalculationDefinition);
    }
}
