import Guid from 'common/util/guid';

export default class Document {
    canEdit!: boolean;

    category = 'Not set';

    categoryName!: string;

    documentCategoryId!: Guid;

    documentId!: Guid;

    documentSourceId!: Guid;

    fileName!: string;

    effectiveDate: Date = new Date();

    fileType!: {
        canEdit: boolean;
        contentType: string;
        extension: string;
        id: Guid;
        isAvailableOnline: boolean;
        name: string;
        status: string;
    }

    history: Array<{
        documentHistoryId: Guid;
        type: string;
        updated: Date;
        updatedBy: Guid;
    }> = []

    jobId!: Guid | null;

    jobs?: Array<{
        jobDocumentId: Guid;
        jobId: Guid;
    }>

    memberId?: Guid;

    name!: string;

    sequence!: number;

    source: {
        canEdit: boolean;
        id?: Guid;
        isEmail: boolean;
        isScanning: boolean;
        isUser: boolean;
        name: string;
    } = {
        canEdit: false,
        isEmail: false,
        isScanning: false,
        isUser: false,
        name: '',
    }

    sourceName!: string;

    sourceType!: string;

    status!: string;

    updated!: Date;

    updatedBy!: Guid;
}
