import Vue from 'vue';
import Vuex from 'vuex';

import commonModules from 'common/store/modules';
import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: { ...modules, ...commonModules },
    strict: process.env.NODE_ENV !== 'production',
});

export default store;
