
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DateComponent, DynamicUiDate, DynamicUiEnumDropdown } from '@lcp/dynamic-ui-typescript';
import Guid from 'common/util/guid';
import ClientContext from '@/models/clientContext';
import Client from 'common/models/client';
import dayjs from '~/dayjs';

@Component({
    components: { DynamicUiDate, DynamicUiEnumDropdown },
})
export default class HeaderBar extends Vue {
    datePreset = 'Custom';

    datesToggled = false;

    @Watch('clientContext', { immediate: true })
    clientContextChanged () {
        if (!this.hasLoaded && this.clientContext) this.loadDateData();
    }

    formatDate (date: string | Date) {
        return dayjs(date).format('YYYY-MM-DD');
    }

    get viewMonthly () {
        return this.$store.getters['clientContext/viewMonthly'](this.clientId);
    }

    set viewMonthly (viewMonthly: boolean) {
        this.$store.commit('clientContext/setViewMonthly', { clientId: this.clientId, viewMonthly });
        this.loadDateData();
    }

    get showDates () {
        return this.$route.meta?.showDates;
    }

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    get dateOptions () {
        return this.clientContext ? [
            ...this.clientContext.presetDateOptions,
            { name: 'Custom', start: new Date(), end: new Date() }]
            : [{ name: 'Custom', start: new Date(), end: new Date() }];
    }

    get clientContext () {
        if (this.clientId) {
            return this.$store.getters['clientContext/context'](this.clientId) as ClientContext;
        }
        return null;
    }

    get minDate () {
        return this.clientContext?.minDate;
    }

    get maxDate () {
        return this.clientContext?.maxDate;
    }

    get fromDate () {
        return this.$store.getters['clientContext/fromDate'](this.clientId) as string;
    }

    set fromDate (val: string | Date) {
        if (val === this.fromDate) return;
        if (this.$refs.fromDate && (this.$refs.fromDate as unknown as { localDefinition: DateComponent}).localDefinition.isValid) {
            this.$store.commit('clientContext/setFromDate', { clientId: this.clientId, date: val });
        }
        this.loadDateData();
        if (val !== this.datePresetObject.start) this.datePreset = 'Custom';
    }

    get toDate () {
        return this.$store.getters['clientContext/toDate'](this.clientId) as string;
    }

    set toDate (val: string | Date) {
        if (val === this.toDate) return;
        if (this.$refs.toDate && (this.$refs.toDate as unknown as { localDefinition: DateComponent}).localDefinition.isValid) {
            this.$store.commit('clientContext/setToDate', { clientId: this.clientId, date: val });
        }
        this.loadDateData();
        if (val !== this.datePresetObject.end) this.datePreset = 'Custom';
    }

    get datePresetObject () {
        const obj = this.dateOptions.find((a) => a.name === this.datePreset)!;
        return {
            ...obj,
            start: this.formatDate(obj.start),
            end: this.formatDate(obj.end),
        };
    }

    get hasLoaded () {
        return this.clientContext && !!this.$store.getters['clientStatistics/stats'](this.clientId, 'completedCases');
    }

    datePresetChanged () {
        if (this.datePreset !== 'Custom') {
            this.fromDate = this.datePresetObject.start;
            this.toDate = this.datePresetObject.end;
        }
    }

    loadDateData () {
        if (this.fromDate && this.toDate && this.clientId) {
            this.$store.dispatch('clientStatistics/loadClientStatsForDates', {
                clientId: this.clientId,
                fromDate: this.fromDate,
                toDate: this.toDate,
                viewMonthly: this.viewMonthly,
            });
        }
    }
}
