
import * as STORE from 'common/store/storepaths';
import Client from 'common/models/client';
import { Component, Prop, Vue } from '~/vue-property-decorator';

@Component({
    name: 'member-button',
    components: {},
})
export default class MemberButton extends Vue {
    @Prop()
    memberId!: string;

    get currentClient () {
        return (this.$store.getters[STORE.GET_CURRENT_CLIENT] as Client);
    }

    get title () {
        return `Go to member ${this.memberId}`;
    }

    gotoMember () {
        const routeData = this.$router.resolve(`/${this.currentClient.code}/memberData/${this.memberId}`);
        window.open(routeData.href, '_blank');
    }
}
