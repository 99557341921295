import Guid from 'common/util/guid';
import ReminderSummary from './reminderSummary';
import JobReferral from './jobReferral';
import JobTimeStatus from './jobTimeStatus';
import JobStatus from './jobStatus';

export default class JobSummary {
    jobId!: Guid;

    clientId!: Guid;

    reference!: string;

    name!: string;

    description!: string;

    status!: JobStatus;

    timeStatus?: JobTimeStatus;

    startDate!: Date;

    targetDate!: Date;

    dueInDays!: number;

    overdueDays!: number;

    clientName!: string;

    definitionName!: string;

    completionDate?: Date;

    memberId?: number;

    userId?: Guid;

    externalReferral?: string;

    reminder?: ReminderSummary;

    actionDate!: Date;

    updated?: Date;

    created!: Date;

    surname?: string;

    forenames?: string;

    nino?: string;

    whistleblowingEndDate?: Date;

    referral?: JobReferral;
}
