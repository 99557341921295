
import { Component, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';

@Component
export default class SchemeGovernance extends Vue {
    selectedSeries: { [category: string ]: string } = {};

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get row () {
        return this.$route.params.row;
    }

    pointSelected ({ category, series }: { category: string; series: string }) {
        Vue.set(this.selectedSeries, category, series);
    }

    mounted () {
        this.$nextTick(() => {
            if (this.$refs[this.row]) {
                (this.$refs.content as HTMLElement).scrollTop = ((this.$refs[this.row] as Vue).$el as HTMLElement).offsetTop;
            }
        });
    }
}
