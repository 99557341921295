
import Status from 'common/util/pulseStatusEnum';
import Client from 'common/models/client';
import * as STORE from 'common/store/storepaths';
import Guid from 'common/util/guid';
import JobSummary from 'common/models/jobSummary';
import moment from '~/moment';

import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';

@Component({
    name: 'job-selector',
    components: { },
})
export default class JobSelector extends Vue {
    @Prop()
    memberId!: number;

    @Prop()
    systemMemberId!: number;

    @Prop()
    value!: string;

    @Prop()
    disabled!: boolean;

    @Prop()
    reassigning!: boolean;

    @Prop()
    name!: string;

    @Prop()
    removePrefix!: string;

    @Prop()
    hideFinal!: boolean;

    @Prop()
    clientLevel!: boolean;

    showDropdown = false;

    jobs: Array<JobSummary> = [];

    get jobPrefix () {
        return this.removePrefix;
    }

    get memberData () {
        if (!this.currentClient) return {};
        const memberData = this.$store.getters['memberData/memberData']?.(this.currentClient.name, this.memberId);
        return memberData;
    }

    get storedSystemMemberId () {
        return this.systemMemberId ?? (this.memberData ? this.memberData?.memberDetails?.systemMemberId : undefined);
    }

    get currentClient () {
        return (this.$store.getters[STORE.GET_CURRENT_CLIENT] as Client);
    }

    get jobUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION]['pulse-api.url'];
    }

    getColor (status: string) {
        const color = Status.getColour(status);
        return color;
    }

    getIcon (status: string) {
        const icon = Status.getIcon(status);
        return icon;
    }

    getName (status: string) {
        const name = Status.getName(status);
        return name;
    }

    formatDate (date: Date) {
        return moment(date).format('DD/MM/YYYY');
    }

    input (val: unknown) {
        this.$emit('input', val);
    }

    async getJobs () {
        this.jobs = [];
        if (this.currentClient.clientId && this.clientLevel) {
            this.jobs = [...this.jobs, ...await this.$store.dispatch('jobs/getPulseClientJobs', { clientId: this.currentClient.clientId, jobUrl: this.jobUrl, filter: 'Active', commitJob: false })];
            this.jobs = [...this.jobs, ...await this.$store.dispatch('jobs/getPulseClientJobs', { clientId: this.currentClient.clientId, jobUrl: this.jobUrl, filter: 'Upcoming', commitJob: false })];
        } else if (this.storedSystemMemberId) {
            this.jobs = [...this.jobs, ...await this.$store.dispatch('jobs/getPulseMemberJobs', { clientId: this.currentClient.clientId, memberId: this.storedSystemMemberId, jobUrl: this.jobUrl, filter: 'Active', commitJob: false })];
            this.jobs = [...this.jobs, ...await this.$store.dispatch('jobs/getPulseMemberJobs', { clientId: this.currentClient.clientId, memberId: this.storedSystemMemberId, jobUrl: this.jobUrl, filter: 'Upcoming', commitJob: false })];
        }
        if (this.value) {
            const currentJob = await this.$store.dispatch('jobs/getJob', { jobId: this.value, pulseApiUrl: this.jobUrl });
            if (!this.jobs.find(a => a.jobId === currentJob.jobId)) this.jobs = [currentJob, ...this.jobs ];
        }
        const allowedStatuses = ['InProgress', 'Upcoming', 'ReferredInternal', 'ReferredExternal'];



        this.jobs = this.jobs.filter((a) => a.jobId.toString() === this.value
         || (allowedStatuses.includes(a.status) && (!this.clientLevel || (this.clientLevel && !a.memberId))))
            .map((a) => ({ ...a, number: a.reference.replace(this.jobPrefix, '') }))
            .sort((a, b) => (a.targetDate < b.targetDate ? -1 : 1))
            .sort((a, b) => (a.status === 'Complete' && b.status !== 'Complete' ? 1 : -1));

    }

    jobSelected (row: PointerEvent, selectedJobId: Guid) {
        this.$emit('change', selectedJobId);
    }

    @Watch('storedSystemMemberId')
    memberIdChanged (val: boolean) {
        if (val) {
            this.getJobs();
        }
    }

    @Watch('currentClient', { immediate: true })
    currentClientChanged (val: boolean) {
        if (val) {
            this.getJobs();
        }
    }
}

