
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import ModellerStats from '@/models/modellerStats';
import Highcharts from 'highcharts';

@Component
export default class ModellerVisitsByAge extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean;

    viewByPage = false;

    ageBands = [
        { min: 101, max: 200, label: '> 100' },
        { min: 91, max: 100 },
        { min: 81, max: 90 },
        { min: 71, max: 80 },
        { min: 61, max: 70 },
        { min: 51, max: 60 },
        { min: 41, max: 50 },
        { min: 31, max: 40 },
        { min: 19, max: 30 },
        { min: 0, max: 18, label: '< 19' },
    ];

    get modellerVisits (): Array<ModellerStats> | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'modellerStats');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'modellerStats');
    }

    get colours () {
        return ColourService.colours;
    }

    get documentTypes () {
        if (!this.modellerVisits) return [];
        const pages: Array<string> = [];

        this.modellerVisits.forEach((row) => {
            pages.push(...row.ageGroupSections.map((a) => a.sectionName));
        });

        const filteredPages = pages.filter((value) => value !== 'Print summary?'
            && value !== 'Request an illustration?'
            && value !== 'Introduction'
            && value !== 'introduction');

        return [...new Set(filteredPages)];
    }

    get modellerVisitsTransformed () {
        if (!this.modellerVisits) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];

        this.ageBands.forEach((ageBand) => {
            const ageBandData: Array<number> = [];
            let ageTotal = 0;
            if (this.viewByPage) {
                this.documentTypes.forEach((page) => {
                    let total = 0;
                    this.modellerVisits!.forEach((row) => {
                        const section = row.ageGroupSections.find((a) => a.sectionName === page)?.viewsByAge;

                        Object.keys(section || {}).forEach((age) => {
                            if (Number(age) >= ageBand.min && Number(age) <= ageBand.max) {
                                total += section![age];
                            }
                        });
                    });
                    ageTotal += total;
                    ageBandData.push(total);
                });
            } else {
                this.modellerVisits!.forEach((row) => {
                    let total = 0;
                    Object.keys(row.visitsByAge).forEach((age) => {
                        if (Number(age) >= ageBand.min && Number(age) <= ageBand.max) {
                            total += row.visitsByAge[age];
                        }
                    });
                    ageBandData.push(total);
                    ageTotal += total;
                });
            }
            if (ageTotal === 0) return;
            if (!this.filter || this.filter === (ageBand.label ?? `${ageBand.min} - ${ageBand.max}`)) {
                vals.push({
                    name: ageBand.label ?? `${ageBand.min} - ${ageBand.max}`,
                    type: 'column',
                    data: ageBandData,
                    color: ColourService.getSeriesColour('age', ageBand.label ?? `${ageBand.min} - ${ageBand.max}`),
                });
            }
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
            },
            xAxis: {
                categories: this.viewByPage ? this.documentTypes : this.modellerVisits?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => (this.viewByPage ? this.formatSectionName(that.value as string) : dayjs(that.value).format('DD MMM'))),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            colors: this.colours,
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.modellerVisitsTransformed || [],
        };
    }

    formatSectionName (sectionName: string) {
        switch (sectionName) {
            case 'Your pension benefits':
                return 'Retirement pension';
            case 'Get a cash lump sum on retirement?':
                return 'Retirement cash lump sum';
            case 'Your retirement benefits':
                return 'Retirement pension age';
            case 'Introduction':
            case 'introduction':
                return 'Transfer value';
            default:
                return sectionName;
        }
    }
}
