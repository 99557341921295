/* eslint-disable max-classes-per-file */

export class TransferStat {
    startDate!: Date;

    endDate!: Date;

    quotes!: number;

    quotesAmount!: number;

    transfers!: number;

    transferAmount!: number;
}

export default class TransferStats {
    dataByDate!: Array<TransferStat>;
}
