import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import HighchartsVue from 'highcharts-vue';
import {

    faPlus, faUser,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faHome, faUserAlt, faUserClock, faEnvelope, faLink, faPhone, faUsersClass, faMoneyBill,
    faFileWord, faFilePdf, faFileExcel, faPoundSign, faSearch,
    faSignOutAlt, faAngry, faTimesCircle, faNewspaper, faDownload, faFileAlt, faFileDownload,
    faTimes, faCalendar, faInfo, faExchange, faFileSignature, faUserFriends,
    faClipboardListCheck, faMoneyCheck, faQuestion, faFilePlus, faGlobe, faArrowDown, faArrowUp,
    faCaretDown, faUserMagnifyingGlass, faArrowLeft, faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import IdleVue from 'idle-vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DynamicUiCommonUi, clickOutside } from '@lcp/dynamic-ui-typescript';
import More from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import VTooltip from 'v-tooltip';
import Highcharts from 'highcharts';
import MapChart from '@lcp/map-chart';
import dayjs from 'dayjs';
import App from './App.vue';
import router from './router';
import store from './store/index';
import 'common/components/_globals';
import '@/components/_global';
import '@/components/memberDataTypes/_global';

More(Highcharts);
SolidGauge(Highcharts);
Vue.config.productionTip = false;
Vue.use(HighchartsVue, { Highcharts });
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('dynamic-ui-common-ui', DynamicUiCommonUi);
Vue.use(MapChart);

const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 30 * 60000, // 30 minutes
    startAtIdle: false,
});
clickOutside(Vue);
Highcharts.setOptions({
    lang: {
        thousandsSep: ',',
    },
});
Vue.use(VTooltip, { defaultTrigger: 'hover focus click' });

Vue.filter('integer', (value: number) => {
    if (value === undefined) return '';
    return value.toString();
});

Vue.filter('date', (value: string) => {
    if (value === undefined) return '';
    return dayjs(value).format('DD MMM YYYY');
});

Vue.filter('decimal', (value: number) => {
    if (value === undefined) return '';
    return (Math.round(value * 100) / 100).toLocaleString();
});

Vue.filter('currency', (value: number) => {
    if (value === undefined) return '';
    return `£${(Math.round(value * 100) / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`;
});

Vue.filter('percentage', (value: number) => {
    if (value === undefined) return '';
    return `${(Math.round(value * 100)).toLocaleString()}%`;
});

Vue.filter('twoDpPercentage', (value: number) => {
    if (value === undefined) return '';
    return `${(Math.round(value * 10000) / 100).toLocaleString()}%`;
});

library.add(faUser, faPlus, faHome, faUserAlt, faUserClock, faPhone, faLink, faEnvelope, faUsersClass, faMoneyBill, faFileWord, faFilePdf, faFileExcel, faPoundSign, faSearch, faAngry, faTimesCircle, faNewspaper, faDownload, faFileAlt, faUser, faSignOutAlt);
library.add(faUser, faPlus, faHome, faUserAlt, faUserClock, faPhone, faLink, faEnvelope, faUsersClass, faUserFriends,
    faMoneyBill, faFileWord, faFilePdf, faFileExcel, faPoundSign, faSearch, faAngry, faTimesCircle, faExchange, faFilePlus, faGlobe,
    faNewspaper, faDownload, faFileAlt, faUser, faFileDownload, faTimes, faCalendar, faInfo, faFileSignature, faClipboardListCheck, faQuestion, faMoneyCheck,
    faArrowUp, faArrowDown, faCaretDown, faUserMagnifyingGlass, faArrowLeft, faArrowRight);
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
