import DataValidation from './dataValidation';

export default class MemberDataTypeResponse {
    latestData!: unknown;

    isSuccess!: boolean;

    validationResults!: Array<DataValidation>;

    duiValidationResults: unknown;
}
