import Http from 'common/api/apiHelper';
import Client from 'common/models/client';
import MemberSummary from 'common/models/memberSummary';
import User from 'common/models/user';
import PhoneCall from 'common/models/phoneCall';
import Guid from 'common/util/guid';
import MemberDetail from 'common/models/memberDetail';
import ClientDashboardSummary from 'common/models/clientDashboardSummary';
import UnverifiedDataSummary from 'common/models/unverifiedDataSummary';
import ClientMemberWebDetails from 'common/models/webRegistrations/clientMemberWebDetails';
import CorrespondenceSummary from 'common/models/correspondence/correspondenceSummary';
import CorrespondenceEmail from 'common/models/correspondence/email';
import EmailAttachment from 'common/models/correspondence/emailAttachment';
import CorrespondenceWeb2Query from 'common/models/correspondence/webQuery';
import ClientHistory from 'common/models/clientHistory';
import MemberFeedback from 'common/models/memberFeedback';
import ClientWebDocument from 'common/models/clientWebDocument';
import MemberSearchParams from 'common/models/memberSearchParams';
import ClientMemberDataTypeOverride from 'common/models/clientMemberDataTypeOverride';
import MemberValidationAuditHistory from 'common/models/memberValidations/memberValidationAuditHistory';
import MemberDataTypeSummary from 'common/models/memberDataTypeSummary';
import ValidationRuleDefinitionSummary from 'common/models/validationRuleDefinitionSummary';
import EmailHistory from 'common/models/correspondence/emailHistory';
import ClientUnverifiedDataSummary from 'common/models/clientUnverifiedDataSummary';
import InsurerPolicyUploadOption from 'common/models/insurerPolicyUploadOption';
import InsurerReceiptUploadQuery from 'common/models/insurerReceiptUploadQuery';
import InsurerReceiptUploadResult from 'common/models/insurerReceiptUploadResult';
import MemberDataType from 'common/models/memberDataType';

const apiVersion = 'v1.0';

export default class ClientsApi {

    static addClient (data: { name: string; code: string; teamId: Guid|null; }) {
        return Http.post(`${apiVersion}/client`, data);
    }

    static getClients () {
        return Http.getAsArray(`${apiVersion}/client`, Client);
    }

    static getClientDashboard (clientId: Guid) {
        return Http.get<ClientDashboardSummary>(`${apiVersion}/client/${clientId}/dashboard`);
    }

    static updateClient (client: Client) {
        return Http.put(`${apiVersion}/client/${client.clientId}/`, client);
    }

    static getClient (clientId: Guid) {
        return Http.get<Client>(`${apiVersion}/client/${clientId}/`);
    }

    static getTimeRecordingClients () {
        return Http.get<string[]>(`${apiVersion}/timerecording/clients`);
    }

    static memberAdvancedSearch (clientId: Guid, search: MemberSearchParams, pulseApiUrl = '') {
        return Http.getAsArray<MemberSummary>(`${pulseApiUrl}${apiVersion}/client/${clientId}/member?${Object.keys(search).filter((key) => search[key]?.toString().length).map((key) => `${key}=${search[key]}`).join('&')}`, MemberSummary, {
            returnHeaders: true,
            useBasePath: !pulseApiUrl,
        }) as Promise<(Array<MemberSummary> & {headers: { 'x-total-count': number } })>;
    }

    static getClientMembers (clientId: Guid, search: string, pulseApiUrl = '') {
        return Http.getAsArray<MemberSummary>(`${pulseApiUrl}${apiVersion}/client/${clientId}/member?search=${search}`, MemberSummary, { useBasePath: !pulseApiUrl });
    }

    static getClientMember (clientId: Guid, memberId: string) {
        return Http.get<MemberDetail>(`${apiVersion}/client/${clientId}/member/${memberId}`);
    }

    static getTeamUsers (teamId: Guid) {
        return Http.getAsArray<User>(`${apiVersion}/team/${teamId}/user`);
    }

    static getRecentMembers (clientId: Guid) {
        return Http.getAsArray<MemberSummary>(`${apiVersion}/client/${clientId}/member/recent`);
    }

    static createClientCall (clientId: Guid, data: { time: string; data: { conversation: string; incomingCall: boolean; otherCallerDetails: { capacity: string; name: string; company: string; number: string }; subject: string } }) {
        return Http.post<PhoneCall>(`${apiVersion}/client/${clientId}/call`, data);
    }

    static getUserClients (pulseApiUrl = '') {
        return Http.getAsArray<Client>(`${pulseApiUrl}${apiVersion}/user/client`, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static createClientMember (clientId: Guid) {
        return Http.post<MemberSummary>(`${apiVersion}/client/${clientId}/member/`, null);
    }

    static createClientTestMember (clientId: Guid) {
        return Http.post<MemberSummary>(`${apiVersion}/client/${clientId}/member/seeded`, null);
    }

    static getUnverifiedData (clientId: Guid, pageNumber = 1, pageSize = 50, sortBy: string = '', sortAscending: boolean = false, memberId: string = '', surname: string = '', memberDataType:string = '') {

        var url = `${apiVersion}/client/${clientId}/member/unverified?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=${sortBy}&sortAscending=${sortAscending}`;

        var params = new URLSearchParams();

        if (memberId) {
            params.append('memberId', memberId);
        }

        if (surname) {
            params.append('surname', surname);
        }

        if (memberDataType) {
            params.append('memberDataType', memberDataType);
        }

        if (params.toString().length){
            url += "&" + params.toString();
        }

        return Http.get<UnverifiedDataSummary>(url, UnverifiedDataSummary, { returnHeaders: true }) as Promise<(UnverifiedDataSummary & {headers: { 'x-total-count': number } })>;
    }

    static getAllClientsUnverifiedData () {
        return Http.getAsArray<ClientUnverifiedDataSummary>(`${apiVersion}/client/unverified`);
    }

    static getAllClientsUnverifiedReport () {
        return Http.get<ArrayBuffer>(`${apiVersion}/client/unverified/report`, undefined, {
            returnHeaders: true,
            config: {
                responseType: 'blob',
            },
        }) as Promise<ArrayBuffer & {headers: { 'content-disposition': string } }>;
    }

    static getMemberWebDetails (clientId: Guid) {
        return Http.get<ClientMemberWebDetails>(`${apiVersion}/${clientId}/web/detail`);
    }

    static getClientCorrespondence (clientId: Guid, type: string, fromDate: string, toDate: string, searchTerm: string, includeWithdrawn: boolean, pageNumber: number, pageSize: number) {
        return Http.getAsArray<CorrespondenceSummary>(`${apiVersion}/client/${clientId}/correspondence/${type}?fromDate=${fromDate}&toDate=${toDate}&searchTerm=${searchTerm}&includeWithdrawn=${includeWithdrawn}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
            CorrespondenceSummary, { returnHeaders: true }) as Promise<(Array<CorrespondenceSummary> & {headers: { 'x-total-count': number } })>;
    }

    static getClientCorrespondenceDocument (clientId: Guid, documentId: string) {
        return Http.get<CorrespondenceSummary>(`${apiVersion}/client/${clientId}/correspondence/document/${documentId}`);
    }

    static getClientCorrespondenceEmail (clientId: Guid, emailId: string) {
        return Http.get<CorrespondenceSummary>(`${apiVersion}/client/${clientId}/correspondence/email/${emailId}`);
    }

    static getClientCorrespondencePhoneCall (clientId: Guid, phoneCallId: string) {
        return Http.get<CorrespondenceSummary>(`${apiVersion}/client/${clientId}/correspondence/phoneCall/${phoneCallId}`);
    }

    static getClientCorrespondenceWebQuery (clientId: Guid, webQueryId: string) {
        return Http.get<CorrespondenceSummary>(`${apiVersion}/client/${clientId}/correspondence/webQuery/${webQueryId}`);
    }

    static getClientCall (clientId: Guid, callId: number) {
        return Http.get<PhoneCall>(`${apiVersion}/client/${clientId}/call/${callId}`);
    }

    static duplicateClientCall (clientId: Guid, callId: number) {
        return Http.post<PhoneCall>(`${apiVersion}/client/${clientId}/call/${callId}/copy`, null);
    }

    static deleteClientCall (clientId: Guid, callId: number) {
        return Http.delete(`${apiVersion}/client/${clientId}/call/${callId}`);
    }

    static amendClientCall (clientId: Guid, callId: number, data: {subject: string; conversation: string}) {
        return Http.put<PhoneCall>(`${apiVersion}/client/${clientId}/call/${callId}`, data, undefined, {
            showSuccessMessage: true,
            message: 'Call updated',
        });
    }

    static getClientEmail (clientId: Guid, emailReference: string, url = '') {
        return Http.get<CorrespondenceEmail>(`${url}${apiVersion}/client/${clientId}/email/${emailReference}`, undefined, { useBasePath: !url });
    }

    static deleteClientEmail (clientId: Guid, emailReference: string) {
        return Http.delete(`${apiVersion}/client/${clientId}/email/${emailReference}`);
    }

    static updateClientEmail (clientId: Guid, emailReference: string, url = '', data: {description?: string}) {
        return Http.put(`${url}${apiVersion}/client/${clientId}/email/${emailReference}`, data, undefined, {
            showSuccessMessage: true,
            message: 'Email updated',
            useBasePath: !url,
        });
    }

    static getClientEmailAttachment (clientId: Guid, emailReference: string, attachmentId: string, url = '') {
        return Http.get<EmailAttachment>(`${url}${apiVersion}/client/${clientId}/email/${emailReference}/attachment/${attachmentId}`, undefined, { useBasePath: !url });
    }

    static getClientEmailHistory (clientId: Guid, emailReference: string, url = '') {
        return Http.getAsArray<EmailHistory>(`${url}${apiVersion}/client/${clientId}/email/${emailReference}/history`, undefined, { useBasePath: !url });
    }

    static getClientWebQuery (clientId: Guid, webQueryId: number) {
        return Http.get<CorrespondenceWeb2Query>(`${apiVersion}/client/${clientId}/web/${webQueryId}`);
    }

    static getClientHistory (clientId: Guid,
        fromDate: string, toDate: string,
        includeIndividual: boolean, includeBulk: boolean,
        memberId?: string, servicePeriodId?: string,
        dataType?: string, historyType?: string,
        pageNumber = 1, pageSize = 50) {
        return Http.getAsArray<ClientHistory>(`${apiVersion}/client/${clientId}/history?fromDate=${fromDate}&toDate=${toDate}&includeIndividual=${includeIndividual}&includeBulk=${includeBulk}&memberId=${memberId}&servicePeriodId=${servicePeriodId}&dataType=${dataType}&historyType=${historyType}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
            ClientHistory,
            {
                returnHeaders: true,
            }) as Promise<(Array<ClientHistory> & { headers: { 'x-total-count': number } })>;
    }

    static getMemberFeedback (clientId: Guid) {
        return Http.getAsArray<MemberFeedback>(`${apiVersion}/client/${clientId}/member/feedback`);
    }

    static updateMemberFeedback (clientId: Guid, feedbackId: Guid, data: {communicationScore: number; serviceScore: number}) {
        return Http.put<MemberFeedback>(`${apiVersion}/client/${clientId}/member/feedback/${feedbackId}/`, data, undefined, {
            showSuccessMessage: true,
            message: 'Feedback updated',
        });
    }

    static createMemberFeedback (clientId: Guid, data: { year: number; quarter: string; communicationScore: number; serviceScore: number}) {
        return Http.post<MemberFeedback>(`${apiVersion}/client/${clientId}/member/feedback`, data);
    }

    static deleteMemberFeedback (clientId: Guid, feedbackId?: Guid) {
        return Http.delete(`${apiVersion}/client/${clientId}/member/feedback/${feedbackId}`);
    }

    static getClientWebDocuments (clientId: Guid) {
        return Http.getAsArray<ClientWebDocument>(`${apiVersion}/client/${clientId}/clientweb/document`);
    }

    static uploadClientWebDocument (clientId: Guid, request: FormData) {
        return Http.post<ClientWebDocument>(`${apiVersion}/client/${clientId}/clientweb/document`, request, undefined, {
            useToken: true,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
            message: 'Document uploaded',
            showSuccessMessage: true,
        }).then((r) => r);
    }

    static deleteClientWebDocument (clientId: Guid, fileName: string) {
        return Http.delete(`${apiVersion}/client/${clientId}/clientweb/document?name=${encodeURIComponent(fileName)}`, undefined, undefined, {
            message: 'Document deleted',
            showSuccessMessage: true,
        });
    }

    static getClientMemberDataTypeOverrides (clientId: Guid) {
        return Http.getAsArray<ClientMemberDataTypeOverride>(`${apiVersion}/client/${clientId}/memberDataTypeOverrides`);
    }

    static updateClientMemberDataTypeOverrides (clientId: Guid, memberDataTypeOverrides: Array<ClientMemberDataTypeOverride>) {
        return Http.put<Array<ClientMemberDataTypeOverride>>(`${apiVersion}/client/${clientId}/memberDataTypeOverrides`, memberDataTypeOverrides);
    }

    static getMemberValidationAuditHistory (clientId: Guid, searchTerm: string, memberDataTypeId: string, validationRuleDefinitionId: string, status: string, pageNumber: number, pageSize: number) {
        return Http.getAsArray<MemberValidationAuditHistory>(`${apiVersion}/client/${clientId}/memberValidationAuditHistory?pageNumber=${pageNumber}&pageSize=${pageSize}` +
        (searchTerm !== '' ? `&search=${searchTerm}` : '') +
        (memberDataTypeId !== '' && validationRuleDefinitionId === '' ? `&memberDataType=${memberDataTypeId}` : '') +
        (validationRuleDefinitionId !== '' ? `&validationRuleDefinition=${validationRuleDefinitionId}` : '') +
        (status !== '' ? `&status=${status}` : ''),
        MemberValidationAuditHistory, { returnHeaders: true }) as Promise<(Array<MemberValidationAuditHistory> & {headers: { 'x-total-count': number } })>;
    }

    static runAllMemberValidation (clientId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/validation`, null, undefined, {
            showSuccessMessage: true, message: 'Member validation running in batch',
        });
    }

    static runMemberValidation (clientId: Guid, validationId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/validation/${validationId}`, null, undefined, {
            showSuccessMessage: true, message: 'Member validation running in batch',
        });
    }

    static runMemberDataTypeValidations (clientId: Guid, memberDataTypeId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/validation/memberDataType/${memberDataTypeId}`, null, undefined, {
            showSuccessMessage: true, message: 'Member validations running in batch',
        });
    }

    static getMemberDataTypeSummary (clientId: Guid) {
        return Http.getAsArray<MemberDataTypeSummary>(`${apiVersion}/client/${clientId}/validation/memberDataType`);
    }

    static getValidationRuleDefinitons (clientId: Guid, memberDataTypeId: Guid) {
        return Http.getAsArray<ValidationRuleDefinitionSummary>(`${apiVersion}/client/${clientId}/validation/memberDataType/${memberDataTypeId}/definition`);
    }

    static uploadInsurerReceiptFile (clientId: Guid, policyId: Guid, data: unknown) {
        return Http.post<InsurerReceiptUploadResult>(`${apiVersion}/client/${clientId}/insurerReceipt/${policyId}`, data, undefined, {
            useToken: true,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
            showSuccessMessage: false
        });
    }

    static getInsurerPolicyUploadOptions (clientId: Guid) {
        return Http.getAsArray<InsurerPolicyUploadOption>(`${apiVersion}/client/${clientId}/insurerReceipt/policyOptions`);
    }

    static getInsurerPolicyUploads (clientId: Guid) {
        return Http.get<InsurerReceiptUploadQuery>(`${apiVersion}/client/${clientId}/insurerReceipt`,
        InsurerReceiptUploadQuery,
        { returnHeaders: true }) as Promise<(InsurerReceiptUploadQuery & {headers: { 'x-total-count': number } })>;
    }

    static getDataTypesForUnverifiedData(clientId: Guid) {
        return Http.getAsArray<MemberDataType>(`${apiVersion}/client/${clientId}/memberDataType/unverified`, MemberDataType);
    }
}
