
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import MemberInteractions from '@/models/memberInteractions';
import ColourService from '@/services/colourService';
import Highcharts from 'highcharts';

@Component
export default class MemberInteractionsComponent extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    get memberInteractions (): MemberInteractions | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberInteractions');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberInteractions');
    }

    get colours () {
        return ColourService.colours;
    }

    get memberInteractionsTransformed () {
        if (!this.memberInteractions) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];
        if (!this.filter || this.filter === 'Phone calls') {
            vals.push({
                name: 'Phone calls',
                type: 'column',
                data: this.memberInteractions.dataByDate.map((a) => a.phoneCalls),
                color: ColourService.getSeriesColour('interactions', 'Phone calls'),
            });
        }
        if (!this.filter || this.filter === 'Web requests') {
            vals.push({
                name: 'Web requests',
                type: 'column',
                data: this.memberInteractions.dataByDate.map((a) => a.webQueries),
                color: ColourService.getSeriesColour('interactions', 'Web requests'),
            });
        }
        if (!this.filter || this.filter === 'Emails') {
            vals.push({
                name: 'Emails',
                type: 'column',
                data: this.memberInteractions.dataByDate.map((a) => a.emails),
                color: ColourService.getSeriesColour('interactions', 'Emails'),
            });
        }
        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
            },
            xAxis: {
                categories: this.memberInteractions?.dataByDate.map((a) => dayjs(a.startDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            colors: this.colours,
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.memberInteractionsTransformed || [],
        };
    }
}
