
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import ColourService from '@/services/colourService';
import PageVisits from '@/models/pageVisits';
import PageVisitDate from '@/models/pageVisitDate';

@Component
export default class PageVisitsTable extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    currentFilter: string | null = null;

    get pageVisits (): Array<PageVisits> | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'pageVisits');
    }

    get filteredPageVisitsByDate (): Array<PageVisitDate> | null {
        const filteredPageVisitsByDate = new Array<PageVisitDate>();
        if (!this.pageVisits) return filteredPageVisitsByDate;

        this.pageVisits.forEach((date) => {
            date.pageVisitsByDate.forEach((page) => {
                if (page.pageName.includes('submissions')) {
                    page.pageName = 'My Forms';
                }
                if (page.pageName.includes('/pension-news')) {
                    page.pageName = 'News';
                }
                filteredPageVisitsByDate.push(page);
            });
        });

        return filteredPageVisitsByDate;
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'pageVisits');
    }

    get totalVisits () {
        if (!this.filteredPageVisitsByDate) return 0;

        const totalVisits = this.filteredPageVisitsByDate.reduce((a, b) => a + b.visits, 0);
        return totalVisits;
    }

    get totalAverageTimeSpent () {
        if (!this.filteredPageVisitsByDate) return 0;

        const totalTime = this.filteredPageVisitsByDate.reduce((a, b) => a + b.time, 0);
        const totalVisits = this.filteredPageVisitsByDate.reduce((a, b) => a + b.visits, 0);

        return totalTime / totalVisits;
    }

    get colours () {
        return [ColourService.colours[0]];
    }

    get pageStats () {
        const pages: Record<string, { visits: number; time: number; count: number }> = {};
        if (!this.filteredPageVisitsByDate) return {};
        this.filteredPageVisitsByDate.forEach((page) => {
            if (!pages[page.pageName]) {
                pages[page.pageName] = {
                    visits: 0,
                    time: 0,
                    count: 0,
                };
            }

            pages[page.pageName].count += 1;
            pages[page.pageName].visits += page.visits;
            pages[page.pageName].time += page.time;
        });

        return pages;
    }

    toUppercase (word: string) {
        const split = word.toLowerCase().split(' ');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < split.length; i++) {
            split[i] = split[i].charAt(0).toUpperCase() + split[i].substring(1);
        }
        return split.join(' ');
    }

    formatUrl (url: string) {
        if (!url?.trim() || url.trim() === '/') return 'Home';
        let newUrl = url.startsWith('/') ? url.substring(1) : url;
        newUrl = newUrl.replace(/-/g, ' ');
        newUrl = newUrl.replace(/\//g, ' - ');
        return newUrl.split(' ').map((a) => this.toUppercase(a)).join(' ').replace('Pension Tool', 'Modeller');
    }

    formatNumber (number: string | number) {
        return Math.round(Number(number ?? 0)).toLocaleString('en');
    }

    getColour (url: string) {
        return ColourService.getSeriesColour('page', url);
    }

    filterRow (page: string) {
        if (this.currentFilter === page) {
            this.currentFilter = null;
            this.$emit('pointSelected', { category: 'pageVisits', series: null });
        } else {
            this.currentFilter = page;
            this.$emit('pointSelected', { category: 'pageVisits', series: page });
        }
    }
}
