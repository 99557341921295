export default class BasicMemberVerification {
    title!: string;

    forenames!: string;

    surname!: string;

    dateOfBirth!: string;

    buildingName!: string;

    address1!: string;

    address2!: string;

    town!: string;

    postCode!: string;

    errors!: Array<string>;

    reference = '';

    result: 'Pass' | 'Fail' | '' = '';
}
