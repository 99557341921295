import Guid from 'common/util/guid';
import ServicePeriod from './servicePeriod';

export default class MemberSummary {
    systemMemberId!: Guid;

    memberId!: number;

    forenames!: string;

    surname!: string;

    email?: string;

    dateBirth!: Date;

    postCode!: string;

    servicePeriods!: Array<ServicePeriod>
}
