import SiteConfiguration from 'common/models/configuration';
import ClientWebConfiguration from 'common/models/clientWebConfiguration';
import ConfigurationApi from 'common/api/configurationApi';
import Config from '@/config';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface ConfigurationState {
    site: SiteConfiguration;
    clientWebSite: ClientWebConfiguration;
}

export const state: ConfigurationState = {
    site: new SiteConfiguration(),
    clientWebSite: new ClientWebConfiguration(),
};

export const getters: GetterTree<ConfigurationState, unknown> = {
    site: (s) => s.site,
    clientWebSite: (c) => c.clientWebSite,
};

export const actions: ActionTree<ConfigurationState, unknown> = {
    async getConfiguration ({ commit }) {
        if (Config.siteAuth === 'clientWeb') {
            const result = await ConfigurationApi.getClientWebConfiguration();
            commit('setClientWebConfiguration', result);
            const config = new SiteConfiguration();
            config['oauth.client.appid'] = result['clientweb.oauth.appid'];
            config['oauth.authority'] = result['clientweb.oauth.authority'];
            config['oauth.tenentid'] = result['clientweb.oauth.tenentid'];
            config['oauth.client.scope'] = result['clientweb.oauth.scope'];
            config['user.blob.url'] = result['clientweb.user.blob.url'];
            commit('setConfiguration', config);
            return config;
        }
        const result = await ConfigurationApi.getConfiguration();
        commit('setConfiguration', result);
        return result;
    },

};

export const mutations: MutationTree<ConfigurationState> = {
    setConfiguration (s, val: SiteConfiguration) {
        s.site = val;
    },
    setClientWebConfiguration (s, val: ClientWebConfiguration) {
        s.clientWebSite = val;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
