import memberApi from 'common/api/member';
import CorrespondenceSummary from 'common/models/correspondence/correspondenceSummary';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface CorrespondenceState {
    correspondence: Array<CorrespondenceSummary>;
    correspondenceDate: Date;
}

export const state: CorrespondenceState = {
    correspondence: [],
    correspondenceDate: new Date(),
};

export const getters: GetterTree<CorrespondenceState, unknown> = {
    correspondence: (s) => s.correspondence,
    correspondenceDate: (s) => s.correspondenceDate,
};

export const actions: ActionTree<CorrespondenceState, unknown> = {
    getMemberCorrespondence ({ commit }, { clientId, memberId }) {
        return memberApi.getCorrespondence(clientId, memberId).then((correspondence) => {
            commit('setCorrespondence', correspondence);
            return correspondence;
        });
    },
    clearCorrespondence ({ commit }) {
        commit('clearCorrespondence');
    },
};

export const mutations: MutationTree<CorrespondenceState> = {
    setCorrespondence (s, correspondence) {
        s.correspondence = correspondence;
        s.correspondenceDate = new Date();
    },
    clearCorrespondence (s) {
        s.correspondence = [];
        s.correspondenceDate = new Date();
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
