

import DeathNotification from 'common/models/deathNotification';
import {
    YesNoUnknown, YesNoDontKnow, RelationshipToDeceased, SpouseOrCivilPartnerContactOptions, ContactPreferences,
} from 'common/models/deathEnums';
import InformantContactDetailsVue from 'common/components/death-notification-informant-contact-details.vue';
import FutureCorrespondenceContactDetailsVue from 'common/components/death-notification-futurecorrespondence-contact-details.vue';
import SpouseOrCivilPartnerContactDetailsVue from 'common/components/death-notification-spouseorcivilpartner-contact-details.vue';
import FutureCorrespondenceContactDetails from 'common/models/deathFutureCorrespondenceContactDetails';
import SpouseOrCivilPartnerContactDetails from 'common/models/deathSpouseOrCivilPartnerContactDetails';
import { IDeathContactDetails } from 'common/models/deathContactDetails';
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';
import { EMAIL_REGEX } from 'common/util/emailHelper';

@Component({
    name: 'death-notification-form',
    components: { InformantContactDetailsVue, FutureCorrespondenceContactDetailsVue, SpouseOrCivilPartnerContactDetailsVue },
})
export default class DeathNotificationForm extends Vue {
    @Prop() memberId!: string;

    @Prop() deathNotification!: DeathNotification;

    @Prop() showSpouseOrCivilPartnerForm!: boolean;

    isDirty = true;

    showSpouseOrCivilPartnerContactOption=false;

    yesNoUnknownOptions= Object.keys(YesNoUnknown).map((a) => ({ name: a, value: YesNoUnknown[a as keyof typeof YesNoUnknown] }));

    yesNoDontKnowOptions= Object.keys(YesNoDontKnow).map((a) => ({ name: a, value: YesNoDontKnow[a as keyof typeof YesNoDontKnow] }));

    spouseOrCivilPartnerContactOptions= Object.keys(SpouseOrCivilPartnerContactOptions).map((a) => ({ name: a, value: SpouseOrCivilPartnerContactOptions[a as keyof typeof SpouseOrCivilPartnerContactOptions] }));

    mounted () {
        this.$emit('validChanged', false);
    }

    get showFutureCorrespondenceDetails () {
        return this.deathNotification!.futureCorrespondenceDiffers;
    }

    changeShowSpouseOrCivilPartnerContactOption () {
        this.showSpouseOrCivilPartnerContactOption = this.deathNotification!.hasSpouseOrCivilPartner === YesNoDontKnow.Yes;

        if (this.deathNotification!.hasSpouseOrCivilPartner !== YesNoDontKnow.Yes) {
            this.deathNotification!.spouseOrCivilPartnerContactOption = null;
        } else {
            this.deathNotification!.spouseOrCivilPartnerContactOption = SpouseOrCivilPartnerContactOptions['No - same as informant'];
        }
    }

    get showSpouseOrCivilPartnerDetails () {
        return this.deathNotification!.spouseOrCivilPartnerContactOption === SpouseOrCivilPartnerContactOptions.Yes;
    }

    hasSpouseOrCivilPartnerCheck () {
        if (this.deathNotification!.hasSpouseOrCivilPartner === null) {
            return;
        }

        const informantRelationship = this.deathNotification!.informantContactDetails.relationshipToDeceased;
        const fututeCorrespondenceRelationship = this.deathNotification!.futureCorrespondenceContactDetails?.relationshipToDeceased;

        if (informantRelationship === RelationshipToDeceased['Widow(er)']
            || informantRelationship === RelationshipToDeceased['Civil Partner']
            || fututeCorrespondenceRelationship === RelationshipToDeceased['Widow(er)']
            || fututeCorrespondenceRelationship === RelationshipToDeceased['Civil Partner']) {
            this.deathNotification!.hasSpouseOrCivilPartner = YesNoDontKnow.Yes;
        }
    }

    @Watch('deathNotification.informantContactDetails.relationshipToDeceased')
    @Watch('deathNotification.futureCorrespondenceContactDetails.relationshipToDeceased')
    relationshipChanged () {
        this.hasSpouseOrCivilPartnerCheck();
    }

    @Watch('deathNotification', { deep: true })
    deathNotificationChanged () {
        this.isDirty = true;
    }

    @Watch('deathNotification.futureCorrespondenceDiffers')
    futureCorrespondenceDiffersChanged (val: boolean) {
        if (val) {
            this.deathNotification.futureCorrespondenceContactDetails = new FutureCorrespondenceContactDetails();
            this.deathNotification.futureCorrespondenceContactDetails!.contactPreference = ContactPreferences.Email;
        } else {
            this.deathNotification.futureCorrespondenceContactDetails = null;
        }
    }

    @Watch('deathNotification.hasSpouseOrCivilPartner')
    hasSpouseOrCivilPartnerChanged (val: YesNoDontKnow) {
        if (val === YesNoDontKnow.Yes) {
            this.deathNotification.spouseOrCivilPartnerContactDetails = new SpouseOrCivilPartnerContactDetails();
            this.deathNotification.spouseOrCivilPartnerContactDetails!.contactPreference = ContactPreferences.Email;
        } else {
            this.deathNotification.spouseOrCivilPartnerContactDetails = null;
        }
    }

    @Watch('deathNotification.spouseOrCivilPartnerContactOption')
    spouseOrCivilPartnerContactOptionChanged (val: SpouseOrCivilPartnerContactOptions) {
        if (val === SpouseOrCivilPartnerContactOptions.Yes) {
            this.deathNotification.spouseOrCivilPartnerContactDetails = new SpouseOrCivilPartnerContactDetails();
            this.deathNotification.spouseOrCivilPartnerContactDetails!.contactPreference = ContactPreferences.Email;
        } else {
            this.deathNotification.spouseOrCivilPartnerContactDetails = null;
        }
    }

    @Watch('validationFailedMessage')
    validationFailedMessageChanged () {
        this.$emit('validChanged', !this.validationFailedMessage);
    }

    @Watch('deathNotification.isDateOfDeathUnknown')
    isDateOfDeathUnknownChanged () {
        this.deathNotification.dateOfDeath = null;
    }

    get validationFailedMessage () {
        const errors = [];
        const dn = this.deathNotification;

        if (dn.isDateOfDeathUnknown === false) {
            if (!dn.dateOfDeath) {
                errors.push('<li><b>Date of death</b> required</li>');
            } else if (new Date(dn.dateOfDeath) > new Date()) {
                errors.push('<li><b>Date of death cannot be in the future</b> </li>');
            }
        }

        if (this.showSpouseOrCivilPartnerForm) {
            if (!dn.hasChildrenUnder23) {
                errors.push('<li><b>Are there children under 23?</b> required</li>');
            }

            if (!dn.isDeceasedBankAccountOpen) {
                errors.push('<li><b>Deceased\'s bank account still open?</b> required</li>');
            }
        }

        const informantErrors = this.checkContactDetails(dn.informantContactDetails, true);

        if (informantErrors) {
            errors.push(`<li><b>Informant details</b> (${informantErrors})</li>`);
        }

        if (!dn.informantContactDetails.relationshipToDeceased) {
            errors.push('<li><b>Informant relationship to deceased</b> is required</li>');
        }

        if (dn.futureCorrespondenceDiffers) {
            const futureCorrespondenceErrors = this.checkContactDetails(dn.futureCorrespondenceContactDetails!, false);

            if (futureCorrespondenceErrors) {
                errors.push(`<li><b>Future correspondence details</b> (${futureCorrespondenceErrors})</li>`);
            }

            if (!dn.futureCorrespondenceContactDetails!.relationshipToDeceased) {
                errors.push('<li><b>Future correspondence relationship to deceased</b> is required</li>');
            }
        }

        if (this.showSpouseOrCivilPartnerForm === true) {
            if (dn.hasSpouseOrCivilPartner === undefined || dn.hasSpouseOrCivilPartner === null) {
                errors.push('<li><b>Is there a Spouse/Civil Partner?</b> required</li>');
            }

            if (dn.hasSpouseOrCivilPartner === YesNoDontKnow.Yes && dn.spouseOrCivilPartnerContactOption === SpouseOrCivilPartnerContactOptions.Yes) {
                const spouseOrCivilPartnerErrors = this.checkContactDetails(dn.spouseOrCivilPartnerContactDetails!, false);

                if (spouseOrCivilPartnerErrors) {
                    errors.push(`<li><b>Spouse / civil partner details</b> (${spouseOrCivilPartnerErrors})</li>`);
                }
            }

            if (dn.hasSpouseOrCivilPartner === YesNoDontKnow.Yes && dn.spouseOrCivilPartnerContactOption === SpouseOrCivilPartnerContactOptions['No - same as future correspondence']) {
                if (!dn.futureCorrespondenceDiffers) {
                    errors.push('<li><b>Spouse/Civil Partner set to use Future Correspondence contact details, but Future Correspondence contact details</b> not set</li>');
                }
            }
        }

        if (!errors.length) {
            return null;
        }

        return `<ul>${errors.join('')}</ul>`;
    }

    checkContactDetails (contactDetails: IDeathContactDetails, isInformant: boolean): string | null {
        const errors = [];

        if (!contactDetails.forenames) {
            errors.push('<b>forename</b> required');
        }

        if (!contactDetails.title) {
            errors.push('<b>title</b> required');
        }

        if (!contactDetails.surname) {
            errors.push('<b>surname</b> required');
        }

        if (!contactDetails.addressLine1) {
            errors.push('<b>address line 1</b> required');
        }

        if (!contactDetails.addressPostCode) {
            errors.push('<b>post code</b> required');
        }

        if (!contactDetails.addressCountry) {
            errors.push('<b>country</b> required');
        }

        if (isInformant && !contactDetails.telephoneNumber) {
            errors.push('<b>telephone number</b> required');
        }

        if (contactDetails.contactPreference === undefined || contactDetails.contactPreference === null) {
            errors.push('<b>contact preference</b> required');
        }

        if (contactDetails.contactPreference === ContactPreferences.Email && !contactDetails.email) {
            errors.push('<b>contact preference set to email, but email not set</b>');
        }

        if (contactDetails.email) {
            if (!contactDetails.email.match(EMAIL_REGEX)) {
                errors.push('<b>email</b> invalid');
            }
        }

        if (errors.length) {
            return errors.length === 1 ? errors[0] : `${errors.slice(0, -1).join(', ')} and ${errors[errors.length - 1]}`;
        }

        return null;
    }
}

