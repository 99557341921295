
import HeaderBar from 'common/components/header-bar.vue';
import BatchJobSummary from 'common/models/batchJobSummary';
import clientsApi from 'common/api/clientsApi';
import batchJobsApi from 'common/api/batchJobsApi';
import User from 'common/models/user';
import Guid from 'common/util/guid';
import * as STORE from 'common/store/storepaths';
import Client from 'common/models/client';
import * as _ from '~/lodash';
import { Column } from '~/@lcp/vue-filter-table';
import moment from '~/moment';
import {
    Component, Vue, Watch, Prop,
} from '~/vue-property-decorator';

@Component({
    components: { HeaderBar },
})
export default class BatchJobs extends Vue {
    @Prop()
    clientName!: string;

    @Prop()
    isMember!: boolean;

    @Prop()
    isFromMemberData!: boolean;

    batchJobs: Array<BatchJobSummary> = [];

    client: Client | null | undefined = null;

    currentFilter = {
        search: '',
        pageSize: 50,
        pageNumber: 1,
    }

    isLoading = false;

    jobsTotal = 0;

    lastRefreshed = '';

    searchExpression: string | null = null;

    async mounted () {
        if (this.clientName) {
            await this.getClient();
        }

        await this.getBatchJobs();
    }

    async getClient () {
        const clients = await clientsApi.getClients();

        if (!clients) {
            return;
        }

        const clientByName = clients.find((c: Client) => c.name === this.clientName);

        if (clientByName) {
            this.client = clientByName;
            return;
        }

        this.client = clients.find((c: Client) => c.code === this.clientName);
    }

    async reload () {
        await this.getBatchJobs();
    }

    pageNumberChanged (pageNumber: number) {
        this.currentFilter.pageNumber = pageNumber;
    }

    pageSizeChanged (newPageSize: number) {
        const { pageNumber }: {pageNumber: number} = this.currentFilter;
        const pageNumberNeedsChanging = ((pageNumber - 1) * newPageSize) > this.jobsTotal;

        if (pageNumberNeedsChanging) {
            this.currentFilter.pageNumber = Math.ceil((this.jobsTotal / newPageSize));
        }

        this.currentFilter.pageSize = newPageSize;
    }

    async getBatchJobs () {
        this.isLoading = true;

        if (this.isMember) {
            await batchJobsApi.getUserBatchJobs(this.currentFilter.search, this.currentFilter.pageNumber, this.currentFilter.pageSize, this.pulseUrl)
                .then((result) => {
                    this.lastRefreshed = moment().toString();
                    this.batchJobs = result;
                    this.jobsTotal = result.headers['x-total-count'];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } else if (this.clientName) {
            // By saying null, we're in pulse admin so it will use the default path, otherwise point at pulse endpoint
            const url = this.isFromMemberData ? this.pulseUrl : null;

            await batchJobsApi.getClientBatchJobs(this.client!.clientId, this.currentFilter.search, this.currentFilter.pageNumber, this.currentFilter.pageSize, url)
                .then((result) => {
                    this.lastRefreshed = moment().toString();
                    this.batchJobs = result.filter((a) => !this.clientName || a.clientCode === this.client?.code);
                    this.jobsTotal = result.headers['x-total-count'];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } else {
            await batchJobsApi.getBatchJobs(this.currentFilter.search, this.currentFilter.pageNumber, this.currentFilter.pageSize)
                .then((result) => {
                    this.lastRefreshed = moment().toString();
                    this.batchJobs = result.filter((a) => !this.clientName || a.clientCode === this.client?.code);
                    this.jobsTotal = result.headers['x-total-count'];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    getUser (userId: Guid) {
        if (!userId) return 'System';
        const user = this.allUsersById[userId.toString()];
        if (!user) return 'System';
        return `${user.forenames} ${user.surname}`;
    }

    onSearch () {
        this.getSearchDebounce();
    }

    get userInfo () {
        return this.$store.getters['auth/userInfo'];
    }

    get allUsersById () {
        return this.$store.getters['users/allUsersByUserId'] as { [key: string]: User };
    }

    get getSearchDebounce () {
        return _.debounce(() => {
            this.currentFilter.search = this.searchExpression!;
        }, 600);
    }

    get pulseUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION]['pulse-api.url'];
    }

    get columns () {
        return [
            new Column({
                id: 'name',
                name: 'Name',
                prop: 'name',
                filterable: false,
                sortable: false,
                width: 200,
            }),
            new Column<BatchJobSummary>({
                id: 'application',
                name: 'Application',
                value: (r: BatchJobSummary) => r.application.name,
                filterable: false,
                sortable: false,
                width: 150,
            }),
            new Column({
                id: 'client',
                name: 'Client',
                prop: 'clientCode',
                filterable: false,
                sortable: false,
                width: 70,
            }),
            new Column({
                id: 'status',
                name: 'Status',
                value: (r: BatchJobSummary) => r.scheduledTime && r.status == 'Pending' ? 'Scheduled' : r.status,
                filterable: false,
                sortable: false,
                width: 130,
            }),
            new Column<BatchJobSummary>({
                id: 'error',
                name: 'Error',
                value: (r: BatchJobSummary) => r.error ?? r.exception,
                filterable: false,
                sortable: false,
            }),
            new Column<BatchJobSummary>({
                id: 'created',
                name: 'Created',
                value: (r: BatchJobSummary) => moment(r.created).format('DD/MM/YYYY HH:mm'),
                filterable: false,
                sortable: false,
                width: 150,
            }),
            new Column<BatchJobSummary>({
                id: 'scheduledTime',
                name: 'Scheduled',
                value: (r: BatchJobSummary) => r.scheduledTime ? moment(r.scheduledTime).format('DD/MM/YYYY HH:mm') : '',
                filterable: false,
                sortable: false,
                width: 150,
            }),
            new Column<BatchJobSummary>({
                id: 'updated',
                name: 'Last updated',
                value: (r: BatchJobSummary) => moment(r.status === 'Pending' ? r.created : r.updated).format('DD/MM/YYYY HH:mm'),
                filterable: false,
                sortable: false,
                width: 150,
            }),
            // remove this column after testing as it will always be the same user
            new Column<BatchJobSummary>({
                id: 'user',
                name: 'User',
                value: (r: BatchJobSummary) => this.getUser(r.createdBy),
                filterable: false,
                sortable: false,
                width: 150,
            }),
        ];
    }

    @Watch('currentFilter', { deep: true })
    currentFilterChanged () {
        this.getBatchJobs();
    }
}
