

import * as STORE from 'common/store/storepaths';
import memberApi from 'common/api/member';
import User from 'common/models/user';
import { TimeZone, timeZones } from 'common/util/timezones';
import ConfirmLeaveDialog from 'common/mixins/confirmLeaveDialog';
import MemberPhoneCallDetails, { ExistingNumberValue } from 'common/models/memberPhoneCallDetails';
import { mixins } from '~/vue-class-component';
import { Column } from '~/@lcp/vue-filter-table';
import moment from '~/moment';
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';
import { capitalize } from '~/lodash';

@Component({
    name: 'member-telephone-conversation',
    components: { },
})
export default class MemberTelephoneConversation extends mixins(ConfirmLeaveDialog) {
    @Prop() memberId!: string;

    authChecked = false;

    copyText = '';

    columns= [
        {
            component: (row: User) => ({
                component: 'profile-photo',
                props: {
                    id: row.userId,
                    email: row.email,
                    color: true,
                },
            }),
        },
        new Column<User>({
            name: 'Name',
            id: 'name',
            value: (row) => `${row.forenames} ${row.surname}`,
            filterable: true,
        }),
    ]

    contactDetails = 'Member';

    conversation = '';

    incomingCall = true;

    informedRecording = false;

    isLoading = false;

    jobId?: string;

    localTime = moment();

    localTimeUtc = moment().utc();

    memberDetailsForCall: MemberPhoneCallDetails | null = null;

    otherName = '';

    otherCallerCapacity = '';

    otherCompany = '';

    otherNumber = '';

    phoneNumber = '';

    phoneNumberType = 'Not recorded';

    isDirty = false;

    securityChecks: Record<string, boolean> = {
        address: false,
        fullName: false,
        niNumber: false,
        dateOfBirth: false,
    };

    securityRequired= true;

    selectedUser: User | null=null;

    subject= '';

    telephoneTypes: Array<string> = [
        'Mobile',
        'Work',
        'Home',
        'Other (Not saved)',
        'Not recorded',
    ];

    timeOfCall: Date = new Date();

    async mounted () {
        memberApi.startCall(this.currentClient.clientId, Number(this.memberId))
            .then((response: MemberPhoneCallDetails) => {
                if (response.existingNumbers) {
                    Object.keys(response.existingNumbers).forEach((numberType) => {
                        if (response.existingNumbers[numberType]) {
                            response.existingNumbers[numberType] = {
                                value: response.existingNumbers[numberType] as string,
                                selected: false,
                            };
                        } else {
                            delete response.existingNumbers[numberType];
                        }
                    });
                }

                if (response.security) {
                    response.security.keyDates.forEach((servicePeriod) => {
                        Object.keys(servicePeriod.keyDates).forEach((date) => {
                            if (servicePeriod.keyDates[date]) {
                                Vue.set(this.securityChecks, `date-${servicePeriod.description}: ${date}`, false);
                            }
                        });
                    });
                }

                this.memberDetailsForCall = response;
                window.document.title = this.title;

                this.getUsers();
                this.isLoading = false;
                this.$nextTick(() => {
                    this.isDirty = false;
                });
            });

        this.confirmLeaveSetupDirtyFunction(() => this.isDirty);
        this.confirmLeaveSetupClearFunction(() => { this.isDirty = false; });
    }

    get title () {
        return `Member Data - Telephone conversation - ${this.memberId} ${this.memberDetailsForCall?.security?.fullName ?? ''} - ${this.formatDate(this.timeOfCall)}`;
    }

    get canSave () {
        return !this.requiredFields;
    }

    get country () {
        return this.memberDetailsForCall?.country;
    }

    get currentClient () {
        return this.$store.getters[STORE.GET_CURRENT_CLIENT];
    }

    get localSecondsAngle () {
        return { transform: `rotate(${((360 / 60) * Number(this.localTime.format('s'))) + 180}deg)` };
    }

    get localMinuteAngle () {
        return { transform: `rotate(${((360 / 60) * Number(this.localTime.format('m'))) + 180}deg)` };
    }

    get localHourAngle () {
        return { transform: `rotate(${((360 / 12) * Number(this.localTime.format('h'))) + 180}deg)` };
    }

    get memberTime () {
        return this.localTimeUtc.add(timeZones.find((a: TimeZone) => a.name === this.country)?.gmt || 0, 'hour');
    }

    get hasExistingNumbers () {
        return Object.values(this.memberDetailsForCall?.existingNumbers || {}).filter((a) => a).length;
    }

    get memberSecondsAngle () {
        return { transform: `rotate(${((360 / 60) * Number(this.memberTime.format('s'))) + 180}deg)` };
    }

    get memberMinuteAngle () {
        return { transform: `rotate(${((360 / 60) * Number(this.memberTime.format('m'))) + 180}deg)` };
    }

    get memberHourAngle () {
        return { transform: `rotate(${((360 / 12) * Number(this.memberTime.format('h'))) + 180}deg)` };
    }

    get numberToSave () {
        if (this.contactDetails === 'Internal') {
            return { number: '', type: '' };
        }

        if (this.selectedNumber && this.selectedNumber.number) {
            return { number: this.selectedNumber.number, type: this.selectedNumber.type };
        }

        if (this.phoneNumberType === 'Other (Not saved)') {
            return { number: this.phoneNumber, type: 'Other' };
        }

        return this.phoneNumberType !== 'Not recorded' ? { number: this.phoneNumber, type: this.phoneNumberType } : { number: '', type: 'Other' };
    }

    get recordCalls () {
        return this.currentClient.name && this.currentClient.name.indexOf('Tetley') > -1;
    }

    get requiredFields () {
        const fieldsRequired = [];

        if (this.contactDetails === 'Member') {
            if (!this.selectedNumber && !this.phoneNumber.length && this.phoneNumberType !== 'Not recorded') {
                fieldsRequired.push('select or enter the <b>phone number</b> for the call');
            }
        } else if (this.contactDetails === 'Internal') {
            if (!this.selectedUser) {
                fieldsRequired.push('select an <b> LCP contact</b> for the call');
            }
        } else if (this.contactDetails === 'Other') {
            if (!this.otherName.length) {
                fieldsRequired.push('enter the <b>caller name</b> for the call');
            }
            if (!this.otherCallerCapacity.length) {
                fieldsRequired.push('enter the <b>caller capacity</b> for the call');
            }
            if (!this.otherNumber.length) {
                fieldsRequired.push('enter the <b>phone number</b> for the call');
            }
        }

        if (!this.subject.length) {
            fieldsRequired.push('enter a <b>subject</b>');
        }

        if (!this.conversation.length) {
            fieldsRequired.push('enter details for the <b>conversation</b>');
        }

        if (this.contactDetails === 'Other' && !this.authChecked && this.securityRequired) {
            fieldsRequired.push('make sure that <b>identity and authorisation</b> have been checked');
        }

        if (!this.securityPassed && this.securityRequired && this.memberId) {
            fieldsRequired.push('ensure that <b>security</b> is passed or marked as "Not required / completed"');
        }

        if (!fieldsRequired.length) return null;

        const fields = fieldsRequired.length === 1 ? fieldsRequired[0] : `${fieldsRequired.slice(0, -1).join(', ')} and ${fieldsRequired[fieldsRequired.length - 1]}`;

        return `Please ${fields} to submit the form`;
    }

    get securityPassed () {
        if (!this.securityRequired) {
            return false;
        }

        let count = 0;
        let hasDate = false;

        Object.keys(this.securityChecks).forEach((key) => {
            if (key.startsWith('date-')) {
                if (hasDate && this.securityChecks[key]) {
                    return;
                } if (this.securityChecks[key]) {
                    hasDate = true;
                }
            }
            count += this.securityChecks[key] ? 1 : 0;
        });
        return (this.contactDetails === 'Other' && count > 3) || count > 4;
    }

    get selectedNumber () {
        let selectedNumber = null;
        let selectedNumberType = null;

        if (this.memberDetailsForCall == null) {
            return null;
        }

        const memberDetailsForCall = { ...this.memberDetailsForCall };

        Object.keys(memberDetailsForCall.existingNumbers).forEach((numberType: string) => {
            if ((memberDetailsForCall.existingNumbers[numberType] as ExistingNumberValue).selected) {
                selectedNumber = (memberDetailsForCall.existingNumbers[numberType] as ExistingNumberValue).value;
                selectedNumberType = numberType;
            }
        });
        if (!selectedNumber || !selectedNumberType) return null;
        return {
            number: selectedNumber, type: selectedNumberType,
        };
    }

    get users () {
        return this.$store.getters[STORE.GET_ALL_USERS];
    }

    get userToSave () {
        if (this.contactDetails !== 'Internal') {
            return {
                userId: null, email: '', forenames: '', surname: '',
            };
        }

        return this.selectedUser;
    }

    get verifiedSecurityItems () {
        const items: Record<string, boolean> = {};
        Object.keys(this.securityChecks).forEach((key) => {
            if (this.securityChecks[key]) {
                items[key] = true;
            }
        });
        return items;
    }

    callClicked (number: ExistingNumberValue | string, select: boolean) {
        const phoneNumber = (number as ExistingNumberValue).value ? (number as ExistingNumberValue).value : number as string;
        const telNo = phoneNumber.startsWith('0') ? phoneNumber.replace('0', '+44').replace(' ', '') : phoneNumber.replace(' ', '');
        this.copyText = telNo;
        if (!this.recordCalls) {
            if (select) {
                (number as ExistingNumberValue).selected = true;
            }
            this.copy(telNo);
            return;
        }

        if (select) {
            (number as ExistingNumberValue).selected = true;
        }
        window.open(`outboundcall:tel:${telNo};service=sip:lcp-tetley-outbound@emea.luware.cloud;activity=Outbound Call`, '_blank', 'location=0');
    }

    copy (text: string) {
        this.copyText = text;
        this.$nextTick(() => {
            const copyText = document.getElementById('copyField') as HTMLInputElement;
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand('copy');

            this.$store.dispatch('messages/addMessage', {
                status: 'Success', title: 'Copy successful', popup: true, message: `Value has been copied to the clipboard<br/><br/><span class="select">${text}</span>`,
            });
        });
    }

    capitalize (text: string) {
        return capitalize(text);
    }

    closeWindow () {
        window.close();
    }

    formatDate (date: Date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    fullTime (date: Date) {
        return moment(date).format('ddd DD MMM YYYY hh:mm A');
    }

    getClockMarkerPos (index: number) {
        const minDeg = (index / 12) * 360;
        const minRad = minDeg * (Math.PI / 180);
        const x = 55 * Math.cos(minRad) + 57;
        const y = 55 * Math.sin(minRad) + 54;
        return { transform: `translateX(${x}px) translateY(${y}px) rotate(${minDeg - 90}deg)` };
    }

    getUsers () {
        if (!this.users.length) {
            const pulseApiUrl = this.$store.getters[STORE.GET_SITE_CONFIGURATION]['pulse-api.url'];
            this.$store.dispatch(STORE.SET_ALL_USERS, pulseApiUrl);
        }
    }

    resetUser () {
        this.selectedUser = null;
    }

    save () {
        this.isLoading = true;
        this.isDirty = false;
        const postData = {
            jobId: this.jobId,
            time: moment(this.timeOfCall).format('YYYY-MM-DDTHH:mm:ssZ'),
            data: {
                number: this.numberToSave.number,
                numberType: this.numberToSave.type,
                internalContactId: this.userToSave ? this.userToSave?.userId : null,
                internalContactName: this.userToSave ? `${this.userToSave.forenames} ${this.userToSave.surname}` : null,
                internalContactEmail: this.userToSave?.email,
                isMember: this.contactDetails === 'Member',
                isInternal: this.contactDetails === 'Internal',
                memberId: this.memberId,
                otherCallerDetails: {
                    capacity: this.otherCallerCapacity,
                    name: this.otherName,
                    company: this.otherCompany,
                    number: this.otherNumber,
                    identityChecked: this.authChecked,
                },
                subject: this.subject,
                conversation: this.conversation,
                securityRequired: this.securityRequired,
                incomingCall: this.incomingCall,
                informedRecording: this.informedRecording,
                verifiedSecurityItems: this.securityRequired ? Object.keys(this.verifiedSecurityItems).map((a) => a.replace('date-', '')) : [],
                identityAndAuthorisationChecked: this.authChecked,
            },
        };

        memberApi.saveCall(this.currentClient.clientId, Number(this.memberId), postData).then(() => {
            this.isLoading = false;
            this.closeWindow();
        }).catch(() => {
            this.isLoading = false;
        });
    }

    selectUser (row: User) {
        this.selectedUser = row;
    }

    get pulseApiUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION][STORE.PULSE_API_URL] ?? '';
    }

    get createDeathNotificationButtonVisible () {
        return this.memberDetailsForCall?.canCreateDeathNotification === true
            && this.$store.getters[STORE.GET_SITE_CONFIGURATION]['feature.md.death.enabled'] === 'true'
            && !this.$route.query.hideIntro;
    }

    switchToDeathNotification () {
        localStorage.setItem(`${this.memberId}-death`, 'true');
        window.close();
    }

    @Watch('requiredFields')
    @Watch('securityChecks', { deep: true })
    @Watch('selectedNumber')
    requiredFieldsChanged () {
        this.isDirty = true;
    }
}

