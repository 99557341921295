
import { Component, Prop, Vue } from 'vue-property-decorator';
import Earnings from '@/models/memberDataTypes/earnings';

@Component
export default class EarningsVue extends Vue {
    @Prop()
        dataTypeData!: Earnings;

    get columnNames () {
        const columns = (this.dataTypeData.items?.map((a) => a.salaries?.map((b) => b.numberName) ?? [])).flat();
        return [...new Set(columns)];
    }

    get orderedItems () {
        return this.dataTypeData.items?.sort((a, b) => (b.salaryDate > a.salaryDate ? 1 : -1));
    }
}
