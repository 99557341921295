import UsersApi from 'common/api/usersApi';
import Client from 'common/models/client';
import PermissionGroup from 'common/models/permissions/permissionGroup';
import User from 'common/models/user';
import UserDetails from 'common/models/userDetails';
import Guid from 'common/util/guid';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface UsersState {
    allUsers: Array<User>;
    allUsersByUserId: { [userId: string ]: User };
    gettingUsers: boolean;
    userDetails: { clients: Array<Client>; permissionGroups: Array<PermissionGroup>};
}

export const state: UsersState = {
    allUsers: [],
    allUsersByUserId: {},
    gettingUsers: false,
    userDetails: { clients: [], permissionGroups: [] },
};

export const getters: GetterTree<UsersState, unknown> = {
    allUsers: (s) => s.allUsers,
    allUsersByUserId: (s) => s.allUsersByUserId,
    userPermissions: (s) => s.userDetails.permissionGroups,
    userClients: (s) => s.userDetails.clients,
    hasPermission: (s) => (permissionName: string, clientId?: Guid) => {
        const globalPermission = s.userDetails.permissionGroups?.find((p) => p.permissionName === permissionName && p.clientId == null);

        if (clientId == null) {
            return !!globalPermission;
        }

        const clientSpecificPermisison = s.userDetails.permissionGroups?.find((p) => p.permissionName === permissionName && p.clientId === clientId);

        return !!((globalPermission || clientSpecificPermisison));
    },
};

export const actions: ActionTree<UsersState, unknown> = {
    async getAllUsers ({ commit }, pulseUrl: string) {
        if (state.gettingUsers) return [];
        commit('setGettingUsers', true);
        const users = await UsersApi.getUsers(pulseUrl);
        commit('setAllUsers', users);
        return users;
    },
    getUserDetails ({ commit }, pulseApiUrl: string) {
        return UsersApi.getUserDetails(pulseApiUrl)
            .then((details) => {
                commit('client/setUserClients', details.clients, { root: true });
                details.clients.forEach((c) => {
                    commit('client/setclient', c, { root: true });
                });
                commit('setUserDetails', details);
                return details;
            });
    },
};

export const mutations: MutationTree<UsersState> = {
    setGettingUsers (s, val) {
        s.gettingUsers = val;
    },
    setAllUsers (s, users: Array<User>) {
        s.allUsers = users;
        const u: { [userId: string]: User } = {};
        users.forEach((user) => {
            u[user.userId.toString()] = user;
        });
        s.allUsersByUserId = u;
    },
    setUserDetails (s, details: UserDetails) {
        s.userDetails = details;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
