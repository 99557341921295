
import CorrespondenceWeb2Query from 'common/models/correspondence/webQuery';
import PowerOfAttorneyFile from 'common/models/correspondence/powerOfAttorneyFile';
import JobApi from 'common/api/jobApi';
import inboxApi from 'common/api/inboxApi';
import * as STORE from 'common/store/storepaths';
import JobSummary from 'common/models/jobSummary';
import Status from 'common/util/pulseStatusEnum';
import Guid from 'common/util/guid';
import Client from 'common/models/client';
import memberApi from 'common/api/member';
import clientsApi from 'common/api/clientsApi';
import moment from '~/moment';
import {
    Component, Vue, Prop,
} from '~/vue-property-decorator';
import DocumentsApi from 'common/api/documentsApi';

@Component({
    name: 'WebQueryViewer',
    components: {},
})

export default class WebQueryViewer extends Vue {
    @Prop()
    queryId!: string;

    @Prop()
    webQueryMemberId!: number;

    showJobAssign = false;

    webQuery: CorrespondenceWeb2Query | null = null;

    newJobId?: Guid | null = null;

    loading = false;

    assignedJob?: JobSummary | null = null;

    assignedJobId?: string = '';

    jobs: Array<JobSummary> = [];

    async mounted () {
        if (!this.clients?.length) {
            await this.$store.dispatch('client/getClients');
        }
        if (this.currentClient && !this.$store.getters[STORE.GET_CURRENT_CLIENT]) {
            await this.$store.dispatch(STORE.SET_CURRENT_CLIENT, this.currentClient);
        }
        if (this.queryId) {
            if (this.memberId) {
                await this.getMemberWebQuery(this.memberId);

            } else {
                await this.getClientWebQuery();

            }
            if (this.webQuery?.jobReference) {
                await this.getJob(this.webQuery.jobReference);
            }

            this.assignedJob = this.getJobByReference(this.webQuery!.jobReference);
            this.assignedJobId = this.assignedJob?.jobId.toString();
        }
    }

    getDocumentType (document: { filename: string }) {
        const fileArr = document.filename.split('.');
        const fileType = fileArr[fileArr.length - 1];
        switch (fileType) {
            case 'doc':
            case 'docx':
            case 'docm':
                return 'file-word';
            case 'xls':
            case 'xlsx':
            case 'xlsm':
                return 'file-excel';
            case 'pdf':
                return 'file-pdf';
            case 'txt':
                return 'file';
            case 'msg':
                return 'envelope';
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'bmp':
            case 'gif':
                return 'image';
            default:
                return 'file';
        }
    }

    get hostUrl () {
        return `${window.location.protocol}//${window.location.host}${this.$route.path}`;
    }

    get pulseUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION]['pulse-api.url'];
    }

    get memberDataApiUrl () {
        return this.$store.getters['configuration/site']['memberdata.api.url'];
    }

    get currentClient () {
        if (this.clients && this.itemDetails.length) {
            const id = this.itemDetails[1];
            const found = this.clients.find((a) => a.code === id);
            if (found) return found;
        }
        return null;
    }

    get memberId () {
        if (this.webQueryMemberId) {
            return this.webQueryMemberId;
        }
        if (this.$route.query.memberId) {
            return Number(this.$route.query?.memberId);
        }

        return null;
    }

    get clients () {
        return this.$store.getters[STORE.GET_CLIENTS] as Array<Client>;
    }

    get reAssignMatches () {
        return this.assignedJobId === this.jobs.find((a) => a.reference === this.webQuery?.jobReference)?.jobId;
    }

    get itemDetails () {
        return this.queryId.split('-');
    }

    get isIframed () {
        return this.$route?.query?.hideIntro;
    }

    get webQueryId () {
        if (this.itemDetails.length <= 3 && !this.webQueryMemberId) {
            return Number(this.itemDetails[2]);
        }

        return Number(this.itemDetails[3]);
    }

    get systemMemberId () {
        return this.webQuery?.systemMemberId;
    }

    async getMemberWebQuery (memberId: number) {
        this.loading = true;
        this.webQuery = await memberApi.getWebQuery(this.currentClient!.clientId, memberId, this.webQueryId, this.memberDataApiUrl);
        this.getTitle();
        this.loading = false;
    }

    async getClientWebQuery () {
        this.loading = true;
        this.webQuery = await clientsApi.getClientWebQuery(this.currentClient!.clientId, this.webQueryId);
        this.getTitle();
        this.loading = false;
    }

    async getJob (jobId: string) {
        this.jobs = [await this.$store.dispatch('jobs/getJob', { jobId, pulseApiUrl: this.pulseUrl }) ];
    }

    getTitle () {
        if (this.webQuery && this.webQuery.data) {
            let fullTitle;
            switch (this.webQuery.queryType) {
                case 'General':
                    fullTitle = `W${this.webQueryId} - Enquiry: ${this.webQuery.data?.subject}`;
                    break;
                case 'AddressUpdate':
                    fullTitle = `W${this.webQueryId} - Address Update`;
                    break;
                case 'PhoneUpdate':
                    fullTitle = `W${this.webQueryId} - Phone Update`;
                    break;
                case 'CommunicationPreferencesUpdate':
                    fullTitle = `W${this.webQueryId} - Communication Preferences Update`;
                    break;
                default:
                    fullTitle = `W${this.webQueryId} - ${this.webQuery.data?.subject}`;
                    break;
            }
            this.$emit('setTitle', fullTitle);
        }
        return '';
    }

    getJobById (jobId: Guid) {
        if (!this.jobs.length) {
            return null;
        }

        return (this.jobs.find((job: JobSummary) => job.jobId === jobId) as JobSummary) || null;
    }

    getJobByReference (jobReference: string) {
        if (!this.jobs.length) {
            return null;
        }
        return (this.jobs.find((job: JobSummary) => job.reference === jobReference) as JobSummary) || null;
    }

    getColor (status: string) {
        const color = Status.getColour(status);
        return color;
    }

    getIcon (status: string) {
        const icon = Status.getIcon(status);
        return icon;
    }

    getName (status: string) {
        const name = Status.getName(status);
        return name;
    }

    copyLink () {
        const copyText = document.getElementById('urlToCopy') as HTMLInputElement;
        if (copyText) {
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand('copy');
            this.$store.dispatch('messages/addMessage', {
                status: 'Success', title: 'Copy successful', popup: true, message: `Link has been copied to the clipboard:<br/><br/><span class="select">${copyText.value}</span>`,
            });
        }
    }

    formatDate (date: Date, noHours = false) {
        if (noHours) {
            return moment(date).utc().format('DD/MM/YYYY');
        }
        return moment(date).utc().format('DD/MM/YYYY HH:mm');
    }

    formatKey (key: string) {
        switch (key) {
            case ('niNumber'): return 'NI Number';
            case ('fullName'): return 'Name';
            case ('telephone'): return 'Telephone Number';
            case ('dob'): return 'Date of Birth';
            case ('childPhysicalMentalDependent'): return 'Child Dependant';
            case ('otherDependent'): return 'Other Dependant';
            case ('isBankAccountStillOpen'): return 'Member\'s bank account still open';
            case ('dateOfPartnership'): return 'Date Married Or Civil Partnership';
            case ('isDateOfPartnership'): return 'Is Date Married Or Civil Partnership Unknown';
            case ('addressSameAsMember'): return 'Is Address Same As Member';
            case ('mainPointOfContact'): return 'Is Main Point Of Contact';
            default: return this.capitalizeKey(key);
        }
    }

    formatValue (key: string, value: any) {
        if (value === false) return 'No';
        if (value === true) return 'Yes';

        switch (key) {
            case 'dob': return this.formatDate(new Date(value), true);
            case 'message': return value.replace(/,/g, '<br />');
            case 'dateOfDeath': return  value === 'Unknown' ? 'Unknown' : moment(value).format('DD/MM/YYYY');
            case 'dateOfBirth' : return  value === 'Unknown' ? 'Unknown' : moment(value).format('DD/MM/YYYY');
            case 'dateOfPartnership': return  value === 'Unknown' ? 'Unknown' : moment(value).format('DD/MM/YYYY');
            default: return value;
        }
    }

    jobSelectionChange (selectedJobId: Guid) {
        this.newJobId = selectedJobId;
        this.getJob(this.newJobId.toString());
    }

    assignJob () {
        this.loading = true;
        if (this.newJobId && this.webQuery) {
            JobApi.assignJobToWebQuery(
                this.currentClient!.clientId,
                this.webQuery.webQueryId,
                this.newJobId,
                this.pulseUrl,
                !!this.assignedJob,
            )
                .then(() => {
                    this.loading = false;
                    this.showJobAssign = false;

                    if (this.webQuery) {
                        const job = this.getJobById(this.newJobId!);

                        if (job) {
                            this.assignedJob = job;
                            this.assignedJobId = job.jobId.toString();
                            this.webQuery.jobReference = job.reference;
                        }
                    }
                }).finally(() => {
                    this.loading = false;
                });
        }
    }

    unassignJob () {
        if (this.assignedJob && this.webQuery) {
            JobApi.unAssignJobFromWebQuery(this.assignedJob.jobId, this.webQuery.webQueryId, this.pulseUrl).then(() => {
                this.loading = false;
                this.assignedJob = null;
                this.webQuery!.jobReference = '';
                this.newJobId = null;
                this.assignedJobId = '';
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    async onReturnToIndex () {
        this.loading = true;
        try {
            await inboxApi.returnWebQueryToIndex(this.currentClient!.clientId, this.webQuery!.webQueryId, this.assignedJob?.jobId, this.pulseUrl);
            this.showJobAssign = false;
            this.assignedJob = null;
            this.webQuery!.jobReference = '';
            this.webQuery!.memberId = undefined;
            this.newJobId = null;
            this.reloadParentWindow();
        } finally {
            this.loading = false;
        }
    }

    reloadParentWindow () {
        if (window.parent) {
            window.parent.postMessage('reload', '*');
            this.$emit('loadCorrespondence');
        }
    }

    capitalizeKey (key: string) {
        if (key === "") {
            return key;
        }
        key = key.replace(/([A-Z])/g, ' $1');
        return key.charAt(0).toUpperCase() + key.slice(1);
    }

    isBasicValueKeyPairFormat(queryType: string) {
        return queryType === 'General'
        || queryType === 'PhoneUpdate'
        || queryType === 'AddressUpdate'
        || queryType === 'CommunicationPreferencesUpdate';
    }

    async downloadDocument (file: PowerOfAttorneyFile) {
        const downloadLink = document.createElement('a');
        const fileName = file.filename;

        downloadLink.href = file.sasLink;
        downloadLink.download = fileName;
        downloadLink.target = '_blank';
        downloadLink.click();
    }
}

