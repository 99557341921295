
import FinancialData from '@/models/financialData';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class FeedbackScore extends Vue {
    @Prop()
        clientId!: Guid;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'financials') || false as boolean;
    }

    get financeStats (): FinancialData {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'financials');
    }

    get latestBalance (): number {
        return this.financeStats?.endBalance;
    }

    get previousBalance (): number {
        return this.financeStats?.startBalance;
    }

    get balanceChange (): number {
        if (this.previousBalance === 0) return 100;
        return ((this.latestBalance / this.previousBalance) - 1) * 100;
    }

    get startDate () {
        return this.financeStats?.periods?.[0].fromDate ?? '';
    }

    get endDate () {
        return this.financeStats?.periods?.[this.financeStats.periods.length - 1].toDate ?? '';
    }

    formatDate (date: string) {
        return dayjs(date).format('DD MMM YYYY');
    }

    formatNumber (val?: number) {
        if (!val) return 0;
        return Math.round(val).toLocaleString('en');
    }
}
