
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import WebRegistrations from '@/models/webRegistrations';
import ColourService from '@/services/colourService';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';

@Component
export default class Demographics extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    get webRegistrations (): WebRegistrations | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'webRegistrations');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'webRegistrations');
    }

    get webRegistrationsTransformed () {
        if (!this.webRegistrations?.dataByDate) return null;
        const vals: Array<{ type: 'line'; name: string; data: Array<number> }> = [];

        vals.push({
            name: 'Registrations',
            type: 'line',
            data: this.webRegistrations.dataByDate.map((a) => a.registrations),
        });

        return vals;
    }

    get colours () {
        return [ColourService.colours[0]];
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'line',
            },
            xAxis: {
                categories: this.webRegistrations?.dataByDate.map((a) => dayjs(a.date).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: this.colours,
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                    },
                    lineWidth: 3,
                },
                series: {
                    animation: !this.printing,
                },
            },
            series: this.webRegistrationsTransformed || [],
        };
    }
}
