
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class Info extends Vue {
    @Prop()
        tooltip!: string;
}
