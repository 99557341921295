
import JobSummary from 'common/models/jobSummary';
import Status from 'common/util/pulseStatusEnum';
import moment from '~/moment';
import {
    Component, Vue, Prop,
} from '~/vue-property-decorator';

@Component({
    name: 'CorrespondenceJob',
    components: {},
})

export default class CorrespondenceJob extends Vue {
    @Prop()
    assignedJob?: JobSummary;

    @Prop()
    showAssignmentOptions?: boolean;

    @Prop()
    hideReturnToIndex?: boolean;

    getColor (status: string) {
        const color = Status.getColour(status);
        return color;
    }

    getIcon (status: string) {
        const icon = Status.getIcon(status);
        return icon;
    }

    getName (status: string) {
        const name = Status.getName(status);
        return name;
    }

    formatDate (date: Date, noHours = false) {
        if (noHours) {
            return moment(date).utc().format('DD/MM/YYYY');
        }
        return moment(date).utc().format('DD/MM/YYYY HH:mm');
    }

    showJobAssignModal () {
        this.$emit('jobAssignment');
    }

    showJobUnassignJob () {
        this.$emit('jobUnassignment');
    }

    showReturnToIndex () {
        this.$emit('returnToIndex');
    }
}
