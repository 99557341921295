import memberApi from 'common/api/member';
import DataValidation from 'common/models/dataTypes/dataValidation';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface ValidationsState {
    validations: Array<DataValidation>;
    validationsDate: Date;
}

export const state: ValidationsState = {
    validations: [],
    validationsDate: new Date(),
};

export const getters: GetterTree<ValidationsState, unknown> = {
    validations: (s) => s.validations,
    validationsDate: (s) => s.validationsDate,
};

export const actions: ActionTree<ValidationsState, unknown> = {
    getMemberValidations ({ commit }, { clientId, memberId }) {
        return memberApi.getDataValidations(clientId, memberId).then((validations) => {
            commit('setValidations', validations);
            return validations;
        });
    },
};

export const mutations: MutationTree<ValidationsState> = {
    setValidations (s, validations) {
        s.validations = validations;
        s.validationsDate = new Date();
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
