
import { Component, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import DataScores from '@/components/data-scores.vue';
import ClientCalendar from '@/components/client-calendar.vue';
import FeedbackScore from '@/components/feedback-score.vue';

@Component({
    components: {
        ClientCalendar,
        DataScores,
        FeedbackScore,
    },
})
export default class SchemeGovernance extends Vue {
    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get row () {
        return this.$route.params.row;
    }

    mounted () {
        this.$nextTick(() => {
            if (this.$refs[this.row]) {
                (this.$refs.content as HTMLElement).scrollTop = ((this.$refs[this.row] as Vue).$el as HTMLElement).offsetTop;
            }
        });
    }
}
