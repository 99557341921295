
import clientApi from 'common/api/clientsApi';
import MemberSummary from 'common/models/memberSummary';
import MemberSearchParams from 'common/models/memberSearchParams';
import GridPaging from 'common/components/grid-paging.vue';
import Guid from 'common/util/guid';
import * as STORE from 'common/store/storepaths';
import {
    Component, Prop, Vue, Watch,
} from '~/vue-property-decorator';
import moment from '~/moment';
import { debounce } from '~/lodash';

@Component({
    name: 'Search', components: { GridPaging },
})
export default class Search extends Vue {
    @Prop()
    name!: string;

    @Prop()
    clientName!: string;

    @Prop()
    clientId!: Guid;

    value!: MemberSummary;

    loading = false;

    hasSearched = false;

    centered = true;

    members: Array<MemberSummary> | null = null;

    pendingGoto = NaN;

    totalResults = 0;

    willResetPage = false;

    showAdvancedSearchOptions = true;

    searchOptions: MemberSearchParams = {
        memberId: undefined,
        surname: '',
        forenames: '',
        dateOfBirth: undefined,
        nino: '',
        postCode: '',
        status: 'all',
        phoneNumber: '',
        sort: 'memberId',
        pageNumber: 1,
        pageSize: 10,
    }

    listFilterOptions = [
        { id: 'all', name: 'All' },
        { id: 'active', name: 'Active' },
        { id: 'deferred', name: 'Deferred' },
        { id: 'pensioner', name: 'Pensioner' },
        { id: 'dependant', name: 'Dependant' },
        { id: 'exit', name: 'Exit' },
    ];

    mounted () {
        this.$nextTick(() => {
            this.$nextTick(() => {
                ((this.$refs.memberId as Vue).$el.children[1].children[0] as HTMLInputElement).focus();
            });
        });
    }

    get selectedMember () {
        return this.value;
    }

    set selectedMember (newValue) {
        this.$emit('selectedMemberChange', newValue);
    }

    get isSearchInvalid () {
        return !!this.searchOptions.memberId && !this.searchOptions.memberId?.toString().match('^[0-9]*$');
    }

    get searchResults () {
        return this.results;
    }

    get results () {
        return this.members;
    }

    get memberDataApiUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION]['memberdata.api.url'] ?? '';
    }

    get loadedSearch () {
        return this.hasSearched && !this.loading && this.members && this.members.length !== 0;
    }

    get getDebounce () {
        return debounce(() => {
            this.search();
        }, 600);
    }

    get memberIdDisabled () {
        return !!(this.searchOptions.surname?.length || this.searchOptions.forenames?.length
        || this.searchOptions.dateOfBirth?.length || this.searchOptions.nino?.length
        || this.searchOptions.postCode?.length || this.searchOptions.phoneNumber?.length
        || this.searchOptions.status !== 'all');
    }

    get columns () {
        return [
            {
                id: 'memberId', name: 'ID', prop: 'memberId', class: () => 'id', sortable: true, filterable: false,
            },
            {
                id: 'surname', name: 'Surname', prop: 'surname', sortable: true, filterable: false,
            },
            {
                id: 'forenames', name: 'Forenames', prop: 'forenames', sortable: true, filterable: false,
            },
            {
                id: 'dob', name: 'Date of birth', sortValue: (r: MemberSummary) => r.dateBirth, value: (r: MemberSummary) => this.formatDate(r.dateBirth), sortable: true, filterable: false,
            },
            {
                id: 'nino', name: 'NINO', prop: 'nino', sortable: true, filterable: false,
            },
            {
                id: 'postCode', name: 'Postcode', prop: 'postCode', sortable: true, sortValue: (r: MemberSummary) => r.postCode?.toLowerCase() ?? '', filterable: false,
            },
            {
                name: 'Status', id: 'status', prop: 'status', value: (r: MemberSummary) => (r.servicePeriods && r.servicePeriods.length ? r.servicePeriods[0].status : ''), sortable: false, filterable: false,
            },
            {
                name: 'Status date', id: 'statusDate', sortValue: (r: MemberSummary) => (r.servicePeriods && r.servicePeriods.length ? r.servicePeriods[0].statusDate : ''), sortable: false, filterable: false,
            },
        ];
    }

    tableSorted (param: string, isAscending: boolean) {
        this.searchOptions.pageNumber = 1;
        this.searchOptions.sort = `${isAscending ? '-' : ''}${param}`;
        this.search();
    }

    formatDate (date: Date) {
        return moment(date).format('DD/MM/YYYY');
    }

    formatLcpDate (date: Date) {
        if (!date) return null;
        return `${date.getFullYear}-${date.getMonth}-${date.getDay}`;
    }

    pageNumberChanged (pageNumber: number) {
        this.searchOptions.pageNumber = pageNumber;
        this.search();
    }

    pageSizeChanged (pageSize: number) {
        this.searchOptions.pageSize = pageSize;
        this.search();
    }

    gotoMember () {
        if (this.isInvalidMemberId()) {
            this.loading = false;
            this.willResetPage = false;
            return;
        }
        this.onSearch();
        if (this.loadedSearch && this.searchResults?.length === 1) {
            this.selectedMember = this.searchResults[0];
            return;
        }
        const searchNumber = Number(this.searchOptions.memberId);
        this.pendingGoto = Number.isNaN(searchNumber) || searchNumber < 0
            ? NaN
            : searchNumber;
        this.tryNavigateToMember();
    }

    tryNavigateToMember () {
        if (this.searchResults && this.searchResults.length > 0 && this.searchResults[0].memberId === this.pendingGoto) {
            this.selectedMember = this.searchResults[0];
        }
    }

    onSearch () {
        this.pendingGoto = NaN;
        this.loading = true;
        this.getDebounce();
    }

    isInvalidMemberId () {
        const reg = /^[0-9]+$/;
        return this.searchOptions.memberId && !reg.test(this.searchOptions.memberId.toString());
    }

    search () {
        if (this.isInvalidMemberId()) {
            this.loading = false;
            this.willResetPage = false;
            return;
        }
        this.loading = true;
        if (this.willResetPage) {
            this.willResetPage = false;
            this.searchOptions.pageNumber = 1;
        }
        this.hasSearched = true;
        clientApi.memberAdvancedSearch(this.clientId, this.searchOptions, this.memberDataApiUrl)
            .then((result) => {
                this.members = result;
                this.loading = false;
                this.tryNavigateToMember();
                this.totalResults = result.headers['x-total-count'];
            });
    }

    @Watch('searchOptions.status')
    selectedOptionChanged(){
        this.search();
        this.willResetPage = true;
    }
}
