export default class MemberSearchOptions {
    memberId!: number | null;

    surname = '';

    forenames = '';

    dateOfBirth = '';

    nino = '';

    pageNumber = 1;

    pageSize = 25;

    hasSearched = false;

    totalResults = 0;

    viewAdvancedSearch = false;
}
