export default class ClientStats {
    memberCount!: number;

    countByStatus!: {
        Dependant: number;
        Pensioner: number;
        Deferred: number;
        Active: number;
    };
}
