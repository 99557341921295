// Auth

export const GET_USER_INFO = 'auth/userInfo';
export const LOGGED_IN = 'auth/loggedIn';
export const LOGOUT = 'auth/logout';

// Client

export const GET_CLIENTS = 'client/clients';
export const GET_USER_CLIENTS = 'client/userClients';
export const GET_CURRENT_CLIENT = 'client/currentClient';

export const SET_CLIENTS = 'client/getClients';
export const FETCH_USER_DETAILS = 'users/getUserDetails';
export const SET_CURRENT_CLIENT = 'client/setCurrentClient';
export const SET_CLIENT_FROM_NAME = 'client/setClientFromName';

export const FETCH_RECENT_MEMBERS = 'client/getRecentMembers';
export const GET_RECENT_MEMBERS = 'client/recentMembers';

// Common
export const GET_TITLES = 'common/getTitles';
export const GET_COUNTRIES = 'common/getCountries';
export const LOAD_TITLES = 'common/loadTitles';
export const LOAD_COUNTRIES = 'common/loadCountries';

// Configuration

export const GET_SITE_CONFIGURATION = 'configuration/site';
export const GET_CLIENT_WEB_SITE_CONFIGURATION = 'configuration/clientWebSite';
export const PULSE_API_URL = 'pulse-api.url';
export const MEMBERDATA_API_URL = 'memberdata.api.url';

export const GET_HOLIDAYS = 'config/holidays';
export const LOAD_HOLIDAYS = 'config/getHolidays';
export const GET_COLOURS = 'config/colours';
export const LOAD_COLOURS = 'config/getColours';

// Guidmap

export const GET_GUIDS = 'guidmap/guids';

// Jobs
export const GET_JOBS = 'jobs/jobs';
export const GET_CLIENT_JOBS = 'jobs/clientJobs';
export const GET_JOBS_DATE = 'jobs/jobsDate';
export const SET_JOBS = 'jobs/getPulseMemberJobs';
export const SET_CLIENT_JOBS = 'jobs/getPulseClientJobs';

// Correspondence
export const GET_CORRESPONDENCE = 'correspondence/correspondence';
export const GET_CORRESPONDENCE_DATE = 'correspondence/correspondenceDate';
export const FETCH_CORRESPONDENCE = 'correspondence/getMemberCorrespondence';
export const CLEAR_CORRESPONDENCE = 'correspondence/clearCorrespondence';

// Calculations
export const GET_CALCULATIONS = 'calculations/calculations';
export const GET_CALCULATIONS_DATE = 'calculations/calculationsDate';
export const FETCH_CALCULATIONS = 'calculations/getMemberCalculations';
export const CLEAR_CALCULATIONS = 'calculations/clearCalculations';

// Validations
export const GET_VALIDATIONS = 'validations/validations';
export const GET_VALIDATIONS_DATE = 'validations/validationsDate';
export const FETCH_VALIDATIONS = 'validations/getMemberValidations';

// Verifications
export const GET_VERIFICATIONS = 'verifications/verifications';
export const GET_VERIFICATIONS_DATE = 'validations/verificationsDate';
export const FETCH_VERIFICATIONS = 'verifications/getMemberVerifications';

// Other

export const LOADING = 'loading/loading';

// Users

export const GET_ALL_USERS = 'users/allUsers';
export const HAS_PERMISSION = 'users/hasPermission';
export const GET_ALL_USERS_BY_ID = 'users/allUsersByUserId';
export const SET_ALL_USERS = 'users/getAllUsers';
