
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import MemberMovements from '@/models/memberMovements';

@Component
export default class MemberMovementsTile extends Vue {
    @Prop()
        clientId!: Guid;

    get fromDate () {
        return this.$store.getters['clientContext/fromDate'](this.clientId) as string;
    }

    get toDate () {
        return this.$store.getters['clientContext/toDate'](this.clientId) as string;
    }

    get formattedFromDate () {
        return dayjs(this.fromDate).format('DD MMM YYYY');
    }

    get formattedToDate () {
        return dayjs(this.toDate).format('DD MMM YYYY');
    }

    get memberMovements (): MemberMovements | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'membershipMovements');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'membershipMovements');
    }

    typeVal (type: string, status: { [key: string]: number }) {
        if (status[type]) return status[type];
        return '';
    }

    hasFrom (type: string, statusName: string) {
        if (!this.memberMovements?.movements) return false;
        if (statusName === 'Pensioner' && type === 'Retirement') return true;
        return false;
    }

    arrowPos (type: string, statusName: string, index: number) {
        if (type !== 'Retirement') return 0;
        if (!this.memberMovements?.movements || index < 0) return 0;
        let firstIndex = -1;
        let secondIndex = -1;
        Object.keys(this.memberMovements.movements).forEach((a, i) => {
            if (statusName === a) firstIndex = i;
            if (statusName === 'Deferred' && a === 'Pensioner') secondIndex = i;
        });
        if (secondIndex === -1 || index !== firstIndex) return 0;
        return { width: `${(secondIndex - firstIndex) * 100 - 35}%` };
    }

    get rows () {
        const rows: Array<{ key: string; val: number }> = [];
        if (!this.memberMovements?.movements) return [];
        Object.keys(this.memberMovements.movements).forEach((status) => {
            Object.keys(this.memberMovements!.movements[status])
                .forEach(((key) => {
                    if (rows.find((a) => a.key === key)) return;
                    rows.push({ key, val: this.memberMovements!.movements[status][key] });
                }));
        });
        return rows;
    }

    typeCount (type: string) {
        if (!this.memberMovements?.movements) return 0;
        let number = 0;
        Object.keys(this.memberMovements.movements).forEach((key) => {
            if (key !== type) return;
            Object.keys(this.memberMovements!.movements[key]).forEach((a) => {
                const n = this.memberMovements!.movements[key][a];
                number += a.startsWith(type) ? -n : n;
            });
        });
        return number;
    }

    get highestNumber () {
        let max = 0;
        if (!this.memberMovements) return 0;
        Object.values(this.memberMovements.movements).forEach((movement) => {
            Object.values(movement).forEach((val) => {
                if (val > max) max = val;
            });
        });
        return max;
    }
}
