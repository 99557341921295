
import Guid from 'common/util/guid';
import TermsWording from '@/components/terms-wording.vue';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component({
    components: { TermsWording },
})
export default class Terms extends Vue {
    @Prop()
        clientId!: Guid;

    loading = false;

    selectedNews: never | null = null;
}
