export default class MemberMovements {
    byStatusAtFromDate!: {
        Active?: number;
        Deferred: number;
        Pensioner: number;
        Dependant?: number;
    };

    bytStatusAtToDate!: {
        Active?: number;
        Deferred: number;
        Pensioner: number;
        Dependant?: number;
    };

    movements!: {
        [key: string]: { [movement: string ]: number };
        Active: {
            'Retirement': number;
            'Transfer out': number;
            'Death': number;
            'New dependant': number;
        };
        Deferred: {
            'Retirement': number;
            'Transfer out': number;
            'Death': number;
            'New dependant': number;
        };
        Pensioner: {
            'Retirement': number;
            'Transfer out': number;
            'Death': number;
            'New dependant': number;
        };
        Dependant: {
            'Retirement': number;
            'Transfer out': number;
            'Death': number;
            'New dependant': number;
        };
    };
}
