
import { Component, Prop, Vue } from 'vue-property-decorator';
import ActiveCeasedAccrualCollections from '@/models/memberDataTypes/activeCeasedAccrualCollections';

@Component
export default class ActiveCeasedAccrualCollectionsVue extends Vue {
    @Prop()
        dataTypeData!: ActiveCeasedAccrualCollections;

    get hasLumpSum () {
        return this.dataTypeData.items.some((x) => 'lumpSum' in x);
    }

    get hasComparator () {
        return this.dataTypeData.items.some((x) => 'comparator' in x);
    }

    get hasAlternativeBenefit () {
        return this.dataTypeData.items.some((x) => 'alternativeBenefit' in x);
    }

    get hasAlternativeBenefitComparator () {
        return this.dataTypeData.items.some((x) => 'alternativeBenefitComparator' in x);
    }
}
