export default class MemberLogins {
    logins!: number;

    repeatVisits!: number;

    fromDate!: Date;

    toDate!: Date;

    loginsByAge!: Record<string, number>;

    loginsByStatus!: Record<string, number>;

    repeatVisitsByStatus!: Record<string, number>;
}
