
import moment from '~/moment';
import { Component, Prop, Vue } from '~/vue-property-decorator';

@Component({
    name: 'last-refreshed',
    components: {},
})
export default class LastRefreshed extends Vue {
    @Prop()
    isLoading!: boolean;

    @Prop()
    lastRefreshed!: string;

    formatDate (date: string) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    reload () {
        this.$emit('reload');
    }
}
