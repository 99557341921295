import { LegendOptions } from 'highcharts';
import Vue from 'vue';

export default class PieLegend {
    private static pieLegend: PieLegend;

    isMobile = false;

    static get () {
        if (!PieLegend.pieLegend) PieLegend.pieLegend = Vue.observable(new PieLegend());
        return PieLegend.pieLegend;
    }

    constructor () {
        window.onresize = () => {
            this.isMobile = window.matchMedia('(max-width: 700px)').matches;
        };
        this.isMobile = window.matchMedia('(max-width: 700px)').matches;
    }

    get legend (): LegendOptions {
        const legend: LegendOptions = {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle',
            floating: false,
            itemMarginBottom: 5,
            x: -45,
            navigation: {
                enabled: false,
            },
            itemStyle: {
                fontSize: '1rem',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '400',
            },
        };
        if (this.isMobile) {
            legend.align = 'center';
            legend.layout = 'horizontal';
            legend.verticalAlign = 'bottom';
            legend.x = 0;
        }
        return legend;
    }
}
