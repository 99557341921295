
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import TransferStats from '@/models/transferStats';

@Component
export default class Transfers extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean | null;

    @Prop()
        viewByValue!: boolean | null;

    valueView = false;

    @Watch('viewByValue', { immediate: true })
    viewByValueChanged () {
        this.valueView = !!this.viewByValue;
    }

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'transfers') || false as boolean;
    }

    get transferStats (): TransferStats {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'transfers');
    }

    get transferStatsTranformed () {
        if (!this.transferStats) return null;
        return [{
            name: 'Quotes',
            color: ColourService.getSeriesColour('transfers', 'quotes'),
            type: 'column',
            data: this.transferStats.dataByDate
                .map((a) => (this.valueView ? a.quotesAmount : a.quotes)),
        },
        {
            name: 'Payments',
            color: ColourService.getSeriesColour('transfers', 'transfers'),
            type: 'column',
            data: this.transferStats.dataByDate
                .map((a) => (this.valueView ? a.transferAmount : a.transfers)),
        }] as Array<SeriesOptionsType>;
    }

    get chartOptions (): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
            },
            xAxis: {
                categories: this.transferStats?.dataByDate.map((a) => dayjs(a.startDate).format('DD MMM YYYY')),
                labels: {
                    formatter: (that) => dayjs(that.value).format('DD MMM'),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            tooltip: {
                pointFormat: this.valueView ? '{series.name}: <b>£{point.y}</b><br/>' : '{series.name}: <b>{point.y}</b><br/>',
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
                labels: {
                    formatter () {
                        const label = this.value;
                        return self.valueView ? `£${label.toLocaleString('en')}` : label.toString();
                    },
                },

            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '0.9rem',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '400',
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                // column: {
                //     stacking: 'normal',
                // },
            },
            series: this.transferStatsTranformed || [],
        };
    }
}
