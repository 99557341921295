
import MembersApi from 'common/api/member';
import ClientsApi from 'common/api/clientsApi';
import inboxApi from 'common/api/inboxApi';
import Client from 'common/models/client';
import * as STORE from 'common/store/storepaths';
import CorrespondenceEmail from 'common/models/correspondence/email';
import EmailAttachment from 'common/models/correspondence/emailAttachment';
import EmailHistory from 'common/models/correspondence/emailHistory';
import JobSummary from 'common/models/jobSummary';
import User from 'common/models/user';
import Status from 'common/util/pulseStatusEnum';
import Guid from 'common/util/guid';
import correspondenceJob from './correspondence-job.vue';
import moment from '~/moment';
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';

@Component({
    name: 'EmailViewer',
    components: {
        correspondenceJob,
    },
})

export default class EmailViewer extends Vue {
    @Prop()
    emailId!: string;

    @Prop()
    jobReference!: string;

    @Prop()
    emailMemberId!: string;

    @Prop()
    isQuickPreview: boolean = false;

    email: CorrespondenceEmail | null = null;

    isSaving = false;

    assignedJob?: JobSummary | null = null;

    assignedJobId?: string = '';

    showJobAssign = false;

    newJobId?: Guid | null = null;

    loading = false;

    jobs: Array<JobSummary> = [];

    originalDescription?: string = '';

    versionHistory: Array<EmailHistory> = [];

    viewHistory = false;

    async mounted () {
        if (!this.clients?.length) {
            await this.$store.dispatch('client/getClients');
        }
        if (this.currentClient && !this.$store.getters[STORE.GET_CURRENT_CLIENT]) {
            await this.$store.dispatch(STORE.SET_CURRENT_CLIENT, this.currentClient);
        }
        if (!this.users?.length) {
            this.$store.dispatch('users/getAllUsers', this.pulseUrl);
        }

        this.getEmail();
        this.getJob(this.jobReference);
    }

    get currentClient () {
        if (this.clients && this.itemDetails.length) {
            const id = this.itemDetails[0];
            const found = this.clients.find((a) => a.code === id);
            if (found) return found;
        }
        return null;
    }

    get itemDetails () {
        return this.emailId.split('-');
    }

    get clients () {
        return this.$store.getters[STORE.GET_CLIENTS] as Array<Client>;
    }

    get memberId () {
        if (this.emailMemberId) return Number(this.emailMemberId);
        if (this.$route.query.memberId) return Number(this.$route.query?.memberId);
        return Number(this.itemDetails[1]);
    }

    get emailReference () {
        return this.emailId;
    }

    get isEmbedded () {
        return this.$route.query.wm;
    }

    get hasWorkmanagement () {
        return this.$store.getters['clients/clients']?.find((a: Client) => a.name === this.currentClient!.name)?.workManagement;
    }

    get emailUrl () {
        return `${window.location.protocol}//${window.location.host}${this.$route.path}`;
    }

    get emailContent () {
        if (!this.email?.body?.content) return '';
        let content = this.email.body.content
            .replace(/<a href="file:\/\//g, '<a class="no-link" href="file:\\\\')
            .replace(/<a href/g, '<a target="_blank" href');

        content += '<style>a.no-link {pointer-events: none;text-decoration: none;color: #333;}</style>';
        return content;
    }

    get filteredAttachments () {
        return this.email?.attachments?.filter((a: EmailAttachment) => !a.isInline);
    }

    get jobCount () {
        return this.jobs?.length ?? 0;
    }

    get pulseUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION]['pulse-api.url'];
    }

    get memberDataApiUrl () {
        return this.$store.getters['configuration/site']['memberdata.api.url'];
    }
    
    get isDescriptionSet () {
        return this.email?.description?.length;
    }

    get setOriginalDescription () {
       return this.originalDescription = this.email?.description;
    }

    get hasEmailDetailsChanged () {
       return this.email?.description !== this.originalDescription;
    }

    get usersById () {
        return this.$store.getters[STORE.GET_ALL_USERS_BY_ID] as Record<string, User>;
    }

    get users () {
        return this.$store.getters[STORE.GET_ALL_USERS] as Array<User> | undefined;
    }

    get historyButtonText () {
        return this.viewHistory ? 'View Email' : 'View History'
    }

    get hasEmailHistory () {
        return this.versionHistory.length > 0;
    }

    async getJob (jobId: string) {
        this.jobs = [await this.$store.dispatch('jobs/getJob', { jobId: jobId, pulseApiUrl: this.pulseUrl }) ];
    }

    async getEmail(){
        if (this.memberId) {
            await MembersApi.getEmail(this.currentClient!.clientId, this.memberId, this.emailReference, this.memberDataApiUrl)
                .then((result) => {
                    this.email = result;
                    this.getInlineAttachments();
                    this.setOriginalDescription;
                });
                await MembersApi.getEmailHistory(this.currentClient!.clientId, this.memberId, this.emailReference, this.memberDataApiUrl)
                    .then((result) => {
                this.versionHistory = result;
            });
        } else {
            await ClientsApi.getClientEmail(this.currentClient!.clientId, this.emailReference, this.memberDataApiUrl)
                .then((result) => {
                    this.email = result;
                    this.getInlineAttachments();
                    this.setOriginalDescription;
                });
            await ClientsApi.getClientEmailHistory(this.currentClient!.clientId, this.emailReference, this.memberDataApiUrl)
                .then((result) => {
                this.versionHistory = result;
            });
        }
    }

    getInlineAttachments () {
        if (this.email?.attachments) {
            const unloaded = this.email.attachments.filter((i) => !i.contentBytes && i.isInline);
            if (unloaded.length > 0) {
                let content = this.email.body.content;
                unloaded.forEach((attachment) => {
                    if (this.email?.attachments && this.memberId) {
                        MembersApi.getEmailAttachment(this.currentClient!.clientId, this.memberId, this.emailReference, attachment.id, this.memberDataApiUrl).then((result) => {
                            Vue.set(attachment, 'contentBytes', result.contentBytes);
                            content = content.replace(`cid:${result.contentId}`, `data:image;base64, ${result.contentBytes}`);
                            this.email!.body.content = content;
                        });
                    } else if (this.email?.attachments && !this.memberId) {
                        ClientsApi.getClientEmailAttachment(this.currentClient!.clientId, this.emailReference, attachment.id, this.memberDataApiUrl).then((result) => {
                            Vue.set(attachment, 'contentBytes', result.contentBytes);
                            content = content.replace(`cid:${result.contentId}`, `data:image;base64, ${result.contentBytes}`);
                            this.email!.body.content = content;
                        });
                    }
                });
            }
            // eslint-disable-next-line no-restricted-globals
            if (parent) {
                // eslint-disable-next-line no-restricted-globals
                parent.postMessage('loaded', '*');
            }
        }
    }

    getJobByReference (jobReference: string) {
        if (!this.jobs?.length) {
            return null;
        }

        return (this.jobs.find((job: JobSummary) => job.reference === jobReference) as JobSummary) || null;
    }

    get allInlineLoaded () {
        if (!this.email?.attachments) {
            return true;
        }
        const unloaded = this.email.attachments.filter((i: { contentBytes: unknown; isInline: boolean}) => !i.contentBytes && i.isInline);
        return unloaded.length === 0;
    }

    getDocumentType (attachment: { name: string }) {
        const fileArr = attachment.name.split('.');
        const fileType = fileArr[fileArr.length - 1];
        switch (fileType) {
            case 'doc':
            case 'docx':
            case 'docm':
                return 'file-word';
            case 'xls':
            case 'xlsx':
            case 'xlsm':
                return 'file-excel';
            case 'pdf':
                return 'file-pdf';
            case 'txt':
                return 'file';
            case 'msg':
                return 'envelope';
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'bmp':
            case 'gif':
                return 'image';
            default:
                return 'file';
        }
    }

    getFileSize (size: number) {
        return Math.floor((size / 1024 / 1024) * 100) / 100;
    }

    openWebLink (link: string) {
        window.open(link, '_blank');
    }

    deleteEmail () {
        if (this.emailMemberId) {
            MembersApi.deleteEmail(this.currentClient!.clientId, this.memberId, this.emailReference).then(() => {
                this.email = null;
                this.$emit('emailDeleted', this.emailReference);
            }).catch((err) => {
                this.$store.dispatch('messages/addError', err, { root: true });
                this.isSaving = false;
            });
        } else {
            ClientsApi.deleteClientEmail(this.currentClient!.clientId, this.emailReference).then(() => {
                this.email = null;
                this.$emit('emailDeleted', this.emailReference);
            }).catch((err) => {
                this.$store.dispatch('messages/addError', err, { root: true });
                this.isSaving = false;
            });
        }
    }

    async saveEmailDetails () {
        this.loading = true;
          if (this.emailMemberId) {
            await MembersApi.updateEmail(this.currentClient!.clientId, this.memberId, this.emailReference, this.memberDataApiUrl, {description: this.email?.description}).then(() => {
                this.$emit('emailUpdated', this.emailReference, this.email?.description);
                this.getEmail();
                if (!this.isQuickPreview) {
                    this.reloadParentWindow("reload email");
                }
            }).catch((err) => {
                this.loading = false;
                this.$store.dispatch('messages/addError', err, { root: true });
                
            });
        } else {
            await ClientsApi.updateClientEmail(this.currentClient!.clientId, this.emailReference, this.memberDataApiUrl, {description: this.email?.description}).then(() => {
                this.$emit('emailUpdated', this.emailReference, this.email?.description);
                this.getEmail();
                if (!this.isQuickPreview) {
                    this.reloadParentWindow("reload email");
                }
            }).catch((err) => {
                this.loading = false;
                this.$store.dispatch('messages/addError', err, { root: true });
            });
        }
        this.loading = false;
    }

    async downloadAttachment (attachmentId: string) {
        let emailAttachmentResult = new EmailAttachment();
        if (this.email?.attachments && this.memberId) {
            await MembersApi.getEmailAttachment(this.currentClient!.clientId, this.memberId, this.emailReference, attachmentId).then((result) => {
                emailAttachmentResult = result;
            });
        } else if (this.email?.attachments && !this.memberId) {
            await ClientsApi.getClientEmailAttachment(this.currentClient!.clientId, this.emailReference, attachmentId).then((result) => {
                emailAttachmentResult = result;
            });
        }
        const linkSource = `data:${emailAttachmentResult.contentType};base64,${emailAttachmentResult.contentBytes}`;
        const downloadLink = document.createElement('a');
        const fileName = emailAttachmentResult.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    copyLink () {
        const copyText = document.getElementById('urlToCopy') as HTMLInputElement;
        if (copyText) {
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand('copy');

            this.$store.dispatch('messages/addMessage', {
                status: 'Success', title: 'Copy successful', popup: true, message: `Link has been copied to the clipboard:<br/><br/><span class="select">${copyText.value}</span>`,
            });
        }
    }

    getColor (status: string) {
        const color = Status.getColour(status);
        return color;
    }

    getIcon (status: string) {
        const icon = Status.getIcon(status);
        return icon;
    }

    getName (status: string) {
        const name = Status.getName(status);
        return name;
    }

    getUser (userId: Guid) {
        const user = this.usersById[userId.toString()];
        if (!user) return 'System';
        return `${user.forenames} ${user.surname}`;
    }

    formatDate (date: Date, noHours = false) {
        if (noHours) {
            return moment(date).format('DD/MM/YYYY');
        }
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    openJob () {
        const url = `${this.$store.getters[STORE.GET_SITE_CONFIGURATION]['workmanagement.url']}/${this.currentClient?.name}/jobs/${this.jobReference}`;
        window.open(url, '_blank');
    }

    async returnToIndexing () {
        this.loading = true;
        try {
            await inboxApi.returnEmailToIndexing(this.currentClient!.clientId, this.emailReference, this.assignedJob?.jobId, this.pulseUrl);
            this.showJobAssign = false;
            this.assignedJob = null;
            this.jobReference = '';
            this.newJobId = null;
            this.reloadParentWindow("reload");
        } finally {
            this.loading = false;
        }
    }

    reloadParentWindow (message: string) {
        if (window.parent) {
            window.parent.postMessage(message, '*');
            this.$emit('loadCorrespondence');
        }
    }

    @Watch('jobCount')
    jobsChanged () {
        this.assignedJob = this.getJobByReference(this.jobReference);
    }
}

