/* eslint-disable max-classes-per-file */
export default class GovernanceInformation {
    dataBreaches!: number;

    sarRequests!: number;

    mortalityScreeningDates!: Array<string>;

    dataScores!: {
        commonScore: number;
        specificScore: number;
        date: Date;
    };

    memberFeedback!: {
        ourService: number;
        ourCommunication: number;
    };
}
