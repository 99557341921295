import './interceptor';
import store from '@/store';
import ApiResponse from 'common/models/apiResponse';
import ApiOptions from 'common/models/apiOptions';
import Map from '~/@lcp/class-mapper';
import axios from '~/axios';

export default class Http {
    static baseUrl = process.env.VUE_APP_API_ENDPOINT;

    public static get<T> (url: string, type?: { new (): T }, options?: ApiOptions): Promise<T> {
        return this._request(url, 'get', type, options) as Promise<T>;
    }

    static getAsArray<T> (url: string, type?: { new (): T }, options?: ApiOptions): Promise<Array<T>> {
        return this._request<T>(url, 'get', type, options) as Promise<Array<T>>;
    }

    public static delete<T> (url: string, data: unknown = null, type?: { new (): T }, options?: ApiOptions): Promise<T> {
        return this._dataRequest(url, data, 'delete', type, options) as Promise<T>;
    }

    public static post<T> (url: string, data: unknown, type?: { new (): T }, options?: ApiOptions): Promise<T> {
        return this._dataRequest(url, data, 'post', type, options) as Promise<T>;
    }

    public static put<T> (url: string, data: unknown, type?: { new (): T }, options?: ApiOptions): Promise<T> {
        return this._dataRequest(url, data, 'put', type, options) as Promise<T>;
    }

    public static patch<T> (url: string, data: unknown, type?: { new (): T }, options?: ApiOptions): Promise<T> {
        return this._dataRequest(url, data, 'patch', type, options) as Promise<T>;
    }

    private static async _request<T> (url: string, method: 'get' | 'delete' = 'get', type?: { new (): T }, options?: ApiOptions): Promise<T | T[]> {
        const apiOptions = this.map({ ...options }, ApiOptions) as ApiOptions;
        const token = apiOptions?.useToken ? await store.dispatch('auth/getToken') : '';
        return axios.request<string, ApiResponse<T>>({
            method,
            url: apiOptions?.useBasePath ? `${this.baseUrl}/${url}` : url,
            params: apiOptions?.params,
            ...{
                ...apiOptions?.config,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: apiOptions?.useToken ? `Bearer ${token?.accessToken}` : null,
                    ...apiOptions?.config?.headers,
                },
            },
        }).then((response) => {
            if (apiOptions?.returnHeaders) {
                const d = response.data as T & { headers: unknown };
                const obj = this.map<T>(d, type);
                (obj as T & { headers: unknown }).headers = response.headers;
                return obj;
            }
            if (apiOptions?.showSuccessMessage) {
                store.dispatch('messages/addMessage', {
                    status: 'Success', title: apiOptions?.successTitle, message: apiOptions?.message || 'Success', popup: apiOptions?.showPopup,
                });
            }
            return this.map<T>(response.data, type);
        }).catch((error) => {
            store.dispatch('messages/addError', { ...error, message: apiOptions?.errorMessage || error, title: apiOptions?.errorTitle });
            throw error;
            return error;
        });
    }

    private static async _dataRequest<T> (url: string, data: unknown, method: 'post' | 'put' | 'patch' | 'delete' = 'post', type?: { new (): T }, options?: ApiOptions): Promise<T | T[]> {
        const apiOptions = this.map({ ...options }, ApiOptions) as ApiOptions;
        const token = apiOptions?.useToken ? await store.dispatch('auth/getToken') : '';
        return axios.request<string, ApiResponse<T>>({
            method,
            url: apiOptions?.useBasePath ? `${this.baseUrl}/${url}` : url,
            data,
            params: apiOptions?.params,
            ...{
                ...apiOptions?.config,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: apiOptions?.useToken ? `Bearer ${token.accessToken}` : null,
                    ...apiOptions?.config?.headers,
                },
            },
        }).then((response) => {
            if (apiOptions?.returnHeaders) {
                const d = response.data as T & { headers: unknown };
                d.headers = response.headers;
                return this.map<T>(d, type);
            }
            if (apiOptions?.showSuccessMessage) {
                store.dispatch('messages/addMessage', {
                    status: 'Success', title: apiOptions?.successTitle, message: apiOptions?.message || 'Success', popup: apiOptions?.showPopup,
                });
            }
            return this.map<T>(response.data, type);
        }).catch((error) => {
            console.log(error);
            store.dispatch('messages/addError', { ...error, message: apiOptions?.errorMessage || error, title: apiOptions?.errorTitle });
            throw error;
            return error;
        });
    }

    static map<T> (a: T & { headers?: unknown }, Type?: { new (): T }) {
        if (!Type) return a as T;
        const obj = Map<T>(a, Type);
        if (Array.isArray(Type)) {
            if (a.headers && obj) {
                (obj as { headers?: unknown }).headers = a.headers;
            }
            return obj;
        }
        return obj;
    }
}
