
import memberApi from 'common/api/member';
import jobApi from 'common/api/jobApi';
import inboxApi from 'common/api/inboxApi';
import Client from 'common/models/client';
import * as STORE from 'common/store/storepaths';
import PhoneCall from 'common/models/phoneCall';
import Status from 'common/util/pulseStatusEnum';
import JobSummary from 'common/models/jobSummary';
import Guid from 'common/util/guid';
import JobSelectorPulse from 'common/components/job-selector-pulse.vue';
import PhoneCallHistory from 'common/models/phoneCallHistory';
import PhoneCallDuplicatedInfo from 'common/models/phoneCallDuplicatedInfo';
import clientsApi from 'common/api/clientsApi';
import moment from '~/moment';
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';

@Component({
    name: 'phoneViewer',
    components: {
        JobSelectorPulse,
    },
})
export default class PhoneViewer extends Vue {
    @Prop()
    callNumber!: string;

    @Prop()
    phoneCallMemberId!: number;

    selectedCall?: PhoneCall | null = null;

    selectedHistory?: PhoneCallHistory | null = null;

    isLoading = true;

    isSaving = false;

    isEditing = false;

    subject = '';

    conversation = '';

    jobReference?: string;

    oldSubject = '';

    oldConversation = '';

    newJobGuid?: Guid | null = null;

    previousJobReference: unknown = null; // TODO

    showJobAssign = false; // TODO

    assignedJob: JobSummary | null = null

    copyLinkValue = ''

    copyText = '';

    jobs: Array<JobSummary> = [];

    async mounted () {
        this.isLoading = true;
        if (!this.clients?.length) {
            await this.$store.dispatch('client/getClients');
        }
        if (this.currentClient && !this.$store.getters[STORE.GET_CURRENT_CLIENT]) {
            await this.$store.dispatch(STORE.SET_CURRENT_CLIENT, this.currentClient);
        }
        if (this.memberId) {
            memberApi.getCall(this.currentClient!.clientId, this.memberId, this.callId, this.memberDataApiUrl).then((response) => {
                this.loadCall(response).then(async () => {
                    if (response.jobId) {
                        this.getJob(response.jobId);
                    }
                });
                this.isLoading = false;
            });
        } else {
            clientsApi.getClientCall(this.currentClient!.clientId, this.callId).then((response) => {
                this.loadCall(response).then(async () => {
                    if (response.jobId) {
                        this.getJob(response.jobId);
                    }
                });

                this.isLoading = false;
            });
        }
    }

    async getJob (jobId: string) {
        this.jobs = [await this.$store.dispatch('jobs/getJob', { jobId: jobId, pulseApiUrl: this.jobUrl }) ];

        const job = this.getJobByReference(jobId!) || this.jobs.find(a => a.jobId.toString() === jobId);
        if (job) {
            this.assignJob(job);
        }
    }

    private assignJob (job: JobSummary) {
        this.assignedJob = job;
        this.newJobGuid = job.jobId;

        if (job.reference) {
            this.jobReference = job.reference;
            this.selectedCall!.jobId = job.reference;
        }

    }

    get memberId () {
        if (this.phoneCallMemberId) {
            return this.phoneCallMemberId;
        }
        if (this.$route.query.memberId) {
            return Number(this.$route.query?.memberId);
        }

        return null;
    }

    get currentClient () {
        if (this.clients && this.itemDetails.length) {
            const id = this.itemDetails[0];
            const found = this.clients.find((a) => a.code === id);
            if (found) return found as Client;
        }
        return null;
    }

    get itemDetails () {
        return this.callNumber.split('-');
    }


    get clients () {
        return this.$store.getters[STORE.GET_CLIENTS] as Array<Client>;
    }

    get canEdit () {
        return !this.isEditing && !this.isSaving && (!this.selectedHistory || (this.history && this.selectedHistory === this.history[0]));
    }

    get canSave () {
        return !this.isSaving && this.isEditing && this.isDirty && this.subject.trim() !== '' && this.conversation.trim() !== '';
    }

    get callId () {
        if (this.itemDetails.length <= 2 && !this.phoneCallMemberId) {
            return Number(this.itemDetails[1]);
        }

        return Number(this.itemDetails[2]);
    }

    get contactType () {
        if (!this.selectedCall || !this.selectedCall.data) {
            return '';
        }

        if (this.selectedCall.data.isMember) {
            return 'Member';
        }

        if (this.selectedCall.data.isInternal) {
            return 'Internal';
        }

        return 'Other';
    }

    get jobPrefix () {
        return `J-${this.currentClient?.code}-`;
    }

    get hasWorkmanagement () {
        return this.currentClient?.workManagement;
    }

    get reAssignMatches () {
        return this.newJobGuid === this.jobs.find((a) => a.reference === this.selectedCall?.jobReference)?.jobId;
    }

    get history () {
        if (!this.selectedCall || !this.selectedCall.data || !this.selectedCall.data.history) {
            return null;
        }

        let sorted: Array<PhoneCallHistory> = [];
        this.selectedCall.data.history.forEach((c) => {
            if (c.duplicateInfo) {
                sorted.push({ ...c, lastModified: c.duplicateInfo.duplicatedDate, userName: c.duplicateInfo.fullName });
            }
            if (!c.duplicateInfo?.isDuplicate) {
                sorted.push({ ...c, duplicateInfo: undefined });
            }
        });
        sorted.push({
            lastModified: this.selectedCall.lastModified,
            conversation: this.selectedCall.data.conversation,
            subject: this.selectedCall.data.subject,
            userId: this.selectedCall.lastModifiedInfoUserId,
            userName: this.selectedCall.lastModifiedInfoUsername,
        });
        sorted = sorted
            .filter((a, i, list) => list.findIndex((b) => (this.formatHistoryDate(b.lastModified) === this.formatHistoryDate(a.lastModified))) === i)
            .sort((a, b) => (a.lastModified > b.lastModified ? -1 : 1));

        const getDuplicatedMessage = (item: PhoneCallDuplicatedInfo) => {
            const formattedDate = this.formatHistoryDate(item.duplicatedDate);
            const duplicatedBy = `${item.fullName} at ${formattedDate}`;

            if (item.isDuplicate) {
                return `Duplicate of P${item.originalCallId} by ${duplicatedBy}`;
            }

            return `Duplicate P${item.duplicatedCallId} created from this by ${duplicatedBy}`;
        };

        const getDescription = (item: PhoneCallHistory, i: number) => {
            if (item.duplicateInfo) return getDuplicatedMessage(item.duplicateInfo);
            return i === sorted.length - 1
                ? `Created by ${item.userName} at ${this.formatHistoryDate(item.lastModified)}`
                : `Edited by ${item.userName} at ${this.formatHistoryDate(item.lastModified)}`;
        };

        return sorted.map((item: PhoneCallHistory, i) => ({
            desc: getDescription(item, i),
            subject: item.subject,
            conversation: item.conversation,
            userId: item.userId,
            userName: item.userName,
            duplicateInfo: item.duplicateInfo,
            lastModified: item.lastModified,
        } as PhoneCallHistory));
    }

    get hostUrl () {
        return `${window.location.protocol}//${window.location.host}`;
    }

    get pulseApiUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION][STORE.PULSE_API_URL] ?? '';
    }

    get memberDataApiUrl () {
        return this.$store.getters['configuration/site']['memberdata.api.url'];
    }

    get isDirty () {
        return this.oldSubject.trim() !== this.subject.trim() || this.oldConversation.trim() !== this.conversation.trim();
    }

    get isProduction () {
        return process.env.VUE_APP_ENVIRONMENT === 'live';
    }

    get recordCalls () {
        return this.currentClient!.name && this.currentClient!.name.indexOf('Tetley') > -1;
    }

    // get jobs () {
    //     if (this.memberId) {
    //         return (this.$store.getters[STORE.GET_JOBS].jobs as Array<JobSummary>) || [];
    //     }
    //     return (this.$store.getters[STORE.GET_CLIENT_JOBS].jobs as Array<JobSummary>) || [];
    // }

    get jobUrl () {
        return this.$store.getters[STORE.GET_SITE_CONFIGURATION][STORE.PULSE_API_URL];
    }

    get verifiedSecurityItems () {
        if (!this.selectedCall || !this.selectedCall.data?.verifiedSecurityItems) {
            return '';
        }

        return this.selectedCall.data.verifiedSecurityItems.map((a) => a.replace('address', 'Address')
            .replace('fullName', 'Full name')
            .replace('niNumber', 'NI Number')
            .replace('dateOfBirth', 'Date of birth'))
            .join('\r\n');
    }

    get callTakenByName () {
        if (this.selectedCall) {
            const user = this.usersById[this.selectedCall.userId.toString()];

            if (user) {
                return `${user.forenames} ${user.surname}`;
            }
        }

        return '';
    }

    get usersById () {
        return this.$store.getters[STORE.GET_ALL_USERS_BY_ID];
    }

    get systemMemberId () {
        return this.selectedCall?.systemMemberId;
    }

    formatDate (date: Date, excludeTime: boolean) {
        if (excludeTime) {
            return moment(date).format('DD/MM/YYYY');
        }
        return this.$options.filters?.localDateTime(date);
    }

    formatHistoryDate (date: Date) {
        return this.$options.filters?.localDateTimeHMS(date);
    }

    getColor (status: string) {
        const color = Status.getColour(status);
        return color;
    }

    copy (text: string) {
        this.copyText = text;
        this.$nextTick(() => {
            const copyText = document.getElementById('copyField') as HTMLInputElement;
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand('copy');

            this.$store.dispatch('messages/addMessage', {
                status: 'Success', title: 'Copy successful', popup: true, message: `Value has been copied to the clipboard<br/><br/><span class="select">${encodeURIComponent(text)}</span>`,
            });
        });
    }

    getIcon (status: string) {
        const icon = Status.getIcon(status);
        return icon;
    }

    getJobById (jobId: Guid) {
        if (!this.jobs.length) {
            return null;
        }

        return (this.jobs.find((job: JobSummary) => job.jobId === jobId) as JobSummary) || null;
    }

    getJobByReference (reference: string) {
        if (!this.jobs.length) {
            return null;
        }

        return (this.jobs.find((job: JobSummary) => job.reference === reference?.trim()) as JobSummary) || null;
    }

    isEmbedded () {
        return this.$route.query.wm;
    }

    jobSelectionChanged (jobId: Guid) {
        this.newJobGuid = jobId;
    }

    async loadCall (call: PhoneCall) {
        this.selectedCall = call;
        this.subject = call.data?.subject || '';
        this.conversation = call.data?.conversation || '';
        this.jobReference = call.jobReference;
        if (this.memberId) {
            this.copyLinkValue = `${this.hostUrl}?calls=${this.currentClient!.code}-${this.memberId}-${this.selectedCall.callId}`;
        } else {
            this.copyLinkValue = `${this.hostUrl}/calls-viewer/${this.currentClient!.code}-${this.selectedCall.callId}?hideIntro=true`;
        }
    }

    callClicked (number: string) {
        const telNo = number.startsWith('0') ? number.replace('0', '+44').replace(' ', '') : number.replace(' ', '');
        this.copyText = telNo;
        if (!this.recordCalls) {
            this.copy(telNo);
            return;
        }

        window.open(`outboundcall:tel:${telNo};service=sip:lcp-tetley-outbound@emea.luware.cloud;activity=Outbound Call`, '_blank', 'location=0');
    }

    onAssignJob () {
        this.isLoading = true;
        if (this.newJobGuid && this.selectedCall !== null && this.selectedCall !== undefined) {
            jobApi.assignJobToPhoneCall(
                this.currentClient!.clientId,
                this.selectedCall.phoneCallId,
                this.newJobGuid,
                this.jobUrl,
                !!this.assignedJob,
            ).then(() => {
                this.showJobAssign = false;

                if (this.selectedCall) {
                    if (this.newJobGuid) {
                        this.getJob(this.newJobGuid.toString());
                    }


                }
                this.$emit('loadCorrespondence');

                this.isLoading = false;
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }

    onCancel () {
        this.subject = this.oldSubject;
        this.conversation = this.oldConversation;
        this.resetEditState();
    }

    onDelete () {
        if (!this.canEdit) {
            return;
        }

        this.isSaving = true;
        if (this.memberId) {
            memberApi.deleteCall(this.currentClient!.clientId, this.memberId, this.callId)
                .then(() => {
                    this.performDeletedCall();
                    this.isSaving = false;
                }).finally(() => {
                    this.isSaving = false;
                });
        } else {
            clientsApi.deleteClientCall(this.currentClient!.clientId, this.callId)
                .then(() => {
                    this.performDeletedCall();
                    this.isSaving = false;
                }).finally(() => {
                    this.isSaving = false;
                });
        }
    }

    performDeletedCall () {
        this.selectedCall = null;
        this.subject = '';
        this.conversation = '';
        this.jobReference = '';
        this.$emit('callDeleted', this.callId);
    }

    onDuplicate () {
        if (!this.canEdit) {
            return;
        }
        if (this.memberId) {
            memberApi.duplicateCall(this.currentClient!.clientId, this.memberId, this.callId)
                .then((response: PhoneCall) => {
                    this.performDuplicatedCall(response);
                }).finally(() => {
                    this.isSaving = false;
                });
        } else {
            clientsApi.duplicateClientCall(this.currentClient!.clientId, this.callId)
                .then((response: PhoneCall) => {
                    this.performDuplicatedCall(response);
                }).finally(() => {
                    this.isSaving = false;
                });
        }
    }

    performDuplicatedCall (response: PhoneCall) {
        this.$emit('callDuplicated', response);
        this.isSaving = false;
        this.$store.dispatch('messages/addMessage', {
            status: 'Success', title: 'Duplicate successful', popup: true, message: `Call P${response.callId} created as duplicate of P${this.callId}`,
        });
    }

    onEdit () {
        if (!this.canEdit) {
            return;
        }

        this.oldSubject = this.subject;
        this.oldConversation = this.conversation;
        this.isEditing = true;
    }

    onSave () {
        if (!this.canSave) {
            return;
        }

        this.isSaving = true;

        if (this.memberId) {
            memberApi.amendCall(this.currentClient!.clientId, this.memberId, this.callId, {
                subject: this.subject,
                conversation: this.conversation,
            }).then(async (response: PhoneCall) => {
                this.performAmendedCall(response);
            }).finally(() => {
                this.isSaving = false;
            });
        } else {
            clientsApi.amendClientCall(this.currentClient!.clientId, this.callId, {
                subject: this.subject,
                conversation: this.conversation,
            }).then(async (response: PhoneCall) => {
                this.performAmendedCall(response);
            }).finally(() => {
                this.isSaving = false;
            });
        }
    }

    performAmendedCall (response: PhoneCall) {
        this.resetEditState();
        this.loadCall(response);
        this.isLoading = false;
        this.isSaving = false;
        this.$emit('callSaved', this.selectedCall);
    }

    onUnassignJob () {
        this.isLoading = true;
        if (this.assignedJob && this.selectedCall !== null && this.selectedCall !== undefined) {
            jobApi.unassignJobToPhoneCall(
                this.selectedCall.phoneCallId,
                this.assignedJob?.jobId,
                this.jobUrl,
            ).then(() => {
                this.showJobAssign = false;
                this.assignedJob = null;
                this.jobReference = '';
                this.newJobGuid = null;
                this.isLoading = false;
                this.$emit('loadCorrespondence');
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }

    async onReturnToIndex () {
        this.isLoading = true;
        try {
            await inboxApi.returnCallToIndex(this.currentClient!.clientId, this.selectedCall!.phoneCallId, this.assignedJob?.jobId, this.pulseApiUrl);
            this.showJobAssign = false;
            this.assignedJob = null;
            this.jobReference = '';
            this.newJobGuid = null;
            this.reloadParentWindow();
        } finally {
            this.isLoading = false;
        }
    }

    reloadParentWindow () {
        if (window.parent) {
            window.parent.postMessage('reload', '*');
            this.$emit('loadCorrespondence');
        }
    }

    resetEditState () {
        this.isEditing = false;
        this.oldSubject = '';
        this.oldConversation = '';
    }

    @Watch('history')
    historyChanged () {
        if (this.history === null) {
            this.resetEditState();
            this.selectedHistory = null;
        } else {
            this.selectedHistory = this.history[0];
        }
    }

    @Watch('selectedHistory')
    selectedHistoryChanged () {
        if (this.selectedHistory === null) {
            if (this.selectedCall?.data) {
                this.subject = this.selectedCall.data.subject;
                this.conversation = this.selectedCall.data.conversation;
            } else {
                this.subject = '';
                this.conversation = '';
            }
        } else {
            this.resetEditState();
            if (this.selectedHistory) {
                this.subject = this.selectedHistory.subject;
                this.conversation = this.selectedHistory.conversation;
            } else {
                this.subject = '';
                this.conversation = '';
            }
        }
    }
}

