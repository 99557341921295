
import { Component, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import InprogressJobs from '@/components/inprogress-jobs.vue';
import InprogressPie from '@/components/inprogress-pie.vue';
import CompletedPie from '@/components/completed-pie.vue';
import CompletedPieSla from '@/components/completed-pie-sla.vue';
import CompletedJobsSla from '@/components/completed-jobs-sla.vue';
import CompletedJobs from '@/components/completed-jobs.vue';
import MemberInteractions from '@/components/member-interactions.vue';
import WebRegistrations from '@/components/web-registrations.vue';
import MemberInteractionsPie from '@/components/member-interactions-pie.vue';
import WebRegistrationsPie from '@/components/web-registrations-pie.vue';
import Client from 'common/models/client';

@Component({
    components: {
        InprogressJobs,
        CompletedJobs,
        MemberInteractions,
        InprogressPie,
        CompletedPie,
        MemberInteractionsPie,
        WebRegistrations,
        WebRegistrationsPie,
        CompletedPieSla,
        CompletedJobsSla,
    },
})
export default class Membership extends Vue {
    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    viewByCase = false;

    selectedSeries: { [category: string ]: string } = {};

    pointSelected ({ category, series }: { category: string; series: string }) {
        Vue.set(this.selectedSeries, category, series);
    }

    get row () {
        return this.$route.params.row;
    }

    viewByCaseChanged (val: boolean) {
        this.viewByCase = val;
    }

    mounted () {
        this.$nextTick(() => {
            if (this.$refs[this.row]) {
                (this.$refs.content as HTMLElement).scrollTop = ((this.$refs[this.row] as Vue).$el as HTMLElement).offsetTop;
            }
        });
    }

    get client () {
        return (this.$store.getters['clientContext/clients'] as Array<Client>).find((a) => a.clientId === this.clientId);
    }
}
