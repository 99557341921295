
import { Component, Vue, Watch } from 'vue-property-decorator';
import headerBar from '@/components/header-bar.vue';
import navbar from '@/components/navbar.vue';
import Client from 'common/models/client';
import LoginPage from '@/views/LoginPage.vue';
import ClientNameService from './services/clientNameService';
import ClientApi from './api/clientApi';

@Component({
    components: {
        LoginPage, headerBar, navbar,
    },
})
export default class App extends Vue {
    hasAcceptedTerms = false;

    get loggedIn () {
        return this.$store.getters['auth/loggedIn'];
    }

    get loading () {
        return this.$store.getters['loading/loading'];
    }

    get hideNav () {
        return this.$route.meta?.hideNav;
    }

    get hideHeader () {
        return this.$route.meta?.hideNav;
    }

    get clientName () {
        return this.$route.params?.clientName;
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    get isIdle () {
        return this.$store.state.idleVue.isIdle;
    }

    get pageRequiresLogin () {
        return !this.$route.meta?.public;
    }

    acceptTerms () {
        this.hasAcceptedTerms = true;
        sessionStorage.setItem('terms', 'true');
    }

    mounted () {
        ClientApi.wakePrintApi();
        try {
            const terms = sessionStorage.getItem('terms');
            this.hasAcceptedTerms = terms === 'true';
        } catch (e) {
            console.info('Terms not yet accepted');
        }
        this.$store.commit('clientStatistics/initialise');
    }

    @Watch('loggedIn', { immediate: true })
    loggedInChanged () {
        if (this.loggedIn && (!this.clients || !this.clients.length)) {
            this.$store.dispatch('clientContext/getClients');
        }
    }

    @Watch('isIdle')
    isIdleChanged () {
        if (process.env.NODE_ENV !== 'production') return;
        if (this.isIdle && this.loggedIn) {
            this.$store.dispatch('auth/logout');
        }
    }

    @Watch('clientName', { immediate: true })
    @Watch('clients')
    clientNameChanged () {
        if (!this.clientName || !this.clients) return;
        let client = this.clients.find((a) => this.clientName === ClientNameService.clientNameToPath(a.name));
        if (!client && this.clientName === 'demo') client = this.clients.find((a) => a.name === 'ICI Specialty Chemicals Pension Fund');
        if (client) {
            this.$store.commit('clientContext/setSelectedClient', client.clientId);
        } else {
            this.$router.push('/');
        }
    }
}

