
import { Component, Prop, Vue } from 'vue-property-decorator';
import ContactInfo from '@/models/memberDataTypes/contactInfo';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Component
export default class ContactInfoVue extends Vue {
    @Prop()
        dataTypeData!: ContactInfo;

    getFormattedValue (phoneNumberString: string) : string {
        if (!phoneNumberString) {
            return '';
        }
        const phoneNumber = parsePhoneNumberFromString(phoneNumberString, 'GB');
        if (phoneNumber) {
            if (phoneNumber.country === 'GB') {
                return phoneNumber.formatNational();
            }
            return phoneNumber.formatInternational();
        }
        return phoneNumberString;
    }
}
