
import {
    Component, Vue, Prop, Watch,
} from '~/vue-property-decorator';
import Tiff from '~/tiff.js';
import axios from '~/axios';

@Component
export default class TiffViewer extends Vue {
    @Prop()
    tiffUrl!: string;

    loaded = false;

    async getTiffImage (url: string) {
        this.loaded = false;
        axios.get(url, { responseType: 'arraybuffer' }).then((response) => {
            const tiff = new Tiff({ buffer: response.data });
            for (let i = 0, len = tiff.countDirectory(); i < len; i += 1) {
                tiff.setDirectory(i);
                const canvas = tiff.toCanvas();
                (this.$refs.canvas as HTMLElement).append(canvas);
            }
            this.loaded = true;
        });
    }

    @Watch('tifUrl', { immediate: true })
    tiffUrlChanged () {
        if (this.tiffUrl) {
            this.getTiffImage(this.tiffUrl);
        }
    }
}
