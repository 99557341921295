export default class Address {
    addressLine1 = '';

    addressLine2 = '';

    addressLine3 = '';

    addressLine4 = '';

    addressPostCode = '';

    addressCountry = '';

    [key: string]: string;
}
