
import { Component, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import ClientConfig from '@/models/client';
import * as STORE from 'common/store/storepaths';

@Component
export default class MemberWebsite extends Vue {
    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    viewByDocumentType = false;

    viewByCorrespondenceType = false;

    viewByWebFormType = false;

    viewViewsByPage = false;

    viewTimesByPage = false;

    viewInteractionsByType = false;

    selectedSeries: { [category: string ]: string } = {};

    pointSelected ({ category, series }: { category: string; series: string }) {
        Vue.set(this.selectedSeries, category, series);
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfig ?? {};
    }

    get showModeller () {
        return this.clientConfiguration?.showModeller;
    }

    get showPayslips () {
        return this.clientConfiguration?.showPayslips;
    }

    get row () {
        return this.$route.params.row;
    }

    get webRegistrationBySummaryEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbysummary.enabled'] === 'true';
    }

    get webRegistrationByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbytime.enabled'] === 'true';
    }

    get webRegistrationByLocationEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbylocation.enabled'] === 'true';
    }

    get webRegistrationByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbystatus.enabled'] === 'true';
    }

    get webRegistrationByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbyage.enabled'] === 'true';
    }

    get memberLoginEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.memberlogin.enabled'] === 'true';
    }

    get memberLoginByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.memberloginbyage.enabled'] === 'true';
    }

    get memberLoginByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.memberloginbystatus.enabled'] === 'true';
    }

    get pageVisitsEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.pagevisits.enabled'] === 'true';
    }

    get pageVisitsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.pagevisitsbytime.enabled'] === 'true';
    }

    get modellerVisitsByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellervisitsbyage.enabled'] === 'true';
    }

    get modellerVisitsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellervisitsbytime.enabled'] === 'true';
    }

    get modellerTimesByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellertimesbyage.enabled'] === 'true';
    }

    get modellerTimesByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellertimesbytime.enabled'] === 'true';
    }

    get modellerInteractionsByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellerinteractionsbyage.enabled'] === 'true';
    }

    get modellerInteractionsByPageEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellerinteractionsbypage.enabled'] === 'true';
    }

    get dateUpdatesByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbyage.enabled'] === 'true';
    }

    get dateUpdatesByAgeByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbyage.enabled'] === 'true';
    }

    get dateUpdatesByDataTypeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbydatatype.enabled'] === 'true';
    }

    get dateUpdatesByDataTypeByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbyagebytime.enabled'] === 'true';
    }

    get dateUpdatesByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbystatus.enabled'] === 'true';
    }

    get dateUpdatesByStatusByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbystatusbytime.enabled'] === 'true';
    }

    get memberCorrespondenceEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.membercorrespondence.enabled'] === 'true';
    }

    get payslipViewsVisible () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.payslipsviews.enabled'] === 'true';
    }

    get payslipViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.payslipsviewsbystatus.enabled'] === 'true';
    }

    get payslipViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.payslipsviewsbytime.enabled'] === 'true';
    }

    get correspondenceViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.correspondenceviewsbystatus.enabled'] === 'true';
    }

    get correspondenceViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.correspondenceviewsbytime.enabled'] === 'true';
    }

    get documentViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.documentviewsbystatus.enabled'] === 'true';
    }

    get documentViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.documentviewsbytime.enabled'] === 'true';
    }

    get webFormsViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webformviewsbystatus.enabled'] === 'true';
    }

    get webFormsViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webformviewsbytime.enabled'] === 'true';
    }

    viewByDocumentTypeChanged (val: boolean) {
        this.viewByDocumentType = val;
    }

    viewByCorrespondenceTypeChanged (val: boolean) {
        this.viewByCorrespondenceType = val;
    }

    viewByWebFormTypeChanged (val: boolean) {
        this.viewByWebFormType = val;
    }

    viewViewsByPageChanged (val: boolean) {
        this.viewViewsByPage = val;
    }

    viewTimesByPageChanged (val: boolean) {
        this.viewTimesByPage = val;
    }

    viewInteractionsByTypeChanged (val: boolean) {
        this.viewInteractionsByType = val;
    }

    mounted () {
        this.$nextTick(() => {
            if (this.$refs[this.row]) {
                (this.$refs.content as HTMLElement).scrollTop = ((this.$refs[this.row] as Vue).$el as HTMLElement).offsetTop;
            }
        });
    }
}
