import Http from 'common/api/apiHelper';
import Guid from 'common/util/guid';
import MemberSearchParams from 'common/models/memberSearchParams';
import MemberSearchResult from '@/models/memberSearchResult';
import CalculationResult from 'common/models/calculations/calculationResult';

const apiVersion = 'v1.0';
export default class MembersApi {
    static getAvailableTypes (clientId: Guid, memberId: number) {
        return Http.get<Record<string, string>>(`${apiVersion}/client/${clientId}/member/${memberId}/availableTypes`);
    }

    static getAvailableTypesForServicePeriod (clientId: Guid, memberId: number, servicePeriodId: number) {
        return Http.get<Record<string, string>>(`${apiVersion}/client/${clientId}/members/${memberId}/servicePeriod/${servicePeriodId}/availableTypes`);
    }

    static getClientWebStatus (clientId: Guid, memberId: number) {
        return Http.get<string>(`${apiVersion}/client/${clientId}/member/${memberId}/clientWebStatus`);
    }

    static getMemberSearchResults (clientId: Guid, searchParams: MemberSearchParams) {
        const params = new URLSearchParams();
        Object.keys(searchParams).forEach((param) => {
            if (searchParams[param] !== undefined && searchParams[param]?.toString().length) {
                params.append(param, searchParams[param]!.toString());
            }
        });
        return Http.getAsArray(`${apiVersion}/client/${clientId}/member/search?${params.toString()}`, MemberSearchResult, { returnHeaders: true }) as Promise<Array<MemberSearchResult> & {headers: { 'x-total-count': number } }>;
    }

    static getDataType<T> (clientId: Guid, memberId: number, dataType: string): Promise<T> {
        return Http.get<T>(`${apiVersion}/client/${clientId}/member/${memberId}/${dataType}`);
    }

    static getDataTypeForServicePeriod<T> (clientId: Guid, memberId: number, servicePeriodId: number, dataType: string): Promise<T> {
        return Http.get<T>(`${apiVersion}/client/${clientId}/members/${memberId}/servicePeriod/${servicePeriodId}/${dataType}`);
    }

    static getCalculation (clientId: Guid, memberId: number, servicePeriodId: number, calculationId: string, args: string): Promise<CalculationResult> {
        return Http.get<CalculationResult>(`${apiVersion}/client/${clientId}/members/${memberId}/servicePeriod/${servicePeriodId}/calculation/${calculationId}/run?arguments=${args}`);
    }
}
