
import { Component, Prop, Vue } from 'vue-property-decorator';
import AVCAccumulationHistory from '@/models/memberDataTypes/avcAccumulationHistory';

@Component
export default class AVCAccumulationHistoryVue extends Vue {
    @Prop()
        dataTypeData!: AVCAccumulationHistory;

    get items () {
        return this.dataTypeData?.items?.sort((a, b) => (a.source < b.source ? 1 : -1));
    }
}
