
import { Component, Prop, Vue } from 'vue-property-decorator';
import PensionInPayments from '@/models/memberDataTypes/pensionInPayment';

@Component
export default class PensionHistoryVue extends Vue {
    @Prop()
        dataTypeData!: PensionInPayments;

    get items () {
        return this.dataTypeData?.items?.sort((a, b) => (a.effectiveDate < b.effectiveDate ? 1 : -1));
    }

    totalAmount (items: Array<{ amount: number}>) {
        return items.reduce((a, b) => a + b.amount, 0);
    }

    totalSpouseAmount (items: Array<{ spousesAmount: number}>) {
        return items.reduce((a, b) => a + b.spousesAmount, 0);
    }
}
