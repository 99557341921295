import Document from 'common/models/correspondence/document';
import DocumentCatgeory from 'common/models/correspondence/documentCategory';
import AvailableDocuments from 'common/models/mergeDocument/availableDocuments';
import CreateDocumentResponse from 'common/models/mergeDocument/createDocumentResponse';
import Guid from 'common/util/guid';
import Http from './apiHelper';

const apiVersion = 'v1.0';
export default class DocumentsApi {
    static getDocuments (clientId: Guid, memberId: Guid, filter: string, pulseApiUrl = '') {
        return Http.get(`${pulseApiUrl}${apiVersion}/client/${clientId}/member/${memberId}/document?type=${filter}`, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static previewDocument (clientId: Guid, documentId: Guid, pulseApiUrl = '') {
        return Http.get<{ url: string }>(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/preview`, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static getDocumentCategories (pulseApiUrl = '') {
        return Http.getAsArray<DocumentCatgeory>(`${pulseApiUrl}${apiVersion}/documentCategory`, DocumentCatgeory, {
            useBasePath: !pulseApiUrl,
        });
    }

    static getLinkForEdit (clientId: Guid, documentId: Guid, pulseApiUrl = '') {
        return Http.get<{ url: string }>(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/edit`, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static makeFinal (clientId: Guid, documentId: Guid, pulseApiUrl = '') {
        return Http.post<Document>(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/finalise`, null, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static revertToDraft (clientId: Guid, documentId: Guid, request: unknown, pulseApiUrl = '') {
        return Http.post<Document>(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/draft`, request, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static duplicate (clientId: Guid, documentId: Guid, request: unknown, pulseApiUrl = '') {
        return Http.put<Document>(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/duplicate`, request, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static delete (clientId: Guid, documentId: Guid, pulseApiUrl = '') {
        return Http.delete(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/delete`, null, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static updateOnlineStatus (clientId: Guid, documentId: Guid, request: unknown, pulseApiUrl = '') {
        return Http.put(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/online`, request, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static updateFileDetails (clientId: Guid, documentId: Guid, request: unknown, pulseApiUrl = '') {
        return Http.put(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}`, request, Document, {
            useBasePath: !pulseApiUrl,
        });
    }

    static getFileDetails (clientId: Guid, documentId: string, pulseApiUrl = '') {
        return Http.get(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}`, Document, {
            useBasePath: !pulseApiUrl,
        });
    }

    static getMemberFileDetails (clientId: Guid, documentId: string, memberId: number, pulseApiUrl = '') {
        return Http.get(`${pulseApiUrl}${apiVersion}/client/${clientId}/member/${memberId}/document/${documentId}`, Document, {
            useBasePath: !pulseApiUrl,
        });
    }

    static uploadDocument (clienId: Guid, memberId: Guid, request: unknown, pulseApiUrl = '') {
        return Http.post(`${pulseApiUrl}${apiVersion}/client/${clienId}/member/${memberId}/document`, request, undefined, {
            useBasePath: !pulseApiUrl,
            useToken: true,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        })
            .then((r) => r);
    }

    static uploadClientDocument (clienId: Guid, request: unknown, pulseApiUrl = '') {
        return Http.post(`${pulseApiUrl}${apiVersion}/client/${clienId}/document/create`, request, undefined, {
            useBasePath: !pulseApiUrl,
            useToken: true,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        })
            .then((r) => r);
    }

    static assignJob (jobId: Guid, documentId: Guid, pulseApiUrl = '', reassigned = false) {
        return Http.post(`${pulseApiUrl}${apiVersion}/job/${jobId}/document/${documentId}/assign`, null, undefined, {
            useBasePath: !pulseApiUrl,
            showSuccessMessage: true,
            showPopup: true,
            successTitle: `Document ${reassigned ? 'reassigned' : 'assigned'}`,
            message: `Document ${reassigned ? 'reassigned' : 'assigned'} to job`,
        });
    }

    static unAssignJob (jobId: Guid, jobDocumentId: Guid, pulseApiUrl = '') {
        return Http.post(`${pulseApiUrl}${apiVersion}/job/${jobId}/document/${jobDocumentId}/unassign`, null, undefined, {
            useBasePath: !pulseApiUrl,
            showSuccessMessage: true,
            message: 'Document unassigned from job',
            showPopup: true,
            successTitle: 'Document unassigned',
        });
    }

    static getJobs (clientId: Guid, memberId: Guid, pulseApiUrl = '') {
        return Http.get(`${pulseApiUrl}${apiVersion}/client/${clientId}/member/${memberId}/job`, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static getClientJobs (clientId: Guid, pulseApiUrl = '') {
        return Http.get(`${pulseApiUrl}${apiVersion}/client/${clientId}/job?type=Active`, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static returnToIndex (clientId: Guid, documentId: Guid, pulseApiUrl = '') {
        return Http.post(`${pulseApiUrl}${apiVersion}/client/${clientId}/document/${documentId}/indexing`, null, undefined, {
            useBasePath: !pulseApiUrl,
        });
    }

    static getDocumentsAvailableToCreate (clientId: Guid, memberId: number, servicePeriodId: number) {
        return Http.get<AvailableDocuments>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/document/type`);
    }

    static createMergedDocument (clientId: Guid, memberId: number, servicePeriodId: number,
        data: { documentId: string; calculationId?: Guid; errorIfMissingMergeFields: boolean; useLatestAvailableData: boolean; effectiveDate?: Date | undefined }) {
        return Http.post(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/document`, data, CreateDocumentResponse);
    }
}
