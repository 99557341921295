import { render, staticRenderFns } from "./users-dropdown.vue?vue&type=template&id=119257ee&scoped=true&"
import script from "./users-dropdown.vue?vue&type=script&lang=js&"
export * from "./users-dropdown.vue?vue&type=script&lang=js&"
import style0 from "./users-dropdown.vue?vue&type=style&index=0&id=119257ee&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../ClientWeb/FrontEnd/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119257ee",
  null
  
)

export default component.exports