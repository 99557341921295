export default class ClientContext {
    minDate!: Date;

    maxDate!: Date;

    defaultStartDate!: Date;

    defaultEndDate!: Date;

    presetDateOptions!: Array<{ name: string; start: Date; end: Date }>;
}
