export default class ClientWebConfiguration {
    'clientweb.oauth.scope': string;

    'clientweb.oauth.authority': string;

    'clientweb.oauth.tenentid': string;

    'clientweb.oauth.appid': string;

    'clientweb.user.blob.url': string;

    'clientweb.memberactivity.webregistrationbysummary.enabled': boolean;

    'clientweb.memberactivity.webregistrationbytime.enabled': boolean;

    'clientweb.memberactivity.webregistrationbylocation.enabled': boolean;

    'clientweb.memberactivity.webregistrationbystatus.enabled': boolean;

    'clientweb.memberactivity.webregistrationbyage.enabled': boolean;

    'clientweb.memberactivity.memberlogin.enabled': boolean;

    'clientweb.memberactivity.memberloginbyage.enabled': boolean;

    'clientweb.memberactivity.memberloginbystatus.enabled': boolean;

    'clientweb.memberactivity.pagevisits.enabled': boolean;

    'clientweb.memberactivity.pagevisitsbytime.enabled': boolean;

    'clientweb.memberactivity.modellervisitsbyage.enabled': boolean;

    'clientweb.memberactivity.modellervisitsbytime.enabled': boolean;

    'clientweb.memberactivity.modellertimesbyage.enabled': boolean;

    'clientweb.memberactivity.modellertimesbytime.enabled': boolean;

    'clientweb.memberactivity.modellerinteractionsbyage.enabled': boolean;

    'clientweb.memberactivity.modellerinteractionsbypage.enabled': boolean;

    'clientweb.memberactivity.dateupdatesbyage.enabled': boolean;

    'clientweb.memberactivity.dateupdatesbyagebytime.enabled': boolean;

    'clientweb.memberactivity.dateupdatesbydatatype.enabled': boolean;

    'clientweb.memberactivity.dateupdatesbystatus.enabled': boolean;

    'clientweb.memberactivity.dateupdatesbystatusbytime.enabled': boolean;

    'clientweb.memberactivity.membercorrespondence.enabled': boolean;

    'clientweb.memberactivity.payslipsviewsbystatus.enabled': boolean;

    'clientweb.memberactivity.payslipsviewsbytime.enabled': boolean;

    'clientweb.memberactivity.correspondenceviewsbystatus.enabled': boolean;

    'clientweb.memberactivity.correspondenceviewsbytime.enabled': boolean;

    'clientweb.memberactivity.documentviewsbystatus.enabled': boolean;

    'clientweb.memberactivity.documentviewsbytime.enabled': boolean;

    'clientweb.memberactivity.webformviewsbystatus.enabled': boolean;

    'clientweb.memberactivity.webformviewsbytime.enabled': boolean;

    isClientWeb = true;
}
