import CorrespondenceSummary from 'common/models/correspondence/correspondenceSummary';
import Http from 'common/api/apiHelper';
import PhoneCall from 'common/models/phoneCall';
import Guid from 'common/util/guid';
import DataTypeSummary from 'common/models/dataTypes/dataTypeSummary';
import DataType from 'common/models/dataTypes/dataType';
import CorrespondenceWeb2Query from 'common/models/correspondence/webQuery';
import MemberDataTypeRequest from 'common/models/dataTypes/memberDataTypeRequest';
import MemberDataTypeRequestResponse from 'common/models/dataTypes/memberDataTypeRequestResponse';
import DataTypeReview from 'common/models/dataTypes/dataTypeReview';
import MemberPhoneCallDetails from 'common/models/memberPhoneCallDetails';
import CorrespondenceEmail from 'common/models/correspondence/email';
import DataValidation from 'common/models/dataTypes/dataValidation';
import MemberHistoryItem from 'common/models/dataTypes/memberHistoryItem';
import EmailAttachment from 'common/models/correspondence/emailAttachment';
import MemberSummary from 'common/models/memberSummary';
import DeletedMember from 'common/models/deletedMember';
import MemberStatusOption from 'common/models/memberStatusOption';
import MemberWebHistory from 'common/models/webRegistrations/memberWebHistory';
import MemberRegistrationDetails from 'common/models/webRegistrations/memberRegistrationDetails';
import MemberVerificationSummary from 'common/models/memberVerificationSummary';
import BasicMemberVerification from 'common/models/basicMemberVerification';
import SpouseCheckResult from 'common/models/spouseCheckResult';
import HasDependantHistoryCheckResult from 'common/models/hasDependantHistoryCheckResult';
import MemberVerificationResult from 'common/models/memberVerificationResult';
import MemberSearchParams from 'common/models/memberSearchParams';
import EmailHistory from 'common/models/correspondence/emailHistory';
import UnverifiedMemberDataSummary from 'common/models/unverifiedMemberDataSummary';

const apiVersion = 'v1.0';
export default class MemberApi {
    private static getBaseUrl (clientId: Guid, memberId: number) {
        return `${apiVersion}/client/${clientId}/member/${memberId}`;
    }

    // Correspondence

    static getCorrespondence (clientId: Guid, memberId: number) {
        const baseUrl = this.getBaseUrl(clientId, memberId);
        return Http.getAsArray<CorrespondenceSummary>(`${baseUrl}/correspondence`);
    }

    // History

    static getHistory (clientId: Guid, memberId: number) {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/member/${memberId}/history`, MemberHistoryItem);
    }

    // DataTypes

    static getDataTypes (clientId: Guid, memberId: number) {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/member/${memberId}/dataTypes`, DataTypeSummary);
    }

    static getDataType (clientId: Guid, memberId: number, dataKey: string, includeNewDataValidationChecks: boolean) {
        return Http.get(`${apiVersion}/client/${clientId}/member/${memberId}/${dataKey}?includeNewDataValidationChecks=${includeNewDataValidationChecks}`, DataType);
    }

    static saveDataType (clientId: Guid, memberId: number, dataKey: string, memberDataId: Guid, data: MemberDataTypeRequest) {
        return memberDataId ? Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/${dataKey}/${memberDataId}`, data, MemberDataTypeRequestResponse)
            : Http.post(`${apiVersion}/client/${clientId}/member/${memberId}/${dataKey}`, data, MemberDataTypeRequestResponse);
    }

    static getDataTypeReview (clientId: Guid, memberId: number, dataKey: string) {
        return Http.get(`${apiVersion}/client/${clientId}/member/${memberId}/${dataKey}/review`, DataTypeReview);
    }

    static verifyDataTypeReview (clientId: Guid, memberId: number, dataKey: string, memberDataId: Guid) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/${dataKey}/${memberDataId}/verify`, null);
    }

    static deleteDataType (clientId: Guid, memberId: number, dataKey: string, memberDataId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/${dataKey}/${memberDataId}/delete`, data, undefined);
    }

    static liveUpdateData (clientId: Guid, memberId: number, servicePeriodId: number, dataKey: string, data: unknown) {
        return Http.put<{ latestData: string }>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/${dataKey}/liveData`, data);
    }

    // ServicePeriod DataTypes

    static getServicePeriodDataTypes (clientId: Guid, memberId: number, servicePeriodId: number) {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/dataTypes`, DataTypeSummary);
    }

    static getServicePeriodDataType (clientId: Guid, memberId: number, servicePeriodId: number, dataKey: string, includeNewDataValidationChecks: boolean) {
        return Http.get(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/${dataKey}?includeNewDataValidationChecks=${includeNewDataValidationChecks}`, DataType);
    }

    static saveServicePeriodDataType (clientId: Guid, memberId: number, dataKey: string, memberDataId: Guid, servicePeriodId: number, data: MemberDataTypeRequest) {
        return memberDataId ? Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/${dataKey}/${memberDataId}`, data, MemberDataTypeRequestResponse)
            : Http.post(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/${dataKey}`, data, MemberDataTypeRequestResponse);
    }

    static getServicePeriodDataTypeReview (clientId: Guid, memberId: number, dataKey: string, servicePeriodId: number) {
        return Http.get(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/${dataKey}/review`, DataTypeReview);
    }

    static verifyServicePeriodDataTypeReview (clientId: Guid, memberId: number, dataKey: string, memberDataId: Guid, servicePeriodId: number) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/${dataKey}/${memberDataId}/verify`, null);
    }

    static deleteServicePeriodDataType (clientId: Guid, memberId: number, dataKey: string, memberDataId: Guid, servicePeriodId: number, data: unknown) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/${dataKey}/${memberDataId}/delete`, data, undefined);
    }

    static getDataValidations (clientId: Guid, memberId: number) {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/member/${memberId}/validation`, DataValidation);
    }

    static updateInPayment (clientId: Guid, memberId: number, servicePeriodId: Guid, isInPayment: boolean) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/inPayment`, { isInPayment }, undefined, {
            showSuccessMessage: true,
            message: 'Is in payroll updated',
        });
    }

    // Member Verifications

    static getMemberVerifications (clientId: Guid, memberId: number) {
        return Http.getAsArray<MemberVerificationSummary>(`${apiVersion}/client/${clientId}/member/${memberId}/verification`, MemberVerificationSummary);
    }

    static getExpectedBasicMemberVerification (clientId: Guid, memberId: number) {
        return Http.get(`${apiVersion}/client/${clientId}/member/${memberId}/verification/basic`, BasicMemberVerification);
    }

    static getBasicMemberVerification (clientId: Guid, memberId: number, memberVerificationId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/member/${memberId}/verification/basic/${memberVerificationId}`, BasicMemberVerification);
    }

    static createBasicMemberVerification (clientId: Guid, memberId: number) {
        return Http.post<MemberVerificationResult>(`${apiVersion}/client/${clientId}/member/${memberId}/verification/basic`, {});
    }

    // Phone Calls

    static getCall (clientId: Guid, memberId: number, callId: number, url: string | undefined) {
        return Http.get<PhoneCall>(`${url}${apiVersion}/client/${clientId}/member/${memberId}/call/${callId}`, undefined, { useBasePath: !url });
    }

    static duplicateCall (clientId: Guid, memberId: number, callId: number) {
        const baseUrl = this.getBaseUrl(clientId, memberId);
        return Http.post<PhoneCall>(`${baseUrl}/call/${callId}/copy`, null, undefined, {
            showSuccessMessage: false,
            message: 'Call copied',
        });
    }

    static deleteCall (clientId: Guid, memberId: number, callId: number) {
        const baseUrl = this.getBaseUrl(clientId, memberId);
        return Http.delete(`${baseUrl}/call/${callId}`, null, undefined, {
            showSuccessMessage: true,
            message: 'Call deleted',
        });
    }

    static amendCall (clientId: Guid, memberId: number, callId: number, data: {subject: string; conversation: string}) {
        const baseUrl = this.getBaseUrl(clientId, memberId);
        return Http.put<PhoneCall>(`${baseUrl}/call/${callId}`, data, undefined, {
            showSuccessMessage: true,
            message: 'Call updated',
        });
    }

    static startCall (clientId: Guid, memberId: number) {
        const baseUrl = this.getBaseUrl(clientId, memberId);
        return Http.get<MemberPhoneCallDetails>(`${baseUrl}/call/start`);
    }

    static saveCall (clientId: Guid, memberId: number, data: unknown) {
        const baseUrl = this.getBaseUrl(clientId, memberId);
        return Http.post<PhoneCall>(`${baseUrl}/call/`, data, undefined, {
            showSuccessMessage: true,
            message: 'Call saved',
        });
    }

    // Web Queries

    static getWebQuery (clientId: Guid, memberId: number, webQueryId: number, url = '') {
        return Http.get<CorrespondenceWeb2Query>(`${url}${apiVersion}/client/${clientId}/member/${memberId}/web/${webQueryId}`, undefined, { useBasePath: !url });
    }

    // Emails

    static getEmail (clientId: Guid, memberId: number, emailReference: string, url = '') {
        return Http.get<CorrespondenceEmail>(`${url}${apiVersion}/client/${clientId}/member/${memberId}/email/${emailReference}`, undefined, { useBasePath: !url });
    }

    static deleteEmail (clientId: Guid, memberId: number, emailReference: string) {
        return Http.delete(`${apiVersion}/client/${clientId}/member/${memberId}/email/${emailReference}`, null, undefined, {
            showSuccessMessage: true,
            message: 'Email deleted',
        });
    }

    static getEmailAttachment (clientId: Guid, memberId: number, emailReference: string, attachmentId: string, url = '') {
        return Http.get<EmailAttachment>(`${url}${apiVersion}/client/${clientId}/member/${memberId}/email/${emailReference}/attachment/${attachmentId}`, undefined, { useBasePath: !url });
    }

    static updateEmail (clientId: Guid, memberId: number, emailReference: string, url = '', data: {description?: string}) {
        return Http.put(`${url}${apiVersion}/client/${clientId}/member/${memberId}/email/${emailReference}`, data, undefined, {
            showSuccessMessage: true,
            message: 'Email updated',
            useBasePath: !url
        });
    }

    static getEmailHistory (clientId: Guid, memberId: number, emailReference: string, url = '') {
        return Http.getAsArray<EmailHistory>(`${url}${apiVersion}/client/${clientId}/member/${memberId}/email/${emailReference}/history`, undefined, { useBasePath: !url });
    }

    // Global search

    static getClientMemberSearch (search: MemberSearchParams) {
        return Http.getAsArray<{ clientId: Guid; member: MemberSummary}>(`${apiVersion}/member/search?${Object.keys(search).filter((key) => search[key]?.toString().length).map((key) => `${key}=${search[key]}`).join('&')}`,
            undefined, { returnHeaders: true }) as Promise<(Array<{ clientId: Guid; member: MemberSummary}> & {headers: { 'x-total-count': number } })>;
    }

    // Deleted members
    static deleteMember (clientId: Guid, memberId: number, reason: string) {
        return Http.delete(`${apiVersion}/client/${clientId}/member/${memberId}`, reason, undefined, {
            showSuccessMessage: true,
            message: 'Member deleted',
        });
    }

    static getDeletedMembers (clientId: Guid, pageNumber: number, pageSize: number) {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/member/deleted?pageNumber=${pageNumber}&pageSize=${pageSize}`, DeletedMember, {
            returnHeaders: true,
        }) as Promise<(Array<DeletedMember> & {headers: {'x-total-count': number }})>;
    }

    static restoreMember (clientId: Guid, memberId: number, reason: string) {
        return Http.post(`${apiVersion}/client/${clientId}/member/${memberId}/restore`, reason, undefined, {
            showSuccessMessage: true,
            message: 'Member restored',
        });
    }

    // Dependents
    static createDependant (clientId: Guid, memberId: number, data: { dependentType: string; dateOfChange: Date }) {
        return Http.post<MemberSummary>(`${apiVersion}/client/${clientId}/member/${memberId}/dependent`, data, undefined, {
            showSuccessMessage: true,
            message: 'Dependant created',
        });
    }

    // Member Status
    static getStatusChangeOptions (clientId: Guid, memberId: number, servicePeriodId: number) {
        return Http.getAsArray<MemberStatusOption>(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/status/available`);
    }

    static changeMemberStatus (clientId: Guid, memberId: number, servicePeriodId: number, data: unknown) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/servicePeriod/${servicePeriodId}/status`, data, undefined, {
            showSuccessMessage: true,
            message: 'Status changed',
        });
    }

    static createDeathNotification (clientId: Guid, memberId: number, data: unknown) {
        return Http.put<MemberSummary>(`${apiVersion}/client/${clientId}/member/${memberId}/status/death`, data, undefined, {
            showSuccessMessage: true,
            message: 'Status changed',
        });
    }

    static spouseCheck (clientId: Guid, memberId: number) {
        return Http.get<SpouseCheckResult>(`${apiVersion}/client/${clientId}/member/${memberId}/status/spousecheck`);
    }

    static hasDependantHistoryCheck (clientId: Guid, memberId: number) {
        return Http.get<HasDependantHistoryCheckResult>(`${apiVersion}/client/${clientId}/member/${memberId}/status/hasDependantHistory`);
    }

    // Member web
    static getWebRegistrationDetails (clientId: Guid, memberId: number) {
        return Http.get<MemberRegistrationDetails>(`${apiVersion}/client/${clientId}/member/${memberId}/web/detail`);
    }

    static getWebHistory (clientId: Guid, memberId: number, fromDate: string, toDate: string) {
        return Http.getAsArray<MemberWebHistory>(`${apiVersion}/client/${clientId}/member/${memberId}/web/history?fromDate=${fromDate}&toDate=${toDate}`);
    }

    static generateWebCode (clientId: Guid, memberId: number) {
        return Http.post(`${apiVersion}/client/${clientId}/member/${memberId}/web/registration/generate`, null, undefined, {
            showSuccessMessage: true,
            message: 'Registration code generated',
        });
    }

    static unregisterMemberFromWeb (clientId: Guid, memberId: number) {
        return Http.put(`${apiVersion}/client/${clientId}/member/${memberId}/web/unregister`, null, undefined, {
            showSuccessMessage: true,
            message: 'Member unregistered from web',
        });
    }

    static generateAllEligibleRegistrations (clientId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/web/registration/generate`, null, undefined, {
            showSuccessMessage: true,
            message: 'Registration codes for all now being regenerated',
        });
    }

    static getUnverifiedMemberDataSummary (clientId: Guid, memberId: Guid) {
        return Http.get<UnverifiedMemberDataSummary>(`${apiVersion}/client/${clientId}/member/${memberId}/unverifieddatasummary`, UnverifiedMemberDataSummary);
    }
}
