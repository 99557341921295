export interface TimeZone {
    name: string;
    code: string;
    gmt: number;
}

export const timeZones = [
    { name: 'Afghanistan', code: 'AF', gmt: 4.5 },
    { name: 'Albania', code: 'AL', gmt: 1 },
    { name: 'Algeria', code: 'DZ', gmt: 1 },
    { name: 'Argentina', code: 'AR', gmt: -3 },
    { name: 'Armenia', code: 'AM', gmt: 4 },
    { name: 'Australia', code: 'AU', gmt: 11 },
    { name: 'Austria', code: 'AT', gmt: 1 },
    { name: 'Azerbaijan', code: 'AZ', gmt: 4 },
    { name: 'Bahrain', code: 'BH', gmt: 3 },
    { name: 'Bangladesh', code: 'BD', gmt: 6 },
    { name: 'Belarus', code: 'BY', gmt: 3 },
    { name: 'Belgium', code: 'BE', gmt: 1 },
    { name: 'Belize', code: 'BZ', gmt: -6 },
    { name: 'Bhutan', code: 'BT', gmt: 6 },
    { name: 'Bolivia', code: 'BO', gmt: -4 },
    { name: 'Bosnia and Herzegovina', code: 'BA', gmt: 1 },
    { name: 'Botswana', code: 'BW', gmt: 2 },
    { name: 'Brazil', code: 'BR', gmt: -2 },
    { name: 'Brunei', code: 'BN', gmt: 8 },
    { name: 'Bulgaria', code: 'BG', gmt: 2 },
    { name: 'Cambodia', code: 'KH', gmt: 7 },
    { name: 'Cameroon', code: 'CM', gmt: 1 },
    { name: 'Canada', code: 'CA', gmt: -3.5 },
    { name: 'Chile', code: 'CL', gmt: -4 },
    { name: 'China', code: 'CN', gmt: 8 },
    { name: 'Colombia', code: 'CO', gmt: -5 },
    { name: 'Congo (DRC)', code: 'CD', gmt: 1 },
    { name: 'Costa Rica', code: 'CR', gmt: -6 },
    { name: 'Côte d’Ivoire', code: 'CI', gmt: 0 },
    { name: 'Croatia', code: 'HR', gmt: 1 },
    { name: 'Cuba', code: 'CU', gmt: -5 },
    { name: 'Czech Republic', code: 'CZ', gmt: 1 },
    { name: 'Denmark', code: 'DK', gmt: 1 },
    { name: 'Djibouti', code: 'DJ', gmt: 3 },
    { name: 'Dominican Republic', code: 'DO', gmt: -4 },
    { name: 'Ecuador', code: 'EC', gmt: -5 },
    { name: 'Egypt', code: 'EG', gmt: 2 },
    { name: 'El Salvador', code: 'SV', gmt: -6 },
    { name: 'Eritrea', code: 'ER', gmt: 3 },
    { name: 'Estonia', code: 'EE', gmt: 2 },
    { name: 'Ethiopia', code: 'ET', gmt: 3 },
    { name: 'Faroe Islands', code: 'FO', gmt: 0 },
    { name: 'Finland', code: 'FI', gmt: 2 },
    { name: 'France', code: 'FR', gmt: 1 },
    { name: 'Georgia', code: 'GE', gmt: 4 },
    { name: 'Germany', code: 'DE', gmt: 1 },
    { name: 'Greece', code: 'GR', gmt: 2 },
    { name: 'Greenland', code: 'GL', gmt: -3 },
    { name: 'Guatemala', code: 'GT', gmt: -6 },
    { name: 'Haiti', code: 'HT', gmt: -5 },
    { name: 'Honduras', code: 'HN', gmt: -6 },
    { name: 'Hong Kong SAR', code: 'HK', gmt: 8 },
    { name: 'Hungary', code: 'HU', gmt: 1 },
    { name: 'Iceland', code: 'IS', gmt: 0 },
    { name: 'India', code: 'IN', gmt: 5.5 },
    { name: 'Indonesia', code: 'ID', gmt: 7 },
    { name: 'Iran', code: 'IR', gmt: 3.5 },
    { name: 'Iraq', code: 'IQ', gmt: 3 },
    { name: 'Ireland', code: 'IE', gmt: 0 },
    { name: 'Israel', code: 'IL', gmt: 2 },
    { name: 'Italy', code: 'IT', gmt: 1 },
    { name: 'Jamaica', code: 'JM', gmt: -5 },
    { name: 'Japan', code: 'JP', gmt: 9 },
    { name: 'Jordan', code: 'JO', gmt: 2 },
    { name: 'Kazakhstan', code: 'KZ', gmt: 6 },
    { name: 'Kenya', code: 'KE', gmt: 3 },
    { name: 'Korea', code: 'KR', gmt: 9 },
    { name: 'Kuwait', code: 'KW', gmt: 3 },
    { name: 'Kyrgyzstan', code: 'KG', gmt: 6 },
    { name: 'Laos', code: 'LA', gmt: 7 },
    { name: 'Latvia', code: 'LV', gmt: 2 },
    { name: 'Lebanon', code: 'LB', gmt: 2 },
    { name: 'Libya', code: 'LY', gmt: 2 },
    { name: 'Liechtenstein', code: 'LI', gmt: 1 },
    { name: 'Lithuania', code: 'LT', gmt: 2 },
    { name: 'Luxembourg', code: 'LU', gmt: 1 },
    { name: 'Macao SAR', code: 'MO', gmt: 8 },
    { name: 'Macedonia, FYRO', code: 'MK', gmt: 1 },
    { name: 'Malaysia', code: 'MY', gmt: 8 },
    { name: 'Maldives', code: 'MV', gmt: 5 },
    { name: 'Mali', code: 'ML', gmt: 0 },
    { name: 'Malta', code: 'MT', gmt: 1 },
    { name: 'Mexico', code: 'MX', gmt: -6 },
    { name: 'Moldova', code: 'MD', gmt: 2 },
    { name: 'Monaco', code: 'MC', gmt: 1 },
    { name: 'Mongolia', code: 'MN', gmt: 8 },
    { name: 'Montenegro', code: 'ME', gmt: 1 },
    { name: 'Morocco', code: 'MA', gmt: 0 },
    { name: 'Myanmar', code: 'MM', gmt: 6.5 },
    { name: 'Nepal', code: 'NP', gmt: 5.75 },
    { name: 'Netherlands', code: 'NL', gmt: 1 },
    { name: 'New Zealand', code: 'NZ', gmt: 12 },
    { name: 'Nicaragua', code: 'NI', gmt: -6 },
    { name: 'Nigeria', code: 'NG', gmt: 1 },
    { name: 'Norway', code: 'NO', gmt: 1 },
    { name: 'Oman', code: 'OM', gmt: 4 },
    { name: 'Pakistan', code: 'PK', gmt: 5 },
    { name: 'Panama', code: 'PA', gmt: -5 },
    { name: 'Paraguay', code: 'PY', gmt: -4 },
    { name: 'Peru', code: 'PE', gmt: -5 },
    { name: 'Philippines', code: 'PH', gmt: 8 },
    { name: 'Poland', code: 'PL', gmt: 1 },
    { name: 'Portugal', code: 'PT', gmt: 0 },
    { name: 'Puerto Rico', code: 'PR', gmt: -4 },
    { name: 'Qatar', code: 'QA', gmt: 3 },
    { name: 'Réunion', code: 'RE', gmt: 4 },
    { name: 'Romania', code: 'RO', gmt: 2 },
    { name: 'Russia', code: 'RU', gmt: 2 },
    { name: 'Rwanda', code: 'RW', gmt: 2 },
    { name: 'Saudi Arabia', code: 'SA', gmt: 3 },
    { name: 'Senegal', code: 'SN', gmt: 0 },
    { name: 'Serbia', code: 'RS', gmt: 1 },
    { name: 'Singapore', code: 'SG', gmt: 8 },
    { name: 'Slovakia', code: 'SK', gmt: 1 },
    { name: 'Slovenia', code: 'SI', gmt: 1 },
    { name: 'Somalia', code: 'SO', gmt: 3 },
    { name: 'South Africa', code: 'ZA', gmt: 2 },
    { name: 'Spain', code: 'ES', gmt: 1 },
    { name: 'Sri Lanka', code: 'LK', gmt: 5.5 },
    { name: 'Sweden', code: 'SE', gmt: 1 },
    { name: 'Switzerland', code: 'CH', gmt: 1 },
    { name: 'Syria', code: 'SY', gmt: 2 },
    { name: 'Taiwan', code: 'TW', gmt: 8 },
    { name: 'Tajikistan', code: 'TJ', gmt: 5 },
    { name: 'Thailand', code: 'TH', gmt: 7 },
    { name: 'Trinidad and Tobago', code: 'TT', gmt: -4 },
    { name: 'Tunisia', code: 'TN', gmt: 1 },
    { name: 'Turkey', code: 'TR', gmt: 2 },
    { name: 'Turkmenistan', code: 'TM', gmt: 5 },
    { name: 'Ukraine', code: 'UA', gmt: 2 },
    { name: 'United Arab Emirates', code: 'AE', gmt: 4 },
    { name: 'United Kingdom', code: 'GB', gmt: 0 },
    { name: 'United States', code: 'US', gmt: -5 },
    { name: 'Uruguay', code: 'UY', gmt: -3 },
    { name: 'Uzbekistan', code: 'UZ', gmt: 5 },
    { name: 'Venezuela', code: 'VE', gmt: -4 },
    { name: 'Vietnam', code: 'VN', gmt: 7 },
    { name: 'Yemen', code: 'YE', gmt: 3 },
    { name: 'Zimbabwe', code: 'ZW', gmt: 2 },
];
