import clientsApi from '@/api/clientApi';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface ClientStatisticsState {
    clientData: { [ clientId: string]: { [dataType: string]: unknown } };
    loading: { [ clientId: string]: { [dataType: string]: boolean } };
    apiMappingForDates: { [ type: string]: (clientId: Guid, fromDate: string, toDate: string, granularity: 'Weekly' | 'Monthly') => Promise<unknown> };
    apiMapping: { [ type: string]: ((clientId: Guid) => Promise<unknown>) | ((clientId: Guid, fromDate: string, toDate: string, granularity: string) => Promise<unknown>) };
}

export const state: ClientStatisticsState = {
    loading: {},
    clientData: {},
    apiMappingForDates: {
    },
    apiMapping: {

    },
};

export const getters: GetterTree<ClientStatisticsState, unknown> = {
    stats: (s) => (clientId: Guid, dataType: string) => (s.clientData[clientId.toString()] ? s.clientData[clientId.toString()][dataType] : null),
    isLoading: (s) => (clientId: Guid, dataType: string) => (s.loading[clientId.toString()] ? s.loading[clientId.toString()][dataType] : false),
    areAnyLoading: (s) => (clientId: Guid) => Object.values(s.loading[clientId.toString()]).some((a) => a),
};

export const actions: ActionTree<ClientStatisticsState, unknown> = {
    async loadClientStatsForDashboard ({ commit }, { clientId, context }) {
        commit('setLoading', { clientId, dataType: 'dashboard', isLoading: true });
        Object.keys(state.apiMapping).forEach(async (dataType) => {
            commit('setLoading', { clientId, dataType, isLoading: true });
            const data = await state.apiMapping[dataType](
                clientId,
                dayjs(context.defaultStartDate).format('YYYY-MM-DD'),
                dayjs(context.defaultEndDate).format('YYYY-MM-DD'),
                'Weekly',
            );
            commit('setClientData', { clientId, dataType, data });
            commit('setLoading', { clientId, dataType, isLoading: false });
        });
        commit('setLoading', { clientId, dataType: 'dashboard', isLoading: false });
    },
    async loadClientStatsForDates ({ commit }, {
        clientId, fromDate, toDate, viewMonthly,
    }) {
        commit('setLoading', { clientId, dataType: 'dates', isLoading: true });
        const keys = Object.keys(state.apiMappingForDates);
        keys.forEach(async (dataType, i) => {
            commit('setLoading', { clientId, dataType, isLoading: true });
            const data = await state.apiMappingForDates[dataType](clientId, fromDate, toDate, viewMonthly ? 'Monthly' : 'Weekly').catch((ex) => {
                console.log(ex);
                commit('setLoading', { clientId, dataType, isLoading: false });
                if (i === keys.length - 1) {
                    commit('setLoading', { clientId, dataType: 'dates', isLoading: false });
                }
            });
            commit('setClientData', { clientId, dataType, data });
            commit('setLoading', { clientId, dataType, isLoading: false });
            if (i === keys.length - 1) {
                commit('setLoading', { clientId, dataType: 'dates', isLoading: false });
            }
        });
    },
};

export const mutations: MutationTree<ClientStatisticsState> = {
    setClientData (s, { clientId, dataType, data }) {
        if (!s.clientData[clientId]) Vue.set(s.clientData, clientId, {});
        Vue.set(s.clientData[clientId], dataType, data);
    },
    setLoading (s, { clientId, dataType, isLoading }) {
        if (!s.loading[clientId]) Vue.set(s.loading, clientId, {});
        Vue.set(s.loading[clientId], dataType, isLoading);
    },
    initialise (s) {
        s.apiMappingForDates = {
            inProgressCases: clientsApi.getInProgressJobs,
            completedCases: clientsApi.getCompleteJobs,
            webRegistrations: clientsApi.getWebRegistrations,
            membershipMovements: clientsApi.getMembershipMovements,
            memberInteractions: clientsApi.getMemberInteractions,
            transfers: clientsApi.getTranferStatistics,
            memberRegistrations: clientsApi.getMemberRegistrations,
            memberLogins: clientsApi.getMemberLogins,
            pageVisits: clientsApi.getPageVisits,
            modellerStats: clientsApi.getModellerStats,
            dataUpdates: clientsApi.getDataUpdateStats,
            memberDocumentsStats: clientsApi.getMemberDocumentStats,
            financials: clientsApi.getFinancials,
        };
        s.apiMapping = {
            feedback: clientsApi.getMemberFeedback,
            postcodes: clientsApi.getPostcodes,
            memberStats: clientsApi.getClientStatistics,
            dashboardInProgressCases: clientsApi.getCurrentInProgressJobs,
            governanceInformation: clientsApi.getGovernanceInformation,
            dashboardCompletedCases: clientsApi.getCompleteJobs as (clientId: Guid, fromDate: string, toDate: string, granularity: string) => Promise<unknown>,
            client: clientsApi.getClient,
        };
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
