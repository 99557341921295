import * as Msal from '~/msal';

export default class AuthenticationService {
    config: Msal.Configuration;

    scopes: Msal.AuthenticationParameters;

    msalObj: Msal.UserAgentApplication;

    constructor (config: Msal.Configuration, scopes: Msal.AuthenticationParameters) {
        this.config = config;
        this.scopes = scopes;
        this.msalObj = new Msal.UserAgentApplication(config);
    }

    login () {
        return this.msalObj.loginPopup(this.scopes).then((loginResponse) => {
            if (process.env.VUE_APP_ENVIRONMENT !== 'live') {
                console.log('Login Success :', loginResponse);
            }
        }).catch((error) => {
            console.log('Login Error :', error);
        });
    }

    getToken () {
        return this.msalObj.acquireTokenSilent(this.scopes).then((tokenResponse) => tokenResponse)
            .catch(() => {
                this.msalObj.acquireTokenPopup(this.scopes).then((tokenResponse) => tokenResponse)
                    .catch((er) => {
                        console.log(er);
                    });
            });
    }

    logout () {
        this.msalObj.logout();
    }
}
