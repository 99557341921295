import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import MemberActivity from '../views/MemberActivity.vue';
import Membership from '../views/Membership.vue';
import SchemeGovernance from '../views/SchemeGovernance.vue';
import Finance from '../views/Finance.vue';
import News from '../views/News.vue';
import Documents from '../views/Documents.vue';
import ClientList from '../views/ClientList.vue';
import MemberWebsite from '../views/MemberWebsite.vue';
import Terms from '../views/Terms.vue';
import MemberSearch from '../views/MemberSearch.vue';
import MemberData from '../views/MemberData.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'ClientList',
        component: ClientList,
        meta: {
            hideNav: true,
            showPrint: false,
            hideHeader: true,
        },
    },
    {
        path: '/terms-conditions',
        name: 'Terms',
        component: Terms,
        meta: {
            showDates: false,
            showPrint: false,
            public: true,
        },
    },
    {
        path: '/:clientName',
        name: 'Home',
        component: Home,
        meta: {
            showDates: false,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/terms-conditions',
        name: 'Terms',
        component: Terms,
        meta: {
            showDates: false,
            showPrint: false,
        },
    },
    {
        path: '/:clientName/memberActivity',
        name: 'MemberActivity',
        component: MemberActivity,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/member-activity',
        name: 'MemberActivity',
        component: MemberActivity,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/memberWebsite',
        name: 'MemberWebsite',
        component: MemberWebsite,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/member-website',
        name: 'MemberWebsite',
        component: MemberWebsite,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/membership',
        name: 'Membership',
        component: Membership,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/finance',
        name: 'finance',
        component: Finance,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/schemeGovernance',
        name: 'SchemeGovernance',
        component: SchemeGovernance,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/scheme-governance',
        name: 'SchemeGovernance',
        component: SchemeGovernance,
        meta: {
            showDates: true,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/pensionsNews',
        name: 'News',
        component: News,
        meta: {
            showDates: false,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/pensions-news',
        name: 'News',
        component: News,
        meta: {
            showDates: false,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/documents',
        name: 'Documents',
        component: Documents,
        meta: {
            showDates: false,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/members',
        name: 'MemberSearch',
        component: MemberSearch,
        meta: {
            showDates: false,
            showPrint: true,
        },
    },
    {
        path: '/:clientName/members/:memberId/:dataType?/:servicePeriodId?',
        name: 'MemberData',
        component: MemberData,
        props: true,
        meta: {
            showDates: false,
            showPrint: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
