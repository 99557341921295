import SiteConfiguration from 'common/models/configuration';
import ClientWebConfiguration from 'common/models/clientWebConfiguration';
import ClientConfigurationSummary from 'common/models/clientConfigurationSummary';
import Guid from 'common/util/guid';
import ClientConfigurationDetails from 'common/models/clientConfigurationDetails';
import ClientConfigurationSummaryResponse from 'common/models/ClientConfigurationSummaryResponse';
import Http from './apiHelper';

const apiVersion = 'v1.0';

export default {
    getConfiguration () {
        return Http.get<SiteConfiguration>(`${apiVersion}/configuration`, undefined, { useToken: false, errorMessage: 'Failed to retrieve configuration' });
    },
    getClientWebConfiguration () {
        return Http.get<ClientWebConfiguration>(`${apiVersion}/configuration`, undefined, { useToken: false, errorMessage: 'Failed to retrieve configuration' });
    },
    getHolidays () {
        return Http.getAsArray<Date>(`${apiVersion}/holidays`, Date);
    },
    getClientConfiguration (clientId: Guid) {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/configuration`, ClientConfigurationSummary, { errorMessage: 'Failed to retrieve configuration' });
    },
    getClientConfigurationDataType (clientId: Guid, dataType: string) {
        return Http.get(`${apiVersion}/client/${clientId}/configuration/${dataType}`, ClientConfigurationDetails, { errorMessage: 'Failed to retrieve configuration type' });
    },
    setClientConfigurationDataType (clientId: Guid, dataType: string, data: string) {
        return Http.put(`${apiVersion}/client/${clientId}/configuration/${dataType}`, { data: JSON.stringify(data) }, ClientConfigurationSummaryResponse,
            {
                errorMessage: 'Failed to retrieve configuration type',
                showSuccessMessage: true,
                message: 'Configuration Updated',
            });
    },
    getSystemConfiguration () {
        return Http.getAsArray(`${apiVersion}/systemConfiguration`, ClientConfigurationSummary, { errorMessage: 'Failed to retrieve configuration' });
    },
    getSystemConfigurationDataType (dataType: string) {
        return Http.get(`${apiVersion}/systemConfiguration/${dataType}`, ClientConfigurationDetails, { errorMessage: 'Failed to retrieve configuration type' });
    },
    setSystemConfigurationDataType (dataType: string, data: string) {
        return Http.put(`${apiVersion}/systemConfiguration/${dataType}`, { data: JSON.stringify(data) }, ClientConfigurationSummaryResponse,
            {
                errorMessage: 'Failed to retrieve configuration type',
                showSuccessMessage: true,
                message: 'Configuration Updated',
            });
    },
};
