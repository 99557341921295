export default function toDataURL (src: string, outputFormat: 'image/png' | 'image/jpeg' = 'image/png') {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = img.height;
            canvas.width = img.width;
            ctx!.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL(outputFormat);
            resolve(dataURL);
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
            img.src = src;
        }
        window.setTimeout(() => {
            console.log('timeout', src);
            reject();
        }, 2000);
    });
}
