
import Guid from 'common/util/guid';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class News extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    loading = false;

    selectedNews: never | null = null;

    getSnippet (news: { html: string }) {
        if (this.printing) return news.html;
        return news.html.split('</p>')[0];
    }

    get printedNews () {
        const grouped: Array<Array<unknown>> = [];
        this.pensionNews.forEach((news, i) => {
            if (i % 2 === 0) grouped.push([]);
            const row = grouped[grouped.length - 1];
            row.push(news);
        });
        return grouped;
    }

    pensionNews = [
        {
            id: 'abolition-of-lifetime-allowance',
            title: 'Abolition of the Lifetime Allowance',
            snippet: '',
            image: '/dashboard.jpg',
            color: '#002e5f',
            html: `
                <p>Following the Chancellor’s Autumn Statement, the Finance Bill containing all the necessary detail to carry out the abolition of the Lifetime Allowance
                    received Royal Assent, meaning the Lifetime Allowance was abolished with effect from 6 April 2024. You can find more on the relevant details contained within the Finance Bill
                    in our December 2023 News Alert at  <a href="https://www.lcp.com/our-viewpoint/2023/12/finance-bill-removes-the-lifetime-allowance" target="_blank">Finance Bill removes the
                        Lifetime Allowance | Lane Clark & Peacock LLP (lcp.com)</a>.
                </p>
                <h3>What have we been doing in Pensions Administration?</h3>
                <p>We started planning ahead, prior to the Finance Bill’s publication (and it subsequently receiving Royal Assent), including updating member communications and identifying areas needing change
                    based on the (limited) information previously published by government and HMRC.
                </p>
                <p>Since December, we have been busy implementing the detail contained within the Bill (now an Act) and prioritising work accordingly. These include ensuring: </p>
                <ul>
                    <li>member communications, particularly for quotations for retirements from 6 April 2024 are accurate - not just the figurework but also the explanation of
                        how the pensions tax regime works and what information is needed from the member before benefits can be paid;
                    </li>
                    <li>processes are updated – particularly to ensure ‘edge’ cases (those with ‘Lifetime Allowance protections’ or entitlements to ‘lump sums over 25%’)
                        requiring specialist review continue to be flagged; and
                    </li>
                    <li>systems are updated – including changes to calculation routines and adding the ability to record additional data items to ensure the new reporting
                        requirements imposed by the Act on scheme administrators to both members and HMRC can be met.
                    </li>
                </ul>
                <p>Training staff on the new regime continues as HMRC issue more guidance.</p>
            `,
            sort: 0,
        },
        {
            id: 'cyber-security-article',
            title: 'Cyber security ',
            snippet: '',
            image: '/reminder.jpg',
            html: `
                <p>Cyber security continues to be a hot topic. Following the Pensions Regulator’s (TPR) update of its cyber security principles in December 2023, the Regulator
                    published its regulatory intervention report on 2 February 2024 following the Capita cyber security incident.  In addition to a report on how TPR worked with Capita
                    to assess the risk to the schemes and members affected, this document sets out steps that trustees should take in the event of a cyber security incident.
                </p>
                <p>The report can be read
                    <a href="https://www.thepensionsregulator.gov.uk/en/document-library/enforcement-activity/regulatory-intervention-reports/capita-cyber-security-incident-regulatory-intervention-report"
                    target="_blank">here</a> and highlights the need for trustees to be prepared and to act fast, on the basis that it’s a question of when, not if an incident could happen.
                </p>
                <p>For more information about how LCP can help you prepare, reduce and react to any cyber risks threatening your scheme please see this
                    <a href="https://www.lcp.com/media/dfwdvdno/cyber-crime-and-your-scheme.pdf" target="_blank">flyer</a>.
                </p>
            `,
            sort: 1,
            color: '#002e5f',
        },

        {
            id: 'general-code-of-practice-has-arrived',
            title: 'The Code of Practice',
            image: '/avc.jpg',
            snippet: '',
            html: `
                <p>The General Code, now published on TPR’s website as the <a href="https://www.thepensionsregulator.gov.uk/en/document-library/code-of-practice" target="_blank">Code of Practice</a>,
                    came into force on 28 March 2024. This gives access to the content of all of TPR’s codes.
                </p>
                <p>As you know, the Own Risk Assessment is one of the new risk management requirements and LCP has collaborated with the Pensions and Lifetime Savings Association to produce an updated
                    <a href="https://www.plsa.co.uk/Portals/0/Documents/Made-Simple-Guides/2024/Own-Risk-Assessments-Made-Simple-2024.pdf" target="_blank">Own Risk Assessments Made Simple</a> guide.
                </p>
                <p>Some modules in the Code bring an additional focus on administration areas and cyber security as part of the risk management expected of trustees.  We are working to ensure that
                    our administration and cyber security procedures will support trustees in meeting the relevant requirements.
                </p>
                <p>If you would like to know more, please contact your usual LCP contact.</p>
            `,
            sort: 2,
        },
        {
            id: 'dashboard-update',
            title: 'Pensions dashboards staging timetable confirmed!',
            image: '/small-laptop.jpg',
            snippet: '',
            html: `
                <p>DWP has released its updated guidance on the pensions dashboards, which confirms the staged timetable for connection with the dashboards’ ‘ecosystem’.
                    Pension schemes have been assigned a ‘connect by’ date – to which the Regulator expects trustees to adhere – with all schemes and providers legally required to be connected
                    by 31 October 2026.
                </p>
                <p>LCP has a team of pensions dashboards experts helping to make sure your scheme is dashboard ready – please speak to your usual contact if you want to know more.</p>
            `,
            sort: 3,
        },
        {
            id: 'divorce-charges',
            title: 'Update to recommended divorce charges',
            image: '/dc-savings.jpg',
            snippet: '',
            html: `
                <p>The Pensions and Lifetime Savings Association has updated its
                    <a href="https://www.plsa.co.uk/Policy-and-Research/Document-library/Pension-sharing-charges-Pensions-and-Lifetime-Savings-Association-guidance" target="_blank">'Pension sharing on divorce'</a>
                    guidance and charges document. It contains a recommended range of charges for schemes as well as a flowchart outlining the circumstances when charges can be made.<br>
                    LCP are currently reviewing the revised charges and how these may apply to divorcing couples.
                </p>
            `,
            sort: 4,
        },
        {
            id: 'pasa-accreditation',
            title: 'PASA accreditation',
            image: '/boardroom.jpg',
            snippet: '',
            html: `
                <p>We are delighted that LCP has successfully been re-accredited by The Pensions Administration Standards Association (PASA), demonstrating our unwavering commitment to excellence in pension administration.</p>
                <p>As PASA sets the gold standard for quality, this re-accreditation shows our dedication to delivering professional and high-quality pension administration services.</p>
            `,
            sort: 5,
        },
        {
            id: 'pensions-dashboards',
            title: 'Pensions dashboards',
            image: '/calculator.jpg',
            snippet: '',
            html: `
                <p>As schemes continue their preparations for connection to pensions dashboards by their revised ‘connect by’ dates, a  <a href="https://www.nao.org.uk/reports/investigation-into-the-pensions-dashboards-programme/" target="_blank">National Audit Office report</a>. on the project was published in May. Although the report identified challenges which led to the reset in March 2023, and an increase in the cost of the project, it also concluded the net benefit of it to be around £211 million.</p>
                <p>LCP has a team of pensions dashboards experts helping to make sure your scheme is dashboard ready – please speak to your usual contact if you want to know more.</p>
            `,
            sort: 6,
        },
    ];

    async needBreak () {
        if (!this.printing) return;
        const ref = this.$refs.news as HTMLElement;
        let lastBreakHeight = 0;
        let page = 0;
        for (let i = 0; i < ref.children.length; i += 1) {
            const el = ref.children[i] as HTMLElement;

            if (ref.children[i].getAttribute('data-index')) {
                el.classList.remove('break');
                el.style.height = '0';
            }
        }
        for (let i = 0; i <= ref.children.length - 1; i += 1) {
            const el = ref.children[i] as HTMLElement;
            const pageHeight = 1785;
            if (i >= ref.children.length - 1) {
                el.style.height = `${pageHeight + (page * pageHeight) - el.offsetTop}px`;
            }
            const nextEl = ref.children[i + 1] as HTMLElement;
            if (ref.children[i].getAttribute('data-index')) {
                if (nextEl.offsetTop + nextEl.offsetHeight - lastBreakHeight > pageHeight) {
                    el.classList.add('break');
                    if (page === 1) {
                        el.style.height = `${pageHeight + (page * pageHeight) - el.offsetTop - 13}px`;
                        lastBreakHeight = el.offsetTop + pageHeight + (page * pageHeight) - el.offsetTop - 13;
                    } else {
                        el.style.height = `${pageHeight + (page * pageHeight) - el.offsetTop}px`;
                        lastBreakHeight = el.offsetTop + pageHeight + (page * pageHeight) - el.offsetTop;
                    }
                    page += 1;
                } else {
                    el.classList.remove('break');
                    el.style.height = '0';
                }
            }
        }
    }
}
