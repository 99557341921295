
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DynamicUiText, DynamicUiDate } from '@lcp/dynamic-ui-typescript';
import MemberApi from '@/api/memberApi';
import Guid from 'common/util/guid';
import MemberSearchResult from '@/models/memberSearchResult';
import ClientNameService from '@/services/clientNameService';
import Client from 'common/models/client';
import MemberSearchOptions from '@/models/memberSearchOptions';

@Component({
    components: {
        DynamicUiText, DynamicUiDate,
    },
})
export default class MemberSearch extends Vue {
    viewAdvancedSearch = false;

    memberId: number | null = null;

    surname = '';

    forenames = '';

    dateOfBirth = '';

    nino = '';

    pageNumber = 1;

    pageSize = 25;

    sort = 'memberId';

    isLoading = false;

    hasSearched = false;

    totalResults = 0;

    get searchParams () {
        return this.$store.getters['clientContext/memberSearchOptions'] as MemberSearchOptions;
    }

    get searchResults () {
        return this.$store.getters['clientContext/memberSearchResults'] as Array<MemberSearchResult> ?? [];
    }

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    get clientName () {
        if (!this.clients) return '';
        return this.clients.find((a) => a.clientId === this.clientId)?.name;
    }

    get advancedSearchDisabled () {
        return !this.surname?.length && !this.forenames?.length && this.isInvalidMemberId()
        && !this.dateOfBirth?.length && !this.nino?.length;
    }

    get clientPath () {
        if (!this.clientName) return '';
        if (this.$route.path.indexOf('/demo') > -1) return 'demo';
        return ClientNameService.clientNameToPath(this.clientName);
    }

    sortBy (column: string) {
        const isInversed = this.sort.startsWith('-');
        if (this.sort.includes(column) && isInversed) {
            this.sort = column;
        } else if (this.sort.includes(column) && !isInversed) {
            this.sort = `-${column}`;
        } else {
            this.sort = column;
        }
        this.getResults();
    }

    @Watch('searchParams', { immediate: true })
    searchParamsChanged () {
        this.surname = this.searchParams.surname;
        this.forenames = this.searchParams.forenames;
        this.memberId = this.searchParams.memberId;
        this.nino = this.searchParams.nino;
        this.dateOfBirth = this.searchParams.dateOfBirth;
        this.pageNumber = this.searchParams.pageNumber;
        this.pageSize = this.searchParams.pageSize;
        this.hasSearched = this.searchParams.hasSearched;
        this.totalResults = this.searchParams.totalResults;
        this.viewAdvancedSearch = this.searchParams.viewAdvancedSearch;
    }

    incrementPage () {
        this.pageNumber += 1;
        this.getResults(false);
    }

    decrementPage () {
        this.pageNumber -= 1;
        this.getResults(false);
    }

    isInvalidMemberId () {
        const reg = /^[0-9]+$/;
        const id = this.memberId?.toString(10);
        return this.memberId === undefined || this.memberId === null || id === '' || !reg.test(id!);
    }

    getResults (resetPage = true) {
        if (this.memberId !== undefined && this.memberId !== null && this.memberId.toString(10) !== '' && this.isInvalidMemberId()) {
            return;
        }
        this.isLoading = true;
        this.hasSearched = true;
        if (resetPage) {
            this.pageNumber = 1;
        }

        MemberApi.getMemberSearchResults(this.clientId, {
            memberId: this.viewAdvancedSearch ? this.memberId : null,
            surname: this.surname,
            forenames: this.viewAdvancedSearch ? this.forenames : undefined,
            dateOfBirth: this.viewAdvancedSearch ? this.dateOfBirth : undefined,
            nino: this.viewAdvancedSearch ? this.nino : undefined,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sort: this.sort,
        }).then((result) => {
            this.$store.commit('clientContext/setSearchResults', { clientId: this.clientId, results: result });
            this.totalResults = result.headers['x-total-count'];

            this.$store.commit('clientContext/setSearchParams', {
                clientId: this.clientId,
                options: {
                    memberId: this.memberId,
                    surname: this.surname,
                    forenames: this.forenames,
                    dateOfBirth: this.dateOfBirth,
                    nino: this.nino,
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    hasSearched: this.hasSearched,
                    totalResults: this.totalResults,
                    viewAdvancedSearch: this.viewAdvancedSearch,
                },
            });
        }).finally(() => {
            this.isLoading = false;
        });
    }

    gotoMember (memberId: number) {
        this.$router.push(`/${this.clientPath}/members/${memberId}/`);
    }
}
