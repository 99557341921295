import { render, staticRenderFns } from "./copy-link-button.vue?vue&type=template&id=779faa10&"
import script from "./copy-link-button.vue?vue&type=script&lang=ts&"
export * from "./copy-link-button.vue?vue&type=script&lang=ts&"
import style0 from "./copy-link-button.vue?vue&type=style&index=0&id=779faa10&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../ClientWeb/FrontEnd/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports