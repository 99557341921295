
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import MemberSearch from '@/components/member-search.vue';
import MemberApi from '@/api/memberApi';
import MemberSearchResult from '@/models/memberSearchResult';
import ClientNameService from '@/services/clientNameService';
import Client from 'common/models/client';
import CalculationResultWarning from 'common/models/calculations/calculationResultWarning';
import { DynamicUiText, DynamicUiStringDropdown, DynamicUiDate } from '@lcp/dynamic-ui-typescript';
import dayjs from 'dayjs';
import ClientConfig from '@/models/client';

@Component({
    components: {
        MemberSearch,
        DynamicUiText,
        DynamicUiDate,
        DynamicUiStringDropdown,
    },
})
export default class MemberDataView extends Vue {
    @Prop()
        memberId!: number;

    @Prop()
        dataType?: string;

    @Prop()
        servicePeriodId?: string;

    isLoading = false;

    calculating = false;

    calculationResult: Record<string, number> | null = null;

    calculationWarnings: Array<CalculationResultWarning> | null = null;

    selectedCalculationServicePeriod = 0;

    calculationErrors = false;

    calculationResultData: {
        strings: Record<string, string>;
        doubles: Record<string, number>;
        dates: Record<string, Date>;
    } | null = null;

    memberSummary: MemberSearchResult | null = null;

    clientWebStatus: string | null = null;

    availableDataTypes: Record<string, string> = {};

    servicePeriodAvailableDataTypes: Record<string, Record<string, string>> = {};

    dataTypeData: unknown = null;

    dateOfRetirement: Date | { Day: number; Month: number; Year: number } = new Date();

    get hasMemberWeb () {
        return this.clientConfiguration?.hasMemberWeb;
    }

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clientContext () {
        return this.$store.getters['clientContext/context'](this.clientId);
    }

    get memberName () {
        return `${this.memberSummary?.forenames ?? ''} ${this.memberSummary?.surname ?? ''}`;
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    get clientName () {
        if (!this.clients) return '';
        return this.clients.find((a) => a.clientId === this.clientId)?.name;
    }

    get clientPath () {
        if (!this.clientName) return '';
        if (this.$route.path.indexOf('/demo') > -1) return 'demo';
        return ClientNameService.clientNameToPath(this.clientName);
    }

    get selectedDataTypeName () {
        if (!this.dataType) return '';
        return this.availableDataTypes[this.dataType] ?? this.servicePeriodAvailableDataTypes[this.servicePeriodId ?? '']?.[this.dataType] ?? '';
    }

    get memberStatus () {
        return this.memberSummary?.servicePeriods.find((x) => x.servicePeriodId === Number(this.servicePeriodId));
    }

    get dataTypes () {
        const dataTypes: Array<{ name: string; value: string,}> = [];
        Object.keys(this.availableDataTypes).forEach((key) => {
            dataTypes.push({ name: this.availableDataTypes[key], value: key });
        });
        Object.keys(this.servicePeriodAvailableDataTypes).forEach((period) => {
            Object.keys(this.servicePeriodAvailableDataTypes[period]).forEach((key) => {
                dataTypes.push({ name: `${this.servicePeriodAvailableDataTypes[period][key]} (${period})`, value: `${period}-${key}` });
            });
        });
        return dataTypes;
    }

    get calculationServicePeriods () {
        return this.memberSummary?.servicePeriods.filter((a) => a.status === 'Deferred').map((a) => ({
            value: a.servicePeriodId,
            name: `${a.servicePeriodId} - ${a.status} - ${dayjs(a.statusDate).format('DD MMM YYYY')}`,
        }));
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfig ?? {};
    }

    get showMemberCalculations () {
        return this.clientConfiguration?.showMemberCalculations;
    }

    @Watch('clientId', { immediate: true })
    async clientIdChanged () {
        if (!this.clientId) return;
        this.isLoading = true;
        await this.getMemberInfo();
        await this.getDataTypeData();
        this.isLoading = false;
    }

    @Watch('dataType', { immediate: true })
    async dataTypeChanged () {
        if (!this.dataType || !this.clientId) return;
        this.isLoading = true;
        await this.getDataTypeData();
        this.isLoading = false;
    }

    async getDataTypeData () {
        if (!this.dataType) return;
        if (this.dataType === 'calculations') return;
        if (this.servicePeriodId) {
            await MemberApi.getDataTypeForServicePeriod(this.clientId, Number(this.memberId), Number(this.servicePeriodId), this.dataType!).then((result) => {
                this.dataTypeData = result;
            }).catch((ex) => console.log(ex));
            return;
        }
        await MemberApi.getDataType(this.clientId, Number(this.memberId), this.dataType!).then((result) => {
            this.dataTypeData = result;
        });
    }

    async getAvailableDataTypes () {
        await MemberApi.getAvailableTypes(this.clientId, Number(this.memberId)).then((result) => {
            this.availableDataTypes = result;
        }).catch((ex) => console.log(ex));
        for (let i = 0; i < this.memberSummary!.servicePeriods.length; i += 1) {
            const servicePeriod = this.memberSummary!.servicePeriods[i];
            // eslint-disable-next-line no-await-in-loop
            Vue.set(this.servicePeriodAvailableDataTypes, servicePeriod.servicePeriodId, await MemberApi.getAvailableTypesForServicePeriod(this.clientId, Number(this.memberId), servicePeriod.servicePeriodId));
        }
        if (!this.dataType && Object.keys(this.availableDataTypes).length) {
            this.$router.push(`/${this.clientPath}/members/${this.memberSummary!.memberId}/${Object.keys(this.availableDataTypes)[0]}`);
        }
    }

    async getMemberInfo () {
        await MemberApi.getMemberSearchResults(this.clientId, {
            memberId: this.memberId,
            pageNumber: 1,
            pageSize: 1,
            sort: '',
        }).then(async (result) => {
            if (!result.length) return;
            this.memberSummary = result[0];
            this.selectedCalculationServicePeriod = this.memberSummary.servicePeriods[0].servicePeriodId;
            if (this.hasMemberWeb) {
                await MemberApi.getClientWebStatus(this.clientId, Number(this.memberId))
                    .then((res) => { this.clientWebStatus = res; });
            }
        }).catch((ex) => console.log(ex));
        await this.getAvailableDataTypes();
    }

    async showCalculation () {
        const date = new Date();
        if (!('Day' in this.dateOfRetirement)) return;
        const args = `{"DOC":{"Day":${date.getDate()},"Month":${date.getMonth() + 1},"Year":${date.getFullYear()}}, "DOR": {"Day": ${this.dateOfRetirement.Day}, "Month": ${this.dateOfRetirement.Month}, "Year": ${this.dateOfRetirement.Year}}}`;
        this.calculationErrors = false;
        this.isLoading = true;
        const result = await MemberApi.getCalculation(this.clientId, Number(this.memberId), this.selectedCalculationServicePeriod, 'Retirement from deferred', args)
            .catch(() => {
                this.calculationResultData = null;
                this.calculationErrors = true;
            }).finally(() => {
                this.isLoading = false;
            });

        if (!result) {
            return;
        }

        this.calculationResultData = result.data ?? null;
        this.calculationResult = result.calculationResults ?? null;
        this.calculationErrors = !!result.hasErrors;
    }

    gotoDataType (dataType: string, servicePeriodId?: number) {
        if (dataType.includes('-')) {
            const arr = dataType.split('-');
            // eslint-disable-next-line no-param-reassign
            dataType = arr[1];
            // eslint-disable-next-line no-param-reassign
            servicePeriodId = Number(arr[0]);
        }
        if (servicePeriodId !== undefined) {
            this.$router.push(`/${this.clientPath}/members/${this.memberSummary!.memberId}/${dataType}/${servicePeriodId}`);
            if (this.dataType && this.dataType === dataType) {
                this.servicePeriodId = String(servicePeriodId);
                this.getDataTypeData();
            }
            return;
        }
        this.$router.push(`/${this.clientPath}/members/${this.memberSummary!.memberId}/${dataType}`);
    }

    gotoSearch () {
        this.$router.push(`/${this.clientPath}/members/`);
    }

    getDisplayValue (value: number) {
        if (value === undefined || value === null) {
            return '';
        }

        if (typeof value.toFixed === 'function') {
            return `£${value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        }

        return value;
    }
}
