
import { Component, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import MemberStatisticsOverview from '@/components/member-statistics-tile.vue';
import Demographics from '@/components/demographics.vue';
import MemberMovements from '@/components/member-movements.vue';
import Transfers from '@/components/transfers.vue';

@Component({
    components: {
        MemberStatisticsOverview,
        Demographics,
        MemberMovements,
        Transfers,
    },
})
export default class Membership extends Vue {
    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get row () {
        return this.$route.params.row;
    }

    mounted () {
        this.$nextTick(() => {
            if (this.$refs[this.row]) {
                (this.$refs.content as HTMLElement).scrollTop = ((this.$refs[this.row] as Vue).$el as HTMLElement).offsetTop;
            }
        });
    }
}
