<template lang="html">
    <a class="profile" :title="hoverText">
        <img v-if="url && !useFallback" :src="url" :class="{ 'not-grayscale': color}" @error="useFallback = true" height="50" width="50"/>
        <slot name="fallback" v-else>
            <div class="icon">
                <font-awesome-icon icon="user-alt" />
            </div>
        </slot>
    </a>
</template>

<script>
import base64Image from 'common/util/base64Image';

export default {
    name: 'user-photo',
    components: {
    },

    props: {
        id: String,
        color: {
            default: true,
            type: Boolean,
        },
        email: String,
        prescence: {
            default: 'offline',
            type: String,
        },
        fallbackmage: String,
        disabled: Boolean,
    },
    data () {
        return {
            useFallback: false,
            url: '',
        };
    },
    mounted () {
        this.getImgUrl();
    },
    methods: {
        async getImgUrl () {
            this.url = await base64Image(`${this.userUrl}${this.id}.jpg`);
        },
    },
    computed: {
        hoverText () {
            if (this.title) return this.title;
            if (this.disabled) return this.email;
            if (!this.email) return null;
            return `${this.email}`;
        },
        userUrl () {
            return this.$store.getters['configuration/site']['user.blob.url'];
        },

    },
    watch: {
        id () {
            this.useFallback = false;
        },
    },
};

</script>

<style lang="scss" scoped>
@import 'common/scss/index.scss';

.profile {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 100%;
    position: relative;
    display: block;
    img {
        filter: grayscale(100%);
        transition: filter 0.5s;
        height: 100%;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        width: 100%;
        &.not-grayscale {
            filter: none;
        }
    }

    .icon {
        background: #aaa;
        color: #fff;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        text-align: center;
        ::v-deep svg {
            position: relative;
            margin-top: 19%;
            color: #fff;
            height: 55%;
            width: 55%;
        }
    }

    &:hover {

        &:after {
            opacity: 0;
        }

        img {
            filter: none;
        }
    }

    &:before {
        content: '';
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 100%;
        position: absolute;
        border: 2px solid #ccc;
        background: white;
        bottom: -5%;
        right: -5%;
        z-index: 2;
        display: none;
    }
    &.online:before {
        background: #6eb005;
        border-color: #fff;
    }
}

</style>
