/* eslint-disable @typescript-eslint/ban-ts-ignore */
import Vue from '~/vue';

export default Vue.extend({
    data () {
        return {
            // eslint-disable-next-line vue/no-reserved-keys
            _confirmChanges: true,
            dirtyFunction: null,
            root: this.$root.$children[0],
        };
    },
    mounted () {
        this.root = this.$root.$children[0];
    },
    destroyed () {
        // this.root.setupDirtyFunction(null);
    },
    methods: {
        confirmLeaveSetupDirtyFunction (func: () => void) {
            // @ts-ignore
            this.root.setupDirtyFunction(func);
        },
        confirmLeaveSetupClearFunction (func: () => void) {
            // @ts-ignore
            this.root.setupClearFunction(func);
        },
        confirmLeavePrompt (next: () => void, stop: unknown) {
            // @ts-ignore

            this.root.confirmUnsavedChanges(next, stop);
        },
    },
});
