import Guid from 'common/util/guid';

export default class MemberDataType {
    memberDataTypeId!: Guid;

    name!: string;

    description!: string;

    typeName!: string;

    namespace!: string;

    assemblyName!: string;

    scope!: string;

    canDelete!: boolean;

    isViewable!: boolean;

    isLiveUpdating!: boolean;

    isProtected!: boolean;

    isViewableInClientWeb!: boolean;

    viewPermissionId!: Guid;

    editPermissionId!: Guid;
}
