
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import ColourService from '@/services/colourService';
import dayjs from 'dayjs';
import MemberDocumentStats from '@/models/memberDocumentStats';
import Highcharts from 'highcharts';

@Component
export default class MemberCorrespondenceViews extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        printing!: boolean;

    get documentStats (): Array<MemberDocumentStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberDocumentsStats');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberDocumentsStats');
    }

    get documentStatsTransformed () {
        if (!this.documentStats?.length) return [];
        const vals: Array<{ type: 'line'; name: string; data: Array<number>; color: string }> = [];

        vals.push({
            name: 'Personal correspondence added',
            type: 'line',
            data: this.documentStats.map((a) => a.correspondenceCreatedTotal),
            color: ColourService.getSeriesColour('correspondence', 'personal'),
        });

        vals.push({
            name: 'Forms submitted',
            type: 'line',
            data: this.documentStats.map((a) => Object.values(a.webFormsSubmitted ?? {})
                .reduce((b, c) => b + Object.values(c.splitByStatus)
                    .reduce((d, e) => d + e, 0), 0)),
            color: ColourService.getSeriesColour('correspondence', 'forms'),
        });

        vals.push({
            name: 'Web queries',
            type: 'line',
            data: this.documentStats.map((a) => a.webQueriesSubmittedTotal),
            color: ColourService.getSeriesColour('correspondence', 'webQueries'),
        });

        vals.push({
            name: 'Documents viewed',
            type: 'line',
            data: this.documentStats.map((a) => Object.values(a.documentsViewed ?? {})
                .reduce((b, c) => b + Object.values(c.splitByStatus)
                    .reduce((d, e) => d + e, 0), 0)),
            color: ColourService.getSeriesColour('correspondence', 'documents'),
        });

        return vals;
    }

    get colours () {
        return [ColourService.colours[0]];
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'line',
            },
            xAxis: {
                categories: this.documentStats?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            colors: this.colours,
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                    },
                    lineWidth: 3,
                },
                series: {
                    animation: !this.printing,
                },
            },
            series: this.documentStatsTransformed || [],
        };
    }
}
