
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import JobDateStats from '@/models/jobDateStats';
import Guid from 'common/util/guid';
import Highcharts, { Point } from 'highcharts';
import ColourService from '@/services/colourService';
import ToggleSwitch from 'common/components/toggle-switch.vue';
import PieLegend from '@/services/pieLegendService';

@Component({ components: { ToggleSwitch } })
export default class InProgressJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        showLegend!: boolean;

    selectedPoint: string | null = null;

    selectedColourIndex: number | null = null;

    chartCenterX = 0;

    chartCenterY = 0;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'completedCases') || false as boolean;
    }

    get completedJobStats (): JobDateStats {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'completedCases');
    }

    get totalJobs () {
        if (!this.inprogressJobStatsTranformed) return 0;
        return this.inprogressJobStatsTranformed.filter((a) => a.name === this.selectedPoint || !this.selectedPoint).map((a) => a.y).reduce((a, b) => b + a, 0);
    }

    get centerPos () {
        return {
            top: `calc(${this.chartCenterY}px + 1.5rem) !important`,
            left: `calc(${this.chartCenterX}px + 1.5rem) !important`,
        };
    }

    get inprogressJobStatsTranformed () {
        if (!this.completedJobStats) return null;
        const totals: { [seriesName: string ]: number} = {};
        this.completedJobStats.dataByDate.forEach((date) => {
            Object.keys(date.counts).forEach((seriesName) => {
                if (!totals[seriesName]) totals[seriesName] = 0;
                totals[seriesName] += date.counts[seriesName];
            });
        });
        return Object.keys(totals).sort((a, b) => (a > b ? 1 : -1))
            .map((seriesName) => ({
                name: seriesName,
                y: totals[seriesName],
                color: ColourService.getSeriesColour('jobs', seriesName),
            }));
    }

    pointClicked (point: Point) {
        this.selectedColourIndex = point.colorIndex;
        this.selectedPoint = point.name === this.selectedPoint ? null : point.name;
        this.$emit('pointSelected', { category: 'completedJobs', series: this.selectedPoint });
    }

    clearFilter () {
        (this.$refs.chart as unknown as { chart: Highcharts.Chart }).chart.series[0].points.forEach((a) => a.select(false));
        this.selectedPoint = null;
        this.$emit('pointSelected', { category: 'completedJobs', series: this.selectedPoint });
    }

    get chartOptions (): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
                events: {
                    load () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                    redraw () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                },
            },
            xAxis: {
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: this.showLegend ? PieLegend.get().legend : {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    allowPointSelect: true,
                    showInLegend: true,
                    borderWidth: 0,
                    point: {
                        events: {
                            legendItemClick: (event) => {
                                event.preventDefault();
                                event.target.select(!event.target.selected);
                                this.pointClicked(event.target);
                            },
                        },
                    },
                },
            },
            series: [{
                type: 'pie',
                name: 'Completed cases',
                innerSize: '70%',
                slicedOffset: 5,
                data: this.inprogressJobStatsTranformed!,
                events: {
                    click: (event) => { this.pointClicked(event.point); },
                },
            }],
        };
    }
}
