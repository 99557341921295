import clientsApi from 'common/api/clientsApi';
import teamsApi from 'common/api/teamsApi';
import Client from 'common/models/client';
import TeamSummary from 'common/models/teamSummary';
import User from 'common/models/user';
import Vue from '~/vue';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';

interface ClientState {
    clients: Array<Client>;
    teams: Array<TeamSummary>;
    userClients: Array<Client>;
    referralTeams: null | Array<Client>;
    clientMap: {[clientName: string]: Client };
    teamMap: {[teamName: string]: TeamSummary };
    teamUsers: {[clientName: string]: Array<User> };
    currentClient: Client | null;
}

export const state: ClientState = {
    clients: [],
    clientMap: {},
    teamMap: {},
    teamUsers: {},
    referralTeams: [],
    teams: [],
    currentClient: null,
    userClients: [],
};

export const getters: GetterTree<ClientState, unknown> = {
    referralTeams: (s) => s.referralTeams,
    clients: (s) => s.clients,
    teams: (s) => s.teams,
    currentClient: (s) => s.currentClient,
    userClients: (s) => s.userClients,
    client: (s) => (clientName: string) => s.clientMap[clientName],
    team: (s) => (teamName: string) => s.teamMap[teamName],
    teamUsers: (s) => (clientName: string) => s.teamUsers[clientName] || [],
};

export const actions: ActionTree<ClientState, unknown> = {
    loadTeamUsers ({ commit }, { teamId, teamName }) {
        clientsApi.getTeamUsers(teamId).then((result) => {
            commit('setTeamUsers', { client: teamName, users: result });
        });
    },
    getReferralTeams ({ commit }) {
        teamsApi.getReferralTeams().then(async (result) => {
            commit('setReferralTeams', result);
        });
    },
    getTeams ({ commit }, pulseApiUrl: string) {
        return teamsApi.getTeams(pulseApiUrl)
            .then((teams) => {
                commit('setTeams', teams);
                teams.forEach((t) => {
                    commit('setTeam', t);
                });
                return teams;
            });
    },
    getUserClients ({ commit }, pulseApiUrl: string) {
        return clientsApi.getUserClients(pulseApiUrl)
            .then((clients) => {
                commit('setUserClients', clients);
                clients.forEach((c) => {
                    commit('setclient', c);
                });
                return clients;
            });
    },
    getClients ({ commit }) {
        return clientsApi.getClients()
            .then((clients) => {
                commit('setclients', clients);
                clients.forEach((c) => {
                    commit('setclient', c);
                });
                return clients;
            });
    },
    setclient ({ commit }, client) {
        commit('setclient', client, client.name);
    },
    setTeam ({ commit }, team) {
        commit('setTeam', team, team.name);
    },
    setCurrentClient ({ commit }, client: Client) {
        commit('setCurrentClient', { ...client });
    },
    setClientFromName ({ commit }, clientName: string) {
        const client = state.clients.find((c) => c.code === clientName || c.name === clientName);
        commit('setCurrentClient', { ...client });
    },
};

export const mutations: MutationTree<ClientState> = {
    setclient (s, client) {
        Vue.set(s.clientMap, client.name, client);
    },
    setReferralTeams (s, teams) {
        s.referralTeams = teams;
    },
    setUserClients (s, userClients) {
        s.userClients = userClients;
    },
    setclients (s, clients) {
        s.clients = clients;
    },
    setTeams (s, teams) {
        s.teams = teams;
    },
    setTeamUsers (s, teamUsers) {
        Vue.set(s.teamUsers, teamUsers.client, teamUsers.users);
    },
    setTeam (s, team) {
        Vue.set(s.teamMap, team.name, team);
    },
    setCurrentClient (s, client) {
        s.currentClient = client;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
