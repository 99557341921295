
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import MemberDocumentStats from '@/models/memberDocumentStats';
import Highcharts from 'highcharts';

@Component
export default class PayslipViewsByStatus extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean;

    viewByFileType = false;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberDocumentsStats') || false as boolean;
    }

    get documentStats (): Array<MemberDocumentStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberDocumentsStats') as Array<MemberDocumentStats>;
    }

    get categories () {
        if (!this.documentStats) return [];
        const dataTypes: Array<string> = [];

        this.documentStats.forEach((date) => {
            Object.values(date.documentsViewed).forEach((document) => {
                dataTypes.push(...Object.keys(document.splitByStatus));
            });
        });
        return [...new Set(dataTypes)];
    }

    get documentNames () {
        if (!this.documentStats) return [];
        const dataTypes: Array<string> = [];

        this.documentStats.forEach((date) => {
            if (date.documentsViewed.length > 0) {
                date.documentsViewed.forEach((document) => {
                    dataTypes.push(document.name);
                });
            }
        });
        return [...new Set(dataTypes)];
    }

    get documentStatsTransformed () {
        if (!this.documentStats) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];

        this.categories.forEach((status) => {
            const dataTypeData: Array<number> = [];

            if (this.viewByFileType) {
                this.documentNames.forEach((documentName) => {
                    const val = this.documentStats.reduce((a, b) => a + (b.documentsViewed.find((c) => c.name === documentName)?.splitByStatus[status] ?? 0), 0);
                    dataTypeData.push(val);
                });
            } else {
                this.documentStats!.forEach((row) => {
                    dataTypeData.push(Object.values(row.documentsViewed)
                        .reduce((a, b) => a + (b.splitByStatus[status] ?? 0), 0));
                });
            }
            console.log(dataTypeData);
            if (!this.filter || this.filter === status) {
                vals.push({
                    name: status,
                    type: 'column',
                    data: dataTypeData,
                    color: ColourService.getSeriesColour('status', status),
                });
            }
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.viewByFileType ? this.documentNames : this.documentStats?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => (this.viewByFileType ? that.value as string : dayjs(that.value).format('DD MMM'))),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.documentStatsTransformed || [],
        };
    }

    toUppercase (word: string) {
        const wordArr = word.split('').filter((a) => a);
        return wordArr[0].toUpperCase() + wordArr.slice(1).join('');
    }

    getColour (status: string) {
        return ColourService.getSeriesColour('status', status);
    }

    formatDocument (document: string) {
        let newUrl = document.startsWith('/') ? document.substring(1) : document;
        newUrl = newUrl.replace(/-/g, ' ');
        newUrl = newUrl.replace(/\//g, ' - ');
        return this.toUppercase(newUrl).replace('.pdf', '')
            .replace('Sfs ', 'SFS ')
            .replace('Ici ', 'ICI ');
    }
}
