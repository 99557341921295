import Http from 'common/api/apiHelper';
import DocumentCatgeory from 'common/models/correspondence/documentCategory';
import Guid from 'common/util/guid';

const apiVersion = 'v1.0';
export default class InboxApi {
    static getInboxSummary (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox`);
    }

    static getEmails (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox/email`, undefined, {
            returnHeaders: true,
        }) as Promise<(Array<unknown> & {headers: { 'x-total-count': number } })>;
    }

    static getEmail (clientId: Guid, emailId: string) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox/email/${emailId}`);
    }

    static getEmailAttachments (clientId: Guid, emailId: string) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox/email/${emailId}/attachment`);
    }

    static getEmailAttachment (clientId: Guid, emailId: string, attachmentId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox/email/${emailId}/attachment/${attachmentId}`, undefined,
            {
                config: {
                    responseType: 'blob',
                },
            });
    }

    static returnEmailToIndexing (clientId: Guid, emailId: string, jobId: Guid | undefined, url = '') {
        if (jobId) {
            return Http.post(`${url}${apiVersion}/client/${clientId}/inbox/email/${emailId}/job/${jobId}/indexing`, {}, undefined, { useBasePath: !url });
        }
        return Http.post(`${url}${apiVersion}/client/${clientId}/inbox/email/${emailId}/indexing`, {}, undefined, { useBasePath: !url });
    }

    static assignEmail (clientId: Guid, emailId: string, jobId: Guid, attachmentRequest: unknown) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/email/${emailId}/job/${jobId}/assign`, attachmentRequest);
    }

    static getMessageAttachmentEncrypted (clientId: Guid, emailId: string, attachmentId: string) {
        return Http.get<boolean>(`${apiVersion}/client/${clientId}/inbox/email/${emailId}/attachment/${attachmentId}/isencrypted`);
    }

    static validateMessageAttachmentPassword (clientId: Guid, emailId: string, attachmentId: string, password: string) {
        return Http.post<boolean>(`${apiVersion}/client/${clientId}/inbox/email/${emailId}/attachment/${attachmentId}/validate`, { password });
    }

    static copyMessage (clientId: Guid, emailId: string) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/email/${emailId}/copy`, {});
    }

    static deleteMessage (clientId: Guid, emailId: Guid) {
        return Http.delete(`${apiVersion}/client/${clientId}/inbox/email/${emailId}`);
    }

    static getCalls (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox/call`);
    }

    static getCall (clientId: Guid, callId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox/call/${callId}`);
    }

    static assignCall (clientId: Guid, callId: Guid, jobId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/call/${callId}/job/${jobId}/assign`, {});
    }

    static returnCallToIndex (clientId: Guid, callId: Guid, jobId: Guid | undefined, url = '') {
        if (jobId) {
            return Http.post(`${url}${apiVersion}/client/${clientId}/inbox/call/${callId}/job/${jobId}/indexing`, {}, undefined, { useBasePath: !url });
        }
        return Http.post(`${url}${apiVersion}/client/${clientId}/inbox/call/${callId}/indexing`, {}, undefined, { useBasePath: !url });
    }

    static returnWebQueryToIndex (clientId: Guid, webQueryId: Guid, jobId: Guid | undefined, url = '') {
        if (jobId) {
            return Http.post(`${url}${apiVersion}/client/${clientId}/inbox/web/${webQueryId}/job/${jobId}/indexing`, {}, undefined, { useBasePath: !url });
        }
        return Http.post(`${url}${apiVersion}/client/${clientId}/inbox/web/${webQueryId}/indexing`, {}, undefined, { useBasePath: !url });
    }

    static getWebQueries (clientId: Guid, url = '') {
        return Http.get(`${url}${apiVersion}/client/${clientId}/web`, undefined, { useBasePath: !url });
    }

    static getWebQuery (clientId: Guid, webQueryId: Guid, url = '') {
        return Http.get(`${url}${apiVersion}/client/${clientId}/web/${webQueryId}`, undefined, { useBasePath: !url });
    }

    static deleteWebQuery (clientId: Guid, webQueryId: Guid, url = '') {
        return Http.delete(`${url}${apiVersion}/client/${clientId}/web/${webQueryId}`, {}, undefined, { useBasePath: !url });
    }

    static copyWebQuery (clientId: Guid, webQueryId: Guid, url = '') {
        return Http.post(`${url}${apiVersion}/client/${clientId}/web/${webQueryId}/copy`, {}, undefined, { useBasePath: !url });
    }

    static assignWebQuery (clientId: Guid, webQueryId: Guid, jobId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/web/${webQueryId}/job/${jobId}/assign`, {});
    }

    static assignScannedDocument (clientId: Guid, filename: string, jobId: Guid, data: unknown) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/scanned/${filename}/job/${jobId}/assign`, data);
    }

    static assignToTeam (filename: string) {
        return Http.post(`${apiVersion}/inbox/scanned/${filename}/assignteam`, {});
    }

    static unassignFromTeam (filename: string) {
        return Http.post(`${apiVersion}/inbox/scanned/${filename}/unassignteam`, {});
    }

    static getDocuments (clientId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/inbox/scanned`);
    }

    static getTeamDocuments (teamId: Guid, includeAssignedToTeam: boolean) {
        return Http.get(`${apiVersion}/team/${teamId}/scanned?includeAssignedToTeam=${includeAssignedToTeam}`);
    }

    static deleteScannedDocument (clientId: Guid, filename: string) {
        return Http.delete(`${apiVersion}/client/${clientId}/inbox/scanned/${filename}`, null, undefined, {
            showSuccessMessage: true, message: 'Document deleted',
        });
    }

    static copyScannedDocument (clientId: Guid, filename: string) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/scanned/${filename}/copy`,
            null,
            undefined,
            { showSuccessMessage: true, message: 'Document copied' });
    }

    static unassignScanClient (clientId: Guid, filename: string) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/scanned/${filename}/unassign`, null, undefined, {
            showSuccessMessage: true, message: 'Client unassigned',
        });
    }

    static assignScanClient (clientId: Guid, filename: string, assignClientId: Guid) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/scanned/${filename}/client/${assignClientId}/assign`, null, undefined, {
            showSuccessMessage: true, message: 'Client assigned',
        });
    }

    static deleteTeamScannedDocument (teamId: Guid, filename: string) {
        return Http.delete(`${apiVersion}/team/${teamId}/scanned/${filename}`, null, undefined, {
            showSuccessMessage: true, message: 'Document deleted',
        });
    }

    static copyTeamScannedDocument (teamId: Guid, filename: string) {
        return Http.post(`${apiVersion}/team/${teamId}/scanned/${filename}/copy`,
            null,
            undefined,
            { showSuccessMessage: true, message: 'Document copied' });
    }

    static unassignTeamScanClient (teamId: Guid, filename: string) {
        return Http.put(`${apiVersion}/team/${teamId}/scanned/${filename}/client/unassign`, null, undefined, {
            showSuccessMessage: true, message: 'Client unassigned',
        });
    }

    static assignTeamScanClient (teamId: Guid, filename: string, assignClientId: Guid) {
        return Http.put(`${apiVersion}/team/${teamId}/scanned/${filename}/client/${assignClientId}/assign`, null, undefined, {
            showSuccessMessage: true, message: 'Client assigned',
        });
    }

    static getDocumentCategories () {
        return Http.getAsArray(`${apiVersion}/documentCategory`, DocumentCatgeory);
    }

    static saveDocumentDetails (clientId: Guid, filename: string, documentDetails: unknown) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/scanned/${filename}`, documentDetails, undefined, {
            showSuccessMessage: true, message: 'Document details updated', successTitle: 'Success',
        });
    }

    static uploadDocument (clientId: Guid, jobId: Guid, request: unknown) {
        return Http.post(`${apiVersion}/job/${jobId}/document`, request, undefined, {
            showSuccessMessage: true,
            message: 'Document uploaded',
            useToken: true,
        });
    }

    static sendEmail (clientId: Guid, request: unknown) {
        return Http.post(`${apiVersion}/client/${clientId}/inbox/email`, request, undefined, {
            showSuccessMessage: true,
            message: 'Draft email created',
        });
    }

    static getGloballyUnassignedSummary () {
        return Http.get(`${apiVersion}/inbox/scanned/summary`);
    }
}
