
import { Component, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import MemberStatisticsOverview from '@/components/member-statistics-tile.vue';
import ClientCalendar from '@/components/client-calendar.vue';
import InprogressPie from '@/components/inprogress-pie.vue';
import WebRegistrationsGauge from '@/components/web-registrations-gauge.vue';
import CompletedJobsRag from '@/components/completed-jobs-rag.vue';
import ContactTile from '@/components/contact-tile.vue';
import FeedbackScore from '@/components/feedback-score.vue';
import DataScores from '@/components/data-scores.vue';
import BankBalance from '@/components/bank-balance.vue';
import DocumentSearch from '@/components/document-search.vue';
import ClientConfig from '@/models/client';

@Component({
    components: {
        MemberStatisticsOverview,
        InprogressPie,
        ClientCalendar,
        CompletedJobsRag,
        WebRegistrationsGauge,
        ContactTile,
        FeedbackScore,
        DataScores,
        BankBalance,
        DocumentSearch,
    },
})
export default class Home extends Vue {
    // yearlyFeedbackResult: Array<YearlyQuarterlyFeedback> = new Array<YearlyQuarterlyFeedback>();

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clientContext () {
        return this.$store.getters['clientContext/context'](this.clientId);
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfig ?? {};
    }

    get hasReportingRole () {
        return this.clientConfiguration?.hasReportingRole;
    }

    navigateTo (path: string, row: string) {
        this.$router.push({
            name: path,
            params: { row },
        });
    }

    // yearlyFeedbackResults (lastFourYearsFeedback: Array<YearlyQuarterlyFeedback>) {
    //     this.yearlyFeedbackResult = lastFourYearsFeedback;
    // }
}
