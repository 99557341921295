import Guid from 'common/util/guid';

export default class DocumentCatgeory {
    id!: Guid;

    name!: string;

    isEnabled!: boolean;

    isExternal!: boolean;

    isAvailableOnline!: boolean;

    isScanning!: boolean;

    isEmail!: boolean;

    isUser!: boolean;
}
