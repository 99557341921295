export default class MemberDocumentStats {
    fromDate!: Date;

    toDate!: Date;

    correspondenceCreatedTotal!: number;

    webFormsSubmitted!: Array<{ name: string; splitByStatus: Record<string, number>}>;

    webQueriesSubmittedTotal!: number;

    documentsViewed!: Array<{ name: string; splitByStatus: Record<string, number>}>;

    correspondenceViewedByStatus!: Record<string, number>;

    payslipDataByStatus!: Record<string, number>;
}
