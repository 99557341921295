
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import Guid from 'common/util/guid';
import Highcharts, { Point } from 'highcharts';
import WebRegistrations from '@/models/webRegistrations';

@Component
export default class InProgressJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        showLegend!: boolean;

    selectedPoint: string | null = null;

    selectedColourIndex: number | null = null;

    get webRegistrations (): WebRegistrations | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'webRegistrations');
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'webRegistrations');
    }

    get percentageRegistered () {
        if (!this.inprogressJobStatsTranformed || !this.webRegistrations) return '';
        return `${((this.inprogressJobStatsTranformed.registrations / this.inprogressJobStatsTranformed.total) * 100).toFixed(0)}%`;
    }

    get inprogressJobStatsTranformed () {
        if (!this.webRegistrations) {
            return {
                total: 0,
                registrations: 0,
                availableToRegister: 0,
            };
        }
        const totals: { registrations: number; availableToRegister: number } = {
            registrations: 0,
            availableToRegister: 0,
        };
        this.webRegistrations.dataByDate.forEach((date) => {
            totals.registrations = date.registrations;
            totals.availableToRegister = date.availableToRegister;
        });
        return {
            total: totals.registrations + totals.availableToRegister,
            registrations: totals.registrations,
            availableToRegister: totals.availableToRegister,
        };
    }

    pointClicked (point: Point) {
        this.selectedColourIndex = point.colorIndex;
        this.selectedPoint = point.name === this.selectedPoint ? null : point.name;
        this.$emit('pointSelected', this.selectedPoint);
    }

    clearFilter () {
        (this.$refs.chart as unknown as { chart: Highcharts.Chart }).chart.series[0].points.forEach((a) => a.select(false));
        this.selectedPoint = null;
        this.$emit('pointSelected', this.selectedPoint);
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'solidgauge',
                height: 500,
                backgroundColor: 'transparent',
            },
            xAxis: {
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
                min: 0,
                lineWidth: 0,
                tickPositions: [],
                max: this.inprogressJobStatsTranformed.total,
            },
            legend: {
                enabled: false,
                align: 'right',
                layout: 'vertical',
                verticalAlign: 'middle',
                floating: false,
                itemMarginBottom: 5,
                x: -45,
                navigation: {
                    enabled: false,
                },
                itemStyle: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '400',
                },
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{
                    outerRadius: '87%',
                    innerRadius: '63%',
                    backgroundColor: '#eee',
                    borderWidth: 0,
                }],
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false,
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                    showInLegend: true,
                },
            },
            credits: {
                enabled: false,
            },
            series: [{
                type: 'solidgauge',
                name: 'Web registrations',
                data: [
                    {
                        color: '#eee',
                        radius: '87%',
                        innerRadius: '63%',
                        y: this.inprogressJobStatsTranformed.total,
                        name: 'Members registered',
                    }],
                events: {
                    click: (event) => { this.pointClicked(event.point); },
                },
            }, {
                type: 'solidgauge',
                name: 'Registered',
                data: [
                    {
                        color: '#00a3c7',
                        radius: '87%',
                        innerRadius: '63%',
                        y: this.inprogressJobStatsTranformed.registrations,
                    },
                ],
            }],
        };
    }
}
