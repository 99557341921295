import { render, staticRenderFns } from "./document-views-by-status.vue?vue&type=template&id=6224d988&scoped=true&"
import script from "./document-views-by-status.vue?vue&type=script&lang=ts&"
export * from "./document-views-by-status.vue?vue&type=script&lang=ts&"
import style0 from "./document-views-by-status.vue?vue&type=style&index=0&id=6224d988&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6224d988",
  null
  
)

export default component.exports