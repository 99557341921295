import Guid from 'common/util/guid';
import InternalReferral from './internalReferral';
import JobStatus from './jobStatus';

export default class Job {
    clientId!: Guid;

    description!: string;

    jobDefinitionId!: Guid;

    clientJobDefinitionId?: Guid;

    jobDefinitionName!: string;

    jobId!: Guid;

    manualTargetDate!: Date;

    memberId?: Guid;

    reference!: string;

    startDate!: Date;

    completionDate?: Date;

    externalReferral?: string;

    referral?: InternalReferral;

    status!: JobStatus;

    targetDate!: Date;

    teamId!: Guid;

    userId?: Guid;

    whistleblowingEndDate?: Date;

    whistleblowingStartDate?: Date;

    ssqRequired!: boolean;

    billingType!: string;
}
