
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import JobDateStats from '@/models/jobDateStats';
import Guid from 'common/util/guid';
import Highcharts, { Point } from 'highcharts';
import ColourService from '@/services/colourService';
import ToggleSwitch from 'common/components/toggle-switch.vue';
import PieLegend from '@/services/pieLegendService';

@Component({ components: { ToggleSwitch } })
export default class InProgressJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        showLegend!: boolean;

    @Prop()
        printing!: boolean;

    @Prop()
        chartTitle!: string;

    @Prop()
        showTable!: string;

    selectedPoint: string | null = null;

    selectedColourIndex: number | null = null;

    viewByCase = false;

    chartCenterX = 0;

    chartCenterY = 0;

    get loading () {
        if (this.showLegend) return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'completedCases') || false as boolean;
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'dashboardCompletedCases') || false as boolean;
    }

    get completedJobStats (): JobDateStats {
        if (this.showLegend) return this.$store.getters['clientStatistics/stats'](this.clientId, 'completedCases');
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'dashboardCompletedCases');
    }

    get caseTypes () {
        const arr: Array<string> = [];
        if (!this.completedJobStats) return [];
        this.completedJobStats.dataByDate.forEach(((a) => {
            arr.push(...Object.keys(a.counts));
        }));
        return [...new Set(arr)];
    }

    get totalCases () {
        return this.completedJobStatsTransformedByCase?.reduce((a, b) => b.total + a, 0);
    }

    get totalWithin () {
        return this.completedJobStatsTransformedByCase?.reduce((a, b) => b.within + a, 0);
    }

    get totalBeyond () {
        return this.completedJobStatsTransformedByCase?.reduce((a, b) => b.beyond + a, 0);
    }

    get completedJobStatsTransformedByCase () {
        if (!this.completedJobStats) return null;
        const vals: Array<{ page: string; within: number; total: number; beyond: number; color: string }> = [];
        const data: { [key: string]: Array<number>} = {
            'Within target': [],
            'Beyond target': [],
            'Beyond statutory': [],
        };
        this.caseTypes.forEach((key, i) => {
            Object.values(this.completedJobStats.dataByDate).forEach((date) => {
                if (!date.countsBySla) return;
                if (!data['Within target'][i]) data['Within target'][i] = 0;
                if (!data['Beyond target'][i]) data['Beyond target'][i] = 0;
                if (!data['Beyond statutory'][i]) data['Beyond statutory'][i] = 0;
                Object.keys(date.countsBySla[key] || {}).forEach((type) => {
                    data[type][i] += (date.countsBySla![key][type]) || 0;
                });
            });
        });

        this.caseTypes.forEach((page, i) => {
            vals.push({
                page,
                color: ColourService.getSeriesColour('jobs-sla', page),
                within: data['Within target'][i],
                total: data['Within target'][i] + data['Beyond target'][i] + data['Beyond statutory'][i],
                beyond: data['Beyond statutory'][i],
            });
        });

        return vals;
    }

    get totalJobs () {
        if (!this.inprogressJobStatsTranformed || !this.inprogressJobStatsTranformed.length) return 0;
        const inTarget = this.inprogressJobStatsTranformed.find((a) => a.name === 'Within target')?.y || 0;
        const outOfTarget = this.inprogressJobStatsTranformed.find((a) => a.name === 'Beyond target')?.y || 0;
        const beyondStat = this.inprogressJobStatsTranformed.find((a) => a.name === 'Beyond statutory')?.y || 0;

        let calculatedResult = 0;

        if (this.selectedPoint === 'Beyond target') {
            calculatedResult = ((outOfTarget / (outOfTarget + inTarget + beyondStat)) * 100);
        } else if (this.selectedPoint === 'Beyond statutory') {
            calculatedResult = ((beyondStat / (outOfTarget + inTarget + beyondStat)) * 100);
        } else {
            calculatedResult = ((inTarget / (outOfTarget + inTarget + beyondStat)) * 100);
        }

        let formattedResult = 0;

        if (calculatedResult === 100) {
            formattedResult = 100;
        } else if (calculatedResult > 99) {
            formattedResult = 99;
        } else if (calculatedResult < 99) {
            formattedResult = Math.round(calculatedResult);
        }

        return `${formattedResult}%`;
    }

    get inprogressJobStatsTranformed () {
        if (!this.completedJobStats) return null;
        const totals: { [key: string]: number } = {
            'Within target': 0,
            'Beyond target': 0,
            'Beyond statutory': 0,
        };
        this.completedJobStats.dataByDate.forEach((date) => {
            Object.values(date.countsBySla || {}).forEach((jobType) => {
                Object.keys(jobType).forEach((key) => {
                    if (!totals[key]) totals[key] = 0;
                    totals[key] += Number(jobType[key] || 0);
                });
            });
        });
        return Object.keys(totals)
            .map((seriesName) => ({
                name: seriesName,
                y: totals[seriesName],
                color: ColourService.getSeriesColour('jobs-sla', seriesName),
            }));
    }

    pointClicked (point: Point) {
        this.selectedColourIndex = point.colorIndex;
        this.selectedPoint = point.name === this.selectedPoint ? null : point.name;
        this.$emit('pointSelected', { category: 'completedJobsSla', series: this.selectedPoint });
    }

    clearFilter () {
        (this.$refs.chart as unknown as { chart: Highcharts.Chart }).chart.series[0].points.forEach((a) => a.select(false));
        this.selectedPoint = null;
        this.$emit('pointSelected', { category: 'completedJobsSla', series: this.selectedPoint });
    }

    get centerPos () {
        if (window.innerWidth < 700 && this.showLegend) {
            return {
                top: `calc(${this.chartCenterY}px + 6.8rem) !important`,
                left: `calc(${this.chartCenterX}px + 1.5rem) !important`,
            };
        }
        return {
            top: `calc(${this.chartCenterY}px + 2.5rem) !important`,
            left: `calc(${this.chartCenterX}px + 1.5rem) !important`,
        };
    }

    get chartOptions (): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
                events: {
                    load () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                    redraw () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                },
            },
            xAxis: {
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: this.showLegend ? PieLegend.get().legend : {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    allowPointSelect: true,
                    showInLegend: true,
                    borderWidth: 0,
                    point: {
                        events: {
                            legendItemClick: (event) => {
                                event.preventDefault();
                                event.target.select(!event.target.selected);
                                this.pointClicked(event.target);
                            },
                        },
                    },
                },
            },
            series: [{
                type: 'pie',
                name: 'Completed cases',
                innerSize: '70%',
                slicedOffset: 5,
                data: this.inprogressJobStatsTranformed!,
                events: {
                    click: (event) => { this.pointClicked(event.point); },
                },
            }],
        };
    }
}
