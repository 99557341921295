
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import YearlyQuarterlyFeedback from 'common/models/yearlyQuarterlyFeedback';
import MemberFeedbackScores from '@/models/memberFeedbackScores';
import Guid from 'common/util/guid';
import MemberFeedback from 'common/models/memberFeedback';
import ClientConfiguration from '@/models/client';
import FeedbackQuarterlyScore from './feedback-quarterly-score.vue';

@Component({
    name: 'feedback-quartely-score',
    components: { FeedbackQuarterlyScore },
})
export default class FeedbackScore extends Vue {
    @Prop()
        printing!: true;

    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfiguration ?? {};
    }

    get isFeedbackForClient () {
        return this.clientConfiguration?.memberFeedbackType === 'Client';
    }

    get feedbackScores (): MemberFeedbackScores {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'feedback');
    }

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'feedback');
    }

    get previousFourFeedback (): Array<YearlyQuarterlyFeedback> {
        if (!this.feedbackScores?.memberFeedbackScores) return [];
        const distinctyears = this.findDistinctYears(this.feedbackScores.memberFeedbackScores);
        const feedback: Array<YearlyQuarterlyFeedback> = [];
        distinctyears.forEach((year) => {
            let filtered = this.feedbackScores.memberFeedbackScores.filter((item) => item.year === year);

            filtered = filtered.sort((a, b) => ((a.quarter < b.quarter) ? 1 : -1));
            const memberFeedbacksFiltered = new Array<MemberFeedback>();

            filtered.forEach((mf) => {
                if (memberFeedbacksFiltered.length < 4) {
                    memberFeedbacksFiltered.push(mf);

                    const yearlyFeedback = new YearlyQuarterlyFeedback();
                    yearlyFeedback.year = year;
                    yearlyFeedback.feedback = mf;

                    feedback.push(yearlyFeedback);
                }
            });
        });
        return feedback;
    }

    get hasScores () {
        return this.previousFourFeedback.length;
    }

    get currentServiceScore () {
        if (!this.previousFourFeedback.length) return 0;
        return this.previousFourFeedback[0].feedback.serviceScore;
    }

    get currentCommunicationScore () {
        if (!this.previousFourFeedback.length) return 0;
        return this.previousFourFeedback[0].feedback.communicationScore;
    }

    findDistinctYears (memberFeedback: Array<MemberFeedback>) {
        let distinctyears = [...new Map(memberFeedback.map((item) => [item.year, item.year])).values()];

        distinctyears = distinctyears.sort((a, b) => ((a < b) ? 1 : -1));

        return distinctyears;
    }

    get isGovernanceScreen () {
        return this.$route.name === 'SchemeGovernance';
    }
}

