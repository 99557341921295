
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import MemberDocumentStats from '@/models/memberDocumentStats';
import Highcharts from 'highcharts';

@Component
export default class PayslipViewsByStatus extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean;

    viewByFileType = false;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberDocumentsStats') || false as boolean;
    }

    get documentStats (): Array<MemberDocumentStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberDocumentsStats') as Array<MemberDocumentStats>;
    }

    get categories () {
        if (!this.documentStats) return [];
        const dataTypes: Array<string> = [];

        this.documentStats.forEach((date) => {
            Object.values(date.webFormsSubmitted).forEach((webForm) => {
                dataTypes.push(...Object.keys(webForm.splitByStatus));
            });
        });
        return [...new Set(dataTypes)];
    }

    get webFormNames () {
        if (!this.documentStats) return [];
        const dataTypes: Array<string> = [];

        this.documentStats.forEach((date) => {
            if (date.webFormsSubmitted.length > 0) {
                date.webFormsSubmitted.forEach((webForm) => {
                    dataTypes.push(webForm.name);
                });
            }
        });
        return [...new Set(dataTypes)];
    }

    get documentStatsTransformed () {
        if (!this.documentStats) return null;
        const vals: Array<{ name: string; data: number[]; type: 'column'; color: string }> = [];

        this.categories.forEach((status) => {
            const dataTypeData: Array<number> = [];

            if (this.viewByFileType) {
                this.webFormNames.forEach((webFormName) => {
                    const val = this.documentStats.reduce((a, b) => a + (b.webFormsSubmitted.find((c) => c.name === webFormName)?.splitByStatus[status] ?? 0), 0);
                    dataTypeData.push(val);
                });
            } else {
                this.documentStats!.forEach((row) => {
                    dataTypeData.push(Object.values(row.webFormsSubmitted)
                        .reduce((a, b) => a + (b.splitByStatus[status] ?? 0), 0));
                });
            }
            console.log(dataTypeData);
            if (!this.filter || this.filter === status) {
                vals.push({
                    name: status,
                    type: 'column',
                    data: dataTypeData,
                    color: ColourService.getSeriesColour('status', status),
                });
            }
        });

        return vals;
    }

    formatName (val: string) {
        switch (val) {
            case 'Change of address':
                return 'Holden section handbook';
            case 'Change of name':
                return 'Pensioner FAQs';
            case 'Pension statement':
                return 'Pensions News 2021';
            case 'Latest pension news':
                return 'Privacy notice';
            default:
                return val;
        }
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.viewByFileType ? this.webFormNames : this.documentStats?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => (this.viewByFileType ? this.formatName(this.formatDocument(that.value as string)) : dayjs(that.value).format('DD MMM'))),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.documentStatsTransformed || [],
        };
    }

    formatDocument (document: string) {
        const result = document.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
}
