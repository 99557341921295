export default class ColourService {
    static colours = [
        '#00a3c7',
        '#f27174',
        '#faa743',
        '#7b9fa5',
        '#b7d878',
        '#2bb673',
        '#7167ae',
        '#ea9fc6',
        '#e4205f',
        '#005c5f',
        '#61093f',
        '#002e5f',
        '#fcb769',
        '#f48c89',
        '#92b1b6',
        '#33587f',
        '#33b6d2',
        '#5ad4a7',
        '#03628b',
        '#387e23',
        '#a53c13',
        '#9a9c00',
        '#198ea0',
        '#46a050',
        '#b2693b',
        '#b2a945',
        '#4aae89'];

    static seriesColourIndex: { [ group: string ]: number } = {
        'jobs-sla': 3,
        demographics: 4,
    };

    static seriesColourMap: { [ group: string ]: { [ seriesName: string ]: string } } = {
        'jobs-sla': { 'Within target': '#6bbd6b', 'Beyond target': '#faa743', 'Beyond statutory': '#de5e5e' },
        demographics: {
            Active: '#00a3c7', Pensioner: '#f27174', Dependant: '#faa743', Deferred: '#7b9fa5',
        },
    };

    static getSeriesColour (group: string, seriesName: string) {
        if (!this.seriesColourMap[group]) this.seriesColourMap[group] = {};
        if (!this.seriesColourIndex[group]) this.seriesColourIndex[group] = 0;
        if (!this.seriesColourMap[group][seriesName]) {
            const colour = this.colours[this.seriesColourIndex[group]];
            this.seriesColourIndex[group] += 1;
            this.seriesColourMap[group][seriesName] = colour;
        }
        return this.seriesColourMap[group][seriesName];
    }
}
