import BatchJobSummary from 'common/models/batchJobSummary';
import Guid from 'common/util/guid';
import Http from './apiHelper';

const apiVersion = 'v1.0';
export default class BatchJobsApi {
    static getBatchJobs (search = '', pageNumber = 1, pageSize = 50) {
        return Http.getAsArray<BatchJobSummary>(`${apiVersion}/batchJob?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
            BatchJobSummary,
            { returnHeaders: true }) as Promise<(Array<BatchJobSummary> & {headers: { 'x-total-count': number } })>;
    }

    static getClientBatchJobs (clientId: Guid, search = '', pageNumber = 1, pageSize = 50, pulseApiUrl: string) {
        const url = pulseApiUrl
            ? `${pulseApiUrl}${apiVersion}/batchJob/client/${clientId}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`
            : `${apiVersion}/batchJob/client/${clientId}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

        return Http.getAsArray<BatchJobSummary>(url,
            BatchJobSummary,
            { useBasePath: !pulseApiUrl, returnHeaders: true }) as Promise<(Array<BatchJobSummary> & {headers: { 'x-total-count': number } })>;
    }

    static getUserBatchJobs (search = '', pageNumber = 1, pageSize = 50, pulseApiUrl: string) {
        return Http.getAsArray<BatchJobSummary>(`${pulseApiUrl}${apiVersion}/user/batchjob?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
            BatchJobSummary,
            { useBasePath: !pulseApiUrl, returnHeaders: true }) as Promise<(Array<BatchJobSummary> & {headers: { 'x-total-count': number } })>;
    }
}
