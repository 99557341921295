
import Client from 'common/models/client';
import GovernanceInformation from '@/models/governanceInformation';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class DataScores extends Vue {
    @Prop()
        clientId!: Guid;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'governanceInformation') || false as boolean;
    }

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    get clientName () {
        if (this.$route.path.indexOf('/demo') > -1) return 'Demo Scheme';
        if (this.clientId) {
            const name = this.clients.find((a) => a.clientId === this.clientId)?.name;
            if (name === 'Generali Pension Fund') return 'Assicurazioni Generali SpA (UK Branch) Pension Fund';
            if (name === 'Main Fund ICI') return 'ICI Pension Fund';
            if (name === 'BSI') return 'BSI Retirement Benefits Plan';
            return name;
        }
        return '';
    }

    get governanceInformation (): GovernanceInformation | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'governanceInformation');
    }

    get dataDate () {
        return dayjs(this.governanceInformation?.dataScores.date).format('DD MMM YYYY');
    }

    get title () {
        return this.clientName === 'ICI Pension Fund' ? 'Data scores for small sections' : 'Data scores';
    }

    @Watch('governanceInformation', { immediate: true })
    governanceInformationChanged () {
        for (let i = 0; i < this.commonData.length; i += 1) {
            const rank = this.commonData[i];
            if (rank.value <= (this.governanceInformation?.dataScores?.commonScore || 0) || i === this.commonData.length - 1) {
                Vue.set(rank, 'combined', this.governanceInformation?.dataScores?.commonScore!);
                break;
            }
        }
        for (let i = 0; i < this.schemeData.length; i += 1) {
            const rank = this.schemeData[i];
            if (rank.value <= (this.governanceInformation?.dataScores?.specificScore || 0) || i === this.schemeData.length - 1) {
                Vue.set(rank, 'combined', this.governanceInformation?.dataScores?.specificScore!);
                break;
            }
        }
    }

    commonData: Array<{ value: number; rank: string; pre?: number; combined?: number; post?: number; name: string }> = [
        { value: 95, rank: 'A', name: '> 95%' },
        { value: 90, rank: 'B', name: '90 - 95%' },
        { value: 85, rank: 'C', name: '85 - 90%' },
        { value: 80, rank: 'D', name: '80 - 85%' },
        { value: 75, rank: 'E', name: '75 - 80%' },
        { value: 70, rank: 'F', name: '70 - 75%' },
        { value: 60, rank: 'G', name: '< 70%' },
    ];

    schemeData: Array<{ value: number; rank: string; pre?: number; combined?: number; post?: number; name: string }> = [
        { value: 95, rank: 'A', name: '> 95%' },
        { value: 90, rank: 'B', name: '90 - 95%' },
        { value: 85, rank: 'C', name: '85 - 90%' },
        { value: 80, rank: 'D', name: '80 - 85%' },
        { value: 75, rank: 'E', name: '75 - 80%' },
        { value: 70, rank: 'F', name: '70 - 75%' },
        { value: 60, rank: 'G', name: '< 70%' },
    ];
}
