import Guid from 'common/util/guid';

export default class MemberValidationAuditHistory {
    id!: Guid;

    memberDataTypeName = '';

    memberDataTypeDescription = '';

    ruleName!:string;

    status!:string;

    created!: Date

    createdBy!: Guid;

    lastActionedBy!: Guid;

    lastActionedOn!: Date;
}
