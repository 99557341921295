import AuthenticationService from 'common/util/auth/authenticationService';
import store from '@/store';
import SiteConfiguration from 'common/models/configuration';
import ExternalAuthenticationService from 'common/util/auth/externalAuthenticationService';
import Config from '@/config';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';
import * as Msal from '~/msal';
import { Configuration } from '~/@azure/msal-browser';

let authService: AuthenticationService | ExternalAuthenticationService;

interface AuthState {
    loggedIn: boolean;
    userInfo: unknown;
    token: string;
}

export const state: AuthState = {
    loggedIn: false,
    userInfo: null,
    token: '',
};

export const getters: GetterTree<AuthState, unknown> = {
    loggedIn: (s: AuthState) => s.loggedIn,
    token: (s: AuthState) => s.token,
    userInfo: (s: AuthState) => s.userInfo,
};

export const actions: ActionTree<AuthState, unknown> = {
    async initialise () {
        if (!authService) {
            const result = await store.dispatch('configuration/getConfiguration') as SiteConfiguration;
            const config: Msal.Configuration = {
                auth: {
                    clientId: result['oauth.client.appid'],
                    authority: result['oauth.authority'],
                    validateAuthority: false,
                    knownAuthorities: [`https://${result['oauth.authority']?.replace('https://', '').split('/')[0]}`],
                    redirectUri: window.location.origin,
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            };
            const scopes = {
                scopes: [
                    'openid',
                    result['oauth.client.scope'],
                ],
            };
            authService = Config.AuthService ? new Config.AuthService(config as Configuration, scopes) : new AuthenticationService(config, scopes);
        }
        return authService;
    },
    async login ({ dispatch }) {
        if (!authService) await dispatch('initialise');
        console.log(authService);
        return authService.login();
    },
    async logout ({ dispatch }) {
        if (!authService) await dispatch('initialise');
        return authService.logout();
    },
    async getToken ({ commit, dispatch }) {
        if (!authService) await dispatch('initialise');
        const token = await (authService.getToken() as Promise<Msal.AuthResponse>).catch((err) => {
            dispatch('messages/addError', err, { root: true });
        });
        if (token) {
            commit('setLoggedIn', token.accessToken);
            commit('setUser', token.account?.idTokenClaims || token.idTokenClaims);
            return token;
        }
        return null;
    },

};

export const mutations: MutationTree<AuthState> = {
    setLoggedIn (s, token) {
        s.loggedIn = !!token;
    },
    setUser (s, userInfo) {
        s.userInfo = userInfo;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
