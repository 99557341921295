import Guid from 'common/util/guid';

export default class UserRole {
    roleId!: Guid;

    roleName!: string;

    userId!: Guid;

    isGlobal!: boolean;

    isDefault!: boolean;

    teamId?: Guid;

    teamName?: string;
}
