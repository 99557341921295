import { render, staticRenderFns } from "./document-edit.vue?vue&type=template&id=3cef4711&scoped=true&"
import script from "./document-edit.vue?vue&type=script&lang=ts&"
export * from "./document-edit.vue?vue&type=script&lang=ts&"
import style0 from "./document-edit.vue?vue&type=style&index=0&id=3cef4711&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../ClientWeb/FrontEnd/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cef4711",
  null
  
)

export default component.exports