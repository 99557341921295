
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import DataUpdateStats from '@/models/dataUpdateStats';
import Highcharts from 'highcharts';

@Component
export default class DataUpdatesByAgeChart extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    ageBands = [
        { min: 101, max: 200, label: '> 100' },
        { min: 91, max: 100 },
        { min: 81, max: 90 },
        { min: 71, max: 80 },
        { min: 61, max: 70 },
        { min: 51, max: 60 },
        { min: 41, max: 50 },
        { min: 31, max: 40 },
        { min: 19, max: 30 },
        { min: 0, max: 18, label: '< 19' },
    ];

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'dataUpdates') || false as boolean;
    }

    get dataUpdates (): Array<DataUpdateStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'dataUpdates');
    }

    get dataUpdatesTransformed () {
        if (!this.dataUpdates) return null;
        const vals: Array<{ name: string; data: Array<number | null>; type: 'column'; color: string }> = [];

        this.ageBands.forEach((ageBand) => {
            const ageBandData: Array<number | null> = [];
            this.dataUpdates!.forEach((row) => {
                let total = 0;
                Object.keys(row.dataByAge).forEach((age) => {
                    if (Number(age) >= ageBand.min && Number(age) <= ageBand.max) {
                        total += row.dataByAge[age];
                    }
                });
                ageBandData.push(total || null);
            });
            if (!ageBandData.every((a) => a === null) && (!this.filter || this.filter === (ageBand.label ?? `${ageBand.min} - ${ageBand.max}`))) {
                vals.push({
                    name: ageBand.label ?? `${ageBand.min} - ${ageBand.max}`,
                    type: 'column',
                    data: ageBandData,
                    color: ColourService.getSeriesColour('age', ageBand.label ?? `${ageBand.min} - ${ageBand.max}`),
                });
            }
        });

        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.dataUpdates?.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.dataUpdatesTransformed || [],
        };
    }
}
