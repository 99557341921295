import Guid from 'common/util/guid';

export default class MemberVerificationSummary {
    id!: Guid;

    type!: string;

    status!: 'Verified' | 'Referred' | 'Invalid';

    reference!: string;

    created!: Date;

    createdBy!: Guid;

    invalidatedFields!: Array<string>;
}
