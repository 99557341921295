import Guid from 'common/util/guid';

export default class TeamSummary {
    teamId!: Guid;

    name!: string

    isReferral!: boolean;

    isIndexing!: boolean;

    microsoftTeamName?: string;

    type!: Number;
}
