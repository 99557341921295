export default class EmailAttachment {
    contentType!: string;

    id!: string;

    contentId?: string;

    isInline!: boolean;

    name!: string;

    size!: number;

    oDataType!: string;

    contentBytes!: Uint16Array[];
}
