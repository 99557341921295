import Guid from 'common/util/guid';

export default class BatchJobSummary {
    batchJobId!: Guid;

    application!: {
        applicationId: Guid;
        name: string;
        code: string;
    }

    status!: string;

    clientCode!: string;

    name!: string;

    type!: string;

    data!: string;

    error!: string;

    exception!: string;

    updated!: string;

    created!: string;

    createdBy!: Guid;

    scheduledTime!:Date | null;
}
