
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import JobDateStats from '@/models/jobDateStats';
import ColourService from '@/services/colourService';
import Highcharts, { SeriesOptionsType } from 'highcharts';

@Component
export default class CompletedJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'completedCases') || false as boolean;
    }

    get completedJobStats (): JobDateStats {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'completedCases');
    }

    get averagePerDay () {
        const vals = this.completedJobStats?.dataByDate
            .map((a) => Object.keys(a.counts)
                .filter((b) => !this.filter || b === this.filter)
                .map((b) => a.counts[b])
                .reduce((sum, b) => sum + b, 0)) || [];
        if (!vals.length) return 0;
        const total = vals.reduce((sum, a) => sum + a, 0);
        return Math.round(((total / Object.keys(this.completedJobStats!.dataByDate).length) * 10)) / 10;
    }

    get completedJobStatsTranformed () {
        if (!this.completedJobStats) return null;
        const types = new Set<string>(this.completedJobStats.dataByDate.map((a) => Object.keys(a.counts)).flat(1));
        const vals: Array<SeriesOptionsType> = [];
        vals.push({
            name: 'Average',
            color: '#999',
            data: this.completedJobStats!.dataByDate.map(() => this.averagePerDay),
            type: 'line',
            dashStyle: 'Dash',
            allowPointSelect: false,
            marker: {
                enabled: false,
            },
            opacity: 0.5,
        });
        [...types].sort((a, b) => (a > b ? 1 : -1)).forEach((type) => {
            if (this.filter && this.filter !== type) return;
            vals.push({
                name: type,
                color: ColourService.getSeriesColour('jobs', type),
                type: 'column',
                data: this.completedJobStats!.dataByDate.map((a) => a.counts[type] || 0),
            });
        });
        return vals;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.completedJobStats?.dataByDate.map((a) => dayjs(a.startDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.completedJobStatsTranformed || [],
        };
    }
}
