
import { Component, Vue } from 'vue-property-decorator';
import Guid from 'common/util/guid';
import MemberSearch from '@/components/member-search.vue';

@Component({
    components: {
        MemberSearch,
    },
})
export default class MemberSearchView extends Vue {
    get clientId () {
        return this.$store.getters['clientContext/selectedClient'] as Guid;
    }

    get clientContext () {
        return this.$store.getters['clientContext/context'](this.clientId);
    }

    navigateTo (path: string, row: string) {
        this.$router.push({
            name: path,
            params: { row },
        });
    }
}
