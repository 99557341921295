import { ClassComponent } from '~/@lcp/dynamic-ui-typescript';

export default class ManualCalculationDefinition {
    Id!: string;

    Name!: string;

    ArgumentsDui!: ClassComponent;

    ArgumentsDuiValues!: unknown;

    ResultsDui!: ClassComponent;

    WarningsDui!: ClassComponent;

    Arguments?: Record<string, unknown>;

    Warnings?: Record<string, unknown>;

    CalculationResults?: Record<string, unknown>;

    DetailsDui!: ClassComponent;

    Details!: Record<string, unknown>;
}
