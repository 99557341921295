
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import JobDateStats from '@/models/jobDateStats';
import Highcharts from 'highcharts';

@Component
export default class CompletedJobs extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    get fromDate () {
        return this.$store.getters['clientContext/minDate'](this.clientId) as string;
    }

    get toDate () {
        return dayjs(new Date()).format('YYYY-MM-DD');
    }

    navigateTo () {
        this.$router.push({
            name: 'MemberActivity',
            params: { row: 'completed-jobs' },
        });
    }

    get loading (): boolean {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'dashboardCompletedCases');
    }

    get completedJobStats (): JobDateStats | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'dashboardCompletedCases');
    }

    get averageCompletionTime () {
        return Math.floor((this.completedJobStats?.averageCompletionTime || 0) * 10) / 10;
    }

    get completedJobStatsTranformed (): Array<{ type: 'line'; data: Array<number>; name: string}> {
        if (!this.completedJobStats?.dataByDate) {
            return [{
                name: 'Cases',
                data: [],
                type: 'line',

            }];
        }
        return [
            {
                data: this.completedJobStats.dataByDate.map((date) => Object.values(date.counts).reduce((a, b) => a + b, 0)),
                type: 'line',
                name: 'Cases',
            },
        ];
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'areaspline',

            },
            xAxis: {
                categories: this.completedJobStats?.dataByDate.map((a) => dayjs(a.startDate).format('DD MMM YYYY')),
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
                min: 0,
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                line: {
                    color: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1,
                        },
                        stops: [
                            [0.1, '#f27174'],
                            [0.5, '#faa743'],
                            [1, '#b7d878'],
                        ],
                    },
                    marker: {
                        enabled: false,
                        fillColor: '#fff',
                        radius: 2,
                    },
                    lineWidth: 4,

                    states: {
                        hover: {
                            lineWidth: 2,
                        },
                    },
                    threshold: null,
                },
                area: {
                    opacity: 0.2,
                    color: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1,
                        },
                        stops: [
                            [0.1, '#f27174'],
                            [0.5, '#faa743'],
                            [1, 'rgba(107, 189, 107, 1)'],
                        ],
                    },
                    marker: {
                        enabled: false,
                    },
                    lineWidth: 5,

                    states: {
                        hover: {
                            lineWidth: 6,
                        },
                    },
                    threshold: null,
                },
            },
            series: this.completedJobStatsTranformed,
        };
    }
}
