import clientsApi from '@/api/clientApi';
import ClientContext from '@/models/clientContext';
import Guid from 'common/util/guid';
import Vue from 'vue';
import dayjs from 'dayjs';
import ClientConfiguration from '@/models/client';
import { ActionTree, GetterTree, MutationTree } from '~/vuex';
import MemberSearchOptions from '@/models/memberSearchOptions';
import MemberSearchResult from '@/models/memberSearchResult';

interface ClientContextState {
    context: { [ clientId: string ]: ClientContext };
    fromDate: { [ clientId: string ]: Date };
    toDate: { [ clientId: string ]: Date };
    selectedClient: Guid | null;
    clients: Array<{ clientId: Guid; name: string }> | null;
    viewMonthly: { [clientId: string ]: boolean};
    loadingClients: boolean;
    selectedClientConfiguration: ClientConfiguration | null;
    memberSearchOptions: MemberSearchOptions;
    memberSearchResults: Array<MemberSearchResult>;
}

export const state: ClientContextState = {
    context: {},
    fromDate: {},
    toDate: {},
    selectedClient: null,
    clients: null,
    viewMonthly: {},
    loadingClients: false,
    selectedClientConfiguration: null,
    memberSearchOptions: new MemberSearchOptions(),
    memberSearchResults: [],
};

export const getters: GetterTree<ClientContextState, unknown> = {
    context: (s) => (clientId: Guid) => (clientId ? s.context[clientId.toString()] : null),
    fromDate: (s) => (clientId: Guid) => (clientId ? s.fromDate[clientId.toString()] : null),
    toDate: (s) => (clientId: Guid) => (clientId ? s.toDate[clientId.toString()] : null),
    selectedClient: (s) => s.selectedClient,
    clients: (s) => s.clients,
    minDate: (s) => (clientId: Guid) => (clientId ? s.context[clientId.toString()].minDate : null),
    viewMonthly: (s) => (clientId: Guid) => (clientId ? s.viewMonthly[clientId.toString()] : null),
    loadingClients: (s) => s.loadingClients,
    selectedClientConfiguration: (s) => (clientId: Guid) => (clientId ? s.selectedClientConfiguration : null),
    memberSearchOptions: (s) => s.memberSearchOptions,
    memberSearchResults: (s) => s.memberSearchResults,
};

export const actions: ActionTree<ClientContextState, unknown> = {
    async getContext ({ commit }, clientId: Guid) {
        const context = await clientsApi.getClientContext(clientId);
        commit('setContext', { clientId, context });
        commit('setFromDate', { clientId, date: dayjs(context.defaultStartDate).format('YYYY-MM-DD') });
        commit('setToDate', { clientId, date: dayjs(context.defaultEndDate).format('YYYY-MM-DD') });
        return context;
    },
    async getClients ({ commit }) {
        commit('setLoadingClients', true);
        const clients = await clientsApi.getClients();
        commit('setClients', clients);
        commit('setLoadingClients', false);
        return clients;
    },
    async getClientConfiguration ({ commit }, clientId: Guid) {
        const config = await clientsApi.getClient(clientId);
        commit('setSelectedClientConfiguration', config);
        return config;
    },
};

export const mutations: MutationTree<ClientContextState> = {
    setContext (s, { clientId, context }) {
        Vue.set(s.context, clientId, context);
    },
    setFromDate (s, { clientId, date }) {
        Vue.set(s.fromDate, clientId, date);
    },
    setToDate (s, { clientId, date }) {
        Vue.set(s.toDate, clientId, date);
    },
    setViewMonthly (s, { clientId, viewMonthly }) {
        Vue.set(s.viewMonthly, clientId, viewMonthly);
    },
    setSelectedClient (s, guid: Guid | null) {
        if (s.selectedClient !== guid) {
            s.memberSearchOptions = new MemberSearchOptions();
            s.memberSearchResults = [];
        }
        s.selectedClient = guid;
    },
    setClients (s, clients) {
        s.clients = clients;
    },
    setLoadingClients (s, loading: boolean) {
        s.loadingClients = loading;
    },
    setSelectedClientConfiguration (s, configuration: ClientConfiguration | null) {
        s.selectedClientConfiguration = configuration;
    },
    setSearchParams (s, { options }: { clientId: string, options: MemberSearchOptions }) {
        s.memberSearchOptions = options;
    },
    setSearchResults (s, { results }: { clientId: string, results: Array<MemberSearchResult> }) {
        s.memberSearchResults = results;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
