export default class SiteConfiguration {
    'oauth.client.appid': string;

    'oauth.authority': string;

    'oauth.client.scope': string;

    'appinsights.key': string;

    'environment.name': string;

    'environment.code': string;

    'memberdata.url': string;

    'oauth.tenentid': string;

    'pulseadmin.url': string;

    'user.blob.url': string;

    'workmanagement.url': string;
}
