
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';

@Component
export default class MemberLoginsChart extends Vue {
    @Prop()
        clientId!: Guid;

    viewOverPeriod = false;
}
