export default class ModellerStats {
    fromDate!: Date;

    toDate!: Date;

    totalVisits!: number;

    visitTime!: number;

    ageGroupSections!: Array<{
        sectionName: string;
        timesByAge: Record<string, number>;
        viewsByAge: Record<string, number>;
        visitsByAge: Record<string, number>;
    }>;

    ageGroupInteractions!: Array<{
        interactionName: string;
        dataByAge: Record<string, number>;
    }>;

    visitsByAge!: Record<string, number>;
}
