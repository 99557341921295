import Http from 'common/api/apiHelper';
import User from 'common/models/user';
import JobSummary from 'common/models/jobSummary';
import Guid from 'common/util/guid';
import JobHistoryEntry from 'common/models/jobHistoryEntry';
import Job from 'common/models/job';
import MemberSummary from 'common/models/memberSummary';
import JobBilling from 'common/models/jobBilling';
import CorrespondenceWeb2Query from 'common/models/correspondence/webQuery';

const apiVersion = 'v1.0';

export default {
    getJobs (clientId: Guid, type = 'All', jobType = '', jobUrl = '', scope = '') {
        return Http.getAsArray(`${jobUrl}${apiVersion}/client/${clientId}/job?type=${type}&scope=${scope}&jobType=${jobType}`, JobSummary, { useBasePath: !jobUrl });
    },
    getJobsByTeam (teamId: Guid, type = 'All', jobType = '') {
        return Http.getAsArray(`${apiVersion}/team/${teamId}/job?type=${type}&jobType=${jobType}`, JobSummary);
    },
    getJobsByMemberId (clientId: Guid, memberId: Guid, type = 'All', jobUrl = '') {
        return Http.getAsArray(`${jobUrl}${apiVersion}/client/${clientId}/member/${memberId}/job?type=${type}`, JobSummary, { useBasePath: !jobUrl });
    },
    getActiveJobsByMemberId (clientId: Guid, memberId: Guid, type = 'Active') {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/member/${memberId}/job?type=${type}`, JobSummary);
    },
    getMember (clientId: Guid, memberId: Guid) {
        return Http.get(`${apiVersion}/client/${clientId}/member/${memberId}`, MemberSummary);
    },
    getJobsByDefinitionId (clientId: Guid, jobDefinitionId: Guid, type = 'All') {
        return Http.getAsArray(`${apiVersion}/client/${clientId}/jobdefinition/${jobDefinitionId}/job?type=${type}`, JobSummary);
    },
    getUserJobs () {
        return Http.getAsArray(`${apiVersion}/user/job`, JobSummary);
    },
    getJobFollowUpPaneDetails (clientId: string = '', teamId: string = '') {
        return Http.get(`${apiVersion}/job/getFollowUpJobCountQuery?clientId=${clientId}&teamId=${teamId}`);
    },
    ceaseJob (jobId: Guid) {
        return Http.put(`${apiVersion}/job/${jobId}/cease`, null, undefined, { showSuccessMessage: true, message: 'Job ceased' });
    },
    reactivateJob (jobId: Guid) {
        return Http.put(`${apiVersion}/job/${jobId}/reactivate`, null, undefined, { showSuccessMessage: true, message: 'Job restored' });
    },
    completeJob (jobId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/job/${jobId}/complete`, data, undefined, { showSuccessMessage: true, message: 'Job completed' });
    },
    deleteJob (jobId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/job/${jobId}/delete`, data, undefined, { showSuccessMessage: true, message: 'Job deleted' });
    },
    getActiveJobs (clientId: Guid) {
        return this.getJobs(clientId, 'Active');
    },
    getUpcomingJobs (clientId: Guid) {
        return this.getJobs(clientId, 'Upcoming');
    },
    getJob (jobRef: string, jobUrl = '') {
        return Http.get(`${jobUrl}${apiVersion}/job/${jobRef}`, Job, { useBasePath: !jobUrl });
    },
    getRecent () {
        return Http.get(`${apiVersion}/user/job/recent`);
    },
    referJobInternally (jobId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/job/${jobId}/refer/internal`, data);
    },
    startJob (clientId: Guid, job: unknown) {
        return Http.post(`${apiVersion}/client/${clientId}/job/`, job);
    },
    startScheduledJob (clientId: Guid, job: unknown) {
        return Http.post(`${apiVersion}/client/${clientId}/jobSchedule/`, job);
    },
    assignJob (jobId: Guid, userId: Guid) {
        return Http.put(`${apiVersion}/job/${jobId}/assign`, { userId });
    },
    assignMultipleUsersToJob (data: { userId: Guid; jobIds: Array<Guid> }) {
        return Http.put(`${apiVersion}/job/assign`, data);
    },
    getJobBilling (jobId: Guid) {
        return Http.get(`${apiVersion}/job/${jobId}/billing`, JobBilling);
    },
    changeBilling (jobId: Guid, type: string, comment?: string) {
        return Http.put(`${apiVersion}/job/${jobId}/billing`, { type, comment });
    },
    changeBillingType (jobId: Guid, billingType: string) {
        return Http.put(`${apiVersion}/job/${jobId}/billingType`, { billingType });
    },
    getBillingTypes () {
        return Http.get(`${apiVersion}/job/billingTypes`);
    },
    startCorrespondenceJob (clientId: Guid, data: { memberId?: Guid; webRequestId?: string }) {
        return Http.post(`${apiVersion}/client/${clientId}/correspondenceJob/`, data);
    },
    unassignJob (jobId: Guid) {
        return Http.put(`${apiVersion}/job/${jobId}/unassign`, {});
    },
    assignReferral (jobId: Guid, userId: Guid) {
        return Http.put(`${apiVersion}/job/${jobId}/refer/internal/assign`, { userId });
    },
    unassignReferral (jobId: Guid) {
        return Http.put(`${apiVersion}/job/${jobId}/refer/internal/unassign`, {});
    },
    completeInternalReferral (jobId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/job/${jobId}/refer/internal/complete`, data);
    },
    cancelInternalReferral (jobId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/job/${jobId}/refer/internal/cancel`, data);
    },
    getExternalContacts () {
        return Http.get(`${apiVersion}/list/externalcontacts`);
    },
    referJobExternally (jobId: Guid, data: unknown) {
        return Http.put(`${apiVersion}/job/${jobId}/refer/external`, data);
    },
    cancelExternalReferral (jobId: Guid) {
        return Http.delete(`${apiVersion}/job/${jobId}/refer/external`);
    },
    completeExternalReferral (jobId: Guid) {
        return Http.put(`${apiVersion}/job/${jobId}/refer/external/end`, {});
    },
    getJobHistory (jobId: Guid) {
        return Http.getAsArray<JobHistoryEntry>(`${apiVersion}/job/${jobId}/history`);
    },
    getTargetDateHistory (jobId: Guid) {
        return Http.get(`${apiVersion}/job/${jobId}/targetDate`);
    },
    getTargetDate (jobId: Guid, startDate: Date) {
        return Http.post<Date>(`${apiVersion}/job/${jobId}/targetDate`, { startDate });
    },
    searchJobs (clientId: Guid, search: { [key: string]: string}) {
        let queryString = '?';
        Object.keys(search).forEach((s) => {
            if (search[s] !== undefined && search[s] !== null && search[s].toString().length) {
                queryString += `${s}=${search[s]}&`;
            } else {
                queryString += `${''}=${search[s]}&`;
            }
        });
        queryString = queryString.substring(0, queryString.length - 1);
        return Http.getAsArray(`${apiVersion}/client/${clientId}/job/search${queryString}`, User, { returnHeaders: true });
    },
    updateJobDates (jobId: Guid, dates: unknown) {
        return Http.put(`${apiVersion}/job/${jobId}/dates`, dates, undefined, { showSuccessMessage: true, message: 'Dates updated' });
    },
    updateJobDescription (jobId: Guid, description: { descripton: string }) {
        return Http.put(`${apiVersion}/job/${jobId}`, description, undefined, { showSuccessMessage: true, message: 'Description updated' });
    },
    updateSsqRequired (jobId: Guid, isRequired: boolean) {
        return Http.put(`${apiVersion}/job/${jobId}/ssq`, { ssqRequired: isRequired }, undefined, { showSuccessMessage: true, message: 'Ssq required updated' });
    },
    getJobDefinition (jobId: Guid) {
        return Http.get(`${apiVersion}/job/${jobId}/definition`);
    },
    assignJobToPhoneCall (clientId: Guid, callId: Guid, jobId: Guid, url?: string, reassigned = false) {
        return Http.post(`${url}${apiVersion}/client/${clientId}/inbox/call/${callId}/job/${jobId}/assign`, null, undefined, {
            useBasePath: !url,
            showSuccessMessage: true,
            showPopup: true,
            successTitle: `Call ${reassigned ? 'reassigned' : 'assigned'}`,
            message: `Call ${reassigned ? 'reassigned' : 'assigned'} to job`,
        });
    },
    unassignJobToPhoneCall (callId: Guid, jobId: Guid, url?: string) {
        return Http.post(`${url}${apiVersion}/job/${jobId}/call/${callId}/unassign`, null, undefined, {
            useBasePath: !url,
            showSuccessMessage: true,
            showPopup: true,
            successTitle: 'Call unassigned',
            message: 'Call unassigned from job',
        });
    },
    unAssignJobFromWebQuery (jobId: Guid, webQueryId: Guid, url?: string) {
        return Http.post<CorrespondenceWeb2Query>(`${url}${apiVersion}/job/${jobId}/web/${webQueryId}/unassign`, null, undefined, {
            useBasePath: !url,
            showSuccessMessage: true,
            showPopup: true,
            successTitle: 'Web query unassigned',
            message: 'Web query unassigned from job',
        });
    },
    assignJobToWebQuery (clientId: Guid, webQueryId: Guid, jobId: Guid, url?: string, reassigned = false) {
        return Http.post<CorrespondenceWeb2Query>(`${url}${apiVersion}/client/${clientId}/inbox/web/${webQueryId}/job/${jobId}/assign`, null, undefined, {
            useBasePath: !url,
            showSuccessMessage: true,
            successTitle: `Web query ${reassigned ? 'reassigned' : 'assigned'}`,
            showPopup: true,
            message: `Web query ${reassigned ? 'reassigned' : 'assigned'} to job`,
        });
    },
    generateAICorrespondence (clientId: Guid,jobId: Guid, data: string, url = '') {
        return Http.post<string>(`${url}${apiVersion}/client/${clientId}/correspondence/generateCorrespondence/job/${jobId}`, data, undefined, { useBasePath: !url });
    },
};
