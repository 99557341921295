
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import dayjs from 'dayjs';
import ColourService from '@/services/colourService';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import FinancialData from '@/models/financialData';

@Component
export default class Transfers extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        filter!: string | null;

    @Prop()
        printing!: boolean | null;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'financials') || false as boolean;
    }

    get transferStats (): FinancialData {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'financials');
    }

    get transferStatsTranformed () {
        if (!this.transferStats) return null;

        return [{
            name: 'Bank balance',
            color: ColourService.getSeriesColour('balance', 'balance'),
            type: 'line',
            data: this.transferStats.periods.map((a) => a.endBalance),
        }] as Array<SeriesOptionsType>;
    }

    get chartOptions (): Highcharts.Options {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'line',
            },
            xAxis: {
                categories: this.transferStats?.periods.map((a) => dayjs(a.fromDate).format('DD MMM YYYY')),
                labels: {
                    formatter: (that) => dayjs(that.value).format('DD MMM'),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            tooltip: {
                pointFormat: '{series.name}: <b>£{point.y}</b><br/>',
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
                labels: {
                    formatter () {
                        const label = this.value;
                        return `£${label.toLocaleString('en')}`;
                    },
                },

            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '0.9rem',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '400',
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: this.transferStatsTranformed || [],
        };
    }
}
