
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import Guid from 'common/util/guid';
import Highcharts, { Point } from 'highcharts';
import ColourService from '@/services/colourService';
import ToggleSwitch from 'common/components/toggle-switch.vue';
import PieLegend from '@/services/pieLegendService';
import ModellerStats from '@/models/modellerStats';

@Component({ components: { ToggleSwitch } })
export default class ModellerInteractionsByAgePie extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        showLegend!: boolean;

    @Prop()
        printing!: boolean;

    viewInteractionsByType = false;

    selectedPoint: string | null = null;

    selectedColourIndex: number | null = null;

    chartCenterX = 0;

    chartCenterY = 0;

    ageBands = [
        { min: 101, max: 200, label: '> 100' },
        { min: 91, max: 100 },
        { min: 81, max: 90 },
        { min: 71, max: 80 },
        { min: 61, max: 70 },
        { min: 51, max: 60 },
        { min: 41, max: 50 },
        { min: 31, max: 40 },
        { min: 19, max: 30 },
        { min: 0, max: 18, label: '< 19' },
    ];

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'modellerStats') || false as boolean;
    }

    get modellerStats (): Array<ModellerStats> {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'modellerStats');
    }

    get centerPos () {
        return {
            top: `calc(${this.chartCenterY}px + 1.5rem) !important`,
            left: `calc(${this.chartCenterX}px + 1.5rem) !important`,
        };
    }

    get totalModellerViews () {
        return this.modellerViewsTranformed?.filter((a) => a.name === this.selectedPoint || !this.selectedPoint)?.reduce((a, b) => a + (b.y ?? 0), 0);
    }

    get modellerViewsTranformed () {
        if (!this.modellerStats) return null;
        const totals: Record<string, number> = {};
        const counts: Record<string, number> = {};
        this.ageBands.forEach((ageBand) => {
            const key = ageBand.label ?? `${ageBand.min} - ${ageBand.max}`;
            if (!totals[key]) totals[key] = 0;
            if (!counts[key]) counts[key] = 0;

            this.modellerStats.forEach((date) => {
                date.ageGroupInteractions.forEach((section) => {
                    Object.keys(section.dataByAge).forEach((age) => {
                        if (Number(age) >= ageBand.min && Number(age) <= ageBand.max) {
                            totals[key] += section.dataByAge[age];
                            counts[key] += 1;
                        }
                    });
                });
            });
        });
        return Object.keys(totals)
            .filter((a) => totals[a])
            .map((seriesName) => ({
                name: seriesName,
                y: (totals[seriesName] ?? 0),
                color: ColourService.getSeriesColour('age', seriesName),
            }));
    }

    pointClicked (point: Point) {
        this.selectedColourIndex = point.colorIndex;
        this.selectedPoint = point.name === this.selectedPoint ? null : point.name;
        this.$emit('pointSelected', { category: 'modellerInteractions', series: this.selectedPoint });
    }

    formatNumber (number?: string | number) {
        return Math.round(Number(number ?? 0)).toLocaleString('en');
    }

    clearFilter () {
        (this.$refs.chart as unknown as { chart: Highcharts.Chart }).chart.series[0].points.forEach((a) => a.select(false));
        this.selectedPoint = null;
        this.$emit('pointSelected', { category: 'modellerInteractions', series: this.selectedPoint });
    }

    get chartOptions (): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return {
            title: {
                text: '',
            },
            chart: {
                height: 444,
                type: 'pie',
                backgroundColor: 'transparent',
                events: {
                    load () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                    redraw () {
                        const series = this.series[0] as unknown as { center: Array<number> };
                        const x = this.plotLeft + series.center[0];
                        const y = this.plotTop + series.center[1];
                        self.chartCenterX = x;
                        self.chartCenterY = y;
                    },
                },
            },
            xAxis: {
                labels: {
                    formatter: ((that) => dayjs(that.value).format('DD MMM')),
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat, sans-serif',
                    },
                },
            },
            legend: this.showLegend ? PieLegend.get().legend : {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    allowPointSelect: true,
                    showInLegend: true,
                    borderWidth: 0,
                    point: {
                        events: {
                            legendItemClick: (event) => {
                                event.preventDefault();
                                event.target.select(!event.target.selected);
                                this.pointClicked(event.target);
                            },
                        },
                    },
                },
            },
            series: [{
                type: 'pie',
                name: 'Modeller interactions',
                innerSize: '70%',
                slicedOffset: 5,
                data: this.modellerViewsTranformed!,
                events: {
                    click: (event) => { this.pointClicked(event.point); },
                },
            }],
        };
    }
}
