export default class DataUpdateStats {
    fromDate!: Date;

    toDate!: Date;

    updates!: number;

    dataByType!: Record<string, number>;

    dataByStatus!: Record<string, number>;

    dataByAge!: Record<string, number>;
}
