import Guid from 'common/util/guid';

export default class UserTeamAssignment {
    fromSync!: boolean;

    userId!: Guid;

    teamId!: Guid;

    teamName!: string;
}
