
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import ToggleSwitch from 'common/components/toggle-switch.vue';
import MemberRegistrations from '@/models/memberRegistrations';
import { MapOptions, MapChart } from '@lcp/map-chart';

@Component({ components: { ToggleSwitch } })
export default class MemberRegistrationsByLocation extends Vue {
    @Prop()
        clientId!: Guid;

    @Prop()
        showLegend!: boolean;

    @Prop()
        printing!: boolean;

    hoveredArea: string | null = null;

    selectedColourIndex: number | null = null;

    loaded = false;

    get loading () {
        return this.$store.getters['clientStatistics/isLoading'](this.clientId, 'memberRegistrations') || false as boolean;
    }

    get mapData () {
        if (!this.memberRegistrations) return {};
        const data: Record<string, number> = {};
        Object.keys(this.memberRegistrations.dataByLocation).forEach((key) => {
            const postcode = key.trim?.();
            data[this.postcodes.postcodesMap[postcode]] = (data[this.postcodes.postcodesMap[postcode]] ?? 0) + this.memberRegistrations!.dataByLocation[key];
        });
        return data;
    }

    get postcodes () {
        const map: Record<string, string> = {};
        const areaMap: Record<string, string> = {};
        this.$store.getters['clientStatistics/stats'](this.clientId, 'postcodes')
            .split('\r\n').forEach((row: string) => {
                const splitRow = row.split(',');
                map[splitRow[0]] = splitRow[1];
                areaMap[splitRow[1]] = splitRow[2];
            });
        return { postcodesMap: map, areaNamesMap: areaMap };
    }

    get mapOptions (): MapOptions {
        return {
            topoJsonSettings: {
                jsonPath: '/utla2.json',
                featureCollectionName: 'subunits',
                areaPropertyName: 'LAD21CD',
            },
            interactive: {
                allowPan: false,
                allowSelectArea: false,
                allowSelectRegion: false,
                allowZoom: false,
            },
            display: {
                colourRange: ['#90ddee', '#00a3c7', '#047b96'],
                gradient: true,
                gradientAmount: { hue: 0, saturation: 0, lightness: 10 },
                zoomAnimationDuration: 0,
                colourAnimationDuration: 0,
                rotateAnimationDuration: 0,
            },
            extents: {
                extentsFunction: () => [0, this.totalRegistrations! * 0.5, this.totalRegistrations],
            },
            events: {
                loading: (loading: boolean) => { this.loaded = !loading; },
                areaHovered: (area: string | null) => { this.hoveredArea = area; },
            },
        };
    }

    get memberRegistrations (): MemberRegistrations | null {
        return this.$store.getters['clientStatistics/stats'](this.clientId, 'memberRegistrations');
    }

    get totalRegistrations () {
        return this.memberRegistrations?.totalRegistrations ?? 0;
    }

    get abroadColour () {
        return this.loaded && (this.$refs.map as MapChart)?.getValueColour(this.mapData.undefined);
    }

    areaName (areaCode: string) {
        return this.postcodes.areaNamesMap[areaCode]?.replace('&#44;', ',');
    }

    areaValue (areaCode: string | undefined) {
        return this.mapData[areaCode!];
    }

    formatNumber (number: string | number) {
        return Number(number ?? 0).toLocaleString('en');
    }
}
